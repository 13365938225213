import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CustomerAddressForm from './CustomerAddressForm';
import { Button } from '@mui/material';

export default function CustomerClientsForm({
  setter = function test(){console.log("algo falló mijo")} , 
  typeHook={error:"error en el hook de cliente"} 
}) {

  
  let setOrg = setter
  let org = typeHook 

  const [client, setClient] = React.useState({Name:"",RFC:"",Addresses:[]})

  const handleChange = (e) =>{
    setClient({
      ...client,
      [e.target.id]:e.target.value
    }) 
  }

  const handlesaveCustomer = () =>{


    if(client.Name !== "" && client.RFC !== "" && client.Addresses !== []){
      let customersArr = [client]
      setOrg({...org,Customers:customersArr})
    }

  }


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Añadir Cliente
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="Name"
            name="name"
            label="Nombre del Cliente"
            fullWidth
            autoComplete="root"
            variant="standard"
            onChange = {handleChange}
            value={client.Name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="RFC"
            name="rfc"
            label="RFC"
            fullWidth
            autoComplete="RFC"
            variant="standard"
            onChange = {handleChange}
            value={client.RFC}
          />
        </Grid>
      </Grid>
      <CustomerAddressForm type="Cliente" setter={setClient} typeHook={client}/>
      {client.Addresses?.length > 0 && <Button onClick={handlesaveCustomer} variant="contained">Guardar cliente</Button>}
    </React.Fragment>
  );
}