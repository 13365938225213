import { createContext, useState } from "react";

export const NavContext = createContext()

export const NavContextComponent = ({children}) => {
    const [open, setOpen] = useState(false)
    const [selectedAction, setSelectedAction] = useState("")
    const toggleDrawer = (open) => {
        if(open === undefined){
            setOpen(!open)
            return
        }
        setOpen(open)
    }
    
    return <NavContext.Provider value={{toggleDrawer, open, selectedAction, setSelectedAction}}>
        {children}
    </NavContext.Provider>
}