const initialActions = [
    {
        "Type":"admin",
        "Title":"Bienvenido:",
        "Actions":[
            {
                "path":"businessData",
                "label":"Ver datos del negocio",
            }
        ]
    },
    {
        "Type":"manager",
        "Title":"Bienvenido:",
        "Actions":[
            {
                "path":"invoices",
                "label":"Facturar servicio",
            }
        ]
    },
    {
        "Type":"coordinator",
        "Title":"Crear un nuevo servicio",
        "Actions":[
            {
                "label":"Nuevo servicio",
                "execute":() => window.location.assign("/:rol/service")
            },
            {
                "label":"Nuevo servicio",
                "execute":() => "console.log()"
            }, 
        ]
    },
    {
        "Type":"employee",
        "Title":"Acción disponible:",
        "Actions":[
            {
                "path":"consultInvoices",
                "label":"Consultar facturas"
            }
        ]
    },
]

export default initialActions
