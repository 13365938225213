//*React
import React, { useEffect, useState } from 'react'
//*App
import { useInvoiceContext } from '../../../../../hooks/useInvoiceContext'
//*MUI
import { Box, Autocomplete, Divider, Grid, Paper, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export const NominaDateTypeComponent = () => {
    const step = 1
    //*InvoiceContext
    const {NominaType, invoicesModelBySteps} = useInvoiceContext()  
    
    //*dataModels
    const NominaModel = invoicesModelBySteps.find(object => object.Nomina).Nomina

    const [dates, setDates] = useState({
        starts:NominaModel[step]["Payroll"]["InitialPaymentDate"],
        final:NominaModel[step]["Payroll"]["FinalPaymentDate"],
        pay:NominaModel[step]["Payroll"]["PaymentDate"]
    })
    const [daysValue, setDaysValue] = useState(NominaModel[step]["Payroll"]["DaysPaid"])
    const [isDaysValueChange,setIsDaysValueChange] = useState(false)
    const [cfdiType, setCfdiType] = useState(NominaModel[step]["Payroll"]["Type"])
    

    //*Date ISO formatter
    const toIsoHelper = (date) => {
        var pad = function(num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds())
    }
    
    const handleDaysValue = (e) => {
        setDaysValue(Number(e.target.value))
        setIsDaysValueChange(true)
    }

    useEffect(() => {
        //*Si el valor de isDaysValueChange es false, entonces es el primer render, o es un re-render que no corresponde al cambio en los días
        if(isDaysValueChange){
            setDates((dates) => {
                return ({
                    ...dates,
                    final:new Date(dates.starts.getFullYear(),dates.starts.getMonth(),dates.starts.getDate()+daysValue-1)
                })
            })
        }
    },[daysValue, isDaysValueChange])

    useEffect(() => {
        console.log(dates)
        let dateFormated = toIsoHelper(new Date(dates.final))
        NominaModel[step]["Payroll"]["FinalPaymentDate"] = dateFormated
    }, [dates, NominaModel])

    useEffect(() => {
        console.log(dates.starts)
        let dateFormated = toIsoHelper(new Date(dates.starts))
        NominaModel[step]["Payroll"]["InitialPaymentDate"] = dateFormated
    },[dates.starts, NominaModel])

    useEffect(() => {
        console.log(dates.pay)
        let dateFormated = toIsoHelper(new Date(dates.pay))
        NominaModel[step]["Payroll"]["PaymentDate"] = dateFormated
    },[dates.pay, NominaModel])
  return (
    <div>
        <Box sx={{width:"100%",display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
            {false ? <><Typography variant="h4" sx={{fontWeight:"1.8vh", margin:"2vh"}}>
                Tipo de nómina y fechas
            </Typography>

            <Autocomplete
                sx={{width:"50%", margin:"2vh"}}
                options={NominaType}
                getOptionLabel={(option) => {
                    switch(option){
                        case "O":
                            return "Ordinaria"
                        case "E":
                            return "Extraordinaria"
                        default:
                            break;
                    }
                }}
                renderInput={(params) => {
                return (
                    <TextField
                    {...params}
                    sx={{width:"100%"}}
                    label="Tipo de nómina"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment:(
                            <React.Fragment>
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                    />
                )
                }}
                value={cfdiType}
                onChange={(e,v,r) => {
                    switch(r){
                        case "selectOption":
                            setCfdiType(v)
                            NominaModel[step]["Payroll"]["Type"] = v
                            break;
                        default:
                            break;
                    }
                }}
            /></>:null}
            

            <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", margin:"2vh"}}>
                <TextField 
                label="Días pagados" 
                value={daysValue}
                sx={{width:"13%", marginRight:"1vw"}}
                type="number"
                onChange={handleDaysValue}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        id="d_date"
                        renderInput={(props) => {
                            return <TextField 
                                    {...props}
                                    sx={{marginRight:"1vw", width:"25%"}}
                                    />
                        }}
                        label="Fecha inicial del pago"
                        value={dates.starts}
                        onChange={(newValue) => {
                            //*Esto es necesario porque al convertir el valor de la fecha en ISO, cambia la zona horaria por automatico
                            let dateFormated = toIsoHelper(new Date(newValue))
                            setDates({
                                ...dates,
                                starts:newValue,
                                final:new Date(newValue.getFullYear(),newValue.getMonth(),newValue.getDate()+daysValue-1)
                            })
                            NominaModel[step]["Payroll"]["InitialPaymentDate"] = dateFormated
                        }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disabled
                        id="d_date"
                        renderInput={(props) => {
                            return <TextField 
                                    {...props}
                                    sx={{marginLeft:"", width:"25%"}}
                                    />
                        }}
                        label="Fecha final del pago"
                        value={dates.final}
                        onChange={(newValue) => {
                            //*Esto es necesario porque al convertir el valor de la fecha en ISO, cambia la zona horaria por automatico
                            let dateFormated = toIsoHelper(new Date(newValue))
                            setDates({
                                ...dates,
                                final:dateFormated
                            })
                        }}
                    />
                </LocalizationProvider>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    id="d_date"
                    renderInput={(props) => {
                        return <TextField 
                                {...props}
                                sx={{width:"25%"}}
                                />
                    }}
                    label="Fecha del pago"
                    value={dates.pay}
                    onChange={(newValue) => {
                        //*Esto es necesario porque al convertir el valor de la fecha en ISO, cambia la zona horaria por automatico
                        // let dateFormated = toIsoHelper(new Date(newValue))
                        setDates({
                            ...dates,
                            pay:newValue
                        })
                    }}
                />
            </LocalizationProvider>
        </Box>
    </div>
  )
}

//*Date ISO formatter
export const toIsoHelper = (date) => {
    var pad = function(num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds())
}

export const NominaDateWithType = ({
    setFechas=()=>{
        console.log("set fechas component")
    },
    fechasState={
        PaymentDate: "",
        InitialPaymentDate: "",
        FinalPaymentDate: "",
        DaysPaid: "",
    },
    defaultDays=7

}) =>{

    const step = 1
    //*InvoiceContext
    const {NominaType, invoicesModelBySteps} = useInvoiceContext()  
    
    //*dataModels
    const NominaModel = invoicesModelBySteps.find(object => object.Nomina).Nomina

    

    const daysToMonday = () => {
        const monday = 1
        
        const today = new Date()
    
        const todayInNumber = today.getDay()
    
        return todayInNumber-monday
    }
    
    const initialDate = () => {
        return new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()-daysToMonday())
    }
    const [daysValue, setDaysValue] = useState(defaultDays)

    const [dates2, setDates2] = useState({
        starts:NominaModel[step]["Payroll"]["InitialPaymentDate"],
        final:NominaModel[step]["Payroll"]["FinalPaymentDate"],
        pay:NominaModel[step]["Payroll"]["PaymentDate"],
    })
    const [dates, setDates] = useState({
        starts:initialDate(),
        final:new Date(initialDate().getFullYear(), initialDate().getMonth(), initialDate().getDate()+(daysValue-1)),
        pay:new Date(),
        days:7
    })
    const [isDaysValueChange,setIsDaysValueChange] = useState(false)
    const [cfdiType, setCfdiType] = useState(NominaModel[step]["Payroll"]["Type"])
    

    
    
    const handleDaysValue = (e) => {
        setDaysValue(Number(e.target.value))
        setIsDaysValueChange(true)
    }

    useEffect(() => {
        //*Si el valor de isDaysValueChange es false, entonces es el primer render, o es un re-render que no corresponde al cambio en los días
        if(isDaysValueChange){
            setFechas({...fechasState, DaysPaid:daysValue})
            setDates((dates) => {
                return ({
                    ...dates,
                    final:new Date(dates.starts.getFullYear(),dates.starts.getMonth(),dates.starts.getDate()+daysValue-1)
                })
            })
        }
    },[daysValue, isDaysValueChange])

    useEffect(() => {
        console.log(dates)
        let dateFormated = toIsoHelper(new Date(dates.final))
        setFechas({...fechasState, FinalPaymentDate:dateFormated})
        //NominaModel[step]["Payroll"]["FinalPaymentDate"] = dateFormated
    }, [dates.final])

    useEffect(() => {
        console.log(dates.starts)
        let dateFormated = toIsoHelper(new Date(dates.starts))
        setFechas({...fechasState, InitialPaymentDate:dateFormated})
        //NominaModel[step]["Payroll"]["InitialPaymentDate"] = dateFormated
    },[dates.starts, /*NominaModel*/])

    useEffect(() => {
        console.log(dates.pay)
        let dateFormated = toIsoHelper(new Date(dates.pay))
        setFechas({...fechasState, PaymentDate:dateFormated})
        //NominaModel[step]["Payroll"]["PaymentDate"] = dateFormated
    },[dates.pay, /*NominaModel*/])

    useEffect(()=>{
        setFechas({
            PaymentDate: toIsoHelper(dates.pay),
            InitialPaymentDate: toIsoHelper(dates.starts),
            FinalPaymentDate: toIsoHelper(dates.final),
            DaysPaid: daysValue,

        })
    },[])

    

return(
                <Grid container spacing={1} columns={18} sx={{padding:2,alignItems:"stretch",height:"100%"}} component={Paper}>
                    <Grid item xs={18} >
                        <Divider variant="fullWidth" flexItem alignItems="left" textAlign='left'>
                            <Typography variant="h6"  color="secondary.dark" sx={{marginBottom:1}}>Periodo nominal</Typography>
                        </Divider>
                    </Grid>
                    <Grid item xs={4}>
                    <TextField 
                    variant='standard'
                    label="Días pagados" 
                    value={daysValue}
                    type="number"
                    onChange={handleDaysValue}
                    />

                    </Grid>
                    <Grid item xs={7}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                id="d_date"
                                label="Inicio"
                                slotProps={{ textField: { variant: 'standard'} }}
                                sx={{width:"100%"}}
                                value={dates.starts}
                                onChange={(newValue) => {
                                    //*Esto es necesario porque al convertir el valor de la fecha en ISO, cambia la zona horaria por automatico
                                    let dateFormated = toIsoHelper(new Date(newValue))
                                    setDates({
                                        ...dates,
                                        starts:newValue,
                                        final:new Date(newValue.getFullYear(),newValue.getMonth(),newValue.getDate()+daysValue-1)
                                    })
                                    NominaModel[step]["Payroll"]["InitialPaymentDate"] = dateFormated
                                }}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={7}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disabled
                                id="d_date"
                                slotProps={{ textField: { variant: 'standard'} }}
                                sx={{width:"100%"}}
                                label="Fin"
                                value={dates.final}
                                onChange={(newValue) => {
                                    //*Esto es necesario porque al convertir el valor de la fecha en ISO, cambia la zona horaria por automatico
                                    let dateFormated = toIsoHelper(new Date(newValue))
                                    setDates({
                                        ...dates,
                                        final:dateFormated
                                    })
                                }}
                            />
                        </LocalizationProvider>

                    </Grid>
                    
                    <Grid item xs={18}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="d_date"
                            slotProps={{ textField: { variant: 'standard'} }}
                            sx={{width:"100%"}}
                            label="Fecha del pago"
                            value={dates.pay}
                            onChange={(newValue) => {
                                //*Esto es necesario porque al convertir el valor de la fecha en ISO, cambia la zona horaria por automatico
                                // let dateFormated = toIsoHelper(new Date(newValue))
                                setDates({
                                    ...dates,
                                    pay:newValue
                                })
                            }}
                        />
                    </LocalizationProvider>

                    </Grid>
                </Grid>

                )
}
