import React, { useEffect, useState } from "react";
//*MUI Componentes
import {
  Container,
  Box,
  Button,
  CssBaseline,
  ThemeProvider,
  Typography,
  Stepper,
  Paper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { AlertDialog } from "../../../components/userFeedback/AlertDialog";
//*Network, api and routing
import CustomerFiscalForm from "./CustomerFiscalForm";
import CustomerAddressForm from "./CustomerAddressForm";
import CustomerSubcompaniesForm from "./CustomerSubcompaniesForm";
import { mapCustomerData } from "../../../services/createCustomer";
import { saveCustomer } from "../../../services/modules/organization/customers";
import { useNavigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import ferbyTheme from "../../../theme/ferbyTheme";
import { getRegimes } from "../../../services/modules/invoices/catalogs";

const steps = ["Información Fiscal", "Agregar Dirección"];

const theme = ferbyTheme;

export const CreateCustomer = () => {
  const subempresas = [];

  const { userid } = useAuth();

  const [subOrganizacion, setSubOrganizacion] = useState({});

  const [customer, setCustomer] = useState({
    RFC: "",
    Nombre: "",
    RegimenFiscal: {},
    Addresses: [],
    subEmpresas: [],
  });
  const [location, setLocation] = useState({
    Type: "",
    CP: "",
    Street: "",
    Number: "",
    State: "",
    Locality: "",
    Name: "",
  });
  const [regimenOptions, setRegimenOptions] = useState([]);

  const [willAddSubOrg, setWillAddSubOrg] = useState(false);
  const [willAddClient, setWillAddClient] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const [step, setStep] = useState(0);

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const handleSetCustomer = (e) => {
    if (step === 1) {
      subempresas.push({
        [e.target.id]: e.target.value,
      });
      return;
    }
    setCustomer({
      ...customer,
      [e.target.id]: e.target.value,
    });
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CustomerFiscalForm
            setter={setCustomer}
            typeHook={customer}
            regimenOptions={regimenOptions}
          />
        );
      case 1:
        return (
          <CustomerAddressForm
            type={"Cliente"}
            setter={setCustomer}
            typeHook={customer}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handleChange = (event) => {
    console.log("event", event);

    event.target.name === "subOrg"
      ? setWillAddSubOrg(!willAddSubOrg)
      : setWillAddClient(!willAddClient);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      subOrganizacion.RFC === "" ||
      subOrganizacion.Nombre === "" ||
      subOrganizacion.RegimenFiscal === {} ||
      subOrganizacion.Addresses === []
    ) {
      console.log("no se pase de erga, llene bien la suborg");
      //TODO alertarle que hay error
    } else {
      customer.subEmpresas.push(subOrganizacion);
      setCustomer({ ...customer });
    }
    let mappedCustomer = mapCustomerData(customer);

    saveCustomer(mappedCustomer, userid)
      .then((response) => {
        console.log(response);
        setDialog({
          ...dialog,
          open: true,
          title: "Guardado exitosamente",
          content: "Que deseas hacer?",
          actions: [
            {
              label: "Terminar",
              execute: () => navigate(`/${userid.claims.rol}/welcome`),
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
        setDialog({
          ...dialog,
          open: true,
          title: "Error guardando cliente",
          content: "Detalle de error: " + err.message,
          actions: [
            {
              label: "Reintentar",
              execute: () => setDialog({ ...dialog, open: false }),
            },
          ],
        });
      });
    /*switch(step){
            case 0:
                if(customer.Rfc !== "" && customer.Nombre !== "" && customer.RegimenFiscal !== {}){
                    setDialog({
                        ...dialog,
                        open:true,
                        title:"¿El cliente tiene subempresa?",
                        content:"Si el cliente tiene sub empresa, agrega sus datos.",
                        actions:[
                            {
                                label:"Continuar",
                                execute:() => {
                                    setDialog({
                                        ...dialog,
                                        open:false
                                    })
                                    setStep(step+2)
                                }
                            },
                            {
                                label:"Añadir sub-empresa",
                                execute:() => {
                                    //*Si el cliente tiene una subempresa, entonces deberá agregar los datos de la subempresa
                                    setStep(step+1)
                                }
                            }
                        ]
                    })
                    return
                }
                setDialog({
                    ...dialog,
                    open:true,
                    title:"No has completado el formulario",
                    content:"Completa todos los datos generales del cliente.",
                    actions:[{
                        label:"Ok",
                        execute:() => setDialog({...dialog, open:false})
                    }]
                })
                break;
            case 1:
                setCustomer({
                    ...customer,
                    subEmpresas:subempresas
                })
                break;
            case 2:
                setCustomer({
                    ...customer,
                    Direcciones:[
                        ...customer.Direcciones,
                        location
                    ]
                })
                break;
            default:
                break;
        }*/
  };

  const handleBack = () => {
    setWillAddClient(false);
    setWillAddSubOrg(false);
    setActiveStep(activeStep - 1);
  };

  const handleUpdateOpen = () => setDialog(!dialog.open);

  useEffect(() => {
    getRegimes(userid, userid)
      .then((data) => {
        if (data.status === 200) {
          setRegimenOptions(data.data);
          return;
        }
        setDialog({
          ...dialog,
          open: true,
          title: "Error obteniendo catálogo de regímenes fiscales",
          content: "La respuesta del servidor no es válida",
          actions: [
            {
              label: "Reintentar",
              execute: () => window.location.reload(),
            },
          ],
          keep: true,
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          setDialog({
            ...dialog,
            open: true,
            title: "Error obteniendo catálogo de regímenes fiscales",
            content: err.response.data.message,
            actions: [
              {
                label: "Reintentar",
                execute: () => window.location.reload(),
              },
            ],
            keep: true,
          });
        }
      });
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AlertDialog
          updateOpenState={handleUpdateOpen}
          open={dialog.open}
          title={dialog.title}
          content={dialog.content}
          actions={dialog.actions}
          keep={true}
        />
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Agregar cliente
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Has completado el registro.
                  </Typography>
                  <Typography variant="subtitle1">
                    Ahora empecemos a utilizar NOMBRE para todas tus nececidades
                    de Facturación
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  {activeStep === steps.length - 1 ? (
                    <Grid container spacing={3}>
                      <Grid item xs={6} sx={{ marginBottom: "4vh" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"subOrg"}
                              onChange={handleChange}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Añadir Sub-Organización"
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                  {!!willAddSubOrg && (
                    <>
                      <CustomerSubcompaniesForm
                        regimenOptions={regimenOptions}
                        setter={setSubOrganizacion}
                        typeHook={subOrganizacion}
                      />{" "}
                    </>
                  )}

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Anterior
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      onClick={
                        activeStep === steps.length - 1
                          ? handleSubmit
                          : handleNext
                      }
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1
                        ? "Registrar"
                        : "Siguiente"}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Container>
      </ThemeProvider>
    </>
  );
};
