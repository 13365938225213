import { indigo, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import * as Color from '@mui/material/colors';
import colors from "./colorimetria";

const ferbyTheme = createTheme({
    palette: {
      mode:"light",
        primary: {
          light:"#4f83cc",
          main: "#01579b",
          dark:"#002f6c"
        },
        secondary:{
          light:"#62efff",
          main: "#00bcd4",
          dark: "#008ba3"
        },
        gray:{
          light:Color.grey[500],
          main:Color.grey[700],
          dark:Color.grey[800]
        }
      },

    
})

export default ferbyTheme;