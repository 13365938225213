import axios from "axios";
const hosts = [
    "http://localhost:9999",
    "https://truckingsfervy.herokuapp.com/",
    "https://fervytest.herokuapp.com/",
    "https://fervystaging-b09417c2ba75.herokuapp.com/",
    "https://fervybeta-3d74f1d2d35d.herokuapp.com/",
    "https://beta.transportech.cloud",
    "https://staging.transportech.cloud",
    "https://app.transportech.cloud",
]
const version = 'api/v1'

let api = new axios.create({
    "baseURL":hosts[5],
    headers: {
        "Content-type": "application/json"
    },
    withCredentials:true
})

const apiObj = {api, version};
export default apiObj