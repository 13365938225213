import { useEffect, useState } from "react";
import {
  GeneralAddressForm,
  initialAddressState,
} from "../Places/ExpeditionPlaceForm";
import { validateRfc } from "../../../modules/invoices/utils/regexp";
import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const LoadingCompanyForm = ({
  handleSetCompany,
  handleCreateCompany,
  handleUpdateCompany,
  isEdition,
  editObj,
}) => {
  const [selected, setSelected] = useState({
    Type: "",
    Address: {},
    Rfc: "",
    TaxName: "",
  });
  const [addressState, setAddresState] = useState(initialAddressState);
  const [showAlert, setShowAlert] = useState(false);
  const [errorText, setErrorText] = useState({
    Rfc: null,
  });

  function validateCompany(obj) {
    console.log("objeto a validar", obj);
    const optionalProperties = [
      "Neighborhood",
      "Municipality",
      "Locality",
      "InteriorNumber",
    ];

    function isNullOrWhitespace(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "")
      );
    }

    function validateProperties(obj) {
      for (const prop in obj) {
        if (optionalProperties.includes(prop)) {
          continue;
        }

        const value = obj[prop];

        if (typeof value === "object") {
          if (!validateProperties(value)) {
            return false;
          }
        } else if (isNullOrWhitespace(value)) {
          return false;
        }
      }
      return true;
    }

    return validateProperties(obj);
  }

  const handleSelect = (destination) => {
    if (handleSetCompany) handleSetCompany(destination);
    return;
  };

  const handleUpdate = (destination) => {
    if (handleUpdateCompany) handleUpdateCompany(destination);
    return;
  };

  const handleSaveAndAddDestination = (save = false, add = false) => {
    let CompanyProposal = { ...selected, Address: addressState };

    if (validateCompany(CompanyProposal)) {
      if (!isEdition) {
        if (save) handleRegister(CompanyProposal);
        if (add) handleSelect(CompanyProposal);
      } else {
        handleUpdate(CompanyProposal);
      }
    } else {
      setShowAlert(true);
    }
  };

  const handleRegister = (destination) => {
    if (handleCreateCompany) handleCreateCompany(destination);
  };

  const handleInput = (e, v, r) => {
    setSelected({ ...selected, [e.target.id]: e.target.value });
  };

  const handleRfc = (e, v, r) => {
    if (validateRfc(String(e.target.value).toUpperCase())) {
      setErrorText({ ...errorText, Rfc: null });
    } else {
      setErrorText({ ...errorText, Rfc: "Formato inválido" });
    }
    selected[e.target.id] = String(e.target.value).toUpperCase();
    setSelected({ ...selected });
  };

  useEffect(() => {
    console.log("is edition, obj", isEdition, editObj);
    if (isEdition) {
      setSelected(editObj);
      setAddresState(editObj.Address);
    }
  }, [isEdition, editObj]);

  return (
    <Grid container spacing={2} sx={{ paddingTop: "2vh" }}>
      <Grid item xs={12}>
        <TextField
          id="TaxName"
          label="Nombre Fiscal"
          onChange={handleInput}
          value={selected.TaxName}
          sx={{ width: "100%" }}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="Rfc"
          error={errorText.Rfc}
          label="RFC"
          value={selected.Rfc}
          onChange={handleRfc}
          sx={{ width: "100%" }}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel>Tipo de compañía</InputLabel>
          <Select
            label="Tipo de compañía"
            value={selected.Type}
            onChange={(e) => setSelected({ ...selected, Type: e.target.value })}
          >
            <MenuItem value="Almacenadora">Almacenadora</MenuItem>
            <MenuItem value="CEDIS">CEDIS</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <GeneralAddressForm
          formData={addressState}
          setFormData={setAddresState}
        />
      </Grid>
      {showAlert && (
        <Alert severity="error">
          Asegúrate de que se completaron todos los campos
        </Alert>
      )}

      <Grid
        item
        xs={6}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {handleSetCompany !== undefined && (
          <Button
            variant="contained"
            color="primary"
            onClick={(e, v, r) => handleSaveAndAddDestination(false, true)}
          >
            Sólo Añadir
          </Button>
        )}
      </Grid>

      <Grid
        item
        xs={6}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={(e, v, r) =>
            handleSaveAndAddDestination(
              true,
              handleSetCompany !== undefined ? true : false
            )
          }
        >
          {handleSetCompany !== undefined ? "Guardar y Añadir" : "Guardar"}
        </Button>
      </Grid>
    </Grid>
  );
};

export { LoadingCompanyForm };
