import React, { createContext, useEffect, useState } from 'react'
import auth from '../firebase.js'
import {
    getAuth, 
    createUserWithEmailAndPassword, 
    signOut, 
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    setPersistence,
    browserSessionPersistence,
} from 'firebase/auth'

const context = createContext()

const AuthContext = ({children}) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [userid, setUserid] = useState("")
    const intervalRef = React.useRef()
    const signUp = (email, pass) => 
        // let role = window.location.pathname
        createUserWithEmailAndPassword(auth,email,pass)
    
    const logout = () => 
        signOut(auth)

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider()
        return signInWithPopup(auth, provider)
    }

    const isValidCredential = (credential) => {
        console.log(credential)
        
        const responseModel = {
            isValid:false,
            reason:"Unverified validation"
        }
        
        const emailVerified = credential.user.emailVerified

        if(!emailVerified){
            responseModel.isValid = !emailVerified
            responseModel.reason = "Email no verificado"
        }
        
        return responseModel
    }

    const refreshUserId = () => {
        if(user){
            auth.currentUser.getIdTokenResult(false)
            .then(token => {
                if (token != userid){
                    setUserid(token)
                }
            })
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user)
            setLoading(false)
            console.log(user)
        })

        return () => unsubscribe()
    },[])

    useEffect(() => {
        if(user){
            auth.currentUser.getIdTokenResult(false)
            .then((token) =>{
                setUserid(token)
                setPersistence(auth, browserSessionPersistence);
            })    
        }
        intervalRef.current = setInterval(refreshUserId, 1000 * 60 * 5)

        return () => {
            if (intervalRef.current){
                clearInterval(intervalRef.current)
            }
        }
        
        
    },[user])


    return (
        <context.Provider value={{signUp, logout, user,userid, loading, googleSignIn, isValidCredential}}>
            {children}
        </context.Provider>
    )

    
}

export {AuthContext, context}
