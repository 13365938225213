import { API_CONTEXT } from "../invoices/invoices"

export const saveCustomer = (body, user, credential) => {
    const saveCustomerPromise = API_CONTEXT("organization","saveCustomer");
    return saveCustomerPromise(body, user)
}

export const searchCustomerByName = (user, credential, nameString, searchField) => {
    const getCustomersPromise = API_CONTEXT("customers", "searchName")
    
    return getCustomersPromise(user, credential, nameString, searchField)
}

export const deleteCustomer = (user, credential,id,body) => {
    const saveCustomerPromise = API_CONTEXT("organization","deleteCustomer");
    return saveCustomerPromise(user, credential, id, body)
}