import React, { useState } from "react";
//*Icons
import EditIcon from "@mui/icons-material/Edit";
//*Mui components
import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import useAuth from "../../../../../../hooks/useAuth";
//*App components
import { AlertDialog } from "../../../../../../components/userFeedback/AlertDialog";
import { useNavigate } from "react-router";
import { deleteIssuer } from "../../../../../../services/modules/invoices/issuers";

export const EditComponent = ({ params }) => {
  const [modal, setModal] = useState({
    open: false,
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  const [alert, setAlert] = useState({
    open: false,
    title: "",
  });

  const { userid } = useAuth();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setModal({
      ...modal,
      open: false,
    });
  };

  const handleEdit = () => {
    setModal({
      ...modal,
      open: true,
    });
  };

  const handleUpdateDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const handleRedirectToCSD = () => {
    navigate(
      `/${userid.claims.rol}/invoices/ActualizarCSD?Rfc=${params.row.Rfc}`
    );
  };

  const handleDeleteIssuer = () => {
    deleteIssuer(userid, userid, params.row.Rfc)
      .then((res) => {
        console.log("poner diálogo de éxito con mensajes", res);
        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "Emisor Eliminado",
            content: "El emisor se ha eliminado correctamente",
            actions: [
              {
                label: "Continuar",
                execute: () => window.location.reload(),
              },
              {
                label: "Ir a Home",
                execute: () => navigate(`/${userid.claims.rol}/welcome`),
              },
            ],
          };
        });
      })
      .catch((err) => {
        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "Error",
            content:
              "Ocurrió un error al intentar eliminar el emisor, intente nuevamente",
            actions: [
              {
                label: "Ok",
                execute: () => window.location.reload(),
              },
            ],
          };
        });
      });
  };

  return (
    <div>
      <IconButton onClick={handleEdit}>
        <EditIcon />
      </IconButton>

      <AlertDialog
        updateOpenState={handleUpdateDialog}
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />

      <Modal open={modal.open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            background: "white",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            width: 400,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleRedirectToCSD}
            sx={{ marginY: "1.5vh", width: "50%" }}
            variant="contained"
          >
            Actualizar CSD
          </Button>
          <Button
            onClick={handleDeleteIssuer}
            sx={{
              marginY: "1.5vh",
              width: "50%",
              background: "red",
              color: "white",
            }}
            variant="contained"
          >
            Eliminar emisor
          </Button>
          {alert.open ? (
            <Alert open={alert.open} onClose={handleCloseAlert}>
              <AlertTitle>{alert.title}</AlertTitle>
            </Alert>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};
