import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function UserForm({
                /*
                Required props to call the component
                */  
                state={error:"error en el hook de cliente"},
                setState = function test(){console.log("algo falló mijo")} 
              }) 
{

  //States
  const [isSamePassword, setIsSamePassword ] = React.useState(false)


  //Prop manipulation
  const handleBuildUserObject = (e) => {
    setState({
      ...state,
      [e.target.id]:e.target.value
    })
  }

  //Component methods
  const validateSamePassword = (e) =>{
    if(state.password === e.target.value)
      setIsSamePassword(true) 
      else
      setIsSamePassword(false) 

      
    }
  
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Create ROOT User
      </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              onChange={handleBuildUserObject}
              required
              id="name"
              name="name"
              label="Nombre/s"
              fullWidth
              autoComplete="root"
              variant="standard"
              />
            <TextField
              onChange={handleBuildUserObject}
              required
              id="lname"
              name="Last name"
              label="Apellido/s"
              fullWidth
              autoComplete="root"
              variant="standard"
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              onChange={handleBuildUserObject}
              required
              id="email"
              name="email"
              label="E-Mail"
              fullWidth
              autoComplete="username@isinvoice.com"
              variant="standard"
              />
          </Grid>
          <Grid item xs={12}  md={6}>
            <TextField
              onChange={handleBuildUserObject}
              required
              id="phone"
              name="phone"
              label="Phone"
              fullWidth
              autoComplete="+16192555444"
              variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleBuildUserObject}
              required
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={validateSamePassword}
              required
              type="password"
              id="password2"
              name="password2"
              label="Confirm Password"
              fullWidth
              variant="standard"
            />
             {!!isSamePassword && <CheckCircleIcon />} 
          </Grid>
        </Grid>
    </React.Fragment>
  );
}