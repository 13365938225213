import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import {
  ServiceItemGeneric,
  TransportItem,
} from "../../../modules/services/utils/ServiceItemsStructures";
import {
  createDestination,
  getAllDestinations,
  getFilteredDestinations,
} from "../../../services/modules/services/destination";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { MerchForm } from "./ServiceMerch";
import { isSATCodesProductsInput } from "../../../modules/invoices/utils/regexp";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { toIsoHelper } from "../../../modules/invoices/issue/invoiceData/Nomina/NominaDateTypeComponent";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DynamicMerchTable } from "../CartaPorte/Products";
//icons
import ClearIcon from "@mui/icons-material/Clear";
import BusinessIcon from "@mui/icons-material/Business";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PlaceIcon from "@mui/icons-material/Place";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { LoadingCompanyForm } from "./LoadingCompanyForm";
import { OperatorServiceComponent } from "./ServiceOperator";
import { TransportServiceComponent } from "./ServiceVehicle";

const TransportItemForm = ({
  data,
  setData,
  options,
  dialog,
  setDialog,
  objectData,
  setObjectData,
  alert,
  setAlert,
}) => {
  let TransportServiceItemStruct = {
    ...ServiceItemGeneric,
    ...TransportItem,
  };

  const { userid } = useAuth();

  const [selected, setSelected] = useState(TransportServiceItemStruct);

  const [showField, setShowField] = useState({
    operator: false,
    vehicle: false,
    merch: false,
  });

  const hasObjectValue = (obj) => {
    return Object.keys(obj).length > 0;
  };

  const [productsInput, setProductsInput] = useState("");

  const handleSetLoadingCompany = (type, v) => {
    let key = "error";
    switch (type) {
      case "load":
        key = "CompanyLoad";
        break;
      case "unload":
        key = "CompanyUnload";
        break;

      default:
        break;
    }
    handleSetKey(key, v);
    setDialog({ ...dialog, open: false });
  };

  const handleCreateLoadingCompany = (destinationObj) => {
    createDestination(userid, destinationObj)
      .then((res) => {
        if (res.status === 201) {
          setDialog({
            ...dialog,
            open: true,
            title: "Éxito al crear la compañía",
            content: "",
            actions: [
              {
                label: "ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
          });
        }
      })
      .catch((err) => {
        setDialog({
          ...dialog,
          open: true,
          title: "Ocurrió un error al crear la compañía",
          content: "",
          actions: [
            {
              label: "ok",
              execute: () => setDialog({ ...dialog, open: false }),
            },
          ],
        });
        console.log("error", err);
      });
  };

  const handleChangeServiceCode = (e, v, r) => {
    let targetValue = e.target.value;
    let id = "Code";

    switch (r) {
      case "selectOption":
        setObjectData({ ...objectData, Code: v });
        r = "x";
        targetValue = v.Value;
        setSelected({ ...selected, [id]: targetValue });
        setObjectData({ ...objectData, [id]: v });
        break;
      case "clear":
        setSelected({ ...selected, [id]: "" });
        setObjectData({ ...objectData, [id]: {} });
      default:
        setSelected({ ...selected, [id]: targetValue });
        break;
    }
  };
  const handleChangeSelection = (e, v, r) => {
    let id = e.target.id.split("-")[0];
    let targetValue = e.target.value;

    switch (id) {
      case "SubTotal":
        targetValue = parseFloat(e.target.value);
        setObjectData({ ...objectData, SubTotal: targetValue });
        break;
      case "Code":
        break;
      default:
        break;
    }

    switch (r) {
      case "selectOption":
        setSelected({ ...selected, [id]: v });
        break;
      case "clear":
        setSelected({ ...selected, [id]: {} });
        setObjectData({ ...objectData, [id]: {} });
      default:
        setSelected({ ...selected, [id]: targetValue });
        break;
    }
  };

  const handleSetKey = (key, v) => {
    setSelected({ ...selected, [key]: v });
  };

  const handleSetData = () => {
    let ServiceItemCopy = TransportServiceItemStruct;
    let keys = Object.keys(ServiceItemCopy);

    for (let index in keys) {
      if (
        selected[keys[index]] !== "" ||
        (typeof selected[keys[index]] === "object" &&
          Object.keys(selected[keys[index]]).length > 0)
      )
        ServiceItemCopy[keys[index]] = selected[keys[index]];
    }

    setData({ ...data, TransportServiceItem: ServiceItemCopy });
  };

  const handleCloseDialog = () => {
    setDialog({ ...dialog, open: false, title: "", content: "", actions: [] });
  };

  const handleMerchForm = () => {
    setDialog({
      ...dialog,
      open: true,
      title: (
        <Typography color={"primary"} variant="h6">
          Mercancía(s) a transportar
        </Typography>
      ),
      content: (
        <MerchForm
          merchObject={selected.Mercancias}
          setMerchObject={(v) => handleSetKey("Mercancias", v)}
          closeDialog={handleCloseDialog}
        />
      ),
      actions: [
        {
          label: "Cerrar",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
      keep: false,
      updateOpenState: (v) => setDialog({ ...dialog, open: v }),
    });
  };

  const handleDeleteProduct = (product) => {
    let tempArr = [...selected.Mercancias.Mercancia];
    let index = tempArr.indexOf(product);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }

    selected.Mercancias.Mercancia = tempArr;
    selected.Mercancias.NumTotalMercancias = tempArr.length;

    setSelected({ ...selected });
  };

  const handleNewLoadingCompany = (type) => {
    setDialog({
      ...dialog,
      open: true,
      title: "Nueva Compañía de Carga/Descarga",
      content: (
        <LoadingCompanyForm
          handleSetCompany={(v) => handleSetLoadingCompany(type, v)}
          handleCreateCompany={(v) => handleCreateLoadingCompany(v)}
        />
      ),
      actions: [
        {
          label: "Cancelar",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
      keep: false,
      updateOpenState: (v) => setDialog({ ...dialog, open: v }),
    });
  };

  const DisplayLoadingCompany = ({ company, type, handleClose }) => {
    let title = "Compañía";
    if (type) {
      if (type === "load") {
        title = "Carga en:";
      }
      if (type === "unload") {
        title = "Descarga en:";
      }
    }
    return (
      <>
        <Paper>
          <Grid container spacing={1} sx={{ padding: "1.5vh" }}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="h6" color={"primary"}>
                {title}
              </Typography>

              <IconButton onClick={handleClose} color="primary">
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <BusinessIcon color="primary" />
              <Typography variant="button" color="primary">
                <b>{company.Type}:</b> {company.TaxName}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <PlaceIcon color="primary" />
              <Typography variant="button">
                <b>Dirección:</b> {company.Address.Street} #
                {company.Address.ExteriorNumber} C.P: {company.Address.ZipCode}.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <ReceiptLongIcon color="primary" />
              <Typography variant="button">
                <b>RFC:</b> {company.Rfc}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Divider />
      </>
    );
  };

  const [loadingInput, setLoadingInput] = useState("");
  const [loadingCompaniesOptions, setLoadingCompaniesOptions] = useState([]);
  const [unloadingInput, setUnloadingInput] = useState("");
  const [unloadingCompaniesOptions, setUnloadingCompaniesOptions] = useState(
    []
  );

  const handleLoadingInput = (e, setInput, setter) => {
    setInput(e.target.value);
    if (e.target.value !== undefined && e.target.value.length > 3) {
      let filters = [
        {
          label: "TaxName",
          param: "TaxName",
          value: e.target.value,
        },
      ];
      getFilteredDestinations(userid, filters)
        .then((res) => {
          if (res.data.length > 0) {
            setter(res.data);
          } else {
            setAlert({
              ...alert,
              open: true,
              title: "Sin resultados",
              message:
                "No se encontraron coincidencias con los valores ingresados.",
              severity: "warning",
            });
          }
        })
        .catch((err) => {
          setAlert({
            ...alert,
            open: true,
            title: "Error",
            message: "Error al obtener resultados, intente nuevamente",
            severity: "error",
          });
        });
    }
  };

  useEffect(() => {
    handleSetData();
  }, [selected]);

  useEffect(() => {
    getAllDestinations(userid)
      .then((response) => {
        setLoadingCompaniesOptions(response.data);
        setUnloadingCompaniesOptions(response.data);
      })
      .catch((err) => {
        setAlert({
          ...alert,
          open: true,
          title: "Error",
          message: "Error al obtener las compañías de carga",
          severity: "error",
        });
      });

    if (data.TransportServiceItem) {
      setSelected(data.TransportServiceItem);
    }
  }, []);

  useEffect(() => {
    if (objectData.Operador?.firstName) {
      setShowField({ ...showField, operator: true });
    }
    if (objectData.Unidad?.type) {
      setShowField({ ...showField, vehicle: true });
    }
  }, [objectData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            id={"SubTotal"}
            label="Subtotal"
            onChange={(e, v, r) => handleChangeSelection(e, v, r)}
            variant="standard"
            sx={{ width: "100%" }}
            InputProps={{
              startAdornment: "$",
              sx: {
                "& input": {
                  textAlign: "right",
                },
              },
            }}
            placeholder={"0.00"}
            type="number"
            step="any"
            value={objectData.SubTotal ? objectData.SubTotal : null}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          {/* Código del servicio */}
          <Autocomplete
            autoComplete
            id="Code"
            options={options.productCodes}
            sx={{ width: "100%" }}
            clearText="Limpiar"
            onChange={handleChangeServiceCode}
            clearOnBlur={false}
            fullWidth={true}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Código del Servicio de Transporte"
                />
              );
            }}
            getOptionLabel={(option) => {
              if (option  == "") {
                return "";
              }
              if (isSATCodesProductsInput(productsInput)) {
                return option.Value;
              }
              return `${option.Name} - ${option.Value}`;
            }}
            isOptionEqualToValue={(option, value) => {
              if (value === "") {
                return true;
              }
              return option.Value === value.Value;
            }}
            value={objectData?.Code?.Value ? objectData.Code : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} sx={{ marginTop: "2vh" }}>
            <Grid item xs={12} sm={6}>
              {!hasObjectValue(selected.CompanyLoad) && (
                <Grid container spacing={2}>
                  <Grid item xs={10} md={10}>
                    <Autocomplete
                      id="CompanyLoad"
                      sx={{ minWidth: "100%" }}
                      options={loadingCompaniesOptions}
                      autoComplete
                      clearText="Limpiar"
                      onChange={(e, v, r) => {
                        switch (r) {
                          case "clear":
                            setSelected({ ...selected, CompanyLoad: {} });
                            break;
                          case "selectOption":
                            handleChangeSelection(e, v, r);
                            break;
                          default:
                            console.log("unrecognized reason");
                            break;
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            //error={error.place.failed}
                            //helperText={error.place.message}
                            value={loadingInput}
                            label="Lugar Carga"
                            onChange={(e) =>
                              handleLoadingInput(
                                e,
                                setLoadingInput,
                                setLoadingCompaniesOptions
                              )
                            }
                            name="Load"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      getOptionLabel={(option) => option.TaxName || ""}
                      renderOption={(props, option, state) => {
                        return <h4 {...props}>{`${option.TaxName}`}</h4>;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }

                        return option.TaxName === value.TaxName;
                      }}
                      value={
                        hasObjectValue(selected?.CompanyLoad)
                          ? selected.CompanyLoad
                          : ""
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <Tooltip title="Añadir nuevo destino">
                      <IconButton
                        variant="contained"
                        onClick={() => handleNewLoadingCompany("load")}
                        color="primary"
                      >
                        <AddLocationAltIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}

              {hasObjectValue(selected?.CompanyLoad) &&
                selected.CompanyLoad.Rfc && (
                  <Grid container spacing={1} columns={12}>
                    <Grid item xs={12}>
                      <DisplayLoadingCompany
                        company={selected.CompanyLoad}
                        type={"load"}
                        handleClose={() =>
                          setSelected({ ...selected, CompanyLoad: {} })
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: "2vh" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDateTimePicker
                          id="l_date"
                          label="Fecha y hora de carga"
                          value={new Date(selected.DateTimeLoad)}
                          onChange={(newValue) => {
                            setSelected({
                              ...selected,
                              DateTimeLoad: toIsoHelper(newValue),
                            });
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {!hasObjectValue(selected?.CompanyUnload) && (
                <Grid container spacing={2}>
                  <Grid item xs={10} md={10}>
                    <Autocomplete
                      id="CompanyUnload"
                      sx={{ minWidth: "100%" }}
                      options={unloadingCompaniesOptions}
                      autoComplete
                      clearText="Limpiar"
                      onChange={(e, v, r) => {
                        switch (r) {
                          case "clear":
                            setSelected({ ...selected, CompanyUnload: {} });
                            break;
                          case "selectOption":
                            handleChangeSelection(e, v, r);
                            break;
                          default:
                            console.log("unrecognized reason");
                            break;
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            //error={error.place.failed}
                            //helperText={error.place.message}
                            value={unloadingInput}
                            onChange={(e) =>
                              handleLoadingInput(
                                e,
                                setUnloadingInput,
                                setUnloadingCompaniesOptions
                              )
                            }
                            label="Lugar Descarga"
                            name="Unload"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      getOptionLabel={(option) => option.TaxName || ""}
                      renderOption={(props, option, state) => {
                        return <h4 {...props}>{`${option.TaxName}`}</h4>;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }

                        return option.TaxName === value.TaxName;
                      }}
                      value={
                        hasObjectValue(selected.CompanyUnload)
                          ? selected.CompanyUnload
                          : ""
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <Tooltip title="Añadir nuevo destino">
                      <IconButton
                        variant="contained"
                        onClick={() => handleNewLoadingCompany("unload")}
                        color="primary"
                      >
                        <AddLocationAltIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}

              {hasObjectValue(selected.CompanyUnload) &&
                selected.CompanyUnload.Rfc && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <DisplayLoadingCompany
                        company={selected.CompanyUnload}
                        type={"unload"}
                        handleClose={() =>
                          setSelected({ ...selected, CompanyUnload: {} })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "2vh" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDateTimePicker
                          id="u_date"
                          //renderInput={(props) => <TextField {...props} sx={{width:"100%"}}/>}
                          label="Fecha y hora de descarga"
                          value={new Date(selected.DateTimeUnload)}
                          onChange={(newValue) => {
                            setSelected({
                              ...selected,
                              DateTimeUnload: toIsoHelper(newValue),
                            });
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
        {/* Aditional Buttons */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              md={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                onClick={() =>
                  setShowField({
                    ...showField,
                    operator: !showField.operator,
                  })
                }
              >
                Añadir Operador
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              order={{ xs: 2, md: 1 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  setShowField({ ...showField, vehicle: !showField.vehicle })
                }
              >
                Añadir Unidad Vehicular
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              order={{ xs: 1, md: 2 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color={!showField.merch ? "primary" : "error"}
                onClick={() => {
                  handleMerchForm();
                }}
              >
                {"Añadir Mercancías"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* End Aditional Buttons */}

        {/* Optional fields in creation*/}
        {(showField.operator || selected.Operador) && (
          <Grid item xs={12}>
            <OperatorServiceComponent
              operatorsData={options?.operators}
              setOperators={(v) => handleSetKey("Operador", v)}
              selected={objectData.Operador ? objectData.Operador : ""}
              setSelected={(value) =>
                setObjectData({ ...objectData, Operador: value })
              }
            />
          </Grid>
        )}
        {showField.vehicle && (
          <Grid item xs={12}>
            <TransportServiceComponent
              options={{
                vehicles: options?.vehicles,
                remolques: options?.remolques,
              }}
              setVehiculo={(v) => handleSetKey("Unidad", v)}
              setRemolque={(v) => handleSetKey("Remolque", v)}
              selected={objectData}
              setSelected={(label, value) =>
                setObjectData({ ...objectData, [label]: value })
              }
            />
          </Grid>
        )}
        {selected.Mercancias.Mercancia.length > 0 && (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{ fontSize: "1.5ev", fontWeight: "bold" }}
            >
              Número total de mercancias: {selected.Mercancias.Mercancia.length}
            </Typography>
            <DynamicMerchTable
              data={selected.Mercancias.Mercancia}
              handleDelete={handleDeleteProduct}
            />
          </Grid>
        )}
        {/* End optional fields in creation */}
      </Grid>
    </>
  );
};

TransportItemForm.defaultProps = {
  formData: {},
  setFormData: () => {
    return;
  },
  options: {
    operators: [],
    vehicles: [],
    remolques: [],
    productCodes: [],
    loadingCompanies: [],
  },
};

export { TransportItemForm };
