import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { EditComponent } from "./components/EditComponent";
import useAuth from "../../../../../hooks/useAuth";
import { AlertDialog } from "../../../../../components/userFeedback/AlertDialog";
import { useNavigate } from "react-router";
import ferbyTheme from "../../../../../theme/ferbyTheme";
import { getIssuers } from "../../../../../services/modules/invoices/issuers";

const issuerColumns = [
  {
    field: "Nombre",
    headerName: "Nombre",
    width: 300,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "Rfc",
    headerName: "RFC",
    editable: true,
    width: 150,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "EmployerRegistration",
    headerName: "Registro empleador",
    width: 150,
    editable: true,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "_id",
    headerName: "Acciones",
    renderCell: (params) => {
      return <EditComponent params={params} />;
    },
    flex: 1,
    headerClassName: "header-table",
  },
];

export const ConsultIssuers = () => {
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  const [loading, setLoading] = useState(true);
  const [issuerRows, setIssuerRows] = useState([]);
  const { userid, credential } = useAuth();
  const navigate = useNavigate();

  const handleCloseDialog = (boolean) => {
    setDialog({
      ...dialog,
      open: boolean,
    });
  };

  useEffect(() => {
    const rowsToSet = [];

    getIssuers(userid, userid.token)
      .then((res) => {
        let issuersData = res.data.data;
        issuersData.forEach((element) => {
          rowsToSet.push({
            id: element._id,
            _id: element._id,
            Nombre: element.Name,
            Rfc: element.Rfc,
            EmployerRegistration: element.EmployerRegistration,
          });
        });
        setIssuerRows((ir) => rowsToSet);
        setLoading((l) => false);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          setLoading((l) => false);
          switch (err.response.status) {
            case 401:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo emisores",
                  content:
                    "No tienes autorización para consultar esta información, verifica que tienes conexión y tu sesión sigue activa.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Terminar",
                      execute: () => navigate(`/`),
                    },
                  ],
                };
              });
              break;
            case 400:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo emisores",
                  content:
                    "El servidor no ha comprendido lo que necesitas, por favor intenta de nuevo, o contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intenar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
            case 500:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR de servidor",
                  content:
                    "Hubo un error al obtener los datos de los emisores. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intenar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
            default:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo emisores",
                  content:
                    "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intenar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
          }
          return;
        }

        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "ERROR Obteniendo emisores",
            content:
              "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
            actions: [
              {
                label: "Recargar",
                execute: () => window.location.reload(),
              },
              {
                label: "Continuar",
                execute: () => navigate(`/`),
              },
            ],
          };
        });
      });
  }, [userid, navigate]);
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <AlertDialog
            updateOpenState={handleCloseDialog}
            open={dialog.open}
            title={dialog.title}
            content={dialog.content}
            actions={dialog.actions}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              "& .header-table": {
                backgroundColor: ferbyTheme.palette.primary.main,
                color: "white",
              },
            }}
          >
            <Typography variant="h4" sx={{ margin: "25px" }}>
              Control de emisores
            </Typography>
            <DataGrid
              sx={{ width: "60%" }}
              rows={issuerRows}
              autoHeight
              columns={issuerColumns}
              disableSelectionOnClick
            />
          </Box>
        </>
      )}
    </div>
  );
};
