import apiObj from "../../../axios"
import { MexicoFederalEntityKeysCatalog } from "../../../modules/invoices/utils/staticCatalogs";

const {api, version} = apiObj;

export const API_CONTEXT = (where, what) => {
    const catalog = {
        "invoiceTypes":(usr, cred) => api.get(`${version}/invoices/catalog/invoiceTypes`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "banks":(usr, cred) => api.get(`${version}/invoices/catalog/banks`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "fiscalRegimes":(usr,cred) => api.get(`${version}/invoices/catalog/fiscalRegimes`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "regimenTypes":(usr,cred) => api.get(`${version}/invoices/catalog/regimenTypes`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "paymentMethods":(usr, cred) => api.get(`${version}/invoices/catalog/paymentMethods`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "paymentForms":(usr, cred) => api.get(`${version}/invoices/catalog/paymentForms`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "paymentFrequencies":(usr, cred) => api.get(`${version}/invoices/catalog/paymentFrequencies`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "currencies":(usr, cred) => api.get(`${version}/invoices/catalog/currencies`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "uses":(usr, cred) => api.get(`${version}/invoices/catalog/uses`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "perceptions":(usr, cred) => api.get(`${version}/invoices/catalog/perceptions`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "deductions":(usr, cred) => api.get(`${version}/invoices/catalog/deductions`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "otherPayments":(usr, cred) => api.get(`${version}/invoices/catalog/otherPayments`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "productsOrServices":(usr,cred, query) => api.get(`${version}/invoices/catalog/ProductsOrServices?q=${query}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "units":(usr,cred, query) => api.get(`${version}/invoices/catalog/units?q=${query}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "postalCodes":(usr, cred, query) => api.get(`${version}/invoices/catalog/postalCodes?q=${query}`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "positionRisks":(usr, cred, query) => api.get(`${version}/invoices/catalog/positionRisks`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),  
        "contractTypes":(usr, cred, query) => api.get(`${version}/invoices/catalog/contractTypes`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),  
        "typesOfJourney":(usr, cred, query) => api.get(`${version}/invoices/catalog/typesOfJourney`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),  
        "federalEntityKeys":MexicoFederalEntityKeysCatalog,
        "transportServicesCodes":(usr, cred, query) => api.get(`${version}/invoices/productsServicesTransport/all`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),  
    }

    const issuers = {
        "expeditionPlc":(usr, cred) => api.get(`${version}/invoices/expeditionPlaces`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "deleteExpeditionPlc":(usr,id) => api.delete(`${version}/invoices/expeditionPlaces/${id}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "list": (usr, cred) => api.get(`${version}/issuers`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "save": (usr, cred, body) => api.post(`${version}/issuers`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "cert": (usr, cred, body, rfc) => api.patch(`${version}/issuers/cer/${rfc}`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "delete": (usr, cred, rfc) => api.delete(`${version}/issuers/${rfc}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "key": (usr, cred, body, rfc) => api.patch(`${version}/issuers/key/${rfc}`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "pac": (usr, cred, body, rfc) => api.post(`${version}/issuers/save-pac/${rfc}`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "search": (usr, cred, nameString, searchField) => api.get(`${version}/issuers`, {
            params: {
              [searchField]: nameString,
            },
            headers: {
              user: usr,
              authorization: usr.token,
            },
          }),
    }

    const employees = {
        "all":(usr, cred) => api.get(`${version}/organization/employees/all`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "new": (usr, cred,employeeJson) => api.post(`${version}/organization/employees/`,employeeJson,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "searchByKey": (usr, cred, keyString, valueString) => api.get(`${version}/organization/employees?${keyString}=${valueString}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "updateEmployee": (usr, cred, body) => api.put(`${version}/organization/employees`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "deleteEmployee": (usr, cred, rfc, body) => api.delete(`${version}/organization/employees/${rfc}`,{
            headers: {
                user: usr,
                authorization: usr.token
            },
            data: body,
        }),
    }

    const organization = {
        "customers": (usr, cred) => api.get(`${version}/customers/?isPagination=false`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "saveCustomer": (body,usr, cred) => api.post(`${version}/customers/`, body ,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "deleteCustomer": (usr, cred, id, body) => api.delete(`${version}/customers/${id}`,{
            headers:{
                user:usr,
                authorization:usr.token
            },
            data:body,
        }),
        "operators":(usr, cred) => api.get(`${version}/organization/employees/?Puesto=OPERADOR&isPagination=false`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "newPlace": (usr, cred, rfc, addressObj) => api.post(`${version}/address?rfc=${rfc}`,addressObj,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "activatePlace": (usr, cred, id, addressObj) => api.post(`${version}/address/saveInPac?addressId=${id}`,addressObj,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "emitidos":(usr, cred,pageNumber,filters) => api.get(`${version}/invoices?pageNumber=${pageNumber}&${filters.join('&')}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "analytics":(usr, cred,range,chunks,days,filters) => api.get(`${version}/invoices/analytics/chunks?${range.join('&')}${chunks?`&numberChunk=${chunks}`:`&daysByChunk=${days}`}&${filters.join('&')}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        
        "getLogo":(usr, cred) => api.get(`${version}/logos/`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "updateLogo":(usr, logo) => api.patch(`${version}/logos/upload`,logo,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        

    }

    const customers ={
        "searchName": (usr, cred, nameString, searchField) => api.get(`${version}/customers`, {
            params: {
              [searchField]: nameString,
            },
            headers: {
              user: usr,
              authorization: usr.token,
            },
          }),
    }

    const transports = {
        "vehicles":(usr, cred) => api.get(`${version}/vehicles/`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "remolques":(usr, cred) => api.get(`${version}/remolques/`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "getVehicle": (usr, cred, id ) => api.get(`${version}/vehicles/${id}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "getRemolque": (usr, cred, id) => api.get(`${version}/remolques/${id}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "newVehicle": (usr, cred, body) => api.post(`${version}/vehicles/`, body ,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "newRemolque": (usr, cred, body) => api.post(`${version}/remolques/`, body ,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "updateVehicle": (usr, cred, id, body) => api.put(`${version}/vehicles/${id}`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "updateRemolque": (usr, cred, id, body) => api.put(`${version}/remolques/${id}`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "deleteVehicle": (usr, cred, id,body) => api.delete(`${version}/vehicles/${id}`,{
            headers:{
                user:usr,
                authorization:usr.token
            },
            data:body,
        }),
        "deleteRemolque": (usr, cred, id, body) => api.delete(`${version}/remolques/${id}`,{
            headers:{
                user:usr,
                authorization:usr.token
            },
            data:body,
        }),

    }

    const issuing = {
        "parseXML":(usr,cred,complemento,empresa,formData) => api.post(`${version}/invoices/files/complementos/${complemento}/${empresa}`, formData ,{
            headers:{
                user:usr,
                authorization:usr.token,
                "Content-type":"multipart/form-data"
            }
        }),
        "build": (usr, cred,invoiceJson) => api.post(`${version}/invoices/preview`,invoiceJson, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "calculateTax": (usr, cred,taxObject) => api.post(`${version}/invoices/calculation/tax`,taxObject, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "sellar":(operation,complement)=>{
            switch(operation){
                case "nomina":
                    return (usr, cred,buildedModel, builded) => api.post(`${version}/invoices/sellar/nomina`, buildedModel, {
                        headers:{
                            authorization:usr.token,
                            user:usr
                        }
                    })
                case "cartaPorte":
                    return (usr, cred,buildedModel, builded) => api.post(`${version}/invoices/sellar/income/true/cartaPorte`, buildedModel, {
                        headers:{
                            authorization:usr.token,
                            user:usr
                        }
                    })
                case "pago":
                    return (usr, cred,buildedModel, builded) => api.post(`${version}/invoices/sellar/income/true/pago`, buildedModel, {
                        headers:{
                            authorization:usr.token,
                            user:usr
                        }
                    })
                case "facturaIngreso":
                    return (usr, cred,buildedModel, builded) => api.post(`${version}/invoices/sellar/income`, buildedModel, {
                        headers:{
                            authorization:usr.token,
                            user:usr
                        }
                    })
                default: throw new Error('Invalid operation type');
            }
            

        }
        ,
        "sellarNomina": (usr, cred,buildedModel, builded) => api.post(`${version}/invoices/sellar/nomina`, buildedModel, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "sellarIngresoComplemento": (usr, cred,buildedModel, builded) => api.post(`${version}/invoices/sellar/income/:complement/:complementType`, buildedModel, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "sellarIngreso": (usr, cred,buildedModel, builded) => api.post(`${version}/invoices/sellar/income/`, buildedModel, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "files": (usr,cred, fileType,invoiceId) => api.get(`${version}/invoices/files/invoice/${fileType}/${invoiceId}`, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "paid": (usr, cred, invoiceId) => api.patch(`${version}/invoices/paid/${invoiceId}`, {}, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "put": (usr, cred, invoiceId, data) => api.put(`${version}/invoices/${invoiceId}`, data, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        "cancel": (usr, cred, invoiceId, password) => api.patch(`${version}/invoices/cancel/${invoiceId}/${password}`, {id: invoiceId, motive:"01"}, {
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
    }
    
    const methods = {
        "catalog":      catalog,
        "issuers":      issuers,
        "organization": organization,
        "customers":    customers,
        "issuing":      issuing,
        "employees":    employees,
        "transports":   transports
    }
    
    return methods[where][what]
}

/**
 * 
 * @param {*} user that must have a rol in the actual context
 * @param {*} context NOT IMPLEMENTED but is thought as a way to build the invoice instance based on certain context  
 * @param {*} redirectFunction is the react router hook passed as param
 */
export const buildInstanceAndRedirect = (user,redirectFunction, context) => {
    redirectFunction(`/${user.claims.rol}/invoices/new`)
}


export const parseXMLCartaPorte = (usr, cred,company, formData) => {
    const parseXMLComplement = API_CONTEXT("issuing", "parseXML")

    return parseXMLComplement(usr, cred, "cartaPorte", company, formData)
}

export const buildModelForTimbrado = (user, credential, invoiceJson) => {
    const buildModel = API_CONTEXT("issuing", "build")

    return buildModel(user, credential, invoiceJson)
} 


export const issueInvoice = (user, credential, invoiceJson, builded,operation) => {
    const issueFunction = API_CONTEXT("issuing", "sellar")
    const issueFunction2 = issueFunction(operation)


    return issueFunction2(user, credential, invoiceJson, builded)
}
export const getInvoiceFiles = (user, credential,fileType, invoiceId) => {
    const fileFunction = API_CONTEXT("issuing", "files")
    return fileFunction(user, credential, fileType, invoiceId)
}

export const paidInvoice = (user, credential, invoiceId) => {
    const paidInvoiceFunction = API_CONTEXT("issuing", "paid")
    return paidInvoiceFunction(user, credential, invoiceId)
}

export const putInvoice = (user, credential, invoiceId, data) => {
    const paidInvoiceFunction = API_CONTEXT("issuing", "put")
    return paidInvoiceFunction(user, credential, invoiceId, data)
}

export const cancelInvoice = (user, credential, invoiceId, password) => {
    const cancelInvoiceFunction = API_CONTEXT("issuing", "cancel")
    return cancelInvoiceFunction(user, credential, invoiceId, password)
}