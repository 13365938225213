import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//*material icons
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import TimerIcon from "@mui/icons-material/Timer";
//*mui componentes
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Typography, Chip, CircularProgress } from "@mui/material";
//*api and auth
import api from "../../axios";
import useAuth from "../../hooks/useAuth";
import ferbyTheme from "../../theme/ferbyTheme";

const servicesColumns = [
  {
    field: "Status",
    headerName: "Status",
    minWidth: 100,
    flex: 1,
    headerClassName: "header-table",
    renderCell: ({ value }) => {
      let renderObject = {
        color: "",
        state: "",
        icon: "",
      };
      switch (value) {
        case "AceptadoSF":
          renderObject.color = "warning";
          renderObject.state = value;
          renderObject.icon = <PendingIcon />;
          break;
        case "En curso":
          renderObject.color = "primary";
          renderObject.state = value;
          renderObject.icon = <TimerIcon />;
          break;
        case "Completo":
          renderObject.color = "success";
          renderObject.state = value;
          renderObject.icon = <CheckCircleIcon />;
          break;
        default:
          break;
      }
      console.log(value);
      return (
        <Chip
          color={renderObject.color}
          label={renderObject.state}
          icon={renderObject.icon}
        />
      );
    },
  },
  {
    field: "id",
    headerName: "Service ID",
    minWidth: 100,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "Fecha",
    headerName: "Fecha",
    minWidth: 100,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "Empresa",
    headerName: "Empresa/cliente",
    minWidth: 100,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "Origen",
    headerName: "Origen",
    minWidth: 100,
    flex: 1,
    headerClassName: "header-table",
    renderCell: ({ value }) => {
      return (
        <Typography variant="body2">
          {" "}
          {value ? value.Calle : "No asignado"} No.{" "}
          {value ? value.Numero : "No asignado"}
        </Typography>
      );
    },
  },
  {
    field: "Destino",
    headerName: "Destino",
    minWidth: 100,
    flex: 1,
    headerClassName: "header-table",
    renderCell: ({ value }) => {
      return (
        <Typography variant="body2">
          {" "}
          {value ? value.Calle : "No asignado"} No.{" "}
          {value ? value.Numero : "No asignado"}
        </Typography>
      );
    },
  },
  {
    field: "Price",
    headerName: "Precio acordado",
    minWidth: 100,
    flex: 1,
    headerClassName: "header-table",
  },
];

const AdminServiceIndex = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const { userid } = useAuth();

  const handleNewService = () => {
    navigate(`/${userid.claims.rol}/services/new`);
  };
  useEffect(() => {
    const sessionServices = () => {
      const sessionServiceExists = window.sessionStorage.getItem("services");
      if (sessionServiceExists) {
        setRows([JSON.parse(sessionServiceExists).services]);
        return true;
      }
      return false;
    };

    if (sessionServices()) {
      setLoading(false);
      return;
    }

    const services = async () =>
      await api.get("/api/services/week", {
        headers: {
          Authorization: userid.token,
          user: userid.user_id,
        },
      });
    services()
      .then((res) => {
        setRows([res.data.data]);
        setLoading(false);
      })
      .catch((err) => {
        if (!err.response) {
          console.log("El servidor no esta disponible");
          return;
        }
        console.log(err.response);
      });
  }, [userid]);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "93vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", fontSize: "5vh", textAlign: "center" }}
        >
          Resumen semanal de servicios
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "80vw",
            justifyContent: { xs: "center", sm: "flex-end" },
            margin: "3vh",
          }}
        >
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            sx={{
              //color:"white",
              //background:"#C00000",
              boxShadow: 8,
              //borderRadius:"10px"
            }}
            onClick={handleNewService}
          >
            Nuevo servicio
          </Button>
        </Box>
        <Box
          sx={{
            width: { xs: "98%", sm: "80vw" },
            height: "60vh",
            "& .header-table": {
              backgroundColor: ferbyTheme.palette.primary.main,
              color: "white",
            },
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              getRowId={(values) => {
                return values.id;
              }}
              columns={servicesColumns}
              rows={rows[0]}
              disableSelectionOnClick
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default AdminServiceIndex;
