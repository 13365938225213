import useAuth from "../../../../hooks/useAuth";
import {
  AlertDialog,
  InitialDialogState,
} from "../../../../components/userFeedback/AlertDialog";
import { React, useState } from "react";
import { useNavigate } from "react-router";
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import {issueInvoice} from "../../../../services/modules/invoices/invoices"
import {getTypeOfInvoice} from "../../../../components/Previews/Getters"
import PostIssuingActions from "../../../../components/actions/PostIssuing";


const IssueButtonComponent = ({ cfdi, label }) => {
  const { userid } = useAuth();
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (v) => setDialog({ ...dialog, open: v }),
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const handleTimbrado = () => {
    setLoading(true)
    setDisabled(true)
    if (cfdi.GeneralModel.Date === "") {
      delete cfdi.GeneralModel.Date;
    }
    if (cfdi.GeneralModel.CfdiType.Type === "P") {
      delete cfdi.GeneralModel.Date;
      delete cfdi.GeneralModel.PaymentForm;
      delete cfdi.GeneralModel.PaymentMethod;
    }
    issueInvoice(userid, userid, cfdi, true, getTypeOfInvoice(cfdi))
      .then((response) => {
        console.log(response.data);
        setDialog({
          title: "Factura emitida",
          open: true,
          content: <>
          <Typography>La factura se ha emitido correctamente</Typography>
          <PostIssuingActions cfdi={{...cfdi, ...response.data}} type={getTypeOfInvoice(cfdi)}/>
          </>,
          actions: [
            {
              label: "Volver a inicio",
              execute: () => {
                window.localStorage.removeItem("invoiceModel");
                navigate(`/${userid.claims.rol}/welcome`);
              },
            },
          ],
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          console.log("err response", err.response);
          setDialog({
            open: true,
            title: "Error",
            content: `
            La factura no se pudo emitir, pero los datos fueron guardados para que puedas hacerlo más tarde. Visita la sección de facturas para emitirla.
    
            ${err.response.data.message}
            `,
            actions: [
              {
                label: "Reintentar",
                execute: () =>
                  handleTimbrado(
                    cfdi,
                    userid,
                    dialog,
                    setDialog,
                    loading,
                    setLoading
                  ),
              },
              {
                label: "Cerrar",
                execute: () => {
                  dialog.updateOpenState(false);
                  //navigate(`/${userid.claims.rol}/invoices/new`);
                },
              },
            ],
          });

          setLoading(false);
          setDisabled(false)
          return;
        }

        console.log(err)
        
      });
  };

  return (
    <>
      <Backdrop open={loading} />
      <AlertDialog {...dialog} />
      <Button disabled={disabled} variant="contained" onClick={handleTimbrado}>
        {loading? <CircularProgress size={"3vh"}/> : label}
      </Button>
    </>
  );
};

export { IssueButtonComponent };
