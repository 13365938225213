import apiObj from "../../../axios";

const { api, version } = apiObj;

export const createUser = (usr, cred, userFormData) => {
  return api.post(`${version}/users`, userFormData, {
    headers: {
      user: usr,
      authorization: usr.token,
    },
  });
};
