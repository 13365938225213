import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

export const AlertDialog = (props) => {
    const {updateOpenState,open,title, content, actions, keep} = props
    const handleClose = (e,r) => {
        if(r === "backdropClick" && keep){
            return
        }
        updateOpenState(false)
    }
  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {content}
            </DialogContentText>
        </DialogContent>
        
        <DialogActions>
            {actions?.map((act, key) => {
                return (
                    <Button key={key} variant='contained' onClick={act.execute}>
                        {act.label}
                    </Button>
                )
            })}
        </DialogActions>
    </Dialog>
  )
}

export const InitialDialogState = {
    open:false,
    keep:false,
    title:"",
    content:"",
    actions:[],
    updateOpenState:()=>{return}
}
