import React,{createContext, useState} from 'react'
import { ErrorComponent } from '../components/userFeedback/ErrorComponent'

//*Context is used to store an invoice state in order to help user retake the process of an invoice creation
//*Also is used for passing data through components in order to render steps, and update the invoice class

const Context = createContext()

const daysToMonday = () => {
    const monday = 1
    
    const today = new Date()

    const todayInNumber = today.getDay()

    return todayInNumber-monday
}

const initialDate = () => {
    return new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()-daysToMonday())
}

const InvoiceContext = ({children}) => {
    //*Invoice types, this done by saving this array instead of asking for catalogs because of network performance reasons
    const types = [
        "",
        "Factura - Ingreso",
        "Nota de crédito",
        "Nomina",
        "Carta Porte",
        "Complemento de pago",
        "Complemento Carta Porte 2.0"
    ]
    
    //*Nomina can be ordinary or extraordinary
    const NominaType = ["O", "E"]
    const daysPaid = 7



    //*Steps by invoice types
    const invoicesModelBySteps = [
        {
            Nomina:
            [{
                dataModel:{
                    "NameId":"16", 
                    "ExpeditionPlace":"",
                    "CfdiType": "N",
                    "PaymentForm": {
                        "Name": "Por definir",
                        "Value": "99"
                    },
                    "PaymentMethod": {
                        "Name": "Pago en una sola exhibición",
                        "Value": "PUE"
                    },
                    "Folio": "",
                    "Receiver": {
                        "Rfc": "",
                        "Name": "",
                        "CfdiUse": {
                            "Natural": true,
                            "Moral": true,
                            "Name": "Por definir",
                            "Value": "P01"
                        }
                    }
                },
                employeeDataModel:{
                    Id:"",
                    Rfc:"",
                    Nombre:"", 
                    ApellidoP:"", 
                    ApellidoM:"",
                    DomFiscal:{
                        CP:"",
                        Estado:"",
                        Calle:"",
                        Localidad:"",
                        Municipio:"",
                        NumExt:"",
                        NumInt:"",
                    },
                    RegimenFiscal:"",
                    CURP:"",
                    NSS:"",
                    FechaInicio:"",
                    Antiguedad:"",
                    TipoContrato:"",
                    Sindicato:Boolean,
                    TipoJornada:"",
                    TipoRegimen:"",
                    Dpto:"",
                    Puesto:"",
                    Licencia:"",
                    Periodicidad:"",
                    BancoClave:"",
                    Banco:"",
                    CtaBanco:"",
                    Sueldo:"",
                    ConfigStatus:{
                        progress:"",
                        lefttValues:""
                    }
                }
            },
            {
                "Payroll": {
                    "Type": NominaType[0],
                    "PaymentDate": new Date(),
                    "InitialPaymentDate": initialDate(),
                    "FinalPaymentDate": new Date(initialDate().getFullYear(), initialDate().getMonth(), initialDate().getDate()+(daysPaid-1)),
                    "DaysPaid": daysPaid,
                    "Issuer": {
                      "EmployerRegistration": "",
                      "FromEmployerRfc": ""
                    },
                }
                
            },
            {
                "Perceptions": {
                    "Details": [
                      {
                        "PerceptionType": {
                            "Name": "Sueldos, Salarios  Rayas y Jornales",
                            "Value": "001"
                        },
                        "Description": "Salario Diario",
                        "TaxedAmount": Number,
                        "ExemptAmount": Number
                      }
                    ]
                }
            },
            {
                "Deductions":{
                    "Details":[{
                        "DeduccionType": {
                            "Name":"Cuotas obrero patronales",
                            "Value":"021"
                        },
                        "Code": "Cuotas patronales",
                        "Description": "Seguridad Social",
                        "Amount": Number
                    }]
                }
            },
            {
                "OtherPayments":[
                    {
                        "OtherPaymentType": "",
                        "Code": "",
                        "Description": "",
                        "Amount": "",
                        "EmploymentSubsidy": {
                          "Amount": ""
                        }
                    }
                ]
            }
            ]
        }
    ]
    
    
    const [invoiceData, setInvoiceData] = useState(undefined)
    // const [nominaData, setNominaData] = useState({})
    
    
    const [issuer, setIssuer] = useState(undefined)
    
    
    const [invoiceType, setInvoiceType] = useState(undefined)
    
    
    const [instance, setInstance] = useState(undefined) 
    const [steps, setSteps] = useState([{label:"No invoice", component:<ErrorComponent message="No hay información para la factura, inténtelo de nuevo"/>}])
    // const {userid} = useAuth()
    
    // const createInstance = () => {
    //     setInstance(new Invoice())
    //     if(invoiceData !== undefined) {
    //         setInvoiceData(undefined)
    //     }
    // }
    
    const handleSetIssuer = (data) => {
        try{
            setIssuer({
                Nombre:data.Nombre,
                FiscalRegime:data.Reg.SATCode,
                FiscalRegimeName:data.Reg.Name,
                Rfc:data.Rfc,
                EmployerRegistration:data.EmployerRegistration,
                CURP:data.CURP,
                Folio:data.Folio
            })
        } catch(err) {
            console.log(err)
        }
    }

    

    return(
        <Context.Provider value={
            {
                invoiceData,
                types,
                invoiceType,
                setInvoiceType, 
                setInvoiceData, 
                issuer, 
                handleSetIssuer, 
                invoicesModelBySteps,
                NominaType,
                instance,
                setInstance,
                setSteps,
                steps
            }
        }>
            {children}
        </Context.Provider>
    )
}

export {InvoiceContext, Context}