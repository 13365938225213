export function toAntiguedadFormat(date, getWeeks  = false) {
    const today = new Date();
    const diff = today.getTime() - date.getTime();
  
    const millisecondsInDay = 86400000;
    const millisecondsInMonth = 2629746000;
    const millisecondsInYear = 31556952000;
    const millisecondsInWeek = 604800000;
  
    const days = Math.floor(diff / millisecondsInDay);
    const months = Math.floor(diff / millisecondsInMonth);
    const years = Math.floor(diff / millisecondsInYear);
    const weeks = Math.floor(diff / millisecondsInWeek);  
  
    if (getWeeks) {
      if (weeks > 0) {
        return weeks;
      }
    }
    
    if (years > 0) {
      const remainingMonths = Math.floor((diff - (years * millisecondsInYear)) / millisecondsInMonth);
      const remainingDays = Math.floor((diff - (years * millisecondsInYear) - (remainingMonths * millisecondsInMonth)) / millisecondsInDay);
      return `P${years}Y${remainingMonths}M${remainingDays}D`;
    } else if (months > 0) {
      const remainingDays = Math.floor((diff - (months * millisecondsInMonth)) / millisecondsInDay);
      return `P${months}M${remainingDays}D`;
    } else if (days > 0) {
      return `P${days}D`;
    } else {
      return "P0D";
    }
  }