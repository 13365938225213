import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Paper,
  Container,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditComponent from '../trucksControl/components/EditComponent';
import useAuth from '../../../hooks/useAuth';
import ferbyTheme from '../../../theme/ferbyTheme';
import { getAllEmployees, deleteEmployee } from '../../../services/modules/employees/employees';
import { formatDateDDMMYYYY } from '../../invoices/utils/dateManipulators';

export const EmployeesIndex = () => {
  const navigate = useNavigate();

  const { userid } = useAuth();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    content: '',
    actions: [],
  });

  const employeesColumns = [
    {
      field: 'Nombre',
      sortable: false,
      headerName: 'Nombre completo',
      headerClassName: 'header-table',
      headerAlign: 'center',
      flex: 2,
      align: 'center',
      renderCell: (params) =>
        `${params.row.Nombre} ${params.row.ApellidoP} ${params.row.ApellidoM}`,
    },
    {
      field: 'Rfc',
      Description: 'RFC del empleado',
      sortable: false,
      headerName: 'RFC',
      headerClassName: 'header-table',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
    },
    {
      field: 'Puesto',
      Description: 'El área de trabajo del empleado',
      sortable: false,
      headerName: 'Puesto',
      headerClassName: 'header-table',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
    },
    {
      field: 'Dpto',
      Description: 'Departamento del área',
      sortable: false,
      headerName: 'Dpto',
      headerClassName: 'header-table',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
    },
    {
      field: 'FechaInicio',
      Description: 'Fecha de inicio del contrato',
      sortable: false,
      headerName: 'Fecha de inicio',
      headerClassName: 'header-table',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params) => `${formatDateDDMMYYYY(params.value, false)}`,
    },
    {
      field: '_id',
      Description: 'Acciones de fila',
      sortable: false,
      headerName: 'Acciones',
      headerClassName: 'header-table',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        params.row.id = params.row._id; // id assignation to EditComponent
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/empleados/actualizar`}
            objName={'Empleado'}
            onDelete={() => deleteEmployees(params.row.Rfc)}
            rowParams={params.row}
          />
        );
      },
    },
  ];

  const deleteEmployees = (rfc) => {
    deleteEmployee(userid,userid,rfc,{})
      .then((response)=>{
        console.log(response)
        setDialog({
          ...dialog,
          open: true,
          title: "Empleado eliminado",
          content: "",
          actions: [{ label: "Ok", execute: () => window.location.reload() }],
        });
      })
      .catch((err)=>{
        console.log(err.response)
        setDialog({
            ...dialog,
            open: true,
            title: "Ocurrió un error al eliminar al empleado",
            content: "Inténtelo más tarde",
            actions: [{ label: "Ok", execute: () => window.location.reload() },{label:"Reintentar",execute:()=>deleteEmployees(rfc)}],
          });
      })
  };

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  };

  useEffect(() => {
    if (userid) {
      const getData = async () => {
        const employeesData = await getAllEmployees(userid, userid.token);
        try {
          if (employeesData.status === 200) {
            setLoading(false);
            setRows(employeesData.data);
          }
        } catch (err) {
          throw new Error(err);
        }
      };

      getData()
      .then(() => console.log('Todos los datos han sido obtenidos con éxito y se ha asignado estado a componente'))
      .catch(err => {
        console.log(err);
        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: 'Fallo al obtener a los empleados',
            content: "Error obteniendo los empleados, favor de volver a intentarlo mas tarde",
            actions: [
              {
                label: 'Recargar',
                execute: () => window.location.reload(),
              },
            ],
          };
        });
      });
    } else {
      setDialog((dialog) => {
        return {
          ...dialog,
          open: true,
          title: 'No tienes permiso',
          content:
            'No tienes usuario, por favor inicia sesión o recarga la página',
          actions: [
            {
              label: 'Recargar',
              execute: () => window.location.reload(),
            },
          ],
        };
      });
    }
  }, [userid]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Dialog open={dialog.open} onClose={handleCloseDialog}>
        <DialogTitle>{dialog.title}</DialogTitle>

        <DialogContent>{dialog.content}</DialogContent>

        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Container component='main' maxWidth='xl' sx={{ mb: 4 }}>
        <Paper
          variant='outlined'
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Grid container spacing={2}>
            <Grid item xs={9} display='flex' justifyContent={'left'}>
              <Typography variant='h4' align='center' color='primary.dark'>
                Empleados
              </Typography>
            </Grid>
            <Grid item xs={3} display='flex' justifyContent={'right'}>
              <Button
                variant='contained'
                color='secondary'
                sx={{ color: 'white' }}
                onClick={() =>
                  navigate(`/${userid.claims.rol}/empleados/crear`)
                }
              >
                Registrar empleado
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                '& .header-table': {
                  backgroundColor: ferbyTheme.palette.primary.main,
                  color: 'white',
                },
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <DataGrid
                  columns={employeesColumns}
                  rows={rows}
                  getRowId={(row) => row._id}
                  onSelectionModelChange={(params) => {
                    console.log(params);
                  }}
                  disableSelectionOnClick
                  disableColumnMenu
                  autoHeight
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};
