

export const ServiceItemGeneric = {
    SubTotal:0
}

export const ManeuverItem = {
    TipoManiobra:"Descarga",
    Empleados:[]
}

export const StayItem = {
    TipoEstadia:"",
    Tiempo:0,
    Riesgo:0,
    Code:"76122401"
}

export const ExtraItem ={
    Tipo:""
}

export const ReturnItem = {
    Tipo:"",
    Mercancia:[]
}

export const TransportItem ={
    Operador:"",
    Unidad:"",
    Remolque:"",
    DateTimeLoad:"",
    DateTimeUnload:"",
    Code:"",
    CompanyLoad:{},
    CompanyUnload:{},
    Mercancias:{Mercancia:[]}
}

export const TrackingStruct = {
    Unidad:{},
    Direccion:{},
    EstimatedDistance:0,
    FechaHoraPartida:"",
    FechaHoraResguardo:"",
    Route:""
}