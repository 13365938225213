import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { DisplayDragAndDrop, DisplayFilePreview } from "../../DragAndDrop/DragAndDrop";
import useAuth from "../../../hooks/useAuth";
import { uploadProductsCsv } from "../../../services/modules/services/products";
import { getProductsServices } from "../../../services/modules/invoices/catalogs";


const MerchForm = ({ merchObject, setMerchObject, closeDialog }) => {

  const { userid } = useAuth();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [csv, setCsv] = useState();
  const [showInputs, setShowInputs] = useState(false);

  //*the options of the autocomplete
  const [productsList, setProductsList] = useState([]);
  const [invoiceProducts, setInvoiceProducts] = useState(
    merchObject.Mercancia || []
  );

  const [inputProduct, setInputProduct] = useState({
    UnidadPeso: "",
    PesoBrutoTotal: "",
    Descripcion: "",
    BienesTransp: "",
    ClaveUnidad: "",
    Cantidad: 0,
    PesoEnKg: 0,
    ValorMercancia: 0,
    Moneda: "MXN",
  });
  const [alert, setAlert] = useState({
    show: false,
    state: "",
    message: "",
  });

  const handleSubmit = (e) => {
    //e.preventDefault()
    setLoadingCSV(true);
    if (inputProduct.UnidadPeso === undefined || inputProduct.UnidadPeso === "") {
      setAlert({
        show: true,
        state: "warning",
        message: "Completa los datos generales",
      });
      setLoadingCSV(false);
      return;
    } else {
      uploadProductsCsv(e, userid, userid)
        .then((response) => {
          const convertedArray = response.data.map((obj) => ({
            ...obj,
            Cantidad: Number(obj.Cantidad),
            PesoEnKg: Number(obj.PesoEnKg),
          }));

          //full array.concat(converted Array)
          merchObject.Mercancia = merchObject.Mercancia.concat(convertedArray);

          setInvoiceProducts(merchObject.Mercancia);
          // setItems(merchObject.Mercancia.length);
          setMerchObject({ ...merchObject });
          setLoadingCSV(false);
          closeDialog();
        })
        .catch((err) => {
          setAlert({
            ...alert,
            state: "error",
            show: true,
            message:
              "Hubo un problema al procesar el archivo. Intentalo de nuevo.",
          });
          setLoadingCSV(false);
          return;
        });
    }
  };

  const handleSearch = () => {
    setShowInputs(!showInputs);
  };

  const handleSubmitManualInput = (e) => {
    if (
      inputProduct.UnidadPeso &&
      inputProduct.Descripcion &&
      inputProduct.Cantidad &&
      inputProduct.PesoEnKg &&
      inputProduct.ClaveUnidad
    ) {
      const productModel = {
        Cantidad: inputProduct.Cantidad * 1,
        BienesTransp: inputProduct.BienesTransp,
        Descripcion: inputProduct.Descripcion,
        ClaveUnidad: inputProduct.ClaveUnidad,
        PesoEnKg: inputProduct.PesoEnKg * 1,
        ValorMercancia: 100,
        Moneda: "MXN",
      };

      merchObject.Mercancia.push(productModel);
      merchObject.UnidadPeso = inputProduct.UnidadPeso;
      merchObject.PesoBrutoTotal = inputProduct.PesoBrutoTotal * 1;
      merchObject.NumTotalMercancias = merchObject.Mercancia.length;

      setInvoiceProducts(merchObject.Mercancia);
      // setItems(merchObject.Mercancia.length);
      setMerchObject({ ...merchObject });

      setAlert({
        show: true,
        state: "success",
        message: "Item cargado exitosamente",
      });
    } else {
      setAlert({
        ...alert,
        show: true,
        state: "error",
        message:
          "No has completado los datos de la mercancía, por favor completa antes de agregar",
      });
    }
  };

  const validateNecessaryDataAndSetCSV = (file) => {
    if ( inputProduct.UnidadPeso === "") {
      setAlert({
        show: true,
        state: "warning",
        message: "Ingresa la unidad de peso",
      });
      setLoadingData(false);
      setLoadingCSV(false);
      return;
    } else {
      setCsv(file);
    }
  };

  const handleInputs = (e) => {
    if (e.target.id === "ClaveUnidad" || e.target.id === "Unidad") {
      setInputProduct({
        ...inputProduct,
        [e.target.id]: e.target.value.toUpperCase(),
      });
      return;
    }
    setInputProduct({ ...inputProduct, [e.target.id]: e.target.value });
  };

  const handleAutoComplete = (e, v, r) => {
    if (r === "selectOption") {
      setInputProduct({
        ...inputProduct,
        BienesTransp: v.Value,
        Descripcion: v.Name,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setAlert({
        ...alert,
        show: false,
      });
      return;
    }

    setAlert({
      ...alert,
      show: true,
    });
  };

  //Search product by query
  useEffect(() => {
    if (
      inputProduct.Descripcion !== undefined &&
      inputProduct.Descripcion.length > 3
    ) {
      setLoadingData(true);

      getProductsServices(userid, userid, inputProduct.Descripcion).then(
        (response) => {
          console.log(response.data);
          if (response.data.length > 0) {
            setProductsList(response.data);
            setLoadingData(false);
          }
        }
      );
    }
    return () => {
      setProductsList([]);
      setLoadingData(false);
    };
  }, [inputProduct.Descripcion]);

  useEffect(() => {
    if (
      inputProduct.UnidadPeso !== undefined 
    ) {
    }

    return () => {
    };
  }, [inputProduct.UnidadPeso]);

  useEffect(()=>{
    let pesoTotal = invoiceProducts.reduce((acc,obj)=>{return acc+obj.PesoEnKg},0)
    merchObject.PesoBrutoTotal=pesoTotal
    merchObject.NumTotalMercancias = invoiceProducts.length;
    setMerchObject({...merchObject})
  },[invoiceProducts])

  useEffect(() => {
    setInputProduct({ ...inputProduct, ValorMercancia: inputProduct.PesoEnKg });
  }, [inputProduct.PesoEnKg]);

  useEffect(() => {
    merchObject.UnidadPeso = inputProduct.UnidadPeso;
    merchObject.PesoBrutoTotal = inputProduct.PesoBrutoTotal * 1;

    setMerchObject({ ...merchObject });
  }, [inputProduct.PesoBrutoTotal, inputProduct.UnidadPeso]);

  useEffect(() => {
    if (csv) {
      handleSubmit(csv);
    }
  }, [csv, inputProduct.UnidadPeso]);

  return (
    <>
      <Grid
        container
        spacing={2}
        component="Box"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingCSV}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleInputs}
            disabled={loadingData}
            id="UnidadPeso"
            label="Unidad de peso"
            value={inputProduct.UnidadPeso}
            sx={{width:"100%"}}
          />
        </Grid>
        {!showInputs ? (
          <Grid item xs={12}>
            <Box
              sx={{
                width: "65%",
                margin: "auto",
                minHeight: "150",
                display: "flex",
                flexDirection: "Column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DisplayDragAndDrop
                setFile={validateNecessaryDataAndSetCSV}
                fileExtension={".csv"}
                componentId={"contained-button-file"}
                sx={{ width: "10%" }}
              />
              {csv && <DisplayFilePreview file={csv} />}
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={showInputs ? 12 : 6}>
          {/* CONDITIONALLY SHOW UPLOAD FILE FOR PROCESSING PRODUCTS DATA */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin:"1vh"
            }}
          >
            <Button
              onClick={handleSearch}
              disabled={loadingData}
              sx={{ color: "white" }}
              variant="contained"
              color="secondary"
            >
              {showInputs ? "Cancelar Búsqueda" : "Buscar manualmente"}
            </Button>
          </Box>
        </Grid>

        {showInputs && (
          <Grid item xs={12}>
            <Box
              component="div"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Autocomplete
                sx={{ width: "50%" }}
                closeText="Limpiar"
                id="Descripcion"
                clearOnBlur={true}
                value={{
                  Name: inputProduct.Descripcion,
                  Value: inputProduct.BienesTransp,
                }}
                onChange={handleAutoComplete}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Nombre del producto"
                      name="satCode"
                      onChange={handleInputs}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingData ? <CircularProgress /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                options={productsList}
                getOptionLabel={(option) => `${option.Name}`}
                isOptionEqualToValue={(option, value) => {
                  return option.Name === value.Name;
                }}
              />
              <TextField
                onChange={handleInputs}
                sx={{ width: "50%", margin: "2vh" }}
                value={inputProduct.ClaveUnidad}
                autoCapitalize="characters"
                id="ClaveUnidad"
                label="Clave unidad peso"
              />
              <TextField
                onChange={handleInputs}
                sx={{ width: "50%", margin: "2vh" }}
                id="Cantidad"
                type="number"
                label="Cantidad"
                value={inputProduct.Cantidad}
              />
              <TextField
                onChange={handleInputs}
                sx={{ width: "50%", margin: "2vh" }}
                id="PesoEnKg"
                type="number"
                label="Peso en KG"
                value={inputProduct.PesoEnKg}
              />
              <Button
                id="add_item"
                onClick={handleSubmitManualInput}
                sx={{ margin: "2vh", background: "green", color: "white" }}
                variant="contained"
              >
                Agregar item
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <Box sx={{ display: "flex", align: "center", justifyContent: "center" }}>
        <Snackbar
          open={alert.show}
          autoHideDuration={2500}
          onClose={handleClose}
        >
          <Alert severity={alert.state || "info"}>{alert.message}</Alert>
        </Snackbar>
      </Box>
      {invoiceProducts.length > 0 && <Box sx={{textAlign:"right"}}>
        <Typography
              variant="body2"
              sx={{ fontSize: "1.ev", fontWeight: "bold" }}
            >
              Número total de mercancias: {invoiceProducts.length}
            </Typography>
      </Box>

      }

    </>
  );
};

export { MerchForm };
