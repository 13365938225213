import React, { useState, useEffect } from 'react'
import { Button, Input, TextField } from '@mui/material'
import { Box } from '@mui/system'
// import axios from 'axios'
import { DashboardNav } from '../../../../../components/navigation/DashboardNav'
import { useNavigate } from 'react-router-dom'
import CsdForm from './CsdForm'

const UpdateCsds = () => {
    const [rfc, setRfc] = useState("");
    const [dialog,setDialog] = useState({})
  
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const rfcParam = urlParams.get("Rfc");
  
      if (rfcParam) {
        setRfc(rfcParam);
      } else {
        setDialog({
          open: true,
          title: "Error obteniendo el Rfc del emisor",
          content: "Asegúrese de que aún hay registro del emisor o contacte a Soporte",
        });
      }
    }, []);
  
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "5vh",
        }}
      >
        {rfc !== "" && <CsdForm issuerRFC={rfc} />}
      </Box>
    );
  };

export {UpdateCsds}