import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Edit, PhotoCamera, Delete, Save } from "@mui/icons-material";
import {
  getOrgLogo,
  updateOrgLogo,
} from "../../../services/modules/organization/logos";
import useAuth from "../../../hooks/useAuth";

function ProfilePic({ feedbackDialog, setFeedbackDialog }) {
  const placeholderImage =
    "https://thenounproject.com/api/private/icons/4927949/edit/?backgroundShape=SQUARE&backgroundShapeColor=%23000000&backgroundShapeOpacity=0&exportSize=752&flipX=false&flipY=false&foregroundColor=%23000000&foregroundOpacity=1&imageFormat=png&rotation=0";
    //TODO : get this or an equivalent image on our server
  const [imageURL, setImageURL] = useState(null);
  const [realImage, setRealImage] = useState(null);
  const [DBimage, setDBImage] = useState(placeholderImage);
  const [openDialog, setOpenDialog] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { userid } = useAuth();

  const handleImageUpload = (selectedImage) => {
    const allowedImageTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/svg+xml",
    ];
    if (selectedImage) {
      if (allowedImageTypes.includes(selectedImage.type)) {
        const imageUrl = URL.createObjectURL(selectedImage);
        setImageURL(imageUrl);
        setRealImage(selectedImage);
      } else {
        setFeedbackDialog({
          ...feedbackDialog,
          open: true,
          title: "Formato inválido",
          content: "Por favor seleccione un archivo de imagen válido",
          actions: [
            {
              label: "Ok",
              execute: () =>
                setFeedbackDialog({ ...feedbackDialog, open: false }),
            },
          ],
        });
      }
    }
    //setOpenDialog(false);
  };

  const handleUpdateLogo = (e, v, r) => {
    function clearLogoStates() {
      setFeedbackDialog({ ...feedbackDialog, open: false });
      setOpenDialog(false);
      handleGetDBLogo();
      setImageURL(null);
      setRealImage(null);
    }

    const formData = new FormData();
    formData.append("logo", realImage, "logo.jpg");
    updateOrgLogo(userid, formData)
      .then((response) => {
        setFeedbackDialog({
          ...feedbackDialog,
          open: true,
          title: "Éxito actualizando el logo",
          content: "",
          actions: [{ label: "Ok", execute: clearLogoStates }],
        });
      })
      .catch((err) => {
        setFeedbackDialog({
          ...feedbackDialog,
          open: true,
          title: "Ocurrió un error",
          content: "al intentar actualizar el logo",
          actions: [
            {
              label: "Reintentar",
              execute: () => {
                setFeedbackDialog({ ...feedbackDialog, open: false });
                handleUpdateLogo();
              },
            },
            { label: "Cerrar", execute: clearLogoStates },
          ],
        });
      });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const selectedImage = event.dataTransfer.files[0];
    handleImageUpload(selectedImage);
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };


  const handleGetDBLogo = () => {
    getOrgLogo(userid)
      .then((res) => {
        let imageUrl = res.data;
        setDBImage(imageUrl);
      })
      .catch((err) => {
        console.log("No logo yet")
      });
  };

  useEffect(() => {
    //check for org logo
    handleGetDBLogo();
  }, []);

  return (
    <div>
      <Tooltip title="Editar">
        <div
          onMouseEnter={() => setImageURL && setEditVisible(true)}
          onMouseLeave={() => setEditVisible(false)}
          onDragOver={(e) => preventDefault(e)}
          onDragEnter={() => setIsDragging(true)}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          style={{
            border: isDragging ? "2px dashed #aaa" : "none",
            borderRadius: "5px",
            padding: "16px",
            textAlign: "center",
            marginBottom: "16px",
            zIndex: 5,
            position: "relative",
          }}
        >
          <Box
            component="img"
            sx={{
              maxHeight: { xs: "65%"},
              maxWidth: { xs: "95%"},
            }}
            alt="Logo."
            src={DBimage}
          />

          {editVisible && (
            <div
              onClick={() => setOpenDialog(true)}
              style={{
                position: "absolute", // Use absolute positioning
                width: "100%",
                height: "100%",
                top: "0%",
                left: "0%",
                //transform: "translate(-50%, -50%)",
                background: "rgba(0,0,0,0.5)",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2,
              }}
            >
              <Edit />
            </div>
          )}
        </div>
      </Tooltip>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          <Typography variant="h5" color={"primary"}>
            Actualizar Logo
          </Typography>
        </DialogTitle>
        <DialogContent>
          {imageURL ? (
            <>
              <Grid container maxWidth={"xs"} spacing={2}>
                <Grid item xs={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <IconButton
                      onClick={() => setImageURL(null)}
                      style={{
                        background: "red",
                        color: "white",
                        position: "absolute",
                        top: "15%",
                        right: "20%",
                        //transform: "translate(-100%, 100%)",
                      }}
                    >
                      <Delete />
                    </IconButton>
                    <Box
                      component="img"
                      sx={{
                        height: 250,
                        width: 250,
                      }}
                      alt="Logo."
                      src={imageURL}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<Save />}
                    onClick={handleUpdateLogo}
                    fullWidth
                  >
                    Aceptar y Guardar Imagen
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : null}
          {!imageURL && (
            <>
              <div
                onDragOver={(e) => preventDefault(e)} // Prevent default behavior for drag-over
                onDragEnter={() => setIsDragging(true)} // Set dragging state to true on drag enter
                onDragLeave={() => setIsDragging(false)} // Set dragging state to false on drag leave
                onDrop={handleDrop} // Handle image drop
                style={{
                  border: "2px dashed #aaa",
                  borderRadius: "5px",
                  padding: "16px",
                  textAlign: "center",
                  marginTop: "16px",
                }}
              >
                <DialogContentText>
                  Arrastre una imagen aquí o selecciónela desde su dispositivo
                </DialogContentText>
              </div>
              <input
                type="file"
                accept="image/*"
                id="image-upload"
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload(e.target.files[0])}
              />
              <label htmlFor="image-upload">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                  fullWidth
                >
                  Buscar Imagen
                </Button>
              </label>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProfilePic;
