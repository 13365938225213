import { SERVICES_API_CONTEXT } from "./services";


export const getAllDestinations = (user) =>{
    const getPromise = SERVICES_API_CONTEXT("destination","get");

    return getPromise(user);
}

export const getFilteredDestinations = (user,filters) =>{
    const getPromise = SERVICES_API_CONTEXT("destination","getFiltered");
    const stringifiedFilter = filters.map((filter) => {
        return `${filter.param}=${filter.value}`;
      
    });
    return getPromise(user,stringifiedFilter);
}


export const getDestinationById = (user,id) =>{
    const getPromise = SERVICES_API_CONTEXT("destination","getOne");

    return getPromise(user,id);
}

export const createDestination = (user, body) =>{
    const getPromise = SERVICES_API_CONTEXT("destination","create");

    return getPromise(user, body);
}

export const updateDestination = (user, id, newObj) =>{
    const getPromise = SERVICES_API_CONTEXT("destination","update");

    return getPromise(user, id, newObj);
}
export const deleteDestination = (user, id) =>{
    const getPromise = SERVICES_API_CONTEXT("destination","delete");

    return getPromise(user, id);
}
