import React, { useState, useEffect } from "react";
//*MUI COMPONENTS
import {
  TextField,
  Alert,
  Autocomplete,
  Typography,
  CircularProgress,
  Button,
  Container,
  Paper,
  Grid,
  Backdrop,
} from "@mui/material";
//*Hooks
import useAuth from "../../../hooks/useAuth.js";
//*Network, routing and API
import { useNavigate } from "react-router";
import { getPostalCodesCatalog } from "../../../services/modules/invoices/catalogs.js";
import { getIssuers } from "../../../services/modules/invoices/issuers.js";
import {
  createExpeditionPlace,
  saveExpeditionPlaceInPac,
} from "../../../services/modules/invoices/organization.js";
import { AlertDialog } from "../../userFeedback/AlertDialog.jsx";

const initialAddressState = {
  Name: "",
  Street: "",
  ExteriorNumber: "",
  InteriorNumber: "",
  Neighborhood: "",
  ZipCode: "",
  Municipality: "",
  Locality: "",
  State: "",
  Country: "México",
};

const ExpeditionPlaceForm = () => {
  const navigate = useNavigate();

  const { userid } = useAuth();

  const [formData, setFormData] = useState(initialAddressState);

  const validateAddress = (address) => {
    let valid = true;
    let invalid = {};

    for (const key in address) {
      if (address.hasOwnProperty(key)) {
        const value = address[key];
        if (
          key !== "Locality" &&
          key !== "InteriorNumber" &&
          (!value || value.trim() === "")
        ) {
          valid = false;
          invalid[key] = "Invalid field";
        }
      }
    }

    return { valid, invalid };
  };

  const [selected, setSelected] = useState({
    issuer: "",
  });

  const [backdrop, setBackdrop] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [{ label: "", execute: () => {} }],
    keep: false,
  });

  const [issuersList, setIssuersList] = useState([]);

  const handleSavePlace = () => {
    console.log(formData, selected.issuer.Rfc);
    setBackdrop(true);
    //if everything is valid
    if (selected.issuer.Rfc !== "" && selected.issuer.Rfc !== undefined) {
      let { valid, invalid } = validateAddress(formData);
      console.log("valid invalid", valid, invalid);
      if (valid) {
        createExpeditionPlace(userid, userid, selected.issuer.Rfc, formData)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              saveExpeditionPlaceInPac(userid, userid, res.data.id)
                .then(() => {
                  setDialog({
                    ...dialog,
                    open: true,
                    title: "Éxito",
                    content:
                      "La dirección se ha guardado y activado con éxito!",
                    actions: [
                      {
                        label: "Continuar",
                        execute: () =>
                          navigate(`/${userid.claims.rol}/welcome`),
                      },
                    ],
                    keep: true,
                  });
                })
                .catch((err) => {
                  setDialog({
                    ...dialog,
                    open: true,
                    title: "Error",
                    content:
                      "La dirección se ha guardado pero no se ha podido activar, favor de contactar a soporte",
                    actions: [
                      {
                        label: "Continuar",
                        execute: () =>
                          navigate(`/${userid.claims.rol}/welcome`),
                      },
                    ],
                    keep: true,
                  });
                });
            }
            setBackdrop(false);
          })
          .catch((err) => {
            console.log("err", err.response, err.message);
            setBackdrop(false);
            setDialog({
              ...dialog,
              open: true,
              title: `Error (${err.response.status})`,
              content: `${err.response.data.message} ${err.response.data.error}`,
              actions: [
                {
                  label: "Reintentar",
                  execute: () => handleSavePlace(),
                },
                {
                  label: "Cerrar",
                  execute: () => setDialog({ ...dialog, open: false }),
                },
              ],
              keep: false,
            });
          });
      } else {
        setBackdrop(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Completar Campos",
          content: "Favor de completar los campos requeridos",
          actions: [
            {
              label: "Ok",
              execute: () => setDialog({ ...dialog, open: false }),
            },
          ],
        });
      }
    } else {
      setBackdrop(false);
      setDialog({
        ...dialog,
        open: true,
        title: "Seleccione un emisor",
        content: "Es necesario seleccionar un emisor",
        actions: [
          { label: "Ok", execute: () => setDialog({ ...dialog, open: false }) },
        ],
      });
    }
  };

  useEffect(() => {
    getIssuers(userid, userid)
      .then((res) => {
        console.log(res);
        setIssuersList(res.data.data);
      })
      .catch((err) => {
        setDialog({
          ...dialog,
          open: true,
          title: "Error obteniendo emisores",
          actions: [
            {
              label: "Recargar",
              execute: () => window.location.reload(),
            },
            {
              label: "Salir",
              execute: () => navigate(`/${userid.claims.rol}/welcome`),
            },
          ],
          keep: true,
        });
      });
  }, []);

  const handleShowDialog = () => {
    setDialog({ ...dialog, open: true });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AlertDialog
          updateOpenState={handleShowDialog}
          open={dialog.open}
          title={dialog.title}
          content={dialog.content}
          actions={dialog.actions}
          keep={dialog.keep}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Nuevo Lugar de Expedición
            </Typography>

            <Grid container spacing={2} marginTop={"3vh"}>
              <Grid item xs={12}>
                <Autocomplete
                  id="issuer"
                  sx={{ minWidth: "100%" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        //error={error.issuer.failed}
                        //helperText={error.issuer.message}
                        label="Emisor"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {backdrop ? <CircularProgress /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                  getOptionLabel={(option) => option.Name || ""}
                  options={issuersList}
                  onChange={(e, v, r) => {
                    switch (r) {
                      case "selectOption":
                        setSelected({ ...selected, issuer: v });

                        break;
                      case "clear":
                        setSelected({ ...selected, issuer: "" });
                        break;

                      default:
                        break;
                    }
                  }}
                  isOptionEqualToValue={(o, v) => {
                    if (v === "") {
                      return true;
                    } else {
                      return o.Name === v.Name;
                    }
                  }}
                  value={selected.issuer}
                  clearOnBlur
                />
                <Alert severity="info">
                  Es necesario vincular el lugar con un Emisor — Asegúrate de
                  seleccionarlo! o{" "}
                  <span
                    onClick={() =>
                      navigate(`/${userid.claims.rol}/invoices/NuevoEmisor`)
                    }
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  >
                    {"<<Registra un nuevo emisor>>"}
                  </span>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <GeneralAddressForm
                  formData={formData}
                  setFormData={setFormData}
                  handleSavePlace={handleSavePlace}
                  setLoading={setBackdrop}
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    </>
  );
};

const GeneralAddressForm = ({
  formData,
  setFormData,
  handleSavePlace,
  setLoading = () => {
    return;
  },
}) => {
  const { userid } = useAuth();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleZipCodeChange = (e) => {
    const zipCode = e.target.value;
    setFormData({
      ...formData,
      ZipCode: zipCode,
    });
  };

  const validateAndSetLocationsFromResponse = (res) => {
    const isValidLocation = (stringCode) => {
      return stringCode !== "" && stringCode !== "NULL";
    };

    const isValidMunicipioCode = (stringCode) => {
      return stringCode !== "" && stringCode !== "NULL";
    };

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        Locality: "",
        Municipality: "",
        State: res.data[0].StateCode,
      };
    });

    if (
      isValidLocation(res.data[0].LocationCode) &&
      isValidMunicipioCode(res.data[0].MunicipalityCode)
    ) {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          Locality: res.data[0].LocationCode,
          Municipality: res.data[0].MunicipalityCode,
          State: res.data[0].StateCode,
        };
      });

      return;
    }

    if (isValidLocation(res.data[0].LocationCode)) {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          Locality: res.data[0].LocationCode,
          Municipality: "",
          State: res.data[0].StateCode,
        };
      });

      return;
    }

    if (isValidMunicipioCode(res.data[0].MunicipalityCode)) {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          Locality: "",
          Municipality: res.data[0].MunicipalityCode,
          State: res.data[0].StateCode,
        };
      });

      return;
    }
  };

  useEffect(() => {
    if (formData.ZipCode.length > 3) {
      setLoading(true);
      getPostalCodesCatalog(userid, userid, formData.ZipCode)
        .then((result) => {
          if (result.data.length === 1) {
            validateAndSetLocationsFromResponse(result);
          }
          setLoading(false);
        })
        .catch((err) => {
          //setAlertMessage('Error obteniendo catálogo de códigos postales');
          setLoading(false);
        });
    }
  }, [formData.ZipCode]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          label="Nombre o Alias del Lugar"
          id="Name"
          variant="filled"
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.Name}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Calle"
          id="Street"
          variant="standard"
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.Street}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Número Exterior"
          id="ExteriorNumber"
          variant="standard"
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.ExteriorNumber}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Número Interior (opcional)"
          id="InteriorNumber"
          variant="standard"
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.InteriorNumber}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Colonia"
          id="Neighborhood"
          variant="standard"
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.Neighborhood}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Código Postal"
          id="ZipCode"
          variant="standard"
          type="number"
          sx={{ minWidth: "100%" }}
          onChange={handleZipCodeChange}
          value={formData.ZipCode}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Municipio o Localidad"
          id="Municipality"
          variant="standard"
          disabled
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.Municipality}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Estado"
          id="State"
          variant="standard"
          disabled
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.State}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Localidad"
          id="Locality"
          variant="standard"
          disabled
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.Locality}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="País"
          id="Country"
          variant="standard"
          disabled
          sx={{ minWidth: "100%" }}
          onChange={handleChange}
          value={formData.Country}
        />
      </Grid>
      {handleSavePlace && (
        <Grid item xs={12} align="center">
          <Button variant="contained" color="primary" onClick={handleSavePlace}>
            Guardar Dirección
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export { ExpeditionPlaceForm, initialAddressState, GeneralAddressForm };
