import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { checkPaymentStatus } from "../../services/payments/trackPayments";
import { PaymentStatusComponent } from "../stripe/PaymentStatusComponent";

export const RedirectComponent = () => {
  const { user, userid } = useAuth();
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [failure, setFailure] = useState({ isStripe: false, isServer: false });

  useEffect(() => {
    if (user && userid) {
      const requestConfig = {
        headers: {
          authorization: userid.token,
          user: user,
        },
        clientSecret: new URLSearchParams(window.location.href).get(
          "payment_intent_client_secret"
        ),
      };
      checkPaymentStatus(requestConfig)
        .then(async (status) => {
          console.log(status);

          setPaymentStatus(() => status);

          setLoading(() => false);
        })
        .catch((err) => {
          console.log(err);

          if (err.isAxiosError && err.status !== 400) {
            setFailure((failure) => {
              return {
                ...failure,
                isServer: true,
              };
            });
            return;
          }

          setFailure((failure) => {
            return {
              ...failure,
              isStripe: true,
            };
          });
        });
    }

    console.log("No user");
    return () => {
      setPaymentStatus("");
      setLoading(() => true);
    };
  }, []);
  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <PaymentStatusComponent status={paymentStatus} />
      )}
    </div>
  );
};
