import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Paper,
  Autocomplete,
} from "@mui/material";
import {
  PermitTypesCatalog,
  TruckTypesCatalog,
} from "../../invoices/utils/staticCatalogs";
import {
  createVehicle,
  getAllRemolques,
  getVehicle,
  updateVehicle,
} from "../../../services/modules/transports/vehicles";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router";
import { AlertDialog } from "../../../components/userFeedback/AlertDialog";

const AdminRegisterTransports = () => {
  const [transport, setTransport] = useState({});
  const [isEdition, setIsEdition] = useState(false);
  const [URLid, setURLid] = useState();
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  const { userid } = useAuth();
  const [loading, setLoading] = useState(false);
  const [remolques, setRemolques] = useState([]);
  const navigate = useNavigate();
  const handleChange = (e) => {
    let id = e.target.id;
    if (id === "model") {
      setTransport({
        ...transport,
        [id]: Number(e.target.value),
      });
    } else {
      setTransport({
        ...transport,
        [id]: e.target.value,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(transport);

    if (isEdition) {
      updateVehicle(userid, userid, transport._id, transport)
        .then((res) => {
          console.log("respuesta remolques ", res);
          setDialog({
            ...dialog,
            open: true,
            title: "Éxito",
            content: "Se ha actualizado el vehículo con éxito",
            actions: [
              {
                label: "Registrar otro",
                execute: () => {
                  navigate(`/${userid.claims.rol}/transports/newTruck`);
                  window.location.reload();
                },
              },
              {
                label: "Ir a Inicio",
                execute: () => navigate(`/${userid.claims.rol}/welcome`),
              },
            ],
          });
          setLoading((l) => false);
        })
        .catch((err) => {
          console.log(err.response);
          setDialog({
            ...dialog,
            open: true,
            title: "Error",
            content: "Error actualizando el vahículo",
            actions: [
              {
                label: "Reintentar",
                execute: (e) => handleSubmit(e),
              },
              {
                label: "Ir a Transportes",
                execute: () => navigate(`/${userid.claims.rol}/transports`),
              },
            ],
          });
        });
    } else {
      createVehicle(userid, userid, transport)
        .then((res) => {
          console.log("respuesta remolques ", res);
          setDialog({
            ...dialog,
            open: true,
            title: "Éxito",
            content: "Se ha registrado el vehículo con éxito",
            actions: [
              {
                label: "Registrar otro",
                execute: () => {
                  navigate(`/${userid.claims.rol}/transports/newTruck`);
                  window.location.reload();
                },
              },
              {
                label: "Ir a Inicio",
                execute: () => navigate(`/${userid.claims.rol}/welcome`),
              },
            ],
          });
          setLoading((l) => false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const [withRemolque, setWithRemolque] = useState(false);

  const handleBindRemolque = () => {
    setWithRemolque(!withRemolque);
  };

  useEffect(() => {
    getAllRemolques(userid, userid)
      .then((res) => {
        console.log("respuesta remolques ", res);
        let issuersData = res.data;
        setRemolques((ir) => issuersData);
        setLoading((l) => false);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          setLoading((l) => false);
          switch (err.response.status) {
            case 401:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo remolques",
                  content:
                    "No tienes autorización para consultar esta información, verifica que tienes conexión y tu sesión sigue activa.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Terminar",
                      execute: () => navigate(`/`),
                    },
                  ],
                };
              });
              break;
            case 400:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo remolques",
                  content:
                    "El servidor no ha comprendido lo que necesitas, por favor intenta de nuevo, o contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intenar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
            case 500:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR de servidor",
                  content:
                    "Hubo un error al obtener los datos de los remolques. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intenar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
            default:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo remolques",
                  content:
                    "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intenar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
          }
          return;
        }

        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "ERROR Obteniendo emisores",
            content:
              "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
            actions: [
              {
                label: "Recargar",
                execute: () => window.location.reload(),
              },
              {
                label: "Continuar",
                execute: () => navigate(`/`),
              },
            ],
          };
        });
      });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("Id");

    //if the component gets an Id in the URL, is supposed to edit that object
    if (id) {
      setIsEdition(true);
      setURLid(id);

      getVehicle(userid, userid, id)
        .then((response) => {
          console.log("respuesta a editar", response);
          setTransport(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AlertDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography variant="h4" align="center" color="primary.dark">
            Registra un Vehículo
          </Typography>

          <Grid container spacing={2} sx={{ mt: "3vh" }}>
            <Grid item xs={12}>
              <Autocomplete
                autoComplete
                sx={{ width: "100%" }}
                options={TruckTypesCatalog}
                getOptionLabel={(option) => {
                  return `${option.value} - ${option.label}`;
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      id="type"
                      sx={{ width: "100%" }}
                      label="Tipo de Vehículo"
                      required
                      value={transport.type || ""}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                value={
                  transport.type
                    ? TruckTypesCatalog.find(
                        (option) => option.value === transport.type
                      )
                    : null
                }
                onChange={(e, v, r) => {
                  console.log(v);
                  if (r === "clear") {
                    transport.type = "";
                  } else {
                    transport.type = v.value;
                  }
                  setTransport({ ...transport });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleChange}
                id="model"
                label="Modelo (Año)"
                type="number"
                value={transport.model}
                InputLabelProps={{
                  shrink: Boolean(transport.model),
                }}
                variant={"standard"}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleChange}
                id="placaTractor"
                label="Placa"
                value={transport.placaTractor}
                InputLabelProps={{
                  shrink: Boolean(transport.placaTractor),
                }}
                variant={"standard"}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "4vh" }}>
              <Autocomplete
                autoComplete
                sx={{ width: "100%" }}
                options={PermitTypesCatalog}
                getOptionLabel={(option) => {
                  return `${option.value} - ${option.label}`;
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      id="permisoSCT"
                      sx={{ width: "100%" }}
                      label="Permiso SCT"
                      required
                      value={transport.permisoSCT || ""}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                value={
                  transport.permisoSCT
                    ? PermitTypesCatalog.find(
                        (option) => option.value === transport.permisoSCT
                      )
                    : null
                }
                onChange={(e, v, r) => {
                  console.log(v);
                  if (r === "clear") {
                    transport.permisoSCT = "";
                  } else {
                    transport.permisoSCT = v.value;
                  }
                  setTransport({ ...transport });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                id="numPermisoSCT"
                label="No. del Permiso"
                value={transport.numPermisoSCT}
                InputLabelProps={{
                  shrink: Boolean(transport.numPermisoSCT),
                }}
                variant="standard"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "4vh" }}>
              <TextField
                onChange={handleChange}
                id="polizaName"
                label="Empresa Aseguradora"
                value={transport.polizaName}
                InputLabelProps={{
                  shrink: Boolean(transport.polizaName),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                id="poliza"
                label="Poliza"
                value={transport.poliza}
                InputLabelProps={{
                  shrink: Boolean(transport.poliza),
                }}
                variant={"standard"}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "4vh" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"remolque"}
                    checked={withRemolque}
                    onChange={handleBindRemolque}
                  />
                }
                label="Vincular Remolque a este Vehículo"
                labelPlacement="end"
              />
            </Grid>
            {/*
            withRemolque && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      autoComplete
                      sx={{ width: "100%" }}
                      options={TowCarTypesCatalog}
                      getOptionLabel={(option) => {
                        return `${option.value} - ${option.label}`;
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            id="tipoRem"
                            sx={{ width: "100%" }}
                            label="Tipo de Remolque"
                            required
                            value={transport.type || ""}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      onChange={(e, v, r) => {
                        console.log(v);
                        if (r === "clear") {
                          transport.tipoRem = "";
                        } else {
                          transport.tipoRem = v.value;
                        }
                        setTransport({ ...transport });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={handleChange}
                      id="placaCaja"
                      label="Placa del Remolque"
                      variant={"standard"}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
            */}
            {withRemolque && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      autoComplete
                      sx={{ width: "100%" }}
                      options={remolques}
                      getOptionLabel={(option) =>
                        "Tipo: " +
                          option.tipoRem +
                          "   Placa: " +
                          option.placaCaja || ""
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            id="remolque"
                            sx={{ width: "100%" }}
                            label="Remolque"
                            required
                            value={transport.type || ""}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      onChange={(e, v, r) => {
                        console.log(v);
                        if (r === "clear") {
                          transport.remolque = "";
                        } else {
                          transport.remolque = v;
                        }
                        setTransport({ ...transport });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                {isEdition ? "Actualizar transporte" : "Registrar transporte"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default AdminRegisterTransports;
