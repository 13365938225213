import React, { useEffect, useState } from 'react'

//*MUI Components
import { 
    Typography, 
    Box, 
    Button,
    CircularProgress,
} from '@mui/material'

//*Core components
import { AlertDialog } from '../../components/userFeedback/AlertDialog.jsx';

//*Welcome config
import inititalActions from './inititalActions.js';

//*Network and API
import { useNavigate } from 'react-router-dom';

//*Auth
import useAuth from '../../hooks/useAuth';


const WelcomeView = () => {
    const [loading, setLoading] = useState(true)
    const [dialog, setDialog] = useState({
        open:false,
        title:"",
        content:"",
        actions:[],
        keep:false
    })
    
    const navigate = useNavigate()
    const {userid, logout} = useAuth()

    const handleExecuteShortcut = (e) => {
        navigate(`${e.target.id}`)
    }
    
    const handleUpdateDialog = () => {
        setDialog({
            ...dialog,
            open:false,
            title:"",
            content:"",
            actions:[]
        })
    }
    
    const type = inititalActions.find(action => {
        return action.Type === window.location.pathname.split("/")[1]
    })
    useEffect(() => {
        if(!userid){
            setLoading((l) => {
                return !l
            })
            return
        } else {
            if(userid.claims.rol === window.location.pathname.split("/")[1]){
                setLoading((loading) => !loading)
                return
            }

            setLoading(l => {
                return !l
            })
            setDialog((dialog) => {
                return ({
                    ...dialog,
                    open:true,
                    keep:true,
                    title:"Ruta incorrecta",
                    content:"Tu credenciales no coinciden con la ruta que quieres visitar, por favor dirigite a donde tienes acceso.",
                    actions:[
                        {
                            label:"Ok",
                            execute:() => {
                                logout()
                            }
                        }
                    ]
                })
            }
            )

        }

    }, [userid, logout])
    return (
        <div>
            {
                loading
                ?
                <CircularProgress/>
                :
                <>
                <AlertDialog 
                updateOpenState={handleUpdateDialog} 
                open={dialog.open} 
                title={dialog.title} 
                content={dialog.content} 
                actions={dialog.actions}
                keep={dialog.keep}
                />
                <Box sx={{background:"white",width:"100%", height:"92.5vh", alignItems:"center", display:"flex", justifyContent:"center", flexDirection:"column"}}>
                    <Box sx={{margin:"2vh",width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <Typography variant='h4' sx={{fontSize:"5vh", fontWeight:"bold"}}>{type.Title}</Typography>
                    </Box>
                    
                    {/* //*Esto solo hace sentido cuando el usuario tiene todos los modulos contratados */}
                    {
                        <>
                            {type.Actions.map((action, key) => {
                                return (
                                    <Button 
                                    sx={{borderRadius:"30px",background: "#C00000",boxShadow:8, margin:"2vh",height:"5vh", color:"white"}}
                                    key={key} 
                                    id={`/${userid.claims.rol}/${action.path}`} 
                                    onClick={handleExecuteShortcut}>
                                        {action.label}
                                    </Button>
                                )
                            })}
                        </>
                    }

                </Box>
                </>
            }
        </div>
    )
}

export default WelcomeView