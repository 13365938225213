



export const mapCustomerData = (obj) => {
    console.log(obj)
    return {
        "TaxName":                          obj.Nombre,
        "FiscalRegime":                     obj.RegimenFiscal,
        "Rfc":                              obj.RFC,
        "Addresses":                        obj.Addresses,
        "subEmpresas":                      obj.subEmpresas
    }
}
