import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import { Grid, TextField } from "@mui/material";
import {
  getBanks,
  getContractTypes,
  getPaymentFrequencies,
  getPositionRisks,
  getPostalCodesCatalog,
  getRegimenTypes,
  getRegimes,
  getTypesOfJourney,
} from "../../../services/modules/invoices/catalogs";
import useAuth from "../../../hooks/useAuth";
import {
  DeductionsTable,
  PerceptionsTable,
} from "../../../components/Forms/Nomina/NominaTables";
import {
  validateCLABE,
  validateCurp,
  validateRfc,
} from "../../invoices/utils/regexp";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toIsoHelper } from "../../invoices/issue/invoiceData/Nomina/NominaDateTypeComponent";
import {
  createEmployee,
  searchEmployeeByKey,
  updateEmployee,
} from "../../../services/modules/employees/employees";
import { toAntiguedadFormat } from "../../invoices/utils/toAntiguedadFormat";
import { useNavigate } from "react-router";
import { getFiscalRegimes } from "../../../services/satCatalogs";

const EmployeeRegistrationForm = React.memo(() => {
  const { userid } = useAuth();

  const navigate = useNavigate();

  const [isEdition, setIsEdition] = useState(false);

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    maxWidth: "md",
    actions: [],
  });

  const [employeeData, setEmployeeData] = useState({
    Id: "",
    Rfc: "",
    Nombre: "",
    ApellidoP: "",
    ApellidoM: "",
    DomFiscal: {
      CP: "",
      Estado: "",
      Calle: "",
      Localidad: "",
      Municipio: "",
      NumExt: "",
      NumInt: "",
    },
    CURP: "",
    NSS: "",
    FechaInicio: new Date(),
    Antiguedad: "P0D",
    TipoContrato: "",
    Sindicato: false,
    TipoJornada: "",
    TipoRegimen: "",
    RegimenFiscal: "",
    Dpto: "",
    Puesto: "",
    Riesgo: "",
    Licencia: "",
    Periodicidad: "",
    BancoClave: "",
    Banco: "",
    CtaBanco: "",
    Sueldo: null,
    SueldoBase: null,
    SueldoDiario: null,
    Perceptions: [],
    Deductions: [],
    ConfigStatus: {
      progress: 0,
      leftValues: [],
    },
  });

  const [options, setOptions] = useState({
    regimeType: [],
    fiscalRegimes: [],
    risk: [],
    journey: [],
    frequency: [],
    bank: [],
    entityKey: [],
    contractType: [],
    sindicato: [],
    departments: [],
    positions: [],
  });

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState({
    Rfc: null,
    Curp: null,
    BankAccount: null,
  });

  // 2. Mapping objects
  const successMsgMap = {
    update: {
      title: "Éxito",
      message: "El empleado ha sido modificado con éxito",
      actions: [
        {
          label: "Continuar",
          execute: () => navigate(`/${userid.claims.rol}/empleados/`),
        },
      ],
    },
    create: {
      title: "Éxito",
      message: "El empleado ha sido agregado con éxito",
      actions: [
        {
          label: "Agregar Otro",
          execute: () => window.location.reload(),
        },
        {
          label: "Continuar",
          execute: () => navigate(`/${userid.claims.rol}/welcome/`),
        },
      ],
    },
  };

  const errorMsgMap = {
    422: {
      title: "Error",
      message: "Algo ha salido mal",
      actions: [
        {
          label: "ok",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
    },
    500: {
      title: "Error",
      message: "Ocurrió un fallo en el servidor",
      actions: [
        {
          label: "Reintentar",
          execute: () => handleSaveEmployee(employeeData),
        },
        {
          label: "Cerrar",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
    },
    400: {
      title: "Error 400",
      message: "Los datos proporcionados no son válidos",
      actions: [
        {
          label: "ok",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
    },
    401: (status) => ({
      title: `Error ${status}`,
      message: "No tienes permiso para realizar esta acción o tu sesión expiró",
      actions: [
        {
          label: "ok",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
    }),
    default: {
      title: "Error",
      message: "Hubo un fallo desconocido",
      actions: [
        {
          label: "ok",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
    },
  };

  const getContractTypesMemo = useMemo(
    () => () => getContractTypes(userid, userid),
    [userid]
  );
  const getTypesOfJourneyMemo = useMemo(
    () => () => getTypesOfJourney(userid, userid),
    [userid]
  );
  const getPaymentFrequenciesMemo = useMemo(
    () => () => getPaymentFrequencies(userid, userid),
    [userid]
  );
  const getBanksMemo = useMemo(() => () => getBanks(userid, userid), [userid]);
  const getRegimenTypesMemo = useMemo(
    () => () => getRegimenTypes(userid, userid),
    [userid]
  );
  const getPositionRisksMemo = useMemo(
    () => () => getPositionRisks(userid, userid),
    [userid]
  );
  const getFiscalRegimesMemo = useMemo(
    () => () => getRegimes(userid, userid),
    [userid]
  );
  const getSindicato = useMemo(
    () => [
      { Name: "Sí", Value: true },
      { Name: "No", Value: false },
    ],
    []
  );
  const getDepartments = useMemo(
    () => [
      { value: "LOGISTICA", label: "LOGISTICA" },
      {
        value: "ADMINISTRACION FINANCIERA",
        label: "ADMINISTRACION FINANCIERA",
      },
      { value: "OPERACIONES", label: "OPERACIONES" },
      { value: "DIRECCION GENERAL", label: "DIRECCION GENERAL" },
    ],
    []
  );
  const getPositions = useMemo(
    () => [
      { value: "OPERADOR", label: "OPERADOR" },
      {
        value: "GESTOR DE DOCUMENTACION DE SERVICIOS",
        label: "GESTOR DE DOCUMENTACION DE SERVICIOS",
      },
      { value: "COORDINADOR", label: "COORDINADOR" },
      { value: "ADMINISTRADOR FINANCIEROL", label: "ADMINISTRADOR FINANCIERO" },
    ],
    []
  );

  useEffect(() => {
    // Edition mode settings
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("Id");

    let reqErrors = [];
    const getOptions = async () => {
      const handlePromiseError = (err, value) => {
        console.log(err);
        reqErrors.push(value);
      };
      const responses = await Promise.all([
        getContractTypesMemo().catch((err) =>
          handlePromiseError(err, "tipos de contrato")
        ),
        getTypesOfJourneyMemo().catch((err) =>
          handlePromiseError(err, "tipos de jornada")
        ),
        getPaymentFrequenciesMemo().catch((err) =>
          handlePromiseError(err, "frecuencias de pago")
        ),
        getBanksMemo().catch((err) => handlePromiseError(err, "bancos")),
        getRegimenTypesMemo().catch((err) =>
          handlePromiseError(err, "tipos de regimen")
        ),
        getPositionRisksMemo().catch((err) =>
          handlePromiseError(err, "riesgos de puesto")
        ),
        getFiscalRegimesMemo().catch((err) =>
          handlePromiseError(err, "régimenes fiscales")
        ),
      ]);
      const respOptions = {
        contractType: responses[0].data,
        journey: responses[1].data,
        frequency: responses[2].data,
        bank: responses[3].data,
        regimeType: responses[4].data,
        risk: responses[5].data,
        fiscalRegimes: responses[6].data,
        sindicato: getSindicato,
        departments: getDepartments,
        positions: getPositions,
      };
      setOptions(respOptions);
      if (id) {
        setIsEdition(true);
        searchEmployeeByKey(userid, userid, "_id", id)
          .then((response) => {
            console.log("Empleado a editar", response.data.data[0]);
            const mappedEmployeeData = response.data.data[0];
            setEmployeeData(mappedEmployeeData);
          })
          .catch((error) => {
            console.error(error);
            setDialog({
              open: true,
              title: "Error al obtener empleado",
              content: "No se pudieron cargar los datos del empleado",
              actions: [
                {
                  label: "Intentar de nuevo",
                  execute: () => {
                    setDialog({ ...dialog, open: false });
                    window.location.reload();
                  },
                },
                {
                  label: "OK",
                  execute: () => {
                    navigate(`/${userid.claims.rol}/empleados`);
                  },
                },
              ],
            });
          });
      }

      setLoading(false);
      if (reqErrors.length > 0) {
        setDialog({
          open: true,
          title: "Error",
          content: `No se pudieron cargar los siguientes datos: ${reqErrors.join(
            ", "
          )}`,
          actions: [
            {
              label: "Intentar de nuevo",
              execute: () => {
                setDialog({ ...dialog, open: false });
                window.location.reload();
              },
            },
            {
              label: "OK",
              execute: () => {
                navigate(`/${userid.claims.rol}/welcome`);
              },
            },
          ],
        });
      }
    };

    //set loading
    setLoading(true);
    getOptions();
  }, []);

  const handleRfcChange = (event, actualValue) => {
    let uppercaseValue = String(event.target.value).toUpperCase();
    actualValue[event.target.name] = uppercaseValue;
    if (!validateRfc(String(event.target.value).toUpperCase())) {
      setErrorText({ ...errorText, Rfc: "Formato inválido" });
    } else {
      setErrorText({ ...errorText, Rfc: null });
    }
  };

  const handleCurpChange = (event, actualValue) => {
    let uppercaseValue = String(event.target.value).toUpperCase();
    actualValue[event.target.name] = uppercaseValue;
    if (!validateCurp(String(event.target.value).toUpperCase())) {
      setErrorText({ ...errorText, Curp: "Formato inválido" });
    } else {
      setErrorText({ ...errorText, Curp: null });
    }
  };

  const handleCtaBancoChange = (event, actualValue) => {
    let uppercaseValue = String(event.target.value).toUpperCase();
    actualValue[event.target.name] = uppercaseValue;
    if (!validateCLABE(event.target.value)) {
      setErrorText({ ...errorText, BankAccount: "Formato inválido" });
    }
  };

  const handleSalaryChange = (event, actualValue) => {
    let numberSalary = Number(event.target.value);
    actualValue[event.target.name] = numberSalary;
  };

  const handleNameChange = (event, actualValue) => {
    let uppercaseValue = String(event.target.value).toUpperCase();
    actualValue[event.target.name] = uppercaseValue;
  };

  const handleInputChange = (event) => {
    setEmployeeData((prevEmployeeData) => {
      let newEmployeeData = { ...prevEmployeeData };

      const functionByInputName = {
        Rfc: handleRfcChange,
        CURP: handleCurpChange,
        CtaBanco: handleCtaBancoChange,
        Sueldo: handleSalaryChange,
        ApellidoM: handleNameChange,
        ApellidoP: handleNameChange,
        Nombre: handleNameChange,
      };

      if (functionByInputName[event.target.name]) {
        functionByInputName[event.target.name](event, newEmployeeData);
      } else {
        newEmployeeData[event.target.name] = event.target.value;
      }
      return newEmployeeData;
    });
  };

  // 1. Helper function to set dialog
  function showDialog(title, message, actions) {
    setDialog({
      ...dialog,
      title,
      content: message,
      actions,
      open: true,
      maxWidth: "sm",
    });
  }

  function checkData2(data, path = "", emptyFields = []) {
    const optionalRoutes = [
      "DomFiscal.Localidad",
      "DomFiscal.Municipio",
      "DomFiscal.NumInt",
    ];
    for (const key in data) {
      const newPath = path === "" ? key : `${path}.${key}`;
      if (typeof data[key] === "object" && data[key] !== null) {
        checkData2(data[key], newPath, emptyFields);
      } else if (data[key] === "" || data[key] === null) {
        if (!optionalRoutes.includes(newPath)) {
          emptyFields.push(newPath);
        }
      }
    }
    return emptyFields;
  }

  function handleError(err) {
    if (err.response) {
      const status = err.response.status;
      const msgObj = errorMsgMap[status]
        ? typeof errorMsgMap[status] === "function"
          ? errorMsgMap[status](status)
          : errorMsgMap[status]
        : errorMsgMap.default;

      showDialog(msgObj.title, msgObj.message, msgObj.actions);
    }
  }

  const handleSaveEmployee = () => {
    let msgObj;
    let emptyEmployee = checkData2(employeeData);
    const MissingList = () => {
      return (
        <Grid container maxWidth={"md"} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" color="error.main">
              Falta información en los siguientes campos:
            </Typography>
          </Grid>
          {emptyEmployee.map((MF, idx /*Missing Field, mofoca */) => {
            if (typeof MF !== "object")
              return (
                <Grid key={idx} item xs={3}>
                  <Typography>-{MF}</Typography>
                </Grid>
              );
          })}
        </Grid>
      );
    };

    if (emptyEmployee.length !== 0) {
      setDialog({
        open: true,
        title: "Datos Faltantes",
        maxWidth: "md",
        content: MissingList(),
        actions: [
          {
            label: "ok",
            execute: () => {
              setDialog({ ...dialog, open: false });
            },
          },
        ],
      });
    } else {
      console.log("🚀 [EMPLOYEE DATA]", employeeData);
      // 3. Separate functions
      function handleUpdateEmployee() {
        updateEmployee(userid, userid, employeeData)
          .then((res) => {
            const msgObj =
              res.status === 201 ? successMsgMap.update : errorMsgMap.default;
            showDialog(msgObj.title, msgObj.message, msgObj.actions);
          })
          .catch(handleError);
      }

      function handleCreateEmployee() {
        createEmployee(userid, userid, employeeData)
          .then((res) => {
            const msgObj =
              res.status === 201 ? successMsgMap.create : errorMsgMap.default;
            showDialog(msgObj.title, msgObj.message, msgObj.actions);
          })
          .catch(handleError);
      }

      // Main logic
      if (isEdition) {
        handleUpdateEmployee();
      } else {
        handleCreateEmployee();
      }
    }
  };

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setDialog({
      ...dialog,
      open: false,
    });
  };

  //Locations handlers
  const handleLocations = (event) => {
    employeeData.DomFiscal = {
      ...employeeData.DomFiscal,
      [event.target.name]: event.target.value,
    };
    setEmployeeData({
      ...employeeData,
    });
  };

  useEffect(() => {
    if (employeeData.DomFiscal.CP.length > 3) {
      getPostalCodesCatalog(userid, userid, employeeData.DomFiscal.CP)
        .then((result) => {
          if (result.data.length === 1) {
            validateAndSetLocationsFromResponse(result);
          }
        })
        .catch((err) => {
          setDialog({
            ...dialog,
            title: "Error",
            content: "Hubo un error al obtener los datos del código postal",
            actions: [
              {
                label: "ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
            open: true,
            maxWidth: "sm",
          });
        });
    }
  }, [employeeData.DomFiscal.CP]);

  const validateAndSetLocationsFromResponse = (res) => {
    const isValid = (code) => {
      return code !== "" && code !== "NULL";
    };
    let prevState = employeeData;
    prevState.DomFiscal.Estado = res.data[0].StateCode;
    setEmployeeData({
      ...prevState,
    });

    if (isValid(res.data[0].LocationCode)) {
      prevState.DomFiscal.Localidad = res.data[0].LocationCode;
      setEmployeeData({
        ...prevState,
      });
    }

    if (isValid(res.data[0].MunicipalityCode)) {
      prevState.DomFiscal.Municipio = res.data[0].MunicipalityCode;
      setEmployeeData({
        ...prevState,
      });
    }
  };

  //Perceptions and deductions
  function setPerceptions(arr) {
    console.log(arr);
    setEmployeeData({
      ...employeeData,
      Perceptions: TableToConceptos("Perception", arr),
    });
  }
  function setDeductions(arr) {
    setEmployeeData({
      ...employeeData,
      Deductions: TableToConceptos("Deduction", arr),
    });
  }
  const ConceptosToTable = (type, array) => {
    //types are "Perception" and "Deduction"
    let mappedPerceptions = [];
    let mappedDeductions = [];

    if (type === "Perception") {
      array.map((DBperception) => {
        mappedPerceptions.push({
          PerceptionType: DBperception.Value,
          Code: "",
          Description: DBperception.Name,
          TaxedAmount: DBperception.TaxedTotal,
          ExemptAmount: DBperception.ExemptTotal,
        });
      });

      return mappedPerceptions;
    }

    if (type === "Deduction") {
      array.map((DBdeduction) => {
        mappedDeductions.push({
          DeduccionType: DBdeduction.Value,
          Code: "",
          Description: DBdeduction.Name,
          Amount: DBdeduction.Amount,
        });
      });

      return mappedDeductions;
    }
  };
  const TableToConceptos = (type, array) => {
    let mappedPerceptions = [];
    let mappedDeductions = [];

    if (type === "Perception") {
      array.map((TBperception) => {
        mappedPerceptions.push({
          Value: TBperception.PerceptionType,
          Name: TBperception.Description,
          TaxedTotal: TBperception.TaxedAmount,
          ExemptTotal: TBperception.ExemptAmount,
        });
      });

      return mappedPerceptions;
    }

    if (type === "Deduction") {
      array.map((TBdeduction) => {
        mappedDeductions.push({
          Value: TBdeduction.DeduccionType,
          Name: TBdeduction.Description,
          Amount: TBdeduction.Amount,
        });
      });

      return mappedDeductions;
    }
  };

  return (
    <Grid container spacing={3} maxWidth={"md"} sx={{ margin: "auto", mb: 3 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={dialog.maxWidth}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Grid item xs={12} sx={{ align: "center" }}>
        <Typography color="primary" variant="h5">
          {isEdition ? "Editar" : "Agregar"} empleado
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} component={Paper} sx={{ padding: 1 }}>
          <Grid item xs={12}>
            <Typography color="primary">Datos Personales</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="standard"
              id="Nombre"
              name="Nombre"
              label="Nombre(s)"
              fullWidth
              autoComplete="given-name"
              value={employeeData.Nombre}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="standard"
              id="ApellidoP"
              name="ApellidoP"
              label="Apellido Paterno"
              fullWidth
              autoComplete="family-name"
              value={employeeData.ApellidoP}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="standard"
              id="ApellidoM"
              name="ApellidoM"
              label="Apellido Materno"
              fullWidth
              autoComplete="family-name"
              value={employeeData.ApellidoM}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={options.fiscalRegimes}
              value={
                options.fiscalRegimes?.find(
                  (op) => op.Value === employeeData.RegimenFiscal
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="FiscalRegime"
                    sx={{ width: "100%" }}
                    label="Régimen Fiscal"
                    required
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  RegimenFiscal: r === "clear" ? "" : v?.Value || "",
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="standard"
              id="Rfc"
              name="Rfc"
              label="RFC"
              fullWidth
              helperText={errorText.Rfc}
              error={errorText.Rfc}
              autoComplete="XXXXXX991231"
              inputProps={{
                style: {
                  "&:invalid": {
                    border: "red solid 2px !important",
                  },
                  textTransform: "uppercase",
                },
                maxLength: 13,
              }}
              value={employeeData.Rfc}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="standard"
              id="CURP"
              name="CURP"
              label="CURP"
              fullWidth
              inputProps={{
                style: {
                  "&:invalid": {
                    border: "red solid 2px !important",
                  },
                  textTransform: "uppercase",
                },
                maxLength: 18,
              }}
              helperText={errorText.Curp}
              error={errorText.Curp}
              autoComplete="off"
              value={employeeData.CURP}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="standard"
              id="NSS"
              name="NSS"
              label="No. Seguridad Social"
              fullWidth
              autoComplete="off"
              value={employeeData.NSS}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Grid>

      {/*Domicilio*/}
      <Grid item xs={12}>
        <Grid container spacing={2} component={Paper} sx={{ padding: 1 }}>
          <Grid item xs={12}>
            <Typography color="primary">Domicilio</Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              sx={{ minWidth: "100%" }}
              variant="standard"
              name="CP"
              label="Código postal"
              id="CodigoPostal"
              value={employeeData.DomFiscal.CP}
              onChange={handleLocations}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              sx={{ minWidth: "100%" }}
              variant="standard"
              disabled
              label="Localidad"
              name="Localidad"
              id="Localidad"
              value={employeeData.DomFiscal.Localidad}
              onChange={handleLocations}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              sx={{ minWidth: "100%" }}
              variant="standard"
              label="Estado"
              name="Estado"
              value={employeeData.DomFiscal.Estado}
              id="Estado"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              sx={{ minWidth: "100%" }}
              variant="standard"
              label="Municipio"
              name="Municipio"
              value={employeeData.DomFiscal.Municipio}
              id="Municipio"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="standard"
              id="DomFiscal.Calle"
              name="Calle"
              label="Calle"
              fullWidth
              autoComplete="street-address"
              value={employeeData.DomFiscal.Calle}
              onChange={handleLocations}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="standard"
              id="DomFiscal.NumExt"
              name="NumExt"
              label="Número Exterior"
              fullWidth
              autoComplete="off"
              value={employeeData.DomFiscal.NumExt}
              onChange={handleLocations}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              id="DomFiscal.NumInt"
              name="NumInt"
              label="Número Interior"
              fullWidth
              autoComplete="off"
              value={employeeData.DomFiscal.NumInt}
              onChange={handleLocations}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Datos Laborales */}
      <Grid item xs={12}>
        <Grid container spacing={2} component={Paper} sx={{ padding: 1 }}>
          <Grid item xs={12}>
            <Typography color="primary">Datos Laborales</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              id="Id"
              name="Id"
              label="Identificador del empleado"
              fullWidth
              value={employeeData.Id}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="FechaInicio"
                name="FechaInicio"
                label="Fecha de inicio de relación laboral"
                required
                disableFuture
                slotProps={{ textField: { variant: "standard" } }}
                sx={{ width: "100%" }}
                value={new Date(employeeData.FechaInicio)}
                onChange={(newValue) => {
                  //*BOOLEAN TO DETERMINE IF THE WEEKS MUST BE RETURNED INSTEAD OF THE STRING COMPOSING THE ANTIQUITY
                  let antig = toAntiguedadFormat(newValue, true);
                  setEmployeeData({
                    ...employeeData,
                    FechaInicio: toIsoHelper(new Date(newValue)),
                    Antiguedad: antig,
                  });
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              id="Antiguedad"
              name="Antiguedad"
              label="Antigüedad"
              fullWidth
              value={employeeData.Antiguedad}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={options.regimeType}
              value={
                options.regimeType.find(
                  (op) => op.Value === employeeData.TipoRegimen
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="RegimeType"
                    sx={{ width: "100%" }}
                    label="Régimen"
                    required
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  TipoRegimen: r === "clear" ? "" : v?.Value || "",
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={options.sindicato}
              value={
                options.sindicato.find(
                  (op) => op.Value === employeeData.Sindicato
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="Unionized"
                    sx={{ width: "100%" }}
                    label="Sindicalizado?"
                    required
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  Sindicato: r === "clear" ? "" : v.Value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="riesgo"
              autoComplete
              sx={{ width: "100%" }}
              options={options.risk}
              value={
                options.risk.find((op) => op.Value === employeeData.Riesgo) ||
                null
              }
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    variant="standard"
                    label="Riesgo"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  Riesgo: r === "clear" ? "" : v?.Value || "",
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="tipo-contrato"
              autoComplete
              sx={{ width: "100%" }}
              options={options.contractType}
              value={
                options.contractType.find(
                  (op) => op.Value === employeeData.TipoContrato
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    variant="standard"
                    label="Tipo de Contrato"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  TipoContrato: r === "clear" ? "" : v?.Value || "",
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="tipo-jornada"
              autoComplete
              sx={{ width: "100%" }}
              options={options.journey}
              value={
                options.journey.find(
                  (op) => op.Value === employeeData.TipoJornada
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    variant="standard"
                    label="Tipo de Jornada"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  TipoJornada: r === "clear" ? "" : v?.Value || "",
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={options.departments}
              value={
                options.departments.find(
                  (op) => op.value === employeeData.Dpto
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.label}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="Dpto"
                    name="Dpto"
                    label="Departamento"
                    fullWidth
                    sx={{ width: "100%" }}
                    required
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  Dpto: r === "clear" ? "" : v?.value || "",
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={options.positions}
              value={
                options.positions.find(
                  (op) => op.value === employeeData.Puesto
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.label}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="Puesto"
                    name="Puesto"
                    label="Puesto"
                    sx={{ width: "100%" }}
                    required
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  Puesto: r === "clear" ? "" : v?.value || "",
                  Licencia:
                    v?.value !== "OPERADOR" ? "x" : employeeData.Licencia || "",
                });
              }}
            />
          </Grid>

          {employeeData.Puesto === "OPERADOR" && (
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                id="Licencia"
                name="Licencia"
                label="Licencia"
                fullWidth
                value={employeeData.Licencia}
                onChange={handleInputChange}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Datos del pago */}
      <Grid item xs={12}>
        <Grid container spacing={2} component={Paper} sx={{ padding: 1 }}>
          <Grid item xs={12}>
            <Typography color="primary">Datos del Pago</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="periodicidad"
              autoComplete
              sx={{ width: "100%" }}
              options={options.frequency}
              value={
                options.frequency.find(
                  (op) => op.Value === employeeData.Periodicidad
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    variant="standard"
                    label="Periodicidad del pago"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  Periodicidad: r === "clear" ? "" : v?.Value || "",
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="banco"
              autoComplete
              sx={{ width: "100%" }}
              options={options.bank}
              value={
                options.bank.find(
                  (op) => op.Value === employeeData.BancoClave
                ) || null
              }
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    variant="standard"
                    label="Banco"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                setEmployeeData({
                  ...employeeData,
                  Banco: r === "clear" ? "" : v?.Name || "",
                  BancoClave: r === "clear" ? "" : v?.Value || "",
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              id="cta-banco"
              name="CtaBanco"
              label="Cuenta bancaria"
              fullWidth
              value={employeeData.CtaBanco}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              id="sueldo"
              name="Sueldo"
              label="Sueldo"
              InputProps={{
                startAdornment: "$",
                inputProps: {
                  step: "0.01",
                  type: "number",
                  pattern: "[0-9]*",
                },
              }}
              placeholder={"0.00"}
              fullWidth
              value={employeeData.Sueldo}
              onChange={(e) => {
                setEmployeeData({
                  ...employeeData,
                  Sueldo: Number(e.target.value),
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              id="sueldo-base"
              name="SueldoBase"
              label="Sueldo base"
              InputProps={{
                startAdornment: "$",
                inputProps: {
                  step: "0.01",
                  type: "number",
                  pattern: "[0-9]*",
                },
              }}
              placeholder={"0.00"}
              fullWidth
              value={employeeData.SueldoBase}
              onChange={(e) => {
                setEmployeeData({
                  ...employeeData,
                  SueldoBase: Number(e.target.value),
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              id="sueldo-diario"
              name="SueldoDiario"
              label="Sueldo diario"
              InputProps={{
                startAdornment: "$",
                inputProps: {
                  step: "0.01",
                  type: "number",
                  pattern: "[0-9]*",
                },
              }}
              placeholder={"0.00"}
              fullWidth
              type="number"
              value={employeeData.SueldoDiario}
              onChange={(e) => {
                setEmployeeData({
                  ...employeeData,
                  SueldoDiario: Number(e.target.value),
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <PerceptionsTable
          perceptionsArray={ConceptosToTable(
            "Perception",
            employeeData.Perceptions
          )}
          setPerceptionArray={setPerceptions}
        />
      </Grid>
      <Grid item xs={12}>
        <DeductionsTable
          deductionsArray={ConceptosToTable(
            "Deduction",
            employeeData.Deductions
          )}
          setDeductionsArray={setDeductions}
        />
      </Grid>

      <Grid item xs={8}></Grid>
      <Grid item xs={4} sx={{ alignItems: "right", alignContent: "right" }}>
        <Button variant="contained" onClick={handleSaveEmployee}>
          {isEdition ? "Editar" : "Agregar"} empleado
        </Button>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
});

export default EmployeeRegistrationForm;
