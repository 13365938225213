import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  TextField,
  Autocomplete,
  Typography,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
  Container,
  Paper,
  Stepper,
  Step,
  Grid,
  StepLabel,
  Box,
  Stack,
  Backdrop,
  StepContent,
} from "@mui/material";
import { AlertDialog } from "../../../../components/userFeedback/AlertDialog.jsx";
import { useInvoiceContext } from "../../../../hooks/useInvoiceContext.js";
import useAuth from "../../../../hooks/useAuth.js";
import { useNavigate } from "react-router";
import {
  getInvoiceTypes,
  getPaymentForms,
  getPaymentMethods,
  getUses,
} from "../../../../services/modules/invoices/catalogs.js";
import {
  getExpeditionPLC,
  getIssuers,
} from "../../../../services/modules/invoices/issuers.js";
import { getCustomers } from "../../../../services/modules/invoices/organization.js";
//*INVOICES OBJECTS
import invoiceJson from "../../utils/invoiceModels.json";
import { stepsObjects } from "../../utils/stepsObjects.js";
import {
  FileUploadButton,
  UploadCartaPorte,
} from "../../../../components/Forms/CartaPorte/UploadXMLCCP.jsx";
import { XMLinvoice } from "../../../../components/Forms/CartaPorte/UploadXMLCCP.jsx";
import { isSATCodesUsesInput } from "../../utils/regexp.js";

const InvoiceGeneralData = () => {
  const invoiceModel = {
    GeneralModel: {
      CfdiType: {},
      PaymentForm: "",
      PaymentMethod: "",
      ExpeditionPlace: "",
      Date: "",
      Folio: "",
      Issuer: {
        FiscalRegime: "",
        Rfc: "",
        Name: "",
      },
      Receiver: {
        Rfc: "",
        CfdiUse: "",
        Name: "",
        FiscalRegime: "",
        TaxZipCode: "",
      },
    },
    Models: {
      I: {
        Items: [],
      },
      E: {
        Items: [],
      },
    },
    Complementos: {
      CartaPorte20: {
        TranspInternac: "No",
        Ubicaciones: [
          {
            TipoUbicacion: "Origen",
            RFCRemitenteDestinatario: "",
            FechaHoraSalidaLlegada: "",
            Domicilio: {
              Pais: "MEX",
              CodigoPostal: "",
              Estado: "",
              Municipio: "",
              Localidad: "",
              Colonia: "",
              Calle: "",
            },
          },
          {
            TipoUbicacion: "Destino",
            RFCRemitenteDestinatario: "",
            FechaHoraSalidaLlegada: "",
            DistanciaRecorrida: "1",
            Domicilio: {
              Pais: "MEX",
              CodigoPostal: "",
              Estado: "",
              Municipio: "",
              Localidad: "",
              Colonia: "",
              Calle: "",
            },
          },
        ],
        Mercancias: {
          UnidadPeso: "",
          PesoBrutoTotal: 0,
          NumTotalMercancias: 0,
          Mercancia: [],
          Autotransporte: {
            PermSCT: "",
            NumPermisoSCT: "",
            IdentificacionVehicular: {
              ConfigVehicular: "",
              PlacaVM: "",
              AnioModeloVM: "",
            },
            Seguros: {
              AseguraRespCivil: "",
              PolizaRespCivil: "",
            },
          },
        },
        FiguraTransporte: [],
      },
      Payroll: {
        Type: "",
        PaymentDate: "",
        InitialPaymentDate: "",
        FinalPaymentDate: "",
        DaysPaid: 0,
        Issuer: {
          EmployerRegistration: "",
        },
        Employee: {
          Curp: "",
          SocialSecurityNumber: "",
          StartDateLaborRelations: "",
          ContractType: "",
          RegimeType: "",
          Unionized: false,
          TypeOfJourney: "",
          EmployeeNumber: "",
          Department: "",
          Position: "",
          PositionRisk: "",
          FrequencyPayment: "",
          Bank: "",
          BankAccount: "",
          BaseSalary: 0,
          DailySalary: 0,
          FederalEntityKey: "",
        },
        Perceptions: {
          Details: [
            {
              PerceptionType: "",
              Code: "",
              Description: "",
              TaxedAmount: 0,
              ExemptAmount: 0,
            },
          ],
        },
        Deductions: {
          Details: [
            {
              DeduccionType: "",
              Code: "",
              Description: "",
              Amount: 0,
            },
            {
              DeduccionType: "",
              Code: "",
              Description: "",
              Amount: 0,
            },
            {
              DeduccionType: "",
              Code: "",
              Description: "",
              Amount: 0,
            },
          ],
        },
        OtherPayments: [
          {
            OtherPaymentType: "",
            Code: "",
            Description: "",
            Amount: 0,
            EmploymentSubsidy: {
              Amount: 0,
            },
          },
        ],
      },
      Payments: [
        {
          Date: "",
          PaymentForm: "",
          Amount: "",
          RelatedDocuments: [
            {
              Uuid: "",
              Folio: "",
              PaymentMethod: "",
              PartialityNumber: "",
              PreviousBalanceAmount: "",
              AmountPaid: "",
            },
          ],
        },
      ],
    },
  };

  //*DATA CONTEXTS HOOKS
  const { invoiceData, setInvoiceData, setSteps } = useInvoiceContext();
  const { userid, logout } = useAuth();
  //*DATA STATES
  const [options, setOptions] = useState({
    issuerList: [],
    typesList: [],
    expeditionPlc: [],
    clients: [],
    usesOptions: [],
    fiscalRegimens: [],
    paymentForms: [],
    paymentMethods: [],
    currencies: [],
    employeesOptions: [],
  });
  const [usesInput, setUsesInput] = useState("");
  const [complements, setComplements] = useState([
    "De pago",
    "Comercio exterior",
    "Donativos",
    "Leyendas fiscales",
    "Carta Porte",
  ]);

  //*RENDER STATES
  const [selected, setSelected] = useState({
    issuer: "",
    place: "",
    type: "",
    client: "",
    use: { Value: "", Name: "" },
    paymentForm: "",
    paymentMethod: "",
    complement: "",
    Folio: "",
  });
  const [loading, setLoading] = useState({
    issuers: true,
    nameIds: true,
    clients: true,
    expePlc: true,
    paymentMethods: true,
    paymentForms: true,
    uses: true,
    employees: true,
  });
  const [backdrop, setBackdrop] = useState(false);
  const [error, setError] = useState({
    Folio: {
      failed: false,
      message: "",
    },
    issuer: {
      failed: false,
      message: "",
    },
    place: {
      failed: false,
      message: "",
    },
    type: {
      failed: false,
      message: "",
    },
    client: {
      failed: false,
      message: "",
    },
    use: {
      failed: false,
      message: "",
    },
    paymentForm: {
      failed: false,
      message: "",
    },
    paymentMethod: {
      failed: false,
      message: "",
    },
    complement: {
      failed: false,
      message: "",
    },
  });
  const [withPayment, setWithPayment] = useState(false);
  const [withComplement, setWithComplement] = useState(
    selected.complement !== undefined && selected.complement !== ""
  );
  const [renderComplementInput, setRenderComplementInput] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    actions: [{ label: "", execute: () => {} }],
    keep: false,
  });
  const [errorAlert, setErrorAlert] = useState({
    issuer: {
      failed: false,
      message: "",
    },
    customers: {
      failed: false,
      message: "",
    },
    invoiceTypes: {
      failed: false,
      message: "",
    },
    expeditionPlaces: {
      failed: false,
      message: "",
    },
    paymentMethods: {
      failed: false,
      message: "",
    },
    paymentForms: {
      failed: false,
      message: "",
    },
    uses: {
      failed: false,
      message: "",
    },
  });

  const navigate = useNavigate();

  //begin stepper changes
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Información General", "Información Específica"];

  const handleNext = () => {
    setActiveStep(activeStep + 1);

    if (withComplement) {
      setRenderComplementInput(true);
    }
  };
  const handleBack = () => {
    if (selected.type && selected.type.Type === "I") {
      if (!withPayment) setWithPayment(true);
    }

    setRenderComplementInput(false);

    setActiveStep(activeStep - 1);
  };

  function getStepContent2(step) {
    switch (step) {
      case 0:
        return generalDataForm();
      case 1:
        return customerDataForm();
      default:
        throw new Error("Unknown step");
    }
  }

  //end stepper changes

  const handleUsesInput = (e) => {
    setUsesInput(e.target.value);
  };

  const FocusOpenAutocomplete = (props) => {
    const [open, setOpen] = useState(false);

    const handleFocus = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <Autocomplete
        {...props}
        open={open}
        onOpen={handleFocus}
        onClose={handleClose}
        renderInput={(params) => {
          return (
            <TextField {...params} {...props.textfield} onFocus={handleFocus} />
          );
        }}
      />
    );
  };

  //steps begin
  const generalDataForm = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          Datos Generales
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              id="issuer"
              sx={{ minWidth: "100%" }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={error.issuer.failed}
                    helperText={error.issuer.message}
                    label="Emisor"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading.issuers ? <CircularProgress /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => option.Name || ""}
              options={options.issuerList}
              onChange={(e, v, r) => {
                switch (r) {
                  case "selectOption":
                    handleChangeSelection(e, v, r);
                    break;
                  case "clear":
                    setSelected({ ...selected, issuer: "" });
                    break;
                  default:
                    break;
                }
              }}
              isOptionEqualToValue={(o, v) => {
                if (v === "") {
                  return true;
                } else {
                  return o.Name === v.Name;
                }
              }}
              value={selected.issuer}
              clearOnBlur
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Folio"
              id="Folio"
              variant="filled"
              error={error.Folio.failed}
              helperText={error.Folio.message}
              sx={{ minWidth: "100%" }}
              inputProps={{ maxLength: 10 }}
              onChange={(e) =>
                handleChangeSelection(e, e.target.value, "input")
              }
              value={selected.Folio}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              id="place"
              sx={{ minWidth: "100%" }}
              options={options.expeditionPlc}
              autoComplete
              clearText="Limpiar"
              onChange={(e, v, r) => {
                switch (r) {
                  case "clear":
                    setSelected({ ...selected, place: "" });
                    break;
                  case "selectOption":
                    handleChangeSelection(e, v, r);
                    break;
                  default:
                    console.log("oh no");
                    break;
                }
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={error.place.failed}
                    helperText={error.place.message}
                    label="Lugar de expedición"
                    name="ExpeditionPlace"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading.expePlc ? <CircularProgress /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => option.Name || ""}
              renderOption={(props, option, state) => {
                return <h4 {...props}>{`${option.Name}`}</h4>;
              }}
              isOptionEqualToValue={(option, value) => {
                if (value === "") {
                  return true;
                }

                return option.Name === value.Name;
              }}
              value={selected.place}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Tipo de operación
            </Typography>
            <Autocomplete
              id="type"
              sx={{ width: "100%" }}
              options={options.typesList}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={error.type.failed}
                    name="type"
                    helperText={error.type.message}
                    label="Nombre del CFDI"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading.nameIds ? <CircularProgress /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => {
                return option.Name || "";
              }}
              isOptionEqualToValue={(option, value) => {
                if (value === "") {
                  return true;
                }
                return option.Name === value.Name;
              }}
              onChange={(e, v, r) => {
                switch (r) {
                  case "selectOption":
                    handleChangeSelection(e, v, r);
                    break;
                  case "clear":
                    setSelected({ ...selected, type: "" });
                    break;
                  default:
                    break;
                }
              }}
              value={selected.type}
            />
          </Grid>
          {withPayment && (
            <Grid item xs={12}>
              <Box sx={{ marginTop: "2vh" }}>
                <Typography variant="h6" gutterBottom>
                  Datos del pago
                </Typography>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Autocomplete
                      id="paymentMethod"
                      autoComplete
                      sx={{ width: "100%" }}
                      options={options.paymentMethods}
                      getOptionLabel={(option) => {
                        return option.Name || "";
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            error={error.paymentMethod.failed}
                            helperText={error.paymentMethod.message}
                            sx={{ width: "100%" }}
                            label="Método de pago"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading.paymentMethods ? (
                                    <CircularProgress />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }

                        return option.Name === value.Name;
                      }}
                      onChange={(e, v, r) => {
                        switch (r) {
                          case "selectOption":
                            handleChangeSelection(e, v, r);
                            break;
                          case "clear":
                            setSelected({ ...selected, paymentMethod: "" });
                            break;
                          default:
                            break;
                        }
                      }}
                      value={selected.paymentMethod}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="paymentForm"
                      autoComplete
                      sx={{ width: "100%" }}
                      options={options.paymentForms}
                      getOptionLabel={(option) => {
                        return option.Name || "";
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            error={error.paymentForm.failed}
                            helperText={error.paymentForm.message}
                            sx={{ width: "100%" }}
                            label="Forma de pago"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading.paymentForms ? (
                                    <CircularProgress />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }
                        return option.Name === value.Name;
                      }}
                      onChange={(e, v, r) => {
                        switch (r) {
                          case "selectOption":
                            handleChangeSelection(e, v, r);
                            break;
                          case "clear":
                            setSelected({ ...selected, paymentForm: "" });
                            break;
                          default:
                            break;
                        }
                      }}
                      value={selected.paymentForm}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const customerDataForm = () => {
    const getUseFromType = (type) => {
      console.log("type mijo", type);
      switch (type.Name) {
        case "Recibo de Nómina":
          console.log(options.usesOptions[0]);
          setSelected({ ...selected, use: options.usesOptions[0] });
          return options.usesOptions[0];
        default:
          return "";
      }
    };
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              Uso
            </Typography>
            <Autocomplete
              id="use"
              sx={{ width: "100%" }}
              error={error.use.failed}
              helperText={error.use.message}
              autoComplete
              clearText="Limpiar"
              onChange={handleChangeSelection}
              options={options.usesOptions}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={error.use.failed}
                    helperText={error.use.message}
                    label="Uso del CFDI"
                    name="Cfdiuse"
                    onChange={handleUsesInput}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading.uses ? <CircularProgress /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => {
                if (isSATCodesUsesInput(usesInput)) {
                  return option.Value;
                }

                return option.Name;
              }}
              renderOption={(props, option, state) => {
                return <li {...props}>{`${option.Name} - ${option.Value}`}</li>;
              }}
              isOptionEqualToValue={(option, value) => {
                if (value.Value === "" && value.Name === "") {
                  return true;
                }
                return (
                  option.Name === value.Name || option.Value === value.Value
                );
              }}
              value={selected.use || getUseFromType(selected.type)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="client"
              sx={{ width: "100%" }}
              options={options.clients}
              getOptionLabel={(opt) => {
                return opt.TaxName || "";
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={error.client.failed}
                    helperText={error.client.message}
                    label={
                      selected.type.Name === "Recibo de Nómina"
                        ? "Empleado"
                        : "Clientes / receptores"
                    }
                    sx={{ width: "100%" }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading.clients ? <CircularProgress /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              isOptionEqualToValue={(o, v) => {
                if (v === "") {
                  return true;
                }

                return o.Name === v.Name;
              }}
              onChange={handleChangeSelection}
              value={selected.client}
            />
          </Grid>

          <Grid item xs={12}></Grid>
        </Grid>
      </>
    );
  };

  const handleShowDialog = (bool) => {
    setDialog({
      ...dialog,
      open: bool,
    });
  };

  const handleWithComplement = () => {
    setWithComplement(!withComplement);
    setRenderComplementInput(!withComplement);
  };

  const handleTypeSelection = (v) => {
    if (v && v.Type === "I") {
      //*If type selected === I, then show payment methopds and forms autocomplete input
      setWithPayment(true);

      setWithComplement(false);
      setSelected({
        ...selected,
        use: { Value: "", Name: "" },
        type: v,
        complement: "",
      });
      return;
    }

    if (v && v.Type === "P") {
      //*If type selected === P, then configure the next fields
      setWithPayment(false);

      setSelected({
        ...selected,
        use: {
          Value: "CP01",
          Name: "Pagos",
          Natural: true,
          Moral: true,
        },
        type: v,
        complement: "De pago",
      });

      setWithComplement(true);
      return;
    }

    //*If type !== I or is unselecting, then disable payment methods and forms selection
    setWithPayment(false);
  };

  const handleChangeSelection = useCallback((e, v, r) => {
    const id = e.target.id.split("-")[0];

    switch (r) {
      case "selectOption":
        if (error[id].failed) {
          setError({
            ...error,
            [id]: {
              failed: false,
              message: "",
            },
          });
        }

        setSelected({
          ...selected,
          [id]: v,
        });

        setInvoiceData({
          ...invoiceData,
          [id]: v,
        });

        if (id === "type") {
          handleTypeSelection(v);
        }
        break;
      case "input":
        setSelected({
          ...selected,
          [id]: v,
        });
        break;

      default:
        break;
    }
  });

  const mapErrors = (values) => {
    const errors = [];
    if (values) {
      Object.entries(values).forEach((entry) => {
        if (
          (entry[0] === "paymentForm" || entry[0] === "paymentMethod") &&
          withPayment
        ) {
          if (entry[1] === "" || entry[1] === undefined || entry[1] === null) {
            errors.push(entry);
          }
          if (typeof entry[1] === "object") {
            mapErrors(entry[1]);
          }
          return;
        }
        if (
          (entry[0] === "complement" || entry[0] === "complement") &&
          withComplement
        ) {
          if (entry[1] === "" || entry[1] === undefined || entry[1] === null) {
            errors.push(entry);
          }
          if (typeof entry[1] === "object") {
            mapErrors(entry[1]);
          }
          return;
        }

        if (entry[0] === "paymentForm" || entry[0] === "paymentMethod") {
          return;
        } else if (entry[0] === "complement" || entry[0] === "complement") {
          return;
        } else {
          if (entry[1] === "" || entry[1] === undefined || entry[1] === null) {
            errors.push(entry);
          }
          if (typeof entry[1] === "object") {
            mapErrors(entry[1]);
          }
        }
      });
    }

    let errorMapped;
    if (errors.length > 0) {
      errors.forEach((err) => {
        errorMapped = {
          ...errorMapped,
          [err[0]]: {
            failed: true,
            message: "Corrige este valor.",
          },
        };
      });
    }

    return { errors, errorMapped };
  };

  const addGeneralToInvoiceModel = () => {
    try {
      //*Payments are required to be added to the model?
      if (withPayment) {
        invoiceModel.GeneralModel.PaymentForm = selected.paymentForm.Value;
        invoiceModel.GeneralModel.PaymentMethod = selected.paymentMethod.Value;
      }

      //*General data
      invoiceModel.GeneralModel.CfdiType = selected.type;

      console.log(selected);
      invoiceModel.GeneralModel.ExpeditionPlace = selected.place;
      // invoiceModel.GeneralModel.Date = new Date().toISOString()
      invoiceModel.GeneralModel.Folio = selected.Folio;

      //*Issuer data
      delete selected.issuer.Address;
      invoiceModel.GeneralModel.Issuer = selected.issuer;

      //*Customer data
      invoiceModel.GeneralModel.Receiver.Rfc = selected.client.Rfc;
      invoiceModel.GeneralModel.Receiver.FiscalRegime =
        selected.client.FiscalRegime.Value;
      invoiceModel.GeneralModel.Receiver.Name = selected.client.TaxName;

      if (selected.client.Addresses.length === 1) {
        invoiceModel.GeneralModel.Receiver.TaxZipCode =
          selected.client.Addresses[0].ZipCode;

        invoiceModel.GeneralModel.Receiver.Address =
          selected.client.Addresses[0];
      }

      invoiceModel.GeneralModel.Receiver.CfdiUse = selected.use;

      invoiceModel.Models = {
        [selected.type.Type]: invoiceJson.Models[selected.type.Type],
      };

      if (withComplement) {
        //*If it is required, the complement must be added to the invoice object
        if (
          selected.complement === "Carta Porte" &&
          Object.keys(XMLinvoice).length > 0
        ) {
          console.log("Must include the XML response in default values");
          XMLinvoice.Mercancias.Mercancia.forEach((object, index) => {
            XMLinvoice.Mercancias.Mercancia[
              index
            ].BienesTransp = `${object.BienesTransp}`;
          });

          invoiceModel.Complementos.CartaPorte20 = XMLinvoice;

          delete invoiceModel.Complementos.Payroll;
          delete invoiceModel.Complementos.Payments;
          return;
        }

        if (selected.complement === "Carta Porte") {
          invoiceModel.Complementos.CartaPorte20 =
            invoiceJson.Complementos.CartaPorte20;
          delete invoiceModel.Complementos.Payroll;
          delete invoiceModel.Complementos.Payments;
          return;
        }

        if (selected.complement === "De pago") {
          invoiceModel.Complementos.Payments = [
            invoiceJson.Complementos.Payments,
          ];
          delete invoiceModel.Complementos.CartaPorte20;
          delete invoiceModel.Complementos.Payroll;
          return;
        }
      } else {
        delete invoiceModel.Complementos.Payments;
        delete invoiceModel.Complementos.CartaPorte20;
        delete invoiceModel.Complementos.Payroll;
      }
    } catch (err) {
      console.log("Error while mapping data to invoice model json");
      console.log(err);
    }
  };

  const buildInvoiceSteps = () => {
    let generalSeps;
    let partialSteps;
    let complementSteps;
    let finalSteps;
    //*Create steps must exist in every invoice creation process.
    generalSeps = stepsObjects["Create"];

    if (
      withComplement &&
      selected.complement !== "" &&
      selected.complement !== undefined
    ) {
      partialSteps = stepsObjects[selected.type.Name].map((obj) => {
        return { label: obj.label, component: obj.component };
      });

      complementSteps = stepsObjects["complementos"].find(
        (obj) => obj[selected.complement]
      )[selected.complement];

      finalSteps = Array.prototype.concat(
        complementSteps,
        partialSteps,
        generalSeps
      );
      return finalSteps;
    } else {
      partialSteps = stepsObjects[selected.type.Name].map((obj) => {
        return { label: obj.label, component: obj.component };
      });

      finalSteps = Array.prototype.concat(partialSteps, generalSeps);
    }

    return finalSteps;
  };

  const handleInvoiceSteps = () => {
    const { errors, errorMapped } = mapErrors(selected);

    if (errors.length > 0) {
      setError({
        ...error,
        ...errorMapped,
      });
      setDialog({
        open: true,
        title: "Datos faltantes",
        message:
          "Revisa la configuración, puede que falte información en el paso anterior",
        actions: [
          {
            label: "ok",
            execute: () => {
              setDialog({ ...dialog, open: false });
            },
          },
        ],
      });
      return;
    }

    try {
      addGeneralToInvoiceModel();
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceModel));

      const steps = buildInvoiceSteps();
      setSteps(steps);
      setInvoiceData({
        ...invoiceData,
        items: [],
      });
      // setInstance(instance)
    } catch (err) {
      setDialog({
        ...dialog,
        open: true,
        title: "Hubo un error al mapear la configuracion.",
        message: "Contacta a soporte.",
      });
    }

    console.log("Navigating...");
    console.log(window.localStorage);
    navigate(`/${userid.claims.rol}/invoices/new/steps`);
  };

  function showDialogEmptyIssuers() {
    let msg = "";
    let fromExisting = false;
    let existingData = null;

    if (!userid.claims.issuerAdded) {
      msg = `Hubo un problema al registrarte como emisor en el servidor,
            itenta cargar tus certificados de nuevo. Si el error persiste contacta a soporte.`;
      fromExisting = true;
      existingData = options.issuerList[0];
      console.log(options);
    }

    if (userid.claims.issuerAdded) {
      msg =
        "No se han guardado los certificados fiscales que proporcionaste. Intenta cargar de nuevo tus certtificados.";
    }

    setDialog((dialog) => {
      return {
        ...dialog,
        open: true,
        title: "Sin emisores registrados en tu organización",
        message: msg,
        actions: [
          {
            label: "Registrar",
            execute: () =>
              navigate(
                `/${userid.claims.rol}/invoices/NuevoEmisor?fromExisting=${fromExisting}`,
                { state: existingData }
              ),
          },
          {
            label: "Cancelar",
            execute: () => navigate(`/${userid.claims.rol}/welcome`),
          },
        ],
        keep: false, //borrar  true
      };
    });
  }

  function showDialogEmptyCustomers() {
    setDialog((dlg) => {
      return {
        ...dlg,
        open: true,
        title: "Sin clientes registrados",
        message:
          "No has registrado clientes, para poder facturar deberas registrar al menos un cliente.",
        actions: [
          {
            label: "Registrar",
            execute: () => navigate(`/${userid.claims.rol}/customers/new`),
          },
        ],
      };
    });
  }

  const showFailedRequestAlert = (requestId, err) => {
    const messagesById = {
      issuers: {
        message: "Error de solicitud: Emisores",
        error: "Detalle " + err,
      },
      customers: {
        message: "Error de solicitud: clientes",
        error: "Detalle " + err,
      },
      invoiceTypes: {
        message: "Error de solicitud: tipos de factura",
        error: "Detalle " + err,
      },
      expeditionPlaces: {
        message: "Error de solicitud: lugares de expedición",
        error: "Detalle " + err,
      },
      paymentMethods: {
        message: "Error de solicitud: métodos de pago",
        error: "Detalle " + err,
      },
      paymentForms: {
        message: "Error de solicitud: formas de pago",
        error: "Detalle " + err,
      },
      uses: {
        message: "Error de solicitud: usos",
        error: "Detalle " + err,
      },
    };

    setErrorAlert((errAlt) => {
      return {
        ...errAlt,
        [requestId]: {
          ...errAlt[requestId],
          failed: true,
          message: messagesById[requestId].message,
          detail: messagesById[requestId].error,
        },
      };
    });
  };

  const getData = () => {
    //*SAT CATALOGS DATA
    return Promise.all([
      getIssuers(userid)
        .then((res) => {
          setLoading((obj) => {
            return { ...obj, issuers: false };
          });
          if (res.data.data.length === 0) {
            showDialogEmptyIssuers();
          }
          if (res.data.data.length > 0) {
            setOptions((obj) => {
              return { ...obj, issuerList: res.data.data };
            });
          }
        })
        .catch((err) => {
          showFailedRequestAlert("issuers", err);
        }),

      getCustomers(userid)
        .then(({ data }) => {
          setOptions((obj) => {
            return { ...obj, clients: data.data };
          });
          setLoading((obj) => {
            return { ...obj, clients: false };
          });
          if (data.data.length === 0) {
            showDialogEmptyCustomers();
          }
        })
        .catch((err) => {
          showFailedRequestAlert("customers", err);
        }),

      getInvoiceTypes(userid)
        .then(({ data }) => {
          console.log("invoicetypes obj structure", data);
          let arr = [
            { Name: "Factura", Type: "I", Value: "1" },
            { Name: "Recibo de Pago", Type: "P", Value: "18" },
          ];
          setOptions((obj) => {
            return { ...obj, typesList: arr };
          });
          setLoading((obj) => {
            return { ...obj, nameIds: false };
          });
        })
        .catch((err) => {
          showFailedRequestAlert("invoiceTypes", err);
        }),

      getExpeditionPLC(userid)
        .then(({ data }) => {
          setOptions((obj) => {
            return { ...obj, expeditionPlc: data };
          });
          setLoading((obj) => {
            return { ...obj, expePlc: false };
          });
        })
        .catch((err) => {
          showFailedRequestAlert("expeditionPlaces", err);
        }),

      getPaymentMethods(userid)
        .then(({ data }) => {
          setOptions((obj) => {
            return { ...obj, paymentMethods: data };
          });
          setLoading((obj) => {
            return { ...obj, paymentMethods: false };
          });
        })
        .catch((err) => {
          showFailedRequestAlert("paymentMethods", err);
        }),

      getPaymentForms(userid)
        .then(({ data }) => {
          setOptions((obj) => {
            return { ...obj, paymentForms: data };
          });
          setLoading((obj) => {
            return { ...obj, paymentForms: false };
          });
        })
        .catch((err) => {
          showFailedRequestAlert("paymentForms", err);
        }),

      getUses(userid)
        .then(({ data }) => {
          setOptions((obj) => {
            return { ...obj, usesOptions: data };
          });
          setLoading((obj) => {
            return { ...obj, uses: false };
          });
        })
        .catch((err) => {
          showFailedRequestAlert("uses", err);
        }),
    ]);
  };

  useEffect(() => {
    getData()
      .then((resolved) => {})
      .catch((err) => {
        console.log(err);
      });


    return () => {
      setDialog((d) => {
        return {
          ...d,
          open: false,
          title: "",
          message: "",
          actions: [],
        };
      });

      setLoading(() => {
        return {
          issuers: false,
          nameIds: false,
          clients: false,
          expePlc: false,
          paymentMethods: false,
          paymentForms: false,
          uses: false,
        };
      });

      setOptions((options) => {
        return {
          ...options,
          issuerList: [],
          typesList: [],
          expeditionPlc: [],
          clients: [],
          usesOptions: [],
          fiscalRegimens: [],
          paymentForms: [],
          paymentMethods: [],
          currencies: [],
        };
      });
    };
  }, [userid, logout, navigate]);

  useEffect(() => {
    if (!loading.issuers && !userid.claims.issuerAdded) {
      showDialogEmptyIssuers();
    }
  }, [options.issuerList]);

  useEffect(() => {
    if (
      loading.clients ||
      loading.issuers ||
      loading.expePlc ||
      loading.nameIds ||
      loading.paymentForms ||
      loading.paymentMethods ||
      loading.uses
    )
      setBackdrop(true);
    else setBackdrop(false);
  }, [loading]);

  useEffect(() => {
    if (selected.type) {
      if (selected.type.Type === "I") setComplements(["Carta Porte"]);
      if (selected.type.Type === "P") setComplements(["De pago"]);
    }
  }, [selected.type]);

  useEffect(() => {
    let GeneralDataCheck = JSON.parse(
      window.localStorage.getItem("invoiceModel")
    );
    if (GeneralDataCheck) {
      window.localStorage.removeItem("invoiceModel");
      window.localStorage.removeItem("relDocs");
      window.localStorage.removeItem("steps");
    }
  }, []);

  useEffect(() => {
    if (options.expeditionPlc.length !== 0) {
      const newSelected = { ...selected, place: options.expeditionPlc[0] };
      setSelected(newSelected);
      setInvoiceData((invoiceData) => {
        return {
          ...invoiceData,
          ExpeditionPlace: options.expeditionPlc[0]?.ZipCode,
        };
      });
    }
  }, [options.expeditionPlc]);

  const contentRef = useRef(null);

  // Use the useEffect hook to manage focus when activeStep changes
  useEffect(() => {
    // Check if the contentRef exists and if it's currently in the DOM
    if (contentRef.current) {
      // Focus on the content when the step changes
      contentRef.current.focus();
    }
  }, [activeStep]);

  useEffect(() => {
    if (selected.paymentMethod?.Value === "PPD") {
      setSelected({
        ...selected,
        paymentForm: {
          Name: "Por definir",
          Value: "99",
        },
      });
    }
  }, [selected.paymentMethod]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AlertDialog
        updateOpenState={handleShowDialog}
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        actions={dialog.actions}
        keep={dialog.keep}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Generar Factura
          </Typography>

          <Stepper
            orientation="horizontal"
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <StepLabel>{label}</StepLabel>
                  </Grid>
                </Grid>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {
              <div
                ref={contentRef}
                tabIndex={activeStep}
                style={{ outline: "none" }}
              >
                {getStepContent2(activeStep)}
              </div>
            }
            {activeStep === steps.length - 1 ? (
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: "4vh" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"complemento"}
                        checked={withComplement}
                        onChange={handleWithComplement}
                        onKeyDown={(event) => {
                          console.log("event", event.key, event);
                          if (event.key === "Enter") {
                            handleWithComplement();
                          }
                        }}
                      />
                    }
                    label="Añadir Complemento"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            ) : null}
            {!!renderComplementInput && (
              <Grid item xs={12} md={12}>
                <Autocomplete
                  sx={{ width: "100%", marginY: "1vh" }}
                  id="complement"
                  options={complements}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        error={error.complement.failed}
                        helperText={error.complement.message}
                        label="Tipo de complemento"
                      />
                    );
                  }}
                  onChange={handleChangeSelection}
                  value={selected.complement}
                />
              </Grid>
            )}

            {!!(
              !!renderComplementInput && selected.complement === "Carta Porte"
            ) && (
              <Grid item xs={12}>
                <Stack alignItems="center" sx={{ marginY: "1vh" }} spacing={2}>
                  <Typography variant="p" color="GrayText">
                    Puede seleccionar un archivo XML o aceptar la configuración
                    para completar la información manualmente
                  </Typography>
                  <FileUploadButton />
                </Stack>
              </Grid>
            )}

            {!!(
              selected.complement === "Carta Porte" &&
              !!withComplement &&
              !!showUpload
            ) && (
              <Grid item xs={12} md={12}>
                <UploadCartaPorte />
              </Grid>
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Anterior
                </Button>
              )}

              <Button
                variant="contained"
                focusRipple
                onClick={
                  activeStep === steps.length - 1
                    ? handleInvoiceSteps
                    : handleNext
                }
                sx={{ mt: 3, ml: 1 }}
              >
                {activeStep === steps.length - 1
                  ? "Aceptar Configuración"
                  : "Siguiente"}
              </Button>
            </Box>
          </React.Fragment>
        </Paper>
      </Container>

      {/* 
                    There was some kind of monster here, 
                    but a hero called Grid came and Gridded all over it
            */}
    </div>
  );
};

export { InvoiceGeneralData };
