import * as React from "react";
import { LinePlot } from "@mui/x-charts/LineChart";
import { BarPlot } from "@mui/x-charts/BarChart";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { LineChart } from "@mui/x-charts/LineChart";
import { AllSeriesType } from "@mui/x-charts/models";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { toIsoHelper } from "../../../issue/invoiceData/Nomina/NominaDateTypeComponent";
import { Box, Container } from "@mui/material";

export default function ValueChart(props) {
  const validData = props.data.data.filter((item) => item.steps !== 0);
  const MIN_WIDTH = validData.length * props.segmentWidth;

  const series = [
    {
      type: "line",
      yAxisKey: "value",
      label: "Ingreso pagado",
      curve: "catmullRom",
      color: "green",
      data: props.data.data.map((item) => item.totalPaidIncome), // Use totalPaidIncome from the response data
    },
    {
      type: "line",
      yAxisKey: "value",
      label: "Ingreso Facturado",
      curve: "catmullRom",
      color: "blue",
      data: props.data.data.map((item) => item.totalIssuedIncome), // Use totalPaidIncome from the response data
    },
  ];

  const formatDate = (date) => {
    return date.toLocaleDateString("es-MX", {
      day: "2-digit",
      month: "2-digit",
    });
  };

  const formatValue = (value) => {
    return `$${value.toFixed(2)}`;
  };

  const formatLargeValue = (value) => {
    return `$${(value / 1000).toFixed(0)}K`;
  };

  const determineXAxisInterval = () => {
    const { initial, final } = props.data.dateRange;
    const startDate = new Date(initial);
    const endDate = new Date(final);
    const diffInDays = Math.abs((endDate - startDate) / (1000 * 60 * 60 * 24));

    return diffInDays > 14 ? 7 : 1;
  };

  console.log(props.data);
  return (
    <Box
      maxWidth={"100%"}
      sx={{ padding: "3%", overflow: "auto", justifyContent: "center" }}
    >
      <Box sx={{ flex: "0 0 2400 600" }}>
        {" "}
        {/* Container for horizontal scrolling */}
        <LineChart
          title="Ingresos"
          series={series}
          height={props.height}
          width={MIN_WIDTH}
          viewBox={`-20 -20 ${MIN_WIDTH} ${props.height}`}
          sx={{
            "--ChartsLegend-itemWidth": "154px",
            width: "100%",
            margin: "auto",
            viewBox: `-20 -20 ${MIN_WIDTH} ${props.height}`,
          }}
          xAxis={[
            {
              id: "dates",
              data: props.data.data.map((item, index) => {
                const { initial, final } = item.dateRange;
                let newInitial = new Date(initial);
                let newFinal = new Date(final);

                return { date: newInitial, nextDate: newFinal, index };
              }),
              scaleType: "band",
              valueFormatter: (item) => {
                return `${formatDate(new Date(item.date))} - ${formatDate(
                  new Date(item.nextDate)
                )}`;
              },
              tickSpacing: 200,
            },
          ]}
          yAxis={[
            {
              id: "value",
              scaleType: "linear",
              data: validData.map((item) => item.totalPaidIncome),
              label: "Ingresos",
              maxTicks: props.yTicks,
              valueFormatter: formatLargeValue,
            },
          ]}
          yxisTickCount={props.yTicks}
        >
          {/*<BarPlot />
      <LinePlot  sx={{maxWidth:"100%"}}/>*/}
          <ChartsXAxis label="Fechas" position="bottom" axisId="dates" />
        </LineChart>
      </Box>
    </Box>
  );
}

ValueChart.defaultProps = {
  data: {
    dateRange: {
      initial: toIsoHelper(new Date("2023-02-02")),
      final: toIsoHelper(new Date("2023-03-05")),
    },
    totalIssued: 10,
    totalPaid: 8,
    totalIssuedIncome: 1000,
    totalPaidIncome: 850,
    data: [
      {
        steps: 7,
        totalIssued: 4,
        totalPaid: 3,
        totalIssuedIncome: 400,
        totalPaidIncome: 350,
        invoices: [],
      },
      {
        steps: 7,
        totalIssued: 6,
        totalPaid: 5,
        totalIssuedIncome: 600,
        totalPaidIncome: 500,
        invoices: [],
      },
      // More data points in the same format
    ],
  },
  width: 500,
  height: 800,
  yTicks: 5,
  segmentWidth: 150,
};
