import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import ValueChart from './ValueChart'; // Assuming ValueChart and VolumeChart components are imported correctly
import VolumeChart from './VolumeChart'; // Replace the import paths with the actual paths to the components.

const DashboardChartComponent = (props) => {
  const {
    testResponse,
    showAmountChart,
    showChunksTextfield,
    graphValue,
    debouncedGraphValue,
    handleChange,
    setShowAmountChart,
    setShowChunksTextfield,
  } = props;

  return (<>
    {testResponse && (
    <Grid container spacing={1}>
      {!showAmountChart && (
        <Grid item xs={12} sm={8}>
          <ValueChart data={testResponse} segmentWidth={150} height={600} />
        </Grid>
      )}
      {showAmountChart && (
        <Grid item xs={12} sm={8}>
          <VolumeChart data={testResponse} segmentWidth={150} height={600} />
        </Grid>
      )}

      <Grid item xs={12} sm={4} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Grid container spacing={4} sx={{ textAlign: "center" }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <Typography variant="body1" color="primary">
              {"No de segmentos"}
            </Typography>
            <Switch
              checked={showChunksTextfield}
              onChange={() => setShowChunksTextfield(!showChunksTextfield)}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
            <Typography variant="body1" color="primary">
              {"Días por segmento"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' align='center'>Valor:{debouncedGraphValue}</Typography>
          </Grid>
          {!showChunksTextfield && (
            <Grid item xs={12}>
              <TextField
                label="Segmentos"
                placeholder="Segmentos"
                onChange={handleChange}
              />
            </Grid>
          )}
          {showChunksTextfield && (
            <Grid item xs={12}>
              <TextField
                label="Días por segmento"
                placeholder="Dias por segmento"
                onChange={handleChange}
              />
            </Grid>
          )}

          <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Typography variant="body1" color="primary">
              {"Ingresos"}
            </Typography>
            <Switch
              checked={showAmountChart}
              onChange={() => setShowAmountChart(!showAmountChart)}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
            <Typography variant="body1" color="primary">
              {"Facturas"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )}
  </>
  );
};

export default DashboardChartComponent;