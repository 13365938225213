import React from 'react'

const AppContext = React.createContext()


const AppContextProvider = ({children}) => {
    let employeesVerificationResponse = []
    return (
        <AppContext.Provider value={
            {
                employeesVerificationResponse,
            }
        }>
            {children}
        </AppContext.Provider>
    )
}

export {AppContext, AppContextProvider}