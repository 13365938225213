import { API_CONTEXT } from "./invoices"

export const catalogs = {
    "invoiceTypes":             API_CONTEXT("catalog","invoiceTypes"),
    "fiscalRegimes":            API_CONTEXT("catalog","fiscalRegimes"),
    "regimenTypes":             API_CONTEXT("catalog","regimenTypes"),
    "paymentMethods":           API_CONTEXT("catalog","paymentMethods") ,
    "paymentForms":             API_CONTEXT("catalog","paymentForms") ,
    "paymentFrequencies":       API_CONTEXT("catalog","paymentFrequencies") ,
    "currencies":               API_CONTEXT("catalog","currencies") ,
    "uses":                     API_CONTEXT("catalog","uses"),
    "ProductsOrServices":       API_CONTEXT("catalog","productsOrServices"),
    "units":                    API_CONTEXT("catalog","units"),
    "postalCodes":              API_CONTEXT("catalog","postalCodes"),
    "positionRisks":            API_CONTEXT("catalog","positionRisks"),
    "contractTypes":            API_CONTEXT("catalog","contractTypes"),
    "banks":                    API_CONTEXT("catalog","banks"),
    "perceptions":              API_CONTEXT("catalog","perceptions"),
    "deductions":               API_CONTEXT("catalog","deductions"),
    "otherPayments":            API_CONTEXT("catalog","otherPayments"),
    "typesOfJourney":           API_CONTEXT("catalog","typesOfJourney"),
    "federalEntityKeys":        API_CONTEXT("catalog","federalEntityKeys"),
    "transportServicesCodes":   API_CONTEXT("catalog","transportServicesCodes"),
    

}
export const getInvoiceTypes = (user, credential) => {
    const requestInvoiceTypesFunction = catalogs["invoiceTypes"]

    return requestInvoiceTypesFunction(user, credential)
}

export const getBanks = (user, credential) => {
    const requestBanks = catalogs["banks"]

    return requestBanks(user, credential)
}

export const getRegimes = (user, credential) => {
    const requestRegimes = catalogs["fiscalRegimes"]

    return requestRegimes(user, credential)
} 

export const getRegimenTypes = (user, credential) => {
    const requestRegimes = catalogs["regimenTypes"]

    return requestRegimes(user, credential)
} 

export const getPositionRisks = (user, credential) => {
    const requestRisks = catalogs["positionRisks"]

    return requestRisks(user, credential)
}

export const getContractTypes = (user, credential) => {
    const requestContracts = catalogs["contractTypes"]

    return requestContracts(user, credential)
} 

export const getPaymentMethods = (user, credential) => {
    const requestInvoicePaymentMethods = catalogs["paymentMethods"]

    return requestInvoicePaymentMethods(user, credential)
} 

export const getPaymentForms = (user, credential) => {
    const requestInvoicePaymentForms = catalogs["paymentForms"]

    return requestInvoicePaymentForms(user, credential)
} 

export const getPaymentFrequencies = (user, credential) => {
    const requestFrequencies = catalogs["paymentFrequencies"]

    return requestFrequencies(user, credential)
} 

export const getCurrencies = (user, credential) => {
    const requestCurrencies = catalogs["currencies"]

    return requestCurrencies(user, credential)
} 
export const getUses = (user, credential) =>{
    const requestInvoiceUses = catalogs["uses"]

    return requestInvoiceUses(user, credential)
}

export const getProductsServices = (user, credential, query) => {
    const requestProductsOrSservices = catalogs["ProductsOrServices"]

    return requestProductsOrSservices(user, credential, query)
}

export const getUnitsCatalog = (user, credential, query) => {
    const requestUnits = catalogs["units"]

    return requestUnits(user, credential, query)
}

export const getPostalCodesCatalog  = (user, credential, query) => {
    const requestPostalCodes = catalogs["postalCodes"]

    return requestPostalCodes(user,credential,query)
}

export const getPerceptions = (user, credential) =>{
    const requestPerceptions = catalogs["perceptions"]

    return requestPerceptions(user, credential)
}

export const getDeductions = (user, credential) =>{
    const requestDeductions = catalogs["deductions"]

    return requestDeductions(user, credential)
}

export const getOtherPayments = (user, credential) =>{
    const requestOP = catalogs["otherPayments"]

    return requestOP(user, credential)
}

export const getTypesOfJourney = (user, credential) =>{
    const requestTOJ = catalogs["typesOfJourney"]

    return requestTOJ(user, credential)
}

export const getFederalEntityKeys = (user, credential) =>{
    return catalogs["federalEntityKeys"]

}

export const getAllTransportProductServicesCodes = (user, credential) =>{
    
    const request = catalogs["transportServicesCodes"]

    return request(user, credential)

}