import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import { getFiscalRegimes } from "../../services/satCatalogs";

export default function OrganizationForm(props) {
  const [fiscalRegimesOptions, setFiscalRegimesOptions] = React.useState([]);
  const [loading, setLoaing] = React.useState({
    fiscalRegimes: true,
    filesProcessing: false,
  });

  const handleFiscalData = (e, v, r) => {
    let value = e.target.value;
    if (v !== undefined && r !== undefined) {
      value = v.Value;
    }

    props.setState({
      ...props.state,
      [e.target.id.split("-")[0]]: value,
    });
  };

  React.useEffect(() => {
    getFiscalRegimes()
      .then((data) => setFiscalRegimesOptions(() => data))
      .catch((err) => console.log(err));

    return () => {
      setFiscalRegimesOptions(() => []);
    };
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Información de la empresa
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="orgName"
            label="Nombre de tu empresa"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            onChange={handleFiscalData}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterTop>
            Información fiscal
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="taxName"
            label="Nombre Fiscal"
            helperText="Tal cual como aparece en la cédula fiscal"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
            onChange={handleFiscalData}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={fiscalRegimesOptions}
            getOptionLabel={(opt) => opt.Name}
            // onSelect={(e) => console.log(e)}
            id="fiscalRegime"
            required
            onChange={handleFiscalData}
            renderInput={(params) => {
              return (
                <TextField
                  label="Régimen Fiscal"
                  fullWidth
                  variant="standard"
                  {...params}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="rfc"
            label="RFC"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
            onChange={handleFiscalData}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="rfc2"
            label="Confirm RFC"
            helperText="Asegúrate de que la Homoclave es correcta"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
            onChange={handleFiscalData}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
