import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

const stripeObject = loadStripe(process.env.REACT_APP_STRIPE_PK)

export const StripeElementsProvider = (props) => {
    return (
        <Elements stripe={stripeObject} options={props.stripeOptions}>
            {props.children}
        </Elements>
    )
}