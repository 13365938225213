import { useState, useEffect } from "react";
import { requireRemolque } from "../CartaPorte/Transport";
import { Autocomplete, Grid, TextField } from "@mui/material";

const TransportServiceComponent = ({ options, setVehiculo, setRemolque, selected, setSelected }) => {  
  const [showRemolque, setShowRemolque] = useState(false);

  const handleSelectTruck = (e, v, r) => {
    if (r === "selectOption") {
      setVehiculo(v._id);
      setSelected("Unidad",v)
    }
  };

  const handleSelectremolque = (e, v, r) => {
    setRemolque(v._id);
    setSelected("Remolque",v)
  };
  useEffect(()=>{
    setShowRemolque(requireRemolque[selected.Unidad?.type]);

  },[selected.Unidad])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            autoComplete
            options={options.vehicles}
            sx={{ width: "100%" }}
            clearText="Limpiar"
            onChange={handleSelectTruck}
            renderInput={(params) => {
              return <TextField {...params} label="Vehículo" name="Vehicle" />;
            }}
            getOptionLabel={(option) =>
              "Modelo: " + option.model + "   Placa: " + option.placaTractor ||
              ""
            }
            isOptionEqualToValue={(o, v) => {
              if (v === "") {
                return true;
              }

              return v.id === o.id;
            }}
            value={selected.Unidad.model?selected.Unidad:null}
          />
        </Grid>
        {showRemolque && (
          <Grid item xs={12}>
            <Autocomplete
              autoComplete
              options={options.remolques}
              sx={{ width: "100%" }}
              clearText="Limpiar"
              onChange={handleSelectremolque}
              renderInput={(params) => {
                return (
                  <TextField {...params} label="Remolque" name="Remolque" />
                );
              }}
              getOptionLabel={(option) =>
                "Tipo: " + option.tipoRem + "   Placa: " + option.placaCaja ||
                ""
              }
              isOptionEqualToValue={(o, v) => {
                if (v === "") {
                  return true;
                }

                return v.id === o.id;
              }}
              value={selected.Remolque.tipoRem?selected.Remolque:null}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export { TransportServiceComponent };
