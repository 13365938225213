import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  IncomeTaxesStrategy,
  InvoiceItemsMappingContext,
} from "../../utils/mappingStrategies";
import { isSATCodesProductsInput } from "../../utils/regexp";
import { AlertDialog } from "../../../../components/userFeedback/AlertDialog";
import { calculateItemsTaxes } from "../../../../services/modules/invoices/taxCalculation";
import useAuth from "../../../../hooks/useAuth";
import {
  getProductsServices,
  getUnitsCatalog,
} from "../../../../services/modules/invoices/catalogs.js";
import { truncateNumber } from "../../utils/numerManipulation";

let taxesStructure = {
  taxes: [
    {
      Name: "IVA RET",
      IsRetention: "true",
      Rate: "0.04",
      Base: "",
      Total: "",
    },
    {
      Name: "IVA",
      Rate: "0.16",
      Base: "",
      Total: "",
    },
  ],
  Total: "",
};

let ItemsDataModel = [];

const InvoiceItemsData = ({props}) => {
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));

  const isIncome = invoiceJson.GeneralModel.CfdiType.Type === "I";
  const hasCartaPorte =
    typeof invoiceJson.Complementos.CartaPorte20 !== undefined;

  //*Contexts
  //const {invoiceData, setInvoiceData} = useInvoiceContext()
  const { userid } = useAuth();

  //*DATA STATES
  const taxObjects = [
    {
      id: "01",
      Value: "No objeto de impuesto",
    },
    {
      id: "02",
      Value: "Sí objeto de impuesto",
    },
    {
      id: "03",
      Value: "Sí objeto de impuesto, no obligado al desglose",
    },
  ];
  const [options, setOptions] = useState({
    productCodes: [],
    unitCodes: [],
    taxes: taxObjects,
  });
  const [counter, setCounter] = useState(
    invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items.length
  );

  //*RENDER STATES
  const [activeItem, setActiveItem] = useState({
    quantity:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .quantity
        : 1,
    product:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .product
        : "",
    unit:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .unit
        : "",
    description:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .description
        : "",
    price:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .price
        : "",
    taxObject:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .taxObject
        : taxObjects[1],
    uuid: undefined,
  });
  const [items, setItems] = useState(
    invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items.length !==
      0
      ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
      : []
  );
  const [showTaxes, setShowTaxes] = useState(false);

  const [backdrop, setBackdrop] = useState(false);

  const [emptyError, setEmptyError] = useState([])

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  //*INPUT LISTENERS
  const [productsInput, setProductsInput] = useState("");
  const [unitsInput, setUnitsInput] = useState("");
  const [addTry,setAddTry] = useState(false)

  //*USER FEEDBACK
  const [loading, setLoading] = useState({
    products: false,
    units: false,
    general: false,
  });
  const [alert, setAlert] = useState({
    state: "",
    message: "",
  });

  const handleItemsData = (e, v, r) => {
    let id;

    if (e.target.id.includes("-")) {
      id = e.target.id.split("-")[0];
    } else {
      id = e.target.id;
    }
    switch (r) {
      case "input":
        setActiveItem({
          ...activeItem,
          [id]: e.target.value,
        });

        break;
      case "selectOption":
        setActiveItem({
          ...activeItem,
          [id]: v,
        });
        break;
      case "clear":
        console.log(id);
        if (id === "product") {
          setActiveItem({
            ...activeItem,
            product: { Name: "", Value: "" },
          });
          setProductsInput("");
        }
        break;
      default:
        break;
    }
  };

  const handleProductsInput = (e) => {
    const inputValue = e.target.value;
    setProductsInput((prevInput)=>inputValue);
  };

  const handleUnitsInput = (e) => {
    setUnitsInput(e.target.value);
    if (e.target.value !== undefined && e.target.value.length > 3) {
      setLoading((load) => {
        return {
          ...load,
          units: true,
        };
      });

      getUnitsCatalog(userid, userid, e.target.value)
        .then((data) => {
          if (data.data.length > 0) {
            setOptions((opts) => {
              return {
                ...opts,
                unitCodes: data.data,
              };
            });
            setLoading({
              ...loading,
              units: false,
            });
          }
        })
        .catch((err) => {
          setAlert((alert) => {
            return {
              ...alert,
              state: "error",
              message: "Error obteniendo el catalogo de unidades",
            };
          });
        });
    }
  };

  const setTaxes = () => {
    const itemsMappingContext = new InvoiceItemsMappingContext(
      new IncomeTaxesStrategy(taxesStructure, activeItem)
    );
    itemsMappingContext.calculateTaxes();

    setActiveItem({
      ...activeItem,
      taxes: taxesStructure.taxes,
      total: taxesStructure.Total,
    });
  };

  const handleDeleteItem = (item) => {
    let tempArr = [...items];
    let index = tempArr.indexOf(item);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }

    invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items = tempArr;

    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
    setCounter(
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items.length
    );

    setItems(tempArr);
  };

  const handleTheft = () => {
    //Aquí se hará la llamada para los impuestos, recuerdo que se necesitaba el emisor y receptor
    let receiver = invoiceJson.GeneralModel.Receiver;
    let issuer = invoiceJson.GeneralModel.Issuer;
    delete issuer.CURP;
    let cfditype = invoiceJson.GeneralModel.CfdiType;
    let airems = items;

    let RequestTaxObject = {
      CfdiType: cfditype,
      Issuer: issuer,
      Receiver: receiver,
      Items: airems,
    };

    const mapTaxedItems = (taxedItems) => {
      let incompleteItems = items;

      //for (let txditm in tax)
      taxedItems.forEach((txditm, idx) => {
        let elarr = txditm.Taxes.map((tax) => ({
          ...tax,
          Total: truncateNumber(tax.Total,6),
        }));

        incompleteItems[idx] = {
          ...incompleteItems[idx],
          taxes: elarr,
          total: truncateNumber(txditm.Total,6),
          subtotal: truncateNumber(txditm.Subtotal,6),
        };
      });
      return incompleteItems;
    };
    setLoading({ ...loading, general: true });
    calculateItemsTaxes(userid, userid, RequestTaxObject)
      .then((response) => {
        let modded = mapTaxedItems(response.data);
        invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items =
          modded;
        window.localStorage.setItem(
          "invoiceModel",
          JSON.stringify(invoiceJson)
        );
        setItems(() => modded);
        setLoading({ ...loading, general: false });
        setShowTaxes(true);
        props.setComplete(true)
      })
      .catch((err) => {
        if (err.response || err.request || err.message) {
          console.log("err", err.response, err.request, err.message);
          setLoading({ ...loading, general: false });
          setDialog({
            open: true,
            title: "Error obteniendo impuestos",
            content: "Se produjo un error al calcular los impuestos",
            actions: [
              {
                label: "Cerrar",
                execute: () => setDialog({ ...dialog, open: false }),
              },
              {
                label: "Reintentar",
                execute: () => {
                  setDialog({ ...dialog, open: false });
                  handleTheft();
                },
              },
            ],
          });
          return;
        }
      });
  };

  const handleAdd = () => {
   setShowTaxes(false)
    const validActiveItem = (item) => {
      if (
        item.quantity !== "" &&
        item.product !== "" &&
        item.unit !== "" &&
        item.price !== "" &&
        item.taxObject !== "" &&
        item.description !== ""
      ) {
        return true;
      }

      return false;
    };

    if (validActiveItem(activeItem)) {
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items.push(
        activeItem
      );
      setCounter(
        invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items.length
      );
      setItems(
        invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items
      );
      //setShowTaxes(false)
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

      setActiveItem({
        ...activeItem,
        quantity: "",
        product: {Name:"",Value:""},
        unit: "",
        description: "",
        price: "",
        taxObject: "",
        uuid: undefined,
        taxes: undefined,
        total: undefined,
      });
    } else {
      setDialog({
        ...dialog,
        open: true,
        title: "Solicitud Incompleta",
        content:
          "Por favor verifique que completó la información en los campos requeridos",
        actions: [
          {
            label: "Ok",
            execute: () => {
              setDialog({ ...dialog, open: false });
            },
          },
        ],
      });
      setAddTry(true)
      if(items.length>0){
        setShowTaxes(true)
      }
    }

    return;
  };

  const useDidUpdate = (fn, inputs) => {
    const didMountRef = useRef(false);

    useEffect(() => {
      if (didMountRef.current) {
        return fn();
      }

      didMountRef.current = true;
      fn(true);
    }, inputs);
  };

  useDidUpdate(async (dflt) => {
    if (dflt) {
      setLoading((load) => {
        return {
          ...load,
          products: true,
          units: true,
        };
      });

      try {
        const defaultsByType = {
          IngresoComplemento: {
            Servicio: "78101801",
            Unidad: "E48",
          },
        };

        if (isIncome && hasCartaPorte) {
          const res = await getProductsServices(
            userid,
            userid,
            defaultsByType["IngresoComplemento"].Servicio
          );
          const res1 = await getUnitsCatalog(
            userid,
            userid,
            `${defaultsByType["IngresoComplemento"].Unidad}`
          );
          if (res.data.length > 0 && res1.data.length > 0) {
            setActiveItem((i) => {
              return {
                ...i,
                product: res.data[0],
                unit: res1.data[0],
              };
            });
          }
        }
      } catch (err) {
        setAlert((alert) => {
          return {
            ...alert,
            state: "error",
            message: "Hubo un problema al obtener el catalogo.",
          };
        });
      }

      setLoading((load) => {
        return {
          ...load,
          products: false,
          units: false,
        };
      });
    }
  }, []);

  useEffect(()=>{
    if(items.length>0){
      handleTheft();
    }
  },[items])

  useEffect(() => {
    let errorArr = [];
    if (addTry) {
      Object.keys(activeItem).forEach((key) => {
        if (activeItem[key] === "" || activeItem[key] === undefined) {
          errorArr.push(key);
        }
      });
      setEmptyError(errorArr);
      setAddTry(false);
    } else {
      // Remove keys from errorArr if they are no longer empty or undefined
      errorArr = emptyError.filter((key) => activeItem[key] === "" || activeItem[key] === undefined);
      setEmptyError(errorArr);
    }
  }, [activeItem, addTry]);

  useEffect(()=>{
    if (productsInput !== undefined && productsInput.length > 3) {
      setLoading((load) => {
        return {
          ...load,
          products: true,
        };
      });

      getProductsServices(userid, userid, productsInput)
        .then((res) => {
          console.log(res);
          if (res.data.length > 0) {
            setOptions((opts) => {
              return {
                ...opts,
                productCodes: res.data,
              };
            });
          }
          setLoading((load) => {
            return {
              ...load,
              products: false,
            };
          });
        })
        .catch((err) => {
          setAlert((alert) => {
            return {
              ...alert,
              state: "error",
              message: "Error obteniendo el catalogo de productos y servicios",
            };
          });
        });
    }
  },[productsInput])

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop || loading.general}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <AlertDialog
        keep={false}
        updateOpenState={(bool) => {
          setDialog({
            ...dialog,
            open: bool,
          });
        }}
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />

      <Typography variant="h6" sx={{ marginTop: "2vh" }}>
        Productos / Servicios a facturar
      </Typography>

      {(
        <Grid
          container
          spacing={3}
          marginTop=".2vh"
          justifyContent={"center"}
          alignSelf={"center"}
          maxWidth={"lg"}
          sx={{ gutterBottom: true }}
        >
          <Grid item xs={8}>
            {/* Código del producto */}
            <Autocomplete
              id="product"
              value={activeItem.product||""}
              onChange={(e,v,r)=>{
                switch (r) {
                  case "clear":
                    setActiveItem({
                      ...activeItem,
                      product: {Name:"",Value:""},
                    });
                    setProductsInput("");
                    break;
                
                  default:
                    handleItemsData(e,v,r)
                    break;
                }
              }}
              options={options.productCodes}
              sx={{ width: "100%" }}
              clearText="Limpiar"
              clearOnBlur={false}
              fullWidth={true}
              autoComplete
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    onChange={handleProductsInput}
                    label="Servicio"
                    error={emptyError.includes("product")}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading.products ? (
                            <CircularProgress />
                          ) : (
                            params.InputProps.endAdornment
                          )}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => {
                if (isSATCodesProductsInput(productsInput)) {
                  return option.Value;
                }

                return option.Name;
              }}
              renderOption={(props, option, state) => {
                return (
                  <li {...props}>
                    {option.Name} - {option.Value}
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => {
                if (value.Value === "" && value.Name === "") {
                  console.log("Input has been cleared, equality will fail");
                  return true;
                }
                return (
                  option.Value === value.Value || option.Name === value.Name
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {/* Cantidad */}
            <TextField
              id="quantity"
              size="small"
              variant="standard"
              value={activeItem.quantity}
              error={emptyError.includes("quantity")}
              sx={{ width: "100%" }}
              label="Cantidad"
              type="number"
              onChange={(e) => handleItemsData(e, e.target.value, "input")}
            />
          </Grid>

          <Grid item xs={8}>
            {/* Unidad */}
            <Autocomplete
              id="unit"
              autoComplete
              options={options.unitCodes}
              onChange={handleItemsData}
              value={activeItem.unit}
              sx={{ width: "100%" }}
              clearText="Limpiar"
              fullWidth={true}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Unidad de medida"
                    name="UnitCode"
                    onChange={handleUnitsInput}
                    error={emptyError.includes("unit")}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading.units ? (
                            <CircularProgress />
                          ) : (
                            params.InputProps.endAdornment
                          )}
                        </>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => option.Name || ""}
              renderOption={(props, option, state) => {
                return <h4 {...props}>{`${option.Name}`}</h4>;
              }}
              isOptionEqualToValue={(option, value) => {
                if (value === "") {
                  return true;
                }
                return option.Name === value.Name;
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {/* Precio */}
            <TextField
              id="price"
              label="Precio del producto/servicio"
              error={emptyError.includes("price")}
              type="number"
              value={activeItem.price}
              sx={{ width: "100%" }}
              onChange={(e) => handleItemsData(e, e.target.value, "input")}
            />
          </Grid>

          <Grid item xs={12} sx={{ minHeight: "3vh" }}>
            {/* Descripción */}
            <TextField
              id="description"
              label="Descripción"
              error={emptyError.includes("description")}
              multiline
              rows={3}
              value={activeItem.description}
              sx={{ width: "100%" }}
              onChange={(e) => handleItemsData(e, e.target.value, "input")}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Objeto de impuesto */}
            <Autocomplete
              id="taxObject"
              autoComplete
              options={options.taxes}
              value={activeItem.taxObject}
              sx={{ width: "100%" }}
              clearText="Limpiar"
              onChange={handleItemsData}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Objeto de impuesto?"
                    error={emptyError.includes("taxObject")}
                    name="TaxObject"
                  />
                );
              }}
              getOptionLabel={(option) => option.Value || ""}
              renderOption={(props, option, state) => {
                return <h4 {...props}>{`${option.Value}`}</h4>;
              }}
              isOptionEqualToValue={(o, v) => {
                if (v === "") {
                  return true;
                }

                return v.id === o.id;
              }}
            />
          </Grid>

          <Box sx={{ display: "flex" }}></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* /*TODO Invoice taxes should be calculated fpr each user depending on its business (Accountant consult) */}

            {/*
        showTaxes && (
            
          <>
            <Typography>Impuestos</Typography>  
            {
              activeItem.taxes.map((taxObj, idx) => {
                return (
                  <Paper elevation={2} square sx={{padding:"1vh", color:"GrayText"}} key={idx}>
                    <Typography>Impuesto:         {taxObj["Name"]}</Typography>  
                    <Typography>Monto Base:       {taxObj["Base"]}</Typography>  
                    <Typography>% de impuesto:    {taxObj["Rate"]}%</Typography>  
                    <Typography>Monto:            <b>{taxObj["Total"]}</b></Typography>  
                    <Typography sx={{overflow:"hidden"}}> ---------------------------------------------------------</Typography>
                  </Paper>
                )
              })
            
            }
            <Typography>Monto total de la factura: <b>{activeItem.total}</b></Typography>
          </>

        )
          */}

            <Button
              onClick={handleAdd}
              variant="contained"
              color="secondary"
              sx={{
                margin: "2vh",
                color: "white",
              }}
            >
              {"Agregar"}
            </Button>
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "2vh" }}
            >
              Items:{`${counter}`}
            </Typography>
          </Box>
        </Grid>
      )}

      {showTaxes && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RobanMasQuePemex data={items} handleDelete={handleDeleteItem} />
          </Grid>
          <Grid item xs={12}>
            {/*<Button variant={"contained"} onClick={() => setShowTaxes(false)}>
              Añadir más productos
            </Button>
      */}
          </Grid>
        </Grid>
      )}

      {/*items && items.length > 0 && !showTaxes && (
        <Grid container>
          <Grid item xs={12}>
            <DynamicTable
              style={{ paddingBottom: "2vh" }}
              data={items}
              handleDelete={handleDeleteItem}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              onClick={handleTheft}
              variant="contained"
              color="secondary"
              sx={{
                width: "25%",
                margin: "2vh",
                color: "white",
                alignSelf: "right",
              }}
            >
              {"Calcular impuestos"}
            </Button>
          </Grid>
        </Grid>
            )*/}
      {alert.state !== "" ? (
        <Alert severity={`${alert.state}`}>{`${alert.message}`}</Alert>
      ) : null}
    </>
  );
};

function DynamicTable({ data, handleDelete }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="dynamic table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "primary.main" }}>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Servicio
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Cantidad
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                SubTotal
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Opciones
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Typography>{item.product.Name}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <Typography>{item.quantity}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <Typography>
                  <b>${item.quantity * item.price}</b>
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDelete(item)}
                >
                  Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function RobanMasQuePemex({ data, handleDelete }) {
  const displayTaxes = (data) => {
    let taxesArr = data.taxes;
    return taxesArr.map((tax) => {
      return (
        <Grid container>
          <Grid item xs={7}>
            <Typography variant={"caption"}>{tax.Name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant={"caption"}>
              $<b>{tax.Total}</b>
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  let total = getTotal();

  function getTotal() {
    let total = 0;
    for (const array in data) {
      total = total + Number(data[array].total);
    }
    return total;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="dynamic table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "primary.main" }}>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Servicio
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Cantidad
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                SubTotal
              </Typography>
            </TableCell>
            <TableCell align="center" colSpan={2}>
              <Typography fontWeight={600} color="white">
                {" "}
                Impuestos
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Total
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Opciones
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Typography>{item.product.Name}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <Typography>{item.quantity}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <Typography>
                  <b>${item.subtotal}</b>
                </Typography>
              </TableCell>
              <TableCell colSpan={2} component="th" scope="row" align="right">
                {displayTaxes(data[index])}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <Typography>
                  <b>${item.total}</b>
                </Typography>
              </TableCell>

              <TableCell align="right">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDelete(item)}
                >
                  Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row" align="right"></TableCell>
            <TableCell component="th" scope="row" align="right"></TableCell>
            <TableCell component="th" scope="row" align="right">
              <Typography sx={{ color: "white" }}>
                <b>${total}</b>
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              <b>Total:</b>
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              <Typography>
                <b>${total}</b>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { InvoiceItemsData, ItemsDataModel };
