import apiObj from "../../axios"
import { loadStripe } from "@stripe/stripe-js"


const {api, version} = apiObj


const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK)

export const subscribeUserToModulePlan = (modulePlan,bodyObj, requestConfig) => {
    
    return api.post(`/${version}/clients/payments/subscription/invoicing`,bodyObj, requestConfig)
}

export const retrievePaymentStatus = (cs) => {
    return new Promise(async (resolve, reject) => {
        const Stripe = await stripe
    
        if(Stripe){
            Stripe.retrievePaymentIntent(cs)
            .then(({paymentIntent}) => {
                resolve(paymentIntent.status)
            })
            .catch(err => {
                reject(err)
            })
            
        }
    })
    
}