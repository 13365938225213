import { Avatar, Box, Button, Grid, Input, Typography } from "@mui/material";
import { useState } from "react";

import AssignmentIcon from "@mui/icons-material/Assignment";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const DisplayFilePreview = ({ file }) => {
  if (file) {
    const filename = file.name;
    //console.log("archivo en preview", file);

    return (
      <Box
        alignItems={"center"}
        display={"flex"}
        flexDirection={"column"}
        sx={{ maxWidth: "100%" }}
      >
        <Avatar color="success" variant="rounded">
          <AssignmentIcon />
        </Avatar>
        <Box display={"block"} maxWidth={"100%"}>
          <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
            {filename}
          </Typography>
        </Box>
      </Box>
    );
  }
};

 export const DisplayDragAndDrop = ({
   fileExtension = "*",
   componentId = "dragAndDrop",
   setFile = (file) => console.log(file),
   validFile = (file) => {console.log(file); return true},
 }) => {
   //const [selectedFile, setSelectedFile] = useState(null);

   const handleFileSelect = (event) => {
     const files = event.target.files;
     //console.log("files", files)
     const latestFile = files.length ? files[files.length-1]:false;
     if (latestFile && latestFile.name.endsWith(fileExtension)) {
       //setSelectedFile(file);
       setFile(latestFile);
     }
   };

   const handleDrop = (event) => {
     event.preventDefault();
     const files = event.dataTransfer.files;
     //console.log("files", files)
     const latestFile = files.length ? files[files.length-1]:false;
     if (latestFile && latestFile.name?.endsWith(fileExtension)) {
       //setSelectedFile(file);
       setFile(latestFile);
     }
   };

   const handleDragOver = (event) => {
     event.preventDefault();
   };

   const onInputClick = (event) => {
    event.target.value = ''
  }

   return (
     <>
       <Grid container spacing={2}>
         <Grid item xs={12}>
           <div
             onDrop={handleDrop}
             onDragOver={handleDragOver}
             style={{
               border: "2px dashed #aaa",
               borderRadius: "5px",
               padding: "16px",
               textAlign: "center",
               marginBottom: "16px",
             }}
           >
             <Input
               inputProps={{
                 type: "file",
                 accept: fileExtension,
               }}
               sx={{
                 display: "none",
               }}
               onChange={handleFileSelect}
               onClick={onInputClick}
               id={componentId}
             />
             <label htmlFor={componentId}>
               <Button
                 variant="contained"
                 startIcon={<CloudUploadIcon />}
                 component="span"
               >
                 Arrastre un archivo {fileExtension} o haga click para buscar
               </Button>
             </label>
           </div>
         </Grid>
       </Grid>
     </>
   );
 };