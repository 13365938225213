import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
//Responsive
import * as Responsive from '../../../../ResponsiveOptions'

const RegisterIndex = () => {
  return (
    <div>
        <Box sx={{ width:"100vw", height:"100vh"}}>
            <Typography>
                Registrar en el sistema
            </Typography>

            <Button variant='contained' sx={{background:"#C00000",}} id="operator"> Registrar operador </Button>
            <Button variant='contained' sx={{background:"#C00000"}} id="transport"> Registrar transporte </Button>
            <Button variant='contained' sx={{background:"#C00000"}} id="csd"> Registrar Emisor </Button>
        </Box>
    </div>
  )
}

export default RegisterIndex
