import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ClientsDashboardTable } from "./ClientsDashboardTable";



export const FilteringComponent = ({options,filters,setFilters,selected,setSelected,selectedClientsArray,setSelectedClientsArray})=>{

    const [showCliente, setShowCliente] = useState(false)
    const [showIssuer, setShowIssuer] = useState(false)
  
    const addFilter = (id, value) => {
      switch (id) {
        case "client":
          const newFilter = {
            label: "Rfc del Receptor",
            param: "GeneralModel.Receiver.Rfc$in",
            value: [],
          };

          const index = filters.findIndex(
            (filter) =>
              filter.label === newFilter.label &&
              filter.param === newFilter.param
          );

          if (index !== -1) {
            if (!filters[index].value.includes(value.Rfc)) {
              filters[index].value.push(value.Rfc);
              setFilters([...filters]);
            }
          } else {
            newFilter.value.push(value.Rfc);
            filters.push(newFilter);
            setFilters([...filters]);
          }

          break;
        case "issuer":
          const newFilter2 = {
            label: "Rfc del Emisor",
            param: "GeneralModel.Issuer.Rfc",
            value: value.Rfc,
          };

          const index2 = filters.findIndex(
            (filter) =>
              filter.label === newFilter2.label &&
              filter.param === newFilter2.param
          );

          if (index2 !== -1) {
            filters[index2].value = newFilter2.value;
            setFilters([...filters]);
          } else {
            filters.push(newFilter2);
            setFilters([...filters]);
          }

          break;
        default:
          break;
      }
    };
  
    const handleChangeSelection =(e,v,r)=>{
  
      const id = e.target.id.split("-")[0];
  
      switch (r) {
        case "selectOption":
          setSelected({...selected,[id]:v})
          if (id === "client" && v.Rfc) {
            if(!selectedClientsArray.includes(v)){
                setSelectedClientsArray((prevArray) => [...prevArray, v]);
            }
          }
  
          addFilter(id,v)
  
          
          break;
        default:
        break;
      }
    }
  
  
    const handleDeleteClient = (client) =>{
      let tempArr = [...selectedClientsArray]
      let index = tempArr.indexOf(client)
      if (index !== -1){
          tempArr.splice(index,1)
      }
      
      setSelectedClientsArray(tempArr)
  
      if(tempArr.length === 0){
      setFilters(prevFilters =>
        prevFilters.filter(filter => filter.param !== "GeneralModel.Receiver.Rfc$in")
      );
    }
  }
  
  
    useEffect(()=>{
      if(selected.client.Rfc){
        setShowCliente(true)
      }
      if(selected.issuer.Rfc){
        setShowIssuer(true)
      }
  
    },[selected])
  
    return (
      <>
        {/* Main buttons */}
        <Grid container spacing={2} sx={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Typography variant="button" color={"primary"}>
              Filtros
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={() => setShowCliente(!showCliente)}
            >
              Cliente
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={() => setShowIssuer(!showIssuer)}
            >
              Emisor
            </Button>
          </Grid>
          {showCliente && (
            <Grid item xs={12}>
              <Autocomplete
                id="client"
                sx={{ width: "100%" }}
                options={options.clients}
                getOptionLabel={(opt) => {
                  return opt.TaxName || "";
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      //error={error.client.failed}
                      //helperText={error.client.message}
                      label={"Clientes / receptores"}
                      sx={{ width: "100%" }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                isOptionEqualToValue={(o, v) => {
                  if (v === "") {
                    return true;
                  }
  
                  return o.Name === v.Name;
                }}
                onChange={(e, v, r) => {
                  switch (r) {
                    case "selectOption":
                      handleChangeSelection(e, v, r);
                      break;
  
                    case "clear":
                      
                    setSelected({ ...selected, client: "" });
                      break;
  
                    default:
                      break;
                  }
                }}
                value={selected.client}
              />
            </Grid>
          )}
          {showIssuer && (
            <Grid item xs={12}>
              <Autocomplete
                id="issuer"
                sx={{ minWidth: "100%" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      //error={error.issuer.failed}
                      //helperText={error.issuer.message}
                      label="Emisor"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                getOptionLabel={(option) => option.Name || ""}
                options={options.issuerList}
                onChange={(e, v, r) => {
                  switch (r) {
                    case "selectOption":
                      handleChangeSelection(e, v, r);
                      break;
  
                    case "clear":
                      setSelected({ ...selected, issuer: "" });
                      setFilters(prevFilters =>
                        prevFilters.filter(filter => filter.param !== "GeneralModel.Issuer.Rfc")
                      );
                      break;
  
                    default:
                      break;
                  }
                }}
                isOptionEqualToValue={(o, v) => {
                  if (v === "") {
                    return true;
                  } else {
                    return o.Name === v.Name;
                  }
                }}
                value={selected.issuer}
                clearOnBlur
              />
            </Grid>
          )}
          {selectedClientsArray.length > 0 && (
            <Grid item xs={12}>
              <ClientsDashboardTable
                data={selectedClientsArray}
                handleDelete={handleDeleteClient}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }