import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import { Grid, TextField } from "@mui/material";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useAuth from "../../../../../hooks/useAuth";
import { getPaymentForms } from "../../../../../services/modules/invoices/catalogs";
import { toIsoHelper } from "../../invoiceData/Nomina/NominaDateTypeComponent";
import { validateRfc } from "../../../utils/regexp";
import { Add as AddIcon } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import ferbyTheme from "../../../../../theme/ferbyTheme";
import { getIssuedDocs } from "../../../../../services/modules/invoices/organization";
import { formatDateDDMMYYYY } from "../../../utils/dateManipulators";

const PaymentConfig = ({props}) => {

  const { userid } = useAuth();
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));
  
  //General component functionality
  
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    Date: null,
    PaymentForm: "",
    Amount: 0,
    RelatedDocuments: [],
  });
  const [options, setOptions] = useState({
    paymentForms: [],
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    maxWidth: "md",
    actions: [],
  });
  const [error, setError]=useState({
    date:false,
    form:false,
    empty:false,
    amountless:[]
  })

  //Related documents hooks
  const [withRelated, setWithRelated] = useState(false);
  const [canInput, setCanInput] = useState(false);
  const [ableToSearch, setAbleToSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [inputValues, setInputValues] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(undefined);

  const [selectedRelatedIds, setSelectedRelatedIds] = useState([]);
  const [invoiceRows, setInvoiceRows] = useState([]);
  const [selectedInvoicesStore, setSelectedInvoicesStore] = useState([]);
  const [rawDocuments, setRawDocuments] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [fullTotal, setFullTotal] = useState(0);

  useMemo(() => {
    const getOptions = async () => {
      //set loading
      setLoading(true);

      const paymentForms = await getPaymentForms(userid, userid);

      setOptions({
        ...options,
        paymentForms: paymentForms.data,
      });
    };

    getOptions()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleWithRelated = () => {
    setWithRelated(!withRelated);
  };

  /*RELATED DOCUMENTS FUNCTIONS */
  const filters = [
    {
      label: "Rfc del Receptor",
      param: "GeneralModel.Receiver.Rfc",
    },
    {
      label: "Rfc del Emisor",
      param: "GeneralModel.Issuer.Rfc",
    },
    {
      label: "Folio interno",
      param: "GeneralModel.Folio",
    },
    {
      label: "Folio fiscal",
      param: "uuid",
    },
  ];

  const invoiceColumns = [
    {
      field: "Folio",
      headerName: "Folio",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.GeneralModel.Folio
          ? params.row.GeneralModel.Folio
          : "sin folio"; /*<RenderDescription data={params}/>*/
      },
    },
    {
      field: "Date",
      headerName: "Fecha",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.GeneralModel.Date
          ? formatDateDDMMYYYY(params.row.GeneralModel.Date, false)
          : "sin fecha"; /*<RenderDescription data={params}/>*/
      },
    },
    {
      field: "TaxName",
      headerName: "Cliente",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.GeneralModel.Receiver.Name
          ? params.row.GeneralModel.Receiver.Name
          : "sin nombre";
      },
    },
    {
      field: "RfcIssuer",
      headerName: "Emisor",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.GeneralModel.Issuer.Rfc
          ? params.row.GeneralModel.Issuer.Rfc
          : "sin rfc"; /*<RenderDescription data={params}/>*/
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.status
          ? params.row.status
          : "sin status"; /*<RenderDescription data={params}/>*/
      },
    },
    {
      field: "Items",
      headerName: "Detalles",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.q
          ? params.q
          : "sin descripción"; /*<RenderDescription data={params}/>*/
      },
    },
  ];

  const handleAddRelateDocuments = () => {
    const mapSelectedDocuments = (arr) => {
      let mappedDocumentArray = [];

      for (let doc in arr) {
        //i tried to initialize this structure outside but there was an error happening
        mappedDocumentArray.push(mapRelated(arr[doc]));
      }
      return mappedDocumentArray;
    };

    function mapRelated(json) {
      const result = {
        TaxObject: "",
        Uuid: "",
        Serie: "",
        Folio: "",
        Currency: "MXN",
        //EquivalenceDocRel: 0,
        PaymentMethod: "",
        PartialityNumber: "",
        PreviousBalanceAmount: "",
        AmountPaid: "",
        ImpSaldoInsoluto: 0,
        Taxes: [],
      };

      console.log("json chars",json);
      result.TaxObject =
        json?.Models[json.GeneralModel.CfdiType.Type]?.Items[0].taxObject.id;
      result.Uuid = json.uuid;
      result.Folio = json.GeneralModel.Folio.toString();
      result.PaymentMethod = "PUE";

      const items = json?.Models[json.GeneralModel.CfdiType.Type]?.Items;
      const payments = json.Payments;

      //Set the partiality
      result.PartialityNumber = payments.length + 1;

      // Calculate the total of the items
      const itemsTotal = items.reduce((total, item) => total + item.total, 0);

      // Calculate the total amount paid
      const amountPaid = payments.reduce(
        (total, payment) => total + parseFloat(payment.Amount),
        0
      );

      // Calculate the previous balance amount
      result.PreviousBalanceAmount = Number(
        (itemsTotal - amountPaid).toFixed(2)
      );

      const taxes = json.Models[json.GeneralModel.CfdiType.Type].Items[0].taxes;
      taxes.forEach((tax) => {
        const mappedTax = {
          Total: tax.Total,
          Name: tax.Name,
          Base: tax.Base,
          Rate: tax.Rate,
          IsRetention: tax.IsRetention,
        };
        result.Taxes.push(mappedTax);
      });

      return result;
    }

    setLoading(true);
    //  we use the selected documents on the table,
    //  if there are none, we display a dialog requiring at least one to add
    if (selectedRelatedIds.length !== 0) {
      let uniqueIds = [];
      const rawDocumentsArray = selectedInvoicesStore.filter((doc) => {
          if (selectedRelatedIds.includes(doc._id) && !uniqueIds.includes(doc._id)) {
              uniqueIds.push(doc._id);
              return true;
          }
          return false;
      });

      //We need to map the selected documents to fit the payment cfdi model
      let mappedDocuments = mapSelectedDocuments(rawDocumentsArray);
      //We then set the related documents, this change should trigger
      //a display of some component, with the addition of tracking the changes of data of each object in the array
      let compositeArr
      paymentData.RelatedDocuments[0]?.Uuid !== "" ? compositeArr = paymentData.RelatedDocuments.concat(mappedDocuments)  : compositeArr = mappedDocuments
      let compositeRawDocs= rawDocuments.concat(rawDocumentsArray)
      
      setPaymentData({ ...paymentData, RelatedDocuments:compositeArr });
      setRawDocuments(compositeRawDocs);
      window.localStorage.setItem("relDocs", JSON.stringify(compositeRawDocs));
      setLoading(false);
    } else {
      setLoading(false);
      setDialog({
        open: true,
        title: "Not selected",
        content: (
          <Typography>
            Por favor seleccione al menos un documento para relacionar
          </Typography>
        ),
        actions: [
          { label: "Ok", execute: () => setDialog({ ...dialog, open: false }) },
        ],
      });
    }
  };

  const relatedDocumentsSelectionChange = (a, b, c) => {
    setSelectedRelatedIds(a);
    if (a.length !== 0) {
      let testArr = invoiceRows.filter((doc) => a.includes(doc._id));

      let sis = selectedInvoicesStore.concat(testArr);
      setSelectedInvoicesStore(sis);
    }
  };
  const handleSearchValue = (e) => {
    if (!ableToSearch) {
      setAbleToSearch(true);
    }
    setSearchValue(e.target.value);
  };

  function transformInvoicesToRows(data) {
    const transformedData = [];

    data.forEach((item, index) => {

      const transformedItem = {
        id: item._id,
        Folio: item.GeneralModel.Folio,
        Date: item.GeneralModel.Date,
        TaxName: item.GeneralModel.Receiver.Name,
        RfcIssuer: item.GeneralModel.Issuer.Rfc,
        Status: item.status || "",
        Items: "", //item.Models.I.Items[0].description || ""
      };

      transformedData.push(transformedItem);
    });

    return transformedData;
  }

  const handleApplyFilters = () => {
    setCanInput(false);
    setLoading(true);
    const parsedInput = searchValue.split(",");
    appliedFilters.forEach((filter, index) => {
      filter.value = parsedInput[index];
    });

    //This is necessary to only get the documents that can be attached
    //it gets concatenaded instead of pushed so it doesn't appear in the UI
    //since the user doesn't need it and shouldn't change it
    let strictRelatableArray = [
      {
        label: "Metodo de pago",
        param: "GeneralModel.PaymentMethod",
        value: "PPD",
      },
      {
        label: "Emitidas",
        param: "Status.issuedStatus$in",
        value: "issued",
      },
      {
        label: "Emitidas",
        param: "status$eq",
        value: "issued",
      },
    ];

    getIssuedDocs(userid, userid, currentPage, appliedFilters.concat(strictRelatableArray))
      .then((res) => {
        setLoading(false);
        if (res.data.data.length === 0) {
          setDialog({
            ...dialog,
            open: true,
            title: "Nada encontrado",
            content: res.data.message,
            actions: [
              {
                label: "OK",
                execute: () =>
                  setDialog({
                    ...dialog,
                    open: false,
                    title: "",
                    message: "",
                    actions: [],
                  }),
              },
            ],
          });
        } else {
          const { data, total_pages, total_records } = res.data;
          setTotalPages(total_pages);
          setTotalRows(total_records);

          // Process the received data and update the DataGrid
          const invoicesData = data;
          const rows = transformInvoicesToRows(invoicesData);
          setInvoiceRows(invoicesData);
          setCanInput(true)
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          setDialog({
            ...dialog,
            open: true,
            title: "Error de valores",
            content:
              "Ha ocurrido un error en el filtrado relacionado a los valores que colocaste y verifica los valores o tu conexión",
            actions: [
              {
                label: "Ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
          });
        }
        if (err.response.status === 401) {
          setDialog({
            ...dialog,
            open: true,
            title: "Tu sesión expiró",
            content: "Recarga la página",
            actions: [
              { label: "Recargar", execute: () => window.location.reload() },
            ],
          });
        }
        if (err.response.status === 500) {
          setDialog({
            ...dialog,
            open: true,
            title: "Error interno",
            content:
              "Ha ocurrido un error en el servidor, intentalo de nuevo más tarde.",
            actions: [
              {
                label: "Ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
          });
        }
      });

  };

  const handleRelatedDocumentsChange = (index, event) => {
    let value = Number(event.target.value);

    function calculateTaxesOfPaidAmount(desiredPayment) {
      let taxesArr = paymentData.RelatedDocuments[index].Taxes;

      let totalTaxRate = paymentData.RelatedDocuments[index].Taxes.reduce(
        (total, item) =>
          item.IsRetention ? total - item.Rate : total + item.Rate,
        0
      );

      let realBase = (desiredPayment / (1 + totalTaxRate)).toFixed(4);
      for (let tax in taxesArr) {
        let taxTotal = realBase * taxesArr[tax].Rate;

        taxesArr[tax].Base = realBase;
        taxesArr[tax].Total = taxTotal.toFixed(4);
      }

      paymentData.RelatedDocuments[index].Taxes = taxesArr;
    }

    paymentData.RelatedDocuments[index][event.target.name] = value;

    paymentData.RelatedDocuments[index].ImpSaldoInsoluto =
      paymentData.RelatedDocuments[index].PreviousBalanceAmount - value;

    calculateTaxesOfPaidAmount(value);
    setInputValues((prevInputValues) => {
      console.log("setting input values");
      const newInputValues = [...prevInputValues];
      newInputValues[index] = value;
      return newInputValues;
    });
    setFullTotal((pastTotal) => pastTotal + value);
    setPaymentData({ ...paymentData });
    error.amountless[index]=false
    setError({...error})
  };

  /* Fin de funciones related documents */

  const hasConfigdPayments = (json) => {
    if (
      json.Complementos.Payments.Amount !== "" ||
      json.Complementos.Payments.PaymentForm !== "" //||
      //json.Complementos.Payments.Date !== ""||
      //json.Complementos.Payments.RelatedDocuments !== []
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getPaymentNameFromCode = (code) => {
    if (code !== "") {
      if (options.paymentForms.length > 0) {
        let selectedOptionName = options.paymentForms?.filter(
          (option) => option.Value === code
        );

        //console.log("son", options.paymentForms);
        //console.log("son", selectedOptionName);
        return selectedOptionName[0];
      }
    } else {
      return { Name: "", Value: "" };
    }
  };

  function fetchInvoices(pageNumber) {
    if (invoiceRows.length > 0) {
      getIssuedDocs(userid, userid, pageNumber, appliedFilters)
        .then((response) => {
          const { data, total_pages } = response.data;
          setTotalPages(total_pages);

          // Process the received data and update the DataGrid
          const invoicesData = data;
          const rows = transformInvoicesToRows(invoicesData);

          setInvoiceRows(invoicesData);
        })
        .catch((err) => {
          console.log(err, err.response);
        });
    }
  }

  const deleteFunction = (idx) => {
    let tempArr2 = [...paymentData.RelatedDocuments];
    if (idx !== -1) {
      tempArr2.splice(idx, 1);
    }

    let tempArr = [...rawDocuments];
    if (idx !== -1) {
      tempArr.splice(idx, 1);
    }

    setRawDocuments([...tempArr]);

    setPaymentData({ ...paymentData, RelatedDocuments: tempArr2 });
  };


  const checkPaymentData = () =>{
    let newError = error
    console.log("checking pd")
    if(paymentData.Date === null){
      newError.date=true
    }
    if(paymentData.PaymentForm === null || paymentData.PaymentForm === ""){
      newError.form=true
    }
    if(paymentData.RelatedDocuments.length === 0){
      newError.empty=true
    }
    else{
      let newAmountless = []
      paymentData.RelatedDocuments.forEach((document) => {
        if (document.AmountPaid === "") {
          newAmountless.push(true);
        } else if (
          !isNaN(parseFloat(document.AmountPaid)) &&
          isFinite(document.AmountPaid)
        ) {
          newAmountless.push(false);
        }
      });
      newError.amountless=newAmountless
    }

    console.log("newError",newError)

    setError(newError)
  }

  const completePaymentData = () => {
    let complete = true;

    if (paymentData.Date === null) {
        complete = false;
        return complete;
    }
    if (paymentData.PaymentForm === null || paymentData.PaymentForm === "") {
        complete = false;
        return complete;
    }
    if (paymentData.RelatedDocuments.length === 0) {
        complete = false;
        return complete;
    }
    paymentData.RelatedDocuments.forEach((document) => {
        if (document.AmountPaid === "" || document.AmountPaid === null) {
            complete = false;
            return complete;
        }
    });

    return complete;
}
  
  useEffect(()=>{
    if(props.hasTried){
      checkPaymentData()
    }
  },[props])
  
  useEffect(() => {
    setFullTotal(inputValues.reduce((acc, curr) => acc + (curr || 0), 0));
  }, [inputValues]);

  useEffect(() => {
    setPaymentData({ ...paymentData, Amount: fullTotal });
  }, [fullTotal]);

  useEffect(() => {
    fetchInvoices(currentPage);
  }, [currentPage]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      //Actual
      if(paymentData.PaymentForm !== "" || paymentData.Date !== null )
      {
      invoiceJson.Complementos.Payments = [paymentData];
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
      }
    }
    if(completePaymentData()){
      props.setComplete(true)
    }else{
      props.setComplete(false)
    }
  }, [paymentData]);

  useEffect(() => {
    let elCalizDelJson = JSON.parse(
      window.localStorage.getItem("invoiceModel")
    );
    let prevValues
    if (firstRender.current && hasConfigdPayments(elCalizDelJson)) {
      prevValues = elCalizDelJson.Complementos.Payments[0]
      
      if (elCalizDelJson.Complementos.Payments[0].Date !== ""){
        setPaymentData({...prevValues});
      }
      else
        setPaymentData({
          ...prevValues,
          Date: new Date(),
        });
    }
    if (firstRender.current) {
      let RelDocs = JSON.parse(window.localStorage.getItem("relDocs"));
      if (RelDocs !== null && RelDocs !== undefined) {
        setRawDocuments(RelDocs);
      }
    }

    firstRender.current = false;
  }, []);


  return (
    <Grid
      container
      spacing={4}
      maxWidth={"md"}
      sx={{ padding: "auto", "& .MuiGrid-item": { padding: "auto" } }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={dialog.maxWidth}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Grid item xs={12} sx={{ align: "center" }}>
        <Typography color="primary" variant="h5">
          Complemento de Pago
        </Typography>
      </Grid>

      {/*General Data*/}
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", padding: 0 }}
      >
        <Grid
          container
          spacing={2}
          component={Paper}
          sx={{ marginLeft: 0.4, "& .MuiGrid-item": { padding: 2.5 } }}
        >
          <Grid item xs={12}>
            <Typography color="primary">Información del pago</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="FechaInicio"
                name="FechaInicio"
                label="Fecha del pago"
                required
                disableFuture
                value={paymentData.Date?new Date(paymentData.Date):new Date()}
                slotProps={{ textField: { variant: "standard" } }}
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  console.log(
                    "e fecha",
                    newValue,
                    newValue.toJSON(),
                    newValue.toDateString()
                  );
                  console.log("fecha en fecha", new Date(newValue));
                  //datosLaborales.StartDateLaborRelations=newValue
                  let isoDate = toIsoHelper(newValue);
                  console.log(isoDate);
                  setPaymentData({
                    ...paymentData,
                    Date: newValue,
                  });
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={options.paymentForms}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="PaymentForm"
                    sx={{ width: "100%" }}
                    label="Forma de Pago"
                    required
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      error:error.form
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                console.log("joder", r, v);
                if (r === "clear") {
                  paymentData.PaymentForm = "";
                } else {
                  paymentData.PaymentForm = v.Value;
                }
                setPaymentData({ ...paymentData });
              }}
              value={getPaymentNameFromCode(paymentData.PaymentForm)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "4vh",
              align: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleWithRelated}
              sx={{ marginBottom: 0 }}
            >
              Relacionar CFDIs
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {withRelated && (
        <>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", padding: 0 }}
          >
            <Grid
              container
              spacing={2}
              component={Paper}
              sx={{ marginLeft: 0.4, "& .MuiGrid-item": { padding: 2.5 } }}
            >
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={filters}
                  renderInput={(params) => {
                    return <TextField {...params} label="Filtros" />;
                  }}
                  renderOption={(props, option, state) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={state.selected}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  onChange={(e, v, r) => {
                    switch (r) {
                      case "selectOption":
                        if (!canInput) {
                          setCanInput(true);
                        }
                        setAppliedFilters(v);
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Valores"
                  sx={{ width: "100%" }}
                  helperText={`${
                    canInput
                      ? "Introduce los valores separados por una coma, en el orden de selección de filtros"
                      : "Primero, selecciona los filtros"
                  }`}
                  disabled={!canInput}
                  onChange={handleSearchValue}
                  value={searchValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          disabled={!ableToSearch}
                          onClick={handleApplyFilters}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  minHeight: "60vh",
                  "& .header-table": {
                    backgroundColor: ferbyTheme.palette.primary.main,
                    color: "white",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    backgroundColor: ferbyTheme.palette.primary.main,
                    color: "white",
                  },
                }}
              >
                <DataGrid
                  columns={invoiceColumns}
                  rows={invoiceRows}
                  page={currentPage - 1}
                  onPageChange={(newPage) => setCurrentPage(newPage + 1)}
                  pagination
                  pageSize={10}
                  rowCount={totalRows}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  getRowId={(row) => {
                    return row._id;
                  }}
                  userid={userid}
                  checkboxSelection
                  onSelectionModelChange={(a, b, c) =>
                    relatedDocumentsSelectionChange(a, b, c)
                  }
                  //selectionModel={invoiceRows}
                  onSelectionChange={
                    (e) =>
                      console.log(
                        "eee",
                        e
                      ) /*relatedDocumentsSelectionChange(e)*/
                  }
                  handleCheckboxClick={(e) =>
                    relatedDocumentsSelectionChange(e)
                  }
                  //disableSelectionOnClick
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button variant="contained" onClick={handleAddRelateDocuments}>
                  Relacionar selección
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {paymentData.RelatedDocuments.length > 0 &&
        paymentData.RelatedDocuments[0].Uuid !== "" && (
          <Grid
            item
            xs={12}
            sx={{ "& b": { color: ferbyTheme.palette.primary.main } }}
          >
            <Alert severity="info">
              Asegúrese de que la información de cada documento es correcta
            </Alert>

            {/* Componente para editar los documentos relacionados */}

            {paymentData.RelatedDocuments.map((doc, index) => {
              let rawDocument = rawDocuments[index];
              console.log("active rawDoc",rawDocument,rawDocuments)
              console.log("error amountles [index]",error.amountless[index])
              return (
                <Card
                  key={doc.Folio + index}
                  sx={{ margin: "15px", padding: "15px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography>
                        <b>Id. del CFDI: </b>
                        {doc.Uuid}
                      </Typography>
                      <IconButton
                        onClick={() => deleteFunction(index)}
                        aria-label="delete"
                        size="small"
                      >
                        <DeleteIcon sx={{ height: "18px", width: "18px" }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <b>Folio: </b>
                        {doc.Folio}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <b>Fecha: </b>
                        {rawDocument.GeneralModel?.Date ? formatDateDDMMYYYY(rawDocument.GeneralModel.Date):"N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <b>RFC Emisor: </b>
                        {rawDocument.GeneralModel.Issuer.Rfc}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <b>Receptor: </b>
                        {rawDocument.GeneralModel.Receiver.Name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <b>RFC Receptor: </b>
                        {rawDocument.GeneralModel.Receiver.Rfc}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <b>Total de la factura: </b>$
                        {rawDocument.Models.I.Items.reduce(
                          (total, item) => total + item.total,
                          0
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="PartialityNumber"
                        label="No. Parcialidad"
                        disabled
                        value={
                          paymentData.RelatedDocuments[index].PartialityNumber
                        }
                        onChange={(e) => handleRelatedDocumentsChange(index, e)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="PreviousBalanceAmount"
                        label="Balance Previo"
                        disabled
                        value={
                          paymentData.RelatedDocuments[index]
                            .PreviousBalanceAmount
                        }
                        onChange={(e) => handleRelatedDocumentsChange(index, e)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="AmountPaid"
                        label="Cantidad del pago"
                        value={paymentData.RelatedDocuments[index].AmountPaid}
                        onChange={(e) => handleRelatedDocumentsChange(index, e)}
                        InputProps={
                          {error:error.amountless[index]}
                        }
                      ></TextField>
                    </Grid>
                  </Grid>
                </Card>
              );
            })}
            <Card>
              <Typography></Typography>
            </Card>
          </Grid>
        )}

      <Grid item xs={8}>
        {/*Spacing grid */}
      </Grid>
    </Grid>
  );
};

export default PaymentConfig;
