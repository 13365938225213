import { Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import useAuth from '../../hooks/useAuth'

export const PaymentStatusComponent = (props) => {
    const navigate = useNavigate()
    const {userid, logout} = useAuth()
    
    const handleAccessNow = () => {
        logout()
    }
    
    const getRenderByStatus = {
        "":<Typography>Nothing here...</Typography>,
        "succeeded": <>
            <Typography>Pago exitoso!</Typography>
            <Button onClick={(handleAccessNow)}>Acceder ya</Button>
        </>,
        "processing":<Typography>El pago se esta procesando, te notificaremos por correo cuando este listo todo.</Typography>,
        "requires_payment_method":<>
            <Typography>Algo salio mal con tu metodo de pago, reintentalo de nuevo.</Typography>
        
            <Button variant="contained">Reintentar</Button>
        </>
    }
  return (
    <div>
        {getRenderByStatus[props.status]}
    </div>
  )
}
