import { useEffect, useState } from "react";
import {
  ServiceItemGeneric,
  StayItem,
} from "../../../modules/services/utils/ServiceItemsStructures";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const StayItemForm = ({ data, setData, dialog, setDialog }) => {
  let StayServiceItemStruct = {
    ...ServiceItemGeneric,
    ...StayItem,
  };

  const [selected, setSelected] = useState(StayServiceItemStruct);

  const [timeUnits, setTimeUnits] = useState("minutos");

  const [errorStates, setErrorStates] = useState({
    Riesgo: false,
  });

  const handleChangeSelection = (e, v, r) => {
    console.log("evr", e, v, r);
    let id = e.target.id.split("-")[0];
    let targetValue = e.target.value;
    console.log(id)
    switch (id) {
      case "SubTotal":
        targetValue = parseFloat(e.target.value);
        break;
      case "Riesgo":
        targetValue = Number(e.target.value);
        if (targetValue > 10 || targetValue < 0) {
          setErrorStates({ ...errorStates, Riesgo: true });
        } else {
          setErrorStates({ ...errorStates, Riesgo: false });
        }
        break;
      case "Tiempo":
        if (timeUnits === "horas") {
          targetValue = Number(e.target.value) * 60;
        } else {
          targetValue = Number(e.target.value);
        }
        break;
      case "Code":
        //setSelectedService(v);
        r = "x";
        targetValue = v.Value;
        break;
      default:
        break;
    }

    switch (r) {
      case "selectOption":
        setSelected({ ...selected, [id]: v });
        break;
      default:
        setSelected({ ...selected, [id]: targetValue });
        break;
    }
  };

  const handleSetData = () => {
    let ServiceItemCopy = StayServiceItemStruct;
    let keys = Object.keys(ServiceItemCopy);

    for (let index in keys) {
      if (selected[keys[index]] !== "" || (typeof selected[keys[index]] === "object" && Object.keys(selected[keys[index]]).length > 0))
        ServiceItemCopy[keys[index]] = selected[keys[index]];
    }

    setData({ ...data, StayServiceItem: ServiceItemCopy });
  };

  useEffect(() => {
    handleSetData();
  }, [selected]);

  useEffect(() => {
    let prevQuantity = selected.Tiempo;
    if (timeUnits === "horas") {
      setSelected({ ...selected, Tiempo: prevQuantity * 60 });
    } else {
      setSelected({ ...selected, Tiempo: prevQuantity / 60 });
    }
  }, [timeUnits]);

  useEffect(() => {
    if (data.StayServiceItem) {
      setSelected(data.StayServiceItem);
    }
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={4}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Tipo de Estadía</InputLabel>
            <Select
              id={"TipoEstadia"}
              label="Tipo de Estadía"
              value={selected.TipoEstadia}
              onChange={(e) =>
                setSelected({ ...selected, TipoEstadia: e.target.value })
              }
            >
              <MenuItem value="Carga">Carga</MenuItem>
              <MenuItem value="Descarga">Descarga</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id={"Riesgo"}
            label="Riesgo (0-10)"
            variant="standard"
            error={errorStates.Riesgo}
            value={selected.Riesgo ? selected.Riesgo : ""}
            onChange={handleChangeSelection}
            inputProps={{
              maxLength: 2,
            }}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id={"SubTotal"}
            label="Subtotal"
            onChange={(e, v, r) => handleChangeSelection(e, v, r)}
            variant="standard"
            sx={{ width: "100%" }}
            InputProps={{
              startAdornment: "$",
              sx: {
                "& input": {
                  textAlign: "right",
                },
              },
            }}
            placeholder={"0.00"}
            type="number"
            step="any"
            value={selected.SubTotal ? selected.SubTotal : ""}
          />
        </Grid>

        <Grid item md={6}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={8}>
              <TextField
                id={"Tiempo"}
                label="Tiempo estimado"
                variant="standard"
                value={
                  timeUnits === "minutos"
                    ? selected.Tiempo
                      ? selected.Tiempo
                      : ""
                    : selected.Tiempo
                    ? selected.Tiempo / 60
                    : ""
                }
                onChange={handleChangeSelection}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel></InputLabel>
                <Select
                  //label="Unidades"
                  sx={{ width: "100%" }}
                  value={timeUnits}
                  onChange={(e) => setTimeUnits(e.target.value)}
                  inputProps={{ width: "100%" }}
                >
                  <MenuItem value="minutos">minutos</MenuItem>
                  <MenuItem value="horas">horas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { StayItemForm };
