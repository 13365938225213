export const isSATCodesProductsInput = (stringInput) => {
    return new RegExp(/^\d+/).test(stringInput)
}  

export const isSATCodesUsesInput = (stringInput) => {
    const codesFromSat = ["G01","G02","G03","I01","I02","I03","I04",
    "I05","I06","I07","I08","D01","D02","D03","D04","D05","D06",
    "D07","D08","D09","D10","S01","CP01","CN01"
    ]

    
    
    return new RegExp(codesFromSat.join('|'),"gi").test(stringInput)
}

export const validateRfc = (string) => {
    const rfcRegexp = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/)

    return rfcRegexp.test(string)
}

export const validateCurp = (string) => {
    const curpRegexp = new RegExp(/^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/)

    return curpRegexp.test(string)
}

export const validateCLABE = (string) => {
    const clabeRegexp = new RegExp(/^\d{18}$/)

    return clabeRegexp.test(string)
}