import { getTypeOfInvoice } from "../../../components/Previews/Getters";
import { Locations } from "../../../components/Forms/CartaPorte/Locations";
import { SelectOperator } from "../../../components/Forms/CartaPorte/Operator";
import { Products } from "../../../components/Forms/CartaPorte/Products";
import { SelectTransport } from "../../../components/Forms/CartaPorte/Transport";
import PaymentConfig from "../issue/complements/payments/PaymentConfig";
import { InvoiceItemsData } from "../issue/invoiceData/InvoiceItemsData";
import { InvoiceGenerator } from "../../../components/actions/InvoiceGenerator";
import { useEffect, useState } from "react";

const OperadoresYTransporte = ({ props }) => {

  const [has,setHas] = useState({operator:false,transport:false})

  useEffect(()=>{
    if(has.operator && has.transport){
      props.setComplete(true)
    }
    else{
      props.setComplete(false)
    }
  },[has])
  return (
    <>
      <SelectOperator   props={{...props,setComplete:(v)=>setHas({...has,operator:v})}} />
      <SelectTransport  props={{...props,setComplete:(v)=>setHas({...has,transport:v})}} />
    </>
  );
};

export const stepsObjects = {
  GeneralDataExtra: {
    Factura: [
      {
        name: "PaymentMethod",
      },
      {
        name: "PaymentForm",
      },
    ],
  },

  "Recibo de Pago": [],
  "Nota de Crédito": [
    {
      label: "Conceptos",
      component: <InvoiceItemsData />,
    },
  ],
  "Recibo de Nómina": [],
  complementos: [
    {
      "Carta Porte": [
        {
          label: "Operadores y transporte",
          component: <OperadoresYTransporte />,
        },
        {
          label: "Mercancias",
          component: <Products />,
        },
        {
          label: "Ubicaciones",
          component: <Locations />,
        },
      ],
    },
    {
      "De pago": [
        {
          label: "Configurar el pago",
          component: <PaymentConfig />,
        },
      ],
    },
  ],
  Factura: [
    {
      label: "Conceptos",
      component: <InvoiceItemsData />,
    },
  ],
  Create: {
    label: "Timbrado",
    component: <InvoiceGenerator />,
  },
};

export const buildStepsFromCFDI = (CFDI) => {
  let type = getTypeOfInvoice(CFDI);
  let pasos = [];
  switch (type) {
    case "pago":
      pasos = [
        {
          label: "Configurar el pago",
          component: <PaymentConfig />,
        },
      ];
      break;
    case "cartaPorte":
      pasos = [
        {
          label: "Operadores y transporte",
          component: <OperadoresYTransporte />,
        },
        {
          label: "Mercancias",
          component: <Products />,
        },
        {
          label: "Ubicaciones",
          component: <Locations />,
        },
        {
          label: "Conceptos",
          component: <InvoiceItemsData />,
        },
      ];

      break;
    case "facturaIngreso":
      pasos = [
        {
          label: "Conceptos",
          component: <InvoiceItemsData />,
        },
      ];

      break;

    default:
      break;
  }

  pasos.push({
    label: "Timbrado",
    component: <InvoiceGenerator />,
  });

  return pasos;
};
