export function getFiscalRegimeNameFromKey(key) {
    console.log("key in select", key);
    switch (String(key)) {
      case "612":
        return "Personas físicas con actividad empresarial";
      case "601":
        return "Régimen General de Ley para personas morales";
        case "626": 
        return "Régimen simplificado de confianza"
      default:
        return "Régimen no soportado para previsualizar";
    }
  }


  
export const getTypeOfInvoice = (jsonCFDI) => {
    if(jsonCFDI.Status){
      if (jsonCFDI.Status.issuedStatus !== "unissued" && jsonCFDI.GeneralModel.CfdiType.Type == "P") {
        return "postPago";
      }
      if (jsonCFDI.Complementos && jsonCFDI.Complementos.Payments) {
        return "postPago";
      }
      if (jsonCFDI.Complementos && jsonCFDI.Complementos.CartaPorte20) {
        return "postCartaPorte";
      }
      if (jsonCFDI.GeneralModel.CfdiType.Type == "I" && jsonCFDI.Status.issuedStatus !== "unissued"){
        return "postIngreso";
      }
      if (jsonCFDI.GeneralModel.CfdiType.Type == "I" && jsonCFDI.Status.issuedStatus === "issued"){
        return "postIngreso";
      }
    }else{
      if (jsonCFDI.Complementos) {
        if (Object.keys(jsonCFDI.Complementos).length === 0 && jsonCFDI.GeneralModel.CfdiType.Type == "I") {
          return "facturaIngreso";
        }
        if (jsonCFDI.Complementos.CartaPorte20) {
          return "cartaPorte";
        }
        if (jsonCFDI.Complementos.Payments) {
          return "pago";
        }
      }
    
      if (jsonCFDI.Complemento) {
        if (jsonCFDI.Complemento.Payroll) {
          return "nomina";
        }
      }

      if (jsonCFDI.GeneralModel.CfdiType.Type == "I") {
        return "facturaIngreso";
      }
    }
    return "invalid";
  };
