import { Autocomplete, Grid, TextField } from "@mui/material";
import { DynamicTable } from "../CartaPorte/Operator";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const OperatorServiceComponent = ({
    setDialog,
    operatorsData,
    setOperators,
    selected,
    setSelected
  }) => {
    const [operatorRows, setOperatorRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [operatorsList, setOperatorList] = useState([]);
    const navigate = useNavigate();
  
    const handleSelectOperator = (event, value, reason) => {
      switch (reason) {
        case "clear":
          setSelected("");
          break;
        case "selectOption":
          setSelected(value);
          setOperators(value.id);
          break;
        default:
          console.log("oh no");
          break;
      }
    };
  
    const handleAddOperator = () => {
      if (selected) {
        const operatorModelForInvoice = {
          TipoFigura: selected.tipoFigura,
          RFCFigura: selected.RFC,
          NombreFigura: selected.firstName + " " + selected.lastName,
          NumLicencia: selected.NumLicencia,
        };
        setOperators((prevOperators) => [...prevOperators, selected]);
        setOperatorList((prevOperators) => [
          ...prevOperators,
          operatorModelForInvoice,
        ]);
        setSelected(null);
      }
    };
  
    const handleDeleteOperator = (operatorToDelete) => {
      setOperatorList((prevOperators) =>
        prevOperators.filter((operator) => operator !== operatorToDelete)
      );
    };
  
    useEffect(() => {
      if (operatorsData) {
        const mappedResponse = operatorsData.map((obj) => ({
          id: obj._id,
          firstName: obj.Nombre,
          lastName: obj.ApellidoP + " " + obj.ApellidoM,
          RFC: obj.Rfc,
          NumLicencia: obj.Licencia,
          NSS: obj.NSS,
          tipoFigura: obj.TipoContrato,
        }));
        setOperatorRows(mappedResponse);
  
        setLoading(false);
      }
    }, [operatorsData]);

  
    return (
      <>
        <Grid
          container
          direction="row"
          spacing={3}
          justifyContent="center"
          minWidth={"xs"}
        >
          <Grid item xs={12} md={12}>
            <Autocomplete
              autoComplete
              options={operatorRows}
              sx={{ width: "100%" }}
              onChange={handleSelectOperator}
              renderInput={(params) => (
                <TextField {...params} label="Operador" name="Operator" />
              )}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName || ""
              }
              isOptionEqualToValue={(o,v)=>{
                    if (v === "") {
                      return true;
                    }
    
                    return v.id === o.id;
                  
              }}
              value={selected.firstName?selected:null}
            />
          </Grid>
          {operatorsList.length > 0 && (
            <Grid item xs={12}>
              <DynamicTable
                name="Operadores"
                data={operatorsList}
                handleDelete={handleDeleteOperator}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  export {  OperatorServiceComponent };
