import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  cancelInvoice,
  getInvoiceFiles,
  paidInvoice,
} from "../../../../services/modules/invoices/invoices";
import LockIcon from "@mui/icons-material/Lock";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CodeIcon from "@mui/icons-material/Code";
import React, { useState } from "react";
import {
  AlertDialog,
  InitialDialogState,
} from "../../../../components/userFeedback/AlertDialog";
import useAuth from "../../../../hooks/useAuth";

const MarkAsPaidComponent = ({ invoiceId }) => {
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (bool) => setDialog({ ...dialog, open: bool }),
  });
  const [loading, setLoading] = useState(false);
  const { userid } = useAuth();

  const handleMarkAsPaid = () => {
    console.log("INVOICE STATUS TO PAID...");
    setLoading(true);
    paidInvoice(userid, userid, invoiceId)
      .then((res) => {
        setLoading(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Factura pagada exitosamente",
          actions: [
            {
              label: "Ok",
              execute: () => window.location.reload(),
            },
          ],
        });
      })
      .catch((err) => {
        setLoading(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Error al marcar factura como pagada",
          content: err.response.data.message,
          actions: [
            {
              label: "Ok",
              execute: () => setDialog({ ...dialog, open: false }),
            },
          ],
        });
      });
  };

  return (
    <div>
      <AlertDialog {...dialog} />
      <Button variant="contained" color="primary" onClick={handleMarkAsPaid}>
        Marcar como Pagada
      </Button>
    </div>
  );
};

const CancelInvoiceComponent = ({ invoiceId }) => {
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (bool) => setDialog({ ...dialog, open: bool }),
  });
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const passwordRef = React.useRef("");
  const { userid } = useAuth();

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    passwordRef.current = newValue;
    setPassword(newValue);
  };

  const handleConfirm = (invoiceId, password) => {
    if (!password) {
      setDialog({
        open: true,
        title: "Error contraseña",
        content: "Favor de escribir su contraseña en el campo de texto",
        actions: [
          {
            label: "Cerrar",
            execute: () => setDialog({ ...dialog, open: false }),
          },
        ],
      });
      return;
    }

    setLoading(true);
    cancelInvoice(userid, userid, invoiceId, password)
      .then((res) => {
        setLoading(false);
        setDialog({
          open: true,
          title: "Cancelada exitosamente",
          content: "La factura fue cancelada con éxito",
          actions: [
            {
              label: "Recargar",
              execute: () => window.location.reload(),
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setDialog({
          open: true,
          title: "Error cancelando factura",
          content: err.response.data.message,
          actions: [
            {
              label: "Cerrar",
              execute: () => setDialog({ ...dialog, open: false }),
            },
          ],
        });
      });
  };

  const openCancelDialog = () => {
    setDialog({
      open: true,
      title: "¿Estás seguro de querer cancelar la factura?",
      content: (
        <>
          "Se cancelará ante la autoridad fiscal y es irreversible. Por favor,
          asegúrate de que realmente deseas cancelar la factura."
          <TextField
            autoFocus
            margin="dense"
            label="Contraseña"
            type="password"
            fullWidth
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: <LockIcon />,
            }}
          />
        </>
      ),
      actions: [
        {
          label: "Cancelar",
          execute: () => {
            setDialog({ ...dialog, open: false });
          },
        },
        {
          label: "Confirmar",
          execute: () => {
            handleConfirm(invoiceId, passwordRef.current);
          },
        },
      ],
    });
  };

  return (
    <div>
      <AlertDialog {...dialog} />
      {loading && <CircularProgress />}
      {!loading && (
        <Button onClick={openCancelDialog} variant="contained" color="error">
          Cancelar Factura
        </Button>
      )}
    </div>
  );
};

const DownloadInvoicePDFComponent = ({ iconView, cfdiData }) => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (bool) => setDialog({ ...dialog, open: bool }),
  });
  const { userid } = useAuth();

  const handleDownloadPDF = () => {
    setLoading(true);

    if (cfdiData.status !== "unissued") {
      getInvoiceFiles(userid, userid, "pdf", cfdiData.invoiceId)
        .then((data) => {
          setLoading(false);
          console.log(cfdiData);
          const binary = atob(data.data.pdf.replace(/\s/g, ""));
          const buffer = new ArrayBuffer(binary.length);
          const view = new Uint8Array(buffer);

          for (let i = 0; i < binary.length; i++) {
            view[i] = binary.charCodeAt(i);
          }

          const blob = new Blob([view], { type: "application/pdf" });

          const source = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          const filename = `Factura - ${cfdiData.GeneralModel.Folio} - ${cfdiData._id}.pdf`;

          link.href = source;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          setLoading(false);
          console.log("error downloading invoice");
          console.log(err);
          setDialog({
            ...dialog,
            open: true,
            title: "Error descargando la factura",
            content:
              "Hubo un error al descargar la factura, recarga la página e inténtalo de nuevo",
            actions: [],
          });
        });
    } else {
      setLoading(false);
      setDialog({
        ...dialog,
        open: true,
        title: "Factura sin timbrar",
        content:
          "Esta factura no se ha timbrado, por lo tanto no se puede descargar",
        actions: [
          {
            label: "Ok",
            execute: () => setDialog({ ...dialog, open: false }),
          },
        ],
      });
    }
  };

  return (
    <div>
      <AlertDialog {...dialog} />
      {loading && <CircularProgress />}
      {!iconView && !loading && (
        <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
          Descargar PDF
        </Button>
      )}
      {iconView && !loading && (
        <Tooltip title="Descargar PDF" arrow>
          <IconButton onClick={handleDownloadPDF}>
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

const DownloadXMLComponent = ({ cfdiData, iconView }) => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (bool) => setDialog({ ...dialog, open: bool }),
  });
  const { userid } = useAuth();

  const handleDownloadXML = () => {
    setLoading(true);

    getInvoiceFiles(userid, userid, "xml", cfdiData.invoiceId)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([Buffer.from(`${res.data.xml}`, "base64").toString("ascii")])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Factura - ${cfdiData.GeneralModel.Folio} - ${cfdiData._id}.xml`
        );
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Error descargando la factura",
          content:
            "Hubo un error al descargar la factura, recarga la página e inténtalo de nuevo",
          actions: [],
        });
      });
  };

  return (
    <div>
      <AlertDialog {...dialog} />
      {loading && <CircularProgress />}
      {!iconView && !loading && (
        <Button variant="contained" color="primary" onClick={handleDownloadXML}>
          Descargar XML
        </Button>
      )}
      {iconView && !loading && (
        <Tooltip title="Descargar XML" arrow>
          <IconButton onClick={handleDownloadXML}>
            <CodeIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export {
  MarkAsPaidComponent,
  CancelInvoiceComponent,
  DownloadInvoicePDFComponent,
  DownloadXMLComponent,
};
