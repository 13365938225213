import apiObj from "../../../axios"

const {api, version} = apiObj;

export const SERVICES_API_CONTEXT = (where, what) => {

    const main = {
        "create": (usr, cred, body) => api.post(`${version}/services`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),

    }

    const destination = {
        "get": (usr) => api.get(`${version}/destinations`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "getFiltered": (usr,filters) => api.get(`${version}/destinations/filter?${filters.join('&')}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "getOne": (usr,id) => api.get(`${version}/destinations_Id=${id}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "create": (usr, body) => api.post(`${version}/destinations`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "update": (usr, id, body) => api.put(`${version}/destinations/${id}`,body,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        "delete": (usr, id) => api.delete(`${version}/destinations/${id}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),

    }

    const methods = {
        "main":             main,
        "destination":      destination,

    }
    
    return methods[where][what]
}