import {
  Alert,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAuth from "../../../../../hooks/useAuth";
import { useInvoiceContext } from "../../../../../hooks/useInvoiceContext";
import {
  getExpeditionPLC,
  getIssuers,
} from "../../../../../services/modules/invoices/issuers";
import { NominaDateWithType } from "./NominaDateTypeComponent";
import {
  DeductionsTable,
  OtherPaymentsTable,
  PerceptionsTable,
} from "../../../../../components/Forms/Nomina/NominaTables";
import { PreviewCFDI } from "../../../../../components/Previews/PreviewTables";
import {
  getInvoiceFiles,
  issueInvoice,
} from "../../../../../services/modules/invoices/invoices";
import { searchEmployeeByKey } from "../../../../../services/modules/employees/employees";
import { getPaymentMethods } from "../../../../../services/modules/invoices/catalogs";
import { MexicoFederalEntityKeysCatalog } from "../../../utils/staticCatalogs";

const SelectIssuersAutocomplete = ({ selected, setSelected }) => {
  const { userid } = useAuth();

  const [options, setOptions] = useState({
    issuerList: [],
  });

  const [loading, setLoading] = useState({
    issuers: true,
  });

  const [error, setError] = useState({
    issuer: {
      failed: false,
      message: "",
    },
  });

  const handleChangeIssuer = (e, v, r) => {
    if (r === "clear") {
      selected.issuer.Rfc = "";
    } else {
      selected.issuer.Rfc = v.Rfc;
    }

    setSelected({ ...selected });
  };

  const getData = () => {
    return new Promise((resolve, reject) => {
      getIssuers(userid).then(({ data }) => {
        setLoading((obj) => {
          return { ...obj, issuers: false };
        });
        if (data.data.length === 0) {
          console.log("no issuers");
        }
        if (data.data.length > 0) {
          setOptions((obj) => {
            return { ...obj, issuerList: data.data };
          });
          selected.issuer.Rfc = data.data[0].Rfc;
          setSelected((obj) => {
            return {
              ...obj,
              issuer: { ...data.data[0], EmployerRegistration: "" },
            };
          });
        }
      });
    }).catch((err) => {
      console.log("issuers error", err);
    });
  };

  useEffect(() => {
    getData().then((resolved) => {});
  }, [userid]);

  return (
    <Autocomplete
      id="issuer"
      sx={{ minWidth: "100%" }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={error.issuer.failed}
            helperText={error.issuer.message}
            //label="Emisor"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {/*loading.issuers ? <CircularProgress /> : null*/}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
      getOptionLabel={(option) => option.Name || ""}
      options={options.issuerList}
      onChange={handleChangeIssuer}
      isOptionEqualToValue={(o, v) => {
        if (v === "") {
          return true;
        } else {
          return o.Name === v.Name;
        }
      }}
      value={
        selected.issuer
          ? options.issuerList.find(
              (option) => option.value === selected.issuer
            )
          : null
      }
      clearOnBlur
    />
  );
};

export const NominaSingleEmployee = () => {
  //*Sabemos que estamos en el paso 0, porque el stepper, devuelve el Componente NominaGeneralData, solo cuando estamos en el paso 0
  const step = 0;
  //*States
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState({
    employeesInput: false,
    nameId: false,
    cfdiOptions: {
      uses: false,
      paymentForm: false,
      paymentMethod: false,
    },
    place: false,
    employeeUpdate: false,
  });
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    status: "",
  });
  const [cfdiOptions, setCfdiOptions] = useState({
    names: [],
    uses: [],
    type: "",
    place: [],
    paymentForm: [],
    paymentMethod: [],
  });
  const [retry, setRetry] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  const [modal, setModal] = useState({
    open: false,
    leftValues: [],
  });

  const [employee, setEmployee] = useState({
    Curp: "",
    SocialSecurityNumber: "",
    StartDateLaborRelations: "",
    ContractType: "",
    RegimeType: "",
    Unionized: false,
    TypeOfJourney: "",
    EmployeeNumber: "",
    Department: "",
    Position: "",
    PositionRisk: "",
    FrequencyPayment: "",
    Bank: "",
    BankAccount: "",
    BaseSalary: 0,
    DailySalary: 0,
    FederalEntityKey: "",
  });

  const [selectedEmployee, setSelectedEmployee] = useState({});

  const [datosCfdi, setDatosCfdi] = useState({
    NameId: "16",
    ExpeditionPlace: "",
    CfdiType: "N",
    PaymentMethod: "PUE",
    Folio: 0,
  });

  const [receiver, setReceiver] = useState({
    Rfc: "",
    Name: "",
    CfdiUse: "CN01",
  });

  const [issuer, setIssuer] = useState({
    Rfc: "",
    EmployerRegistration: "",
    Curp: "",
  });

  const [payrollGeneral, setPayrollGeneral] = useState({
    Type: "O",
    PaymentDate: "",
    InitialPaymentDate: "",
    FinalPaymentDate: "",
    DaysPaid: 0,
    Employee: "",
    Perceptions: { Details: "" },
    Deductions: { Details: "" },
    OtherPayments: { Details: "" },
  });

  const [selected, setSelected] = useState({
    issuer: {
      EmployerRegistration: "",
    },
    place: "",
    paymentMethod: {
      Name: "Pago en una sola exhibición",
      Value: "PUE",
    },
    perception: "",
    deduction: {
      DeduccionType: "",
      Code: "",
      Description: "",
      Amount: "",
    },
  });

  //*Contexts
  const { userid } = useAuth();
  const { invoicesModelBySteps } = useInvoiceContext();

  const navigate = useNavigate();

  //*dataModels
  const NominaModel = invoicesModelBySteps.find(
    (object) => object.Nomina
  ).Nomina;

  const [nominaOrdinaria, setNominaOrdinaria] = useState(true);
  const [finishHasBeenClicked, setFinishedHasBeenClicked] = useState(false);

  const [selectedPerceptions, setSelectedPerceptions] = useState([]);
  const [selectedDeductions, setSelectedDeductions] = useState([]);
  const defaultDeductions = [
    {
      DeduccionType: "001",
      Code: "IMSS",
      Description: "Seguridad Social",
      Amount: 0,
    },
    {
      DeduccionType: "002",
      Code: "ISR",
      Description: "Impuesto Sobre la Renta",
      Amount: 0,
    },
    {
      DeduccionType: "003",
      Code: "RETIRO",
      Description: "Aportacion a retiro",
      Amount: 0,
    },
  ];

  const defaultPercpetions = [
    {
      PerceptionType: "001",
      Code: "001",
      Description: "Sueldos, Salarios  Rayas y Jornales",
      TaxedAmount: 0,
      ExemptAmount: 0,
    },
  ];

  const defaultOtherPayments = [
    {
      OtherPaymentType: "002",
      Code: "002",
      Description: "Subsidio para el empleo",
      Amount: 0,
      EmploymentSubsidy: { Amount: 0 },
    },
  ];

  const [CommonDeductions, setCommonDeductions] = useState(defaultDeductions);

  const [CommonPerceptions, setCommonPerceptions] =
    useState(defaultPercpetions);

  const [commonPayments, setCommonPayments] = useState(defaultOtherPayments);
  const [fechas, setFechas] = useState({
    PaymentDate: "",
    InitialPaymentDate: "",
    FinalPaymentDate: "",
    DaysPaid: "",
  });

  const [hasOtherPayments, setHasOtherPayments] = useState(false);

  const handleWithOtherPayments = () => {
    setHasOtherPayments(!hasOtherPayments);
  };

  const [employeeInput, setEmployeeInput] = useState("");

  const handleEmployeeInput = (e) => {
    setEmployeeInput(e.target.value);
    if (e.target.value !== undefined && e.target.value.length > 3) {
      setLoading((load) => {
        return {
          ...load,
          employeesInput: true,
        };
      });

      searchEmployeeByKey(userid, userid, "Nombre", e.target.value)
        .then((res) => {
          if (res.data.data.length > 0) {
            console.log("optionsSet");
            setEmployees(res.data.data);
          }
          setLoading((load) => {
            return {
              ...load,
              employeesInput: false,
            };
          });
        })
        .catch((err) => {
          setAlert((alert) => {
            return {
              ...alert,
              state: "error",
              message: "Error obteniendo a los Empleados",
            };
          });
        });
    }
  };

  let total = getTotal();

  function getTotal() {
    let total = 0;
    for (const array in CommonPerceptions) {
      total =
        total +
        Number(CommonPerceptions[array].TaxedAmount) +
        Number(CommonPerceptions[array].ExemptAmount);
    }
    for (const array in CommonDeductions) {
      total = total - Number(CommonDeductions[array].Amount);
    }
    if (hasOtherPayments) {
      for (const array in commonPayments) {
        total = total + Number(commonPayments[array].Amount);
      }
    }

    return total;
  }

  //*Input Functions
  const handleSetFolio = (e) => {
    NominaModel[step].dataModel["Folio"] = e.target.value;
    setDatosCfdi({
      ...datosCfdi,
      Folio: e.target.value,
    });
  };

  const handleCloseAlert = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({
      ...alert,
      open: false,
      message: "",
      status: "",
    });
  };

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleSelectEmployee = (selectedEmployee) => {
    console.log("Employee e, v ", selectedEmployee);

    const mappingTool = (type, array) => {
      let mappedPerceptions = [];
      let mappedDeductions = [];
      let mappedOtherPayments = [];

      if (type === "Perception") {
        array.map((DBperception) => {
          mappedPerceptions.push({
            PerceptionType: DBperception.Value,
            Code: DBperception.Value,
            Description: DBperception.Name,
            TaxedAmount: DBperception.TaxedTotal,
            ExemptAmount: DBperception.ExemptTotal,
          });
        });

        return mappedPerceptions;
      }

      if (type === "Deduction") {
        array.map((DBdeduction) => {
          mappedDeductions.push({
            DeduccionType: DBdeduction.Value,
            Code: DBdeduction.Value,
            Description: DBdeduction.Name,
            Amount: DBdeduction.Amount,
          });
        });

        return mappedDeductions;
      }
    };

    if (selectedEmployee !== "") {
      console.log(selectedEmployee);
      let mappedEmployee = {
        Curp: selectedEmployee.CURP,
        SocialSecurityNumber: selectedEmployee.NSS,
        StartDateLaborRelations: selectedEmployee.FechaInicio /* Yacambió */,
        ContractType: selectedEmployee.TipoContrato,
        RegimeType: selectedEmployee.TipoRegimen,
        Unionized: selectedEmployee.Sindicato,
        TypeOfJourney: selectedEmployee.TipoJornada,
        EmployeeNumber: parseInt(selectedEmployee.Id || 0), //this value is missing, im using this as its the only number
        Department: selectedEmployee.Dpto,
        Position: selectedEmployee.Puesto,
        PositionRisk: selectedEmployee.Riesgo,
        FrequencyPayment: selectedEmployee.Periodicidad,
        Bank: selectedEmployee.Banco,
        BankAccount: selectedEmployee.CtaBanco,
        BaseSalary: selectedEmployee.SueldoBase,
        DailySalary: selectedEmployee.SueldoDiario,
        FederalEntityKey: "",
      };

      let mappedReceiver = {
        Rfc: selectedEmployee.Rfc,
        Name:
          selectedEmployee.Nombre +
          " " +
          selectedEmployee.ApellidoP +
          " " +
          selectedEmployee.ApellidoM,
        CfdiUse: "CN01",
        FiscalRegime: `${selectedEmployee.RegimenFiscal}`,
        FederalEntityKey: "",
        TaxZipCode: selectedEmployee.DomFiscal.CP,
      };

      setSelectedDeductions(selectedEmployee.Deductions);
      setSelectedPerceptions(
        mappingTool("Perception", selectedEmployee.Perceptions)
      );

      if (nominaOrdinaria) {
        setCommonDeductions(
          mappingTool("Deduction", selectedEmployee.Deductions)
        );
        setCommonPerceptions(
          mappingTool("Perception", selectedEmployee.Perceptions)
        );
      } //setOtherPaymentssArray()
      setReceiver(mappedReceiver);
      setSelectedEmployee(selectedEmployee);
      setEmployee(mappedEmployee);
    } else {
      setReceiver(selectedEmployee);
      setSelectedEmployee(selectedEmployee);
      setEmployee(selectedEmployee);
      setCommonDeductions(defaultDeductions);
      setCommonPerceptions(defaultPercpetions);
    }
  };

  const handleTimbrado = (cfdi) => {
    //let calizobject = buildModelForTimbrado(userid,userid,cfdi)
    console.log("timbrando");
    let msgObj;
    if (cfdi.Complemento.Payroll.Deductions.Details.length === 0) {
      delete cfdi.Complemento.Payroll.Deductions;
    }
    if (cfdi.Complemento.Payroll.Perceptions.Details.length === 0) {
      delete cfdi.Complemento.Payroll.Deductions;
    }
    issueInvoice(userid, userid, cfdi, true, "nomina")
      .then((res) => {
        switch (res.status) {
          case 201:
            msgObj = {
              message: "Factura creada y timbrada con exito",
              actions: [
                {
                  label: "Descargar PDF",
                  execute: () => {
                    setLoadingBackdrop(true);
                    getInvoiceFiles(userid, userid, "pdf", res.data.invoiceId)
                      .then((data) => {
                        const binary = atob(data.data.pdf.replace(/\s/g, ""));
                        const buffer = new ArrayBuffer(binary.length);
                        const view = new Uint8Array(buffer);

                        for (let i = 0; i < binary.length; i++) {
                          view[i] = binary.charCodeAt(i);
                        }

                        const blob = new Blob([view], {
                          type: "application/pdf",
                        });

                        const source = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        const filename = `Factura - ${cfdi.Folio} - ${res.data.id}.pdf`;

                        link.href = source;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        setLoadingBackdrop(false);
                        setDialog({
                          ...dialog,
                          open: true,
                          title: "Descarga exitosa",
                          content: "Factura descargada con exito",
                          actions: [
                            {
                              label: "ok",
                              execute: () => {
                                setDialog({ ...dialog, open: false });
                                navigate(`/${userid.claims.rol}/welcome`);
                              },
                            },
                          ],
                        });
                      })
                      .catch((err) => {
                        console.log("error downloading invoice");
                        console.log(err);
                        setLoadingBackdrop(false);
                        setDialog({
                          ...dialog,
                          open: true,
                          title: "Error al descargar la factura",
                          content:
                            "Algo ha salido mal al intentar descargar la factura.",
                          actions: [
                            {
                              label: "ok",
                              execute: () =>
                                setDialog({ ...dialog, open: false }),
                            },
                          ],
                        });
                      });
                  },
                },
                {
                  label: "Volver a inicio",
                  execute: () => {
                    navigate(`/${userid.claims.rol}/welcome`);
                  },
                },
              ],
            };
            break;
          case 400:
            msgObj = {
              message: `Error de solicitud.
            ${res.data.message}
            `,
              actions: [
                {
                  label: "ok",
                  execute: () => setDialog({ ...dialog, open: false }),
                },
              ],
            };
            break;
          case 422:
            msgObj = {
              message: "Algo ha salido mal",
              actions: [
                {
                  label: "ok",
                  execute: () => setDialog({ ...dialog, open: false }),
                },
              ],
            };
            break;
          case 500:
            msgObj = {
              message: "Error en el servidor",
              actions: [
                {
                  label: "ok",
                  execute: () => setDialog({ ...dialog, open: false }),
                },
              ],
            };
            break;
          default:
            msgObj = {
              message: "error",
              actions: [
                {
                  label: "ok",
                  execute: () => setDialog({ ...dialog, open: false }),
                },
              ],
            };
        }
        setLoadingBackdrop(false);
        setDialog({
          ...dialog,
          title: "",
          content: msgObj.message,
          open: true,
          actions: msgObj.actions,
        });
      })
      .catch((err) => {
        console.log("error", err.message);
        const res = err.response;
        if (err.response) {
          switch (err.response.status) {
            case 400:
              msgObj = {
                message: `Error de solicitud.
              ${res.data.message}
              `,
                actions: [
                  {
                    label: "ok",
                    execute: () => {
                      setLoadingBackdrop(false);
                      setDialog({ ...dialog, open: false });
                    },
                  },
                ],
              };
              break;
            case 422:
              msgObj = {
                message: "Algo ha salido mal",
                actions: [
                  {
                    label: "ok",
                    execute: () => {
                      setLoadingBackdrop(false);
                      setDialog({ ...dialog, open: false });
                    },
                  },
                ],
              };
              break;
            case 500:
              msgObj = {
                message: "Error en el servidor",
                actions: [
                  {
                    label: "ok",
                    execute: () => {
                      setLoadingBackdrop(false);
                      setDialog({ ...dialog, open: false });
                    },
                  },
                ],
              };
              break;
            default:
              msgObj = {
                message: "error",
                actions: [
                  {
                    label: "ok",
                    execute: () => {
                      setLoadingBackdrop(false);
                      setDialog({ ...dialog, open: false });
                    },
                  },
                ],
              };
          }
          setDialog({
            ...dialog,
            title: "",
            content: msgObj.message,
            open: true,
            actions: msgObj.actions,
          });
        }
      });

    {
      /*new Promise((resolve, reject) => {
      const parsedToken = Buffer.from(process.env.FACTURAMA_TOKEN).toString(
        "base64"
      );
      console.log(parsedToken);*/
    }

    {
      /*axios
        .post(`${"endpoint"}route`, cfdi, {
          headers: {
            Authorization: "Basic " + parsedToken,
          },
        })
        .then((result) => {
          console.log(result);
          resolve(result);
          setDialog({
            open: true,
            title: "Ëxito",
            content: "El CFDI se ha timbrado correctamente",
            actions: [
              {
                label: "Ver",
                execute: () => {
                  setDialog({ ...dialog, open: false });
                },
              },
            ],
          });
        })
        .catch((err) => {
          reject(err);
          setDialog({
            open: true,
            title: "Error",
            content: "Error: no se ha podido enviar el correo electrónico",
            actions: [
              {
                label: "Cerrar",
                execute: () => {
                  setDialog({ ...dialog, open: false });
                },
              },
            ],
          });
        });
    }}}); */
    }
  };

  const handlePreview = () => {
    setFinishedHasBeenClicked(true);

    let incomplete = {
      perceptions: false,
      deductions: false,
    };
    // if (CommonPerceptions.length === 0) {
    //   incomplete.perceptions = true;
    // }
    // if (CommonDeductions.length === 0) {
    //   incomplete.deductions = true;
    // }

    setDialog({
      open: incomplete.perceptions || incomplete.deductions,
      title: "Información requerida",
      content: (
        <>
          {incomplete.perceptions ? (
            <Typography>-Se requiere al menos una percepción</Typography>
          ) : null}

          {incomplete.deductions ? (
            <Typography>-Se requiere al menos una deducción</Typography>
          ) : null}
        </>
      ),
      actions: [
        {
          label: "Cerrar y Corregir",
          execute: () => {
            setDialog({
              ...dialog,
              open: false,
            });
          },
        },
      ],
    });

    //I'm second guessing this implementation
    //seems pretty well handled and not likely to crash at this
    //point, but needs some validations before assigning the values
    //once its in production

    //This object has everything SPECIFIC to PAYROLL
    //needed in the invoice
    let initNom = {
      Receiver: "",
      Issuer: {},
      Complemento: {
        Payroll: {
          Employee: {},
          Perceptions: { Details: [] },
          Deductions: { Details: [] },
          OtherPayments: { Details: [] },
        },
      },
    };
    initNom.Receiver = receiver;
    initNom.Issuer = issuer;
    initNom.Complemento.Payroll = payrollGeneral;

    initNom.Complemento.Payroll.Employee = employee;

    initNom.Complemento.Payroll.Perceptions.Details = CommonPerceptions;
    initNom.Complemento.Payroll.Deductions.Details = CommonDeductions;
    initNom.Complemento.Payroll.OtherPayments.Details = hasOtherPayments
      ? commonPayments
      : [];

    //This is what the full invoice will look like
    let x = Object.assign(datosCfdi, initNom);
    console.log("full invoice test", x);

    setDialog({
      open: incomplete.perceptions || incomplete.deductions,
      title: "Información requerida",
      content: (
        <>
          {incomplete.perceptions ? (
            <Typography>-Se requiere al menos una percepción</Typography>
          ) : null}

          {incomplete.deductions ? (
            <Typography>-Se requiere al menos una deducción</Typography>
          ) : null}
        </>
      ),
      actions: [
        {
          label: "Cerrar y Corregir",
          execute: () => {
            setDialog({
              ...dialog,
              open: false,
            });
          },
        },
      ],
    });

    setDialog({
      open: !(incomplete.perceptions || incomplete.deductions),
      title: "Previsualizacion de Nomina",
      content: (
        <>
          <PreviewCFDI CFDI={x} />
        </>
      ),
      actions: [
        {
          label: "Cerrar y Corregir",
          execute: () => {
            setDialog({
              ...dialog,
              open: false,
            });
          },
        },
        {
          label: "Aceptar y Timbrar",
          execute: () => {
            setDialog({
              ...dialog,
              open: false,
            });
            setLoadingBackdrop(true);
            handleTimbrado(x);
          },
        },
      ],
    });
    console.log("issuer in selected", selected.issuer);
  };

  //*Data fetching functions
  const getData = async () => {
    let reqErrors = [];

    setLoading((loading) => {
      return {
        ...loading,
        nameId: true,
        cfdiOptions: {
          ...loading.cfdiOptions,
          paymentMethod: true,
        },
        place: true,
      };
    });
    setLoadingBackdrop(true);

    const paymentMethodsPromise = getPaymentMethods(userid, userid.token);
    const expeditionPLCPromise = getExpeditionPLC(userid, userid.token);

    const [paymentMethods, expeditionPLC] = await Promise.all([
      paymentMethodsPromise,
      expeditionPLCPromise,
    ]).catch((err) => {
      if (err.response || err.request || err.message) {
        reqErrors.push(
          err.response ? "Métodos de Pago" : "Lugares de expedición"
        );
      }
    });

    const newCfdiOptions = { ...cfdiOptions };
    if (paymentMethods?.data) {
      newCfdiOptions.paymentMethod = paymentMethods.data;
    }
    if (expeditionPLC?.data) {
      newCfdiOptions.place = expeditionPLC.data;
    }

    setCfdiOptions(newCfdiOptions);
    setLoadingBackdrop(false);
  };

  useEffect(() => {
    getData()
      .then(() => {
        setLoading((loading) => {
          return {
            ...loading,
            nameId: false,
            cfdiOptions: {
              ...loading.cfdiOptions,
              paymentForm: false,
              paymentMethod: false,
              uses: false,
            },
            place: false,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        setAlert((alert) => {
          return {
            ...alert,
            open: true,
            message:
              "Error obteniendo alguna de las opciones del catálogo del sat",
            status: "error",
          };
        });
      });
  }, []);

  useEffect(() => {
    if (cfdiOptions.place.length !== 0) {
      const newSelected = { ...selected, place: cfdiOptions.place[0] };
      setSelected(newSelected);
      setDatosCfdi((datosCfdi) => {
        return { ...datosCfdi, ExpeditionPlace: cfdiOptions.place[0]?.ZipCode };
      });
    }
  }, [cfdiOptions.place]);

  useEffect(() => {
    datosCfdi.PaymentMethod = selected.paymentMethod.Value;
  }, [selected.paymentMethod]);

  //set fechas from component into state
  useEffect(() => {
    payrollGeneral.InitialPaymentDate = fechas.InitialPaymentDate;
    payrollGeneral.FinalPaymentDate = fechas.FinalPaymentDate;
    payrollGeneral.DaysPaid = fechas.DaysPaid;
    payrollGeneral.PaymentDate = fechas.PaymentDate;
  }, [fechas]);

  useEffect(() => {
    issuer.Rfc = selected.issuer.Rfc;
    issuer.Name = selected.issuer.Name;
    issuer.FiscalRegime = selected.issuer.FiscalRegime;
    issuer.Curp = selected.issuer.CURP;
    setIssuer({ ...issuer });
  }, [selected.issuer]);

  useEffect(() => {
    issuer.EmployerRegistration = selected.issuer.EmployerRegistration;
    setIssuer({ ...issuer });
  }, [selected.issuer.EmployerRegistration]);

  useEffect(() => {
    if (nominaOrdinaria) {
      setPayrollGeneral({ ...payrollGeneral, Type: "O" });
      console.log(selectedPerceptions);
      if (selectedPerceptions.length === 0) {
        setCommonPerceptions([
          {
            PerceptionType: "001",
            Code: "001",
            Description: "Sueldos, Salarios  Rayas y Jornales",
            TaxedAmount: 0,
            ExemptAmount: 0,
          },
        ]);

        setCommonDeductions([
          {
            DeduccionType: "001",
            Code: "IMSS",
            Description: "Seguridad Social",
            Amount: 1,
          },
          {
            DeduccionType: "002",
            Code: "ISR",
            Description: "Impuesto Sobre la Renta",
            Amount: 1,
          },
          {
            DeduccionType: "003",
            Code: "RETIRO",
            Description: "Aportacion a retiro",
            Amount: 1,
          },
        ]);
      } else {
        setCommonPerceptions(selectedPerceptions);
        setCommonDeductions(selectedDeductions);
      }
    } else {
      setPayrollGeneral({
        ...payrollGeneral,
        Type: "E",
        Employee: {
          ...employee,
          FrequencyPayment: "99",
        },
      });
      setCommonPerceptions([
        {
          PerceptionType: "002",
          Code: "002",
          Description: "Gratificación Anual (Aguinaldo)",
          TaxedAmount: 0,
          ExemptAmount: 0,
        },
      ]);

      setCommonDeductions([]);
    }
  }, [nominaOrdinaria]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "6vh",
        }}
      >
        {/* //*Este componente se puede optimizar para consumir menos recursos pues al ser un componente exclusivo de la expedición de CFDI
            //*de nómina, entonces el nameId y el type siempre son iguales */}

        <Snackbar open={alert.open} onClose={handleCloseAlert}>
          <Alert severity={alert.open ? alert.status : "info"} variant="filled">
            {alert.message}
          </Alert>
        </Snackbar>

        {/* INICIA GRID DE INPUTS */}
        <Grid
          container
          maxWidth={"xl"}
          spacing={{ xs: 3, sm: 5, md: 3 }}
          sx={{ padding: 2 }}
        >
          <Grid
            item
            xs={12}
            md={9}
            textAlign="left"
            sx={{ alignItems: "baseline" }}
          >
            <Typography variant="h4" color="primary">
              Factura Recibo de Nómina
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ alignItems: "center" }}>
            <Box display="flex" flexDirection={"row"}>
              <Typography variant="body1" color="primary">
                {"Extraordinaria"}
              </Typography>
              <Switch
                checked={nominaOrdinaria}
                onChange={() => setNominaOrdinaria(!nominaOrdinaria)}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
              />
              <Typography variant="body1" color="primary">
                {"Ordinaria"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ height: "100%" }}>
            <Grid
              container
              spacing={1}
              alignItems={"stretch"}
              component={Paper}
              sx={{ padding: 2, height: "100%" }}
            >
              <Grid item xs={12}>
                <Divider
                  variant="fullWidth"
                  flexItem
                  alignItems="left"
                  textAlign="left"
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: 1 }}
                    color="secondary.dark"
                  >
                    Emisor
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} md={7}>
                <SelectIssuersAutocomplete
                  selected={selected}
                  setSelected={setSelected}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  variant="standard"
                  label="Registro del patrón"
                  defaultValue={issuer !== undefined ? issuer.Folio : ""}
                  onChange={(e, v, r) => {
                    console.log("registro del patron", e.target.value);
                    selected.issuer.EmployerRegistration = e.target.value;
                    setSelected({ ...selected });
                  }}
                  error={
                    finishHasBeenClicked &&
                    selected.issuer.EmployerRegistration === ""
                      ? true
                      : false
                  }
                  helperText={
                    finishHasBeenClicked &&
                    selected.issuer.EmployerRegistration === ""
                      ? "Este campo es obligatorio"
                      : ""
                  }
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* INICIA DATOS DEL COMPROBANTE */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1} component={Paper} sx={{ padding: 2 }}>
              <Grid item xs={12}>
                <Divider
                  variant="fullWidth"
                  flexItem
                  alignItems="left"
                  textAlign="left"
                >
                  <Typography
                    variant="h6"
                    color="secondary.dark"
                    sx={{ marginBottom: 1 }}
                  >
                    Datos del comprobante
                  </Typography>
                </Divider>
              </Grid>

              <Grid item xs={4} md={2}>
                <TextField
                  variant="standard"
                  label="Folio"
                  defaultValue={issuer !== undefined ? issuer.Folio : ""}
                  onChange={handleSetFolio}
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={8} md={5}>
                <Autocomplete
                  autoComplete
                  sx={{ width: "100%" }}
                  options={cfdiOptions.place}
                  getOptionLabel={(option) => {
                    return `${option.Name}`;
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        label="Lugar de expedición"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading.cfdiOptions.uses ? (
                                <CircularProgress />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                  onChange={(e, v, r) => {
                    if (r === "clear") {
                      setSelected({ ...selected, place: "" });
                      setDatosCfdi({
                        ...datosCfdi,
                        ExpeditionPlace: "",
                      });
                    } else {
                      setSelected({ ...selected, place: v });
                      setDatosCfdi({
                        ...datosCfdi,
                        ExpeditionPlace: v.ZipCode,
                      });
                    }
                  }}
                  value={
                    selected.place !== ""
                      ? selected.place
                      : cfdiOptions.place[0]
                      ? cfdiOptions.place[0]
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Autocomplete
                  autoComplete
                  sx={{ width: "100%" }}
                  options={cfdiOptions.paymentMethod}
                  getOptionLabel={(option) => {
                    return `${option.Name}`;
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        label="Método de pago"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading.cfdiOptions.paymentMethod ? (
                                <CircularProgress />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.Name === value.Name
                  }
                  defaultValue={NominaModel[step]["dataModel"]["PaymentMethod"]}
                  onChange={(e, v, r) => {
                    switch (r) {
                      case "selectOption":
                        NominaModel[step].dataModel["PaymentMethod"] = v;
                        break;
                      case "clear":
                        NominaModel[step].dataModel["PaymentMethod"] = "";
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* TERMINA DATOS DEL COMPROBANTE */}

          {/* INICIA EMPLEADO RECEPTOR */}
          <Grid item xs={12} md={6}>
            <Grid
              container
              spacing={1}
              component={Paper}
              sx={{ padding: 2, height: "100%" }}
            >
              <Grid item xs={12}>
                <Divider
                  variant="fullWidth"
                  flexItem
                  alignItems="left"
                  textAlign="left"
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: 1 }}
                    color="secondary.dark"
                  >
                    Empleado Receptor
                  </Typography>
                </Divider>
              </Grid>

              <Grid item xs={12} md={7}>
                <Autocomplete
                  autoComplete
                  options={employees}
                  getOptionLabel={(option) => {
                    return `${option.Nombre} ${option.ApellidoP} ${option.ApellidoM}`;
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        value={employeeInput}
                        //label="Empleado eceptor"
                        onChange={handleEmployeeInput}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading.employeesInput ? (
                                <CircularProgress />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                  onChange={(e, v, r) => {
                    switch (r) {
                      case "selectOption":
                        handleSelectEmployee(v);
                        break;
                      case "clear":
                        handleSelectEmployee("");
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Autocomplete
                  autoComplete
                  sx={{ width: "100%" }}
                  options={MexicoFederalEntityKeysCatalog}
                  getOptionLabel={(option) => {
                    return `${option.Name}`;
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        id="FederalEntityKey"
                        sx={{ width: "100%" }}
                        label="Lugar donde labora"
                        required
                        variant="standard"
                        value={employee.FederalEntityKey || ""}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                  onChange={(e, v, r) => {
                    console.log(v);
                    if (r === "clear") {
                      receiver.FederalEntityKey = "";
                      employee.FederalEntityKey = "";
                    } else {
                      receiver.FederalEntityKey = v.Value;
                      employee.FederalEntityKey = v.Value;
                    }
                    setEmployee({ ...employee });
                    setReceiver({ ...receiver });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* TERMINA EMPLEADO RECEPTOR */}

          {/* INICIA DATES */}
          <Grid item xs={12} md={6}>
            <NominaDateWithType
              defaultDays={15}
              fechasState={fechas}
              setFechas={setFechas}
            />
          </Grid>

          {/* TERMINA DATES */}

          {/* INICIA PERCEPTIONS */}
          <Grid item xs={12} md={6}>
            <PerceptionsTable
              key={CommonPerceptions[CommonPerceptions.length - 1]}
              perceptionsArray={CommonPerceptions}
              setPerceptionArray={setCommonPerceptions}
            />
          </Grid>

          {/* TERMINA PERCEPTIONS */}

          {/* INICIA DEDUCTIONS */}
          <Grid item xs={12} md={6}>
            <DeductionsTable
              deductionsArray={CommonDeductions}
              setDeductionsArray={setCommonDeductions}
            />
          </Grid>

          {/* TERMINA DEDUCTIONS */}

          {/* INICIA OTROS PAGOS */}

          <Grid item xs={12} md={6} order={{ xs: 7, md: 6 }}>
            {!!hasOtherPayments && (
              <OtherPaymentsTable
                otherPaymentsArray={commonPayments}
                setOtherPaymentssArray={setCommonPayments}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 6, md: 7 }}
            sx={{ marginBottom: "4vh" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name={"otrosPagos"}
                  checked={hasOtherPayments}
                  onChange={handleWithOtherPayments}
                />
              }
              label="Añadir Otros Pagos"
              labelPlacement="end"
            />
          </Grid>

          {/* TERMINA OTROS PAGOS */}

          {/* FINALIZAR */}

          <Grid item xs={6} md={8} order={8}></Grid>
          <Grid item xs={6} md={4} order={{ xs: 9 }}>
            <Typography color="primary.main">
              Total Percibido: ${total}
            </Typography>
            <Button variant="contained" color="primary" onClick={handlePreview}>
              Aceptar y previsualizar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
