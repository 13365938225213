export const responsiveOptions = {
  buttonResize: {
    width: {
      xs: "60%",
      sm: "50%",
      md: "20%",
    },
  },
  fieldResize: {
    width: {
      xs: "80%",
      sm: "50%",
      md: "30%",
    },
  },
  fieldResize_2: {
    width: {
      xs: "80%",
      sm: "50%",
      md: "30%",
    },
    margin: "1%",
  },
  halfField: {
    width: {
      xs: "39%",
      sm: "24%",
      md: "14%",
    },
    margin: "1%",
    flexDirection: "row",
  },
  displayMobileOnly: {
    xs: "flex",
    sm: "none",
  },
  displayDesktopOnly: {
    xs: "none",
    sm: "flex",
  },
  full: "100%",
  half: "50%",
};

export function biDimension(mobile, desktop) {
  const a = mobile.toString();
  const b = desktop.toString();
  return {
    xs: a + "%",
    md: b + "%",
  };
}

export function fullDimension(xs, sm, md, lg) {
  const a = xs.toString();
  const b = sm.toString();
  const c = md.toString();
  const d = lg.toString();
  return {
    xs: a + "%",
    sm: b + "%",
    md: c + "%",
    lg: d + "%",
  };
}

export function largeButton(HEX_bg_color, HEX_txt_color) {
  const a = {};
  console.log(a);
  return {
    background: "#" + HEX_bg_color,
    color: "#" + HEX_txt_color,
    margin: "2vh",
    width: fullDimension(50, 35, 33, 18),
  };
}
