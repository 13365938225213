import * as React from 'react';
import CustomerFiscalForm from './CustomerFiscalForm';
import CustomerAddressForm from './CustomerAddressForm';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import CustomerClientsForm from './CustomerClientsForm';

export default function CustomerSubcompaniesForm({
    regimenOptions =["No hay opciones"], 
    setter = function test(){console.log("algo falló mijo")} , 
    typeHook={error:"error en el hook de cliente"} 
  }) {

    let setSubOrganizacion = setter
    let subOrganizacion = typeHook
    
    const [willAddClient,setWillAddClient]=React.useState(false)

    let clientsArr = []
    
        return (
            <React.Fragment>
                <CustomerFiscalForm regimenOptions={regimenOptions} setter={setSubOrganizacion} typeHook={subOrganizacion}/>
                <CustomerAddressForm type={"Sub-Organización"} setter={setSubOrganizacion} typeHook={subOrganizacion}/>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name={"client"}
                                onChange={()=>setWillAddClient(!willAddClient)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Añadir Clientes"
                        labelPlacement="end"
                    />
                    </Grid>
                {!!willAddClient && <CustomerClientsForm  setter={setSubOrganizacion} typeHook={subOrganizacion}/>}
            </React.Fragment>
        );
}