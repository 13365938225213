import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { Button, Grid } from "@mui/material";
import ferbyTheme from "../../theme/ferbyTheme";
import { getCustomers } from "../../services/modules/invoices/organization";
import EditComponent from "../admin/trucksControl/components/EditComponent";
import { deleteCustomer } from "../../services/modules/organization/customers";
import { AlertDialog } from "../../components/userFeedback/AlertDialog";

const CustomersIndex = () => {
  const [rows, setRows] = useState([]);

  const navigate = useNavigate();

  const { userid } = useAuth();

  const goToCreate = () => {
    navigate(`/${userid.claims.rol}/customers/new`);
  };

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const columns = [
    {
      field: "TaxName",
      headerName: "Nombre",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Rfc",
      headerName: "RFC",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Ubicaciones",
      headerName: "Ubicaciones",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.Ubicaciones.map((location) => {
          return location.ZipCode;
        });
      },
    },
    {
      field: "Actions",
      headerName: "Opciones",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/customers/update`}
            objName={"Cliente"}
            onDelete={() => deleteVehicles(params.row.id)}
            rowParams={params.row}
          />
        );
      },
    },
  ];

  const deleteVehicles = (id) => {
    console.log("userid", userid);
    deleteCustomer(userid, userid, id, { reasonByDelete: "default" })
      .then((response) => {
        console.log(response);
        setDialog({
          ...dialog,
          open: true,
          title: "Cliente eliminado",
          content: "",
          actions: [{ label: "Ok", execute: () => window.location.reload() }],
        });
      })
      .catch((err) => {
        console.log(err.response);
        setDialog({
          ...dialog,
          open: true,
          title: "Ocurrió un error al eliminar al cliente",
          content: "Inténtelo más tarde",
          actions: [
            { label: "Ok", execute: () => window.location.reload() },
            { label: "Reintentar", execute: () => deleteVehicles(id) },
          ],
        });
      });
  };

  useEffect(() => {
    const rowsToSet = [];
    getCustomers(userid, userid.token)
      .then((customersData) => {
        customersData.data.data.forEach((element) => {
          console.log("elementor", element);
          rowsToSet.push({
            id: element._id,
            _id: element._id,
            TaxName: element.TaxName,
            Rfc: element.Rfc,
            Ubicaciones: element.Addresses,
            subEmpresa: element.Subempresa,
          });
        });
        setRows((ir) => rowsToSet);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10vh",
        "& .header-table": {
          backgroundColor: ferbyTheme.palette.primary.main,
          color: "white",
        },
      }}
    >
      <AlertDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />
      <Grid container spacing={2} maxWidth={"lg"}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" onClick={goToCreate} color="primary">
              Crear nuevo
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <DataGrid sx={{ minHeight: "50vh" }} columns={columns} rows={rows} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomersIndex;
