import React from 'react'
import styled from '@mui/system/styled'
import { CircularProgress } from '@mui/material'

const classes = {
    loading: `loading`,
}

const LoadingDivStyled = styled('div')((theme) => ({
    [classes.loading]: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 'calc(50% - 20px)',
        margin: 'auto',
        height: '40px',
        width: '40px',
        '& img': {
            position: 'absolute',
            height: '25px',
            width: 'auto',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
        },
    },
}))

const FerbyLoading = (props) => {
    return (
        <LoadingDivStyled className={classes.loading}>
            {/* <img src="/assets/images/logo-circle.svg" alt="" /> */}
            <CircularProgress color="secondary" />
        </LoadingDivStyled>
    )
}

export default FerbyLoading
