import * as React from "react";
import { LinePlot } from "@mui/x-charts/LineChart";
import { BarPlot } from "@mui/x-charts/BarChart";
import { BarChart } from '@mui/x-charts/BarChart';
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { AllSeriesType } from "@mui/x-charts/models";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { toIsoHelper } from "../../../issue/invoiceData/Nomina/NominaDateTypeComponent";
import { Box } from "@mui/material";

export default function VolumeChart(props) {
  
  const validData = props.data.data.filter((item) => item.steps !== 0)
  
  const MIN_WIDTH = validData.length * props.segmentWidth;

  const series = [
    {
      type: "bar",
      yAxisKey: "amount",
      label: "Emitidas",
      curve: "catmullRom",
      color: "blue",
      data: props.data.data.map((item) => item.totalIssued), // Use totalPaidIncome from the response data
    },
    {
      type: "bar",
      yAxisKey: "amount",
      label: "Pagadas",
      curve: "catmullRom",
      color: "green",
      data: props.data.data.map((item) => item.totalPaid), // Use totalPaidIncome from the response data
    },
  ];

  const formatDate = (date) => {
    return date.toLocaleDateString("es-MX", {
      day: "2-digit",
      month: "2-digit",
    });
  };

  const formatValue = (value) => {
    return `$${value.toFixed(2)}`;
  };

  const determineXAxisInterval = () => {
    const { initial, final } = props.data.dateRange;
    const startDate = new Date(initial);
    const endDate = new Date(final);
    const diffInDays = Math.abs((endDate - startDate) / (1000 * 60 * 60 * 24));

    return diffInDays > 14 ? 7 : 1;
  };

  return (
    <Box maxWidth={"100%"} overflow={"auto"} sx={{ maxWidth: "100%" }}>
      <BarChart
        series={series}
        width={MIN_WIDTH}
        height={props.height}
        xAxis={[
          {
            id: "dates",
            data: props.data.data.map((item, index) => {
              const { initial, final } = item.dateRange;
              let newInitial = new Date(initial);
              let newFinal = new Date(final);

              return { date: newInitial, nextDate: newFinal, index };
            }),
            scaleType: "band",
            valueFormatter: (item) => {
              return `${formatDate(new Date(item.date))} - ${formatDate(
                new Date(item.nextDate)
              )}`;
            },
          },
        ]}
        yAxis={[
          {
            id: "amount",
            scaleType: "linear",
            data: props.data.data.map((item) => {
              console.log(item);
              return item.totalIssued;
            }),
            label: "Facturas",
            //valueFormatter: formatValue,
          },
        ]}
      >
        <ChartsXAxis label="Fechas" position="bottom" axisId="dates" />
        <ChartsYAxis label="Facturas" position="left" axisId="amount" />
      </BarChart>
    </Box>
  );
}

VolumeChart.defaultProps = {
  data: {
    dateRange: {
      initial: toIsoHelper(new Date("2023-02-02")),
      final: toIsoHelper(new Date("2023-03-05")),
    },
    totalIssued: 10,
    totalPaid: 8,
    totalIssuedIncome: 1000,
    totalPaidIncome: 850,
    data: [
      {
        steps: 7,
        totalIssued: 4,
        totalPaid: 3,
        totalIssuedIncome: 400,
        totalPaidIncome: 350,
        invoices: [],
      },
      {
        steps: 7,
        totalIssued: 6,
        totalPaid: 5,
        totalIssuedIncome: 600,
        totalPaidIncome: 500,
        invoices: [],
      },
      // More data points in the same format
    ],
  },
  width: 500,
  height: 800,
  segmentWidth:150
};
