import React, { useState } from "react";
//*MUI Components
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material";
import { Alert, Backdrop, CircularProgress, Grid } from "@mui/material";
//*Network, routing and API
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth.js";
import { PreviewCFDI } from "../Previews/PreviewTables.jsx";
import { FullCfdiKeymap as keyMap } from "../Previews/PreviewTables.jsx";
import { getTypeOfInvoice } from "../Previews/Getters.jsx";
import PostIssuingActions from "./PostIssuing.jsx";
import CloseIcon from "@mui/icons-material/Close";
import { IssueButtonComponent } from "../../modules/invoices/submodules/issueComponent/IssueComponent.jsx";

const loopObject = (obj) => {
  for (const [key, data] of Object.entries(obj)) {
    if (data !== "" || data !== undefined) {
      if (typeof data === "object") {
        if (Array.isArray(data)) {
          data.forEach((el) => {
            if (typeof el === "string" || typeof el === "number") {
              el = "";
            }
          });
        }
        loopObject(data);
      }
      if (typeof data === "string" || typeof data === "number") {
        obj[key] = "";
      }
    }
  }
};

const InvoiceGenerator = (props) => {
  const invoiceJson = window.localStorage.getItem("invoiceModel");
  const navigate = useNavigate();
  const { userid, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    status: "",
    message: "",
    open: false,
  });
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
    content: "",
    actions: [],
  });

  function handleCloseDialog() {
    setDialog({ ...dialog, open: false });
  }

  function handleCloseAlert() {
    setAlert({ ...alert, open: false });
  }


  function checkEmptyData(
    data,
    path = "",
    emptyFields = [],
    optionalRoutes = []
  ) {
    for (const key in data) {
      const newPath = path === "" ? key : `${path}.${key}`;
      const currentRoute = newPath.replace(/^.*?\./, ""); // Extract current route from newPath
      if (typeof data[key] === "object" && data[key] !== null) {
        checkEmptyData(data[key], newPath, emptyFields, optionalRoutes);
      } else if (data[key] === "" || data[key] === null) {
        // Check if current route is not in optionalRoutes array before adding to emptyFields
        console.log("newPath", newPath);
        console.log(optionalRoutes.includes(newPath));
        if (!optionalRoutes.includes(newPath)) {
          emptyFields.push(newPath);
        }
      }
    }
    return emptyFields;
  }

  const deep_value = (obj, peth) => {
    for (let i = 0, path = peth.split("."); i < path.length; i++) {
      if (!obj) return undefined;
      //if the path key is a number, it implies its on an array, so we must "print"
      //the same values as in the 0 element of the array, as is the only one defined in the keymap
      if (!Number.isNaN(Number(path[i]))) obj = obj[0];
      //else: we just keep digging
      else {
        obj = obj[path[i]];

        if (!obj[path[i]]) {
          return path[i];
        }
      }
    }
    return obj;
  };

  const handlePreviewInvoice = () => {
    console.log("Parsed invoiceJson", JSON.parse(invoiceJson));
    let usableJson = JSON.parse(invoiceJson);
    const type = getTypeOfInvoice(usableJson);
    let optionalArray;
    let itemsCount = usableJson.Models.I?.Items.length || 0;
    let addressCount = usableJson.GeneralModel.Issuer.Address?.length || 0;
    let paymentsCount = usableJson.Complementos.Payments?.length || 0;

    switch (type) {
      case "pago":
        optionalArray = [
          "GeneralModel.ExpeditionPlace.InteriorNumber",
          "GeneralModel.ExpeditionPlace.Locality",
          "GeneralModel.ExpeditionPlace.Status.updatedBy",
          "GeneralModel.ExpeditionPlace.Status.updatedAt",
          "GeneralModel.ExpeditionPlace.Status.deletedBy",
          "GeneralModel.ExpeditionPlace.Status.deletedAt",
          "GeneralModel.ExpeditionPlace.Status.reasonByDelete",
          "GeneralModel.Receiver.Address.InteriorNumber",
          "GeneralModel.Receiver.Address.Neighborhood",
          "GeneralModel.Receiver.Address.Municipality",
          "GeneralModel.Receiver.Address.CP",
          "GeneralModel.Date",
          "GeneralModel.PaymentForm",
          "GeneralModel.PaymentMethod",
          "GeneralModel.Issuer.Address",
          "GeneralModel.Issuer.CURP",
          "Models",
        ];
        for (let i = 0; i < paymentsCount; i++) {
          let relatedCount =
            usableJson.Complementos.Payments[i].RelatedDocuments?.length;
          for (let j = 0; j < relatedCount; j++) {
            optionalArray.push(
              `Complementos.Payments.${i}.RelatedDocuments.${j}.Serie`
            );
            optionalArray.push(
              `Complementos.Payments.${i}.RelatedDocuments.${j}.Currency`
            );
            optionalArray.push(
              `Complementos.Payments.${i}.RelatedDocuments.${j}.ImpSaldoInsoluto`
            );
            optionalArray.push(
              `GeneralModel.Issuer.Address.${i}.InteriorNumber`
            );
            optionalArray.push(`GeneralModel.Issuer.Address.${i}.Neighborhood`);
            optionalArray.push(`GeneralModel.Issuer.Address.${i}.Municipality`);
          }
        }
        break;
      case "cartaPorte":
        optionalArray = [
          "GeneralModel.ExpeditionPlace.Locality",
          "GeneralModel.ExpeditionPlace.InteriorNumber",
          "GeneralModel.ExpeditionPlace.Status.updatedBy",
          "GeneralModel.ExpeditionPlace.Status.updatedAt",
          "GeneralModel.ExpeditionPlace.Status.deletedBy",
          "GeneralModel.ExpeditionPlace.Status.deletedAt",
          "GeneralModel.ExpeditionPlace.Status.reasonByDelete",
          "GeneralModel.Receiver.Address.InteriorNumber",
          "GeneralModel.Receiver.Address.Neighborhood",
          "GeneralModel.Receiver.Address.Municipality",
          "GeneralModel.Receiver.Address.CP",
          "GeneralModel.Date",
          "Complementos.CartaPorte20.Ubicaciones.0.Domicilio.Colonia",
          "Complementos.CartaPorte20.Ubicaciones.1.Domicilio.Colonia",
        ];

        for (let i = 0; i < itemsCount; i++) {
          optionalArray.push(`Models.I.Items.${i}.product.Complement`);
          optionalArray.push(`Models.I.Items.${i}.description`);
        }

        for (let i = 0; i < addressCount; i++) {
          optionalArray.push(
            `GeneralModel.Issuer.Address.${i}.Status.deletedAt`
          );
          optionalArray.push(
            `GeneralModel.Issuer.Address.${i}.Status.updatedBy`
          );
          optionalArray.push(
            `GeneralModel.Issuer.Address.${i}.Status.reasonByDelete`
          );
          optionalArray.push(
            `GeneralModel.Issuer.Address.${i}.Status.deletedBy`
          );
          optionalArray.push(`GeneralModel.Issuer.Address.${i}.InteriorNumber`);
          optionalArray.push(`GeneralModel.Issuer.Address.${i}.Neighborhood`);
          optionalArray.push(`GeneralModel.Issuer.Address.${i}.Municipality`);
        }

        break;
      case "facturaIngreso":
        optionalArray = [
          "GeneralModel.ExpeditionPlace.Locality",
          "GeneralModel.ExpeditionPlace.InteriorNumber",
          "GeneralModel.ExpeditionPlace.Status.updatedBy",
          "GeneralModel.ExpeditionPlace.Status.updatedAt",
          "GeneralModel.ExpeditionPlace.Status.deletedBy",
          "GeneralModel.ExpeditionPlace.Status.deletedAt",
          "GeneralModel.ExpeditionPlace.Status.reasonByDelete",
          "GeneralModel.Receiver.Address.InteriorNumber",
          "GeneralModel.Receiver.Address.Neighborhood",
          "GeneralModel.Receiver.Address.Municipality",
          "GeneralModel.Receiver.Address.CP",
          "GeneralModel.Date",
          "Complementos",
        ];

        for (let i = 0; i < itemsCount; i++) {
          optionalArray.push(`Models.I.Items.${i}.product.Complement`);
          optionalArray.push(`Models.I.Items.${i}.description`);
        }

        for (let i = 0; i < addressCount; i++) {
          optionalArray.push(
            `GeneralModel.Issuer.Address.${i}.Status.deletedAt`
          );
          optionalArray.push(
            `GeneralModel.Issuer.Address.${i}.Status.updatedBy`
          );
          optionalArray.push(
            `GeneralModel.Issuer.Address.${i}.Status.reasonByDelete`
          );
          optionalArray.push(
            `GeneralModel.Issuer.Address.${i}.Status.deletedBy`
          );
          optionalArray.push(`GeneralModel.Issuer.Address.${i}.InteriorNumber`);
          optionalArray.push(`GeneralModel.Issuer.Address.${i}.Neighborhood`);
          optionalArray.push(`GeneralModel.Issuer.Address.${i}.Municipality`);
        }

        break;

      default:
        break;
    }

    let emptyFieldsRoutes = checkEmptyData(usableJson, "", [], optionalArray);
    let emptyRoutesKeymappedNames = [];

    for (const route in emptyFieldsRoutes) {
      console.log(route);
      emptyRoutesKeymappedNames.push(deep_value(keyMap, route));
    }

    if (type === "cartaPorte") {
      let configVehicular =
        usableJson.Complementos.CartaPorte20.Mercancias.Autotransporte
          .IdentificacionVehicular.ConfigVehicular;

      if (
        configVehicular !== "VL" &&
        configVehicular !== "C2" &&
        configVehicular !== "C3"
      ) {
        if (
          usableJson.Complementos.CartaPorte20.Mercancias.Autotransporte
            .Remolques.length === 0
        )
          emptyRoutesKeymappedNames.push("Remolques");
      }
    }

    if (type !== "pago") {
      if (usableJson.Models.I.Items.length === 0) {
        emptyRoutesKeymappedNames.push("Conceptos De La Factura");
      }
    }

    const MissingList = () => {
      return (
        <Grid container maxWidth={"md"} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" color="error.main">
              Falta información en los siguientes campos:
            </Typography>
          </Grid>
          {emptyRoutesKeymappedNames.map((MF, idx) => {
            if (typeof MF !== "object")
              return (
                <Grid key={idx} item xs={3}>
                  <Typography>-{MF}</Typography>
                </Grid>
              );
          })}
        </Grid>
      );
    };

    if (
      emptyFieldsRoutes.length !== 0 ||
      emptyRoutesKeymappedNames.length !== 0
    ) {
      setDialog({
        open: true,
        title: "Datos Faltantes",
        content: MissingList(),
        actions: [
          {
            label: "ok",
            execute: () => {
              setDialog({ ...dialog, open: false });
            },
          },
        ],
      });
    } else {
      setDialog({
        open: true,
        title: "Previsualización",
        content: (
          <>
            <PreviewCFDI CFDI={usableJson} />
          </>
        ),
        actions: [
          {
            label: "Cerrar",
            execute: () => {
              handleCloseDialog();
            },
          },
          <IssueButtonComponent cfdi={usableJson} label={"Aceptar y Timbrar"}/>
        ],
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            if(action.label){
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
            }else{
              return action
            }
          })}
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert severity={`${alert.status}`}>{`${alert.message}`}</Alert>
      </Snackbar>
      <>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Grid container align="center" spacing={2} paddingY="15%">
            <Grid item xs={12}>
              <Button
                id="preview"
                onClick={handlePreviewInvoice}
                color="secondary"
                sx={{ color: "white" }}
                disabled={loading}
                variant="contained"
              >
                Previsualizar factura
              </Button>
            </Grid>
          </Grid>
        )}
      </>
    </Box>
  );
};

export { InvoiceGenerator};
