import apiObj from "../../../axios"

export const uploadProductsCsv = async (file,user, credential) => {
    let formData = new FormData()

    formData.append(
        "productos",
        file
    )

    let response = await apiObj.api.post(`/${apiObj.version}/invoices/files/productscsv`, formData, {headers: {
        "Content-type":"multipart/formData",
        "authorization":user.token,
        "user":user
    }})

    return response
}