import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

export default function CustomerFiscalForm(
  {
    regimenOptions =["no options available"] , 
    setter = function test(){console.log("algo falló mijo")} , 
    typeHook={error:"error en el hook de cliente"} 
  }
){
        
        let options = regimenOptions
        let setCustomer = setter
        let customer = typeHook

  const handleSetCustomer = (e) =>{
    setCustomer({
      ...customer,
      [e.target.id]:e.target.value
    })      
  }
        
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Información Fiscal
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <TextField
                required
                id="Nombre"
                name="fiscalName"
                label="Nombre o Razón Social"
                fullWidth
                autoComplete="Nombre"
                variant="standard"
                onChange={handleSetCustomer}
            />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="RFC"
            name="rfc"
            label="RFC"
            fullWidth
            autoComplete="RFC"
            variant="standard"
            onChange={handleSetCustomer}
          />
        </Grid>
        
        <Grid item xs={12}  md={6}>
        <Autocomplete
                        options={options}
                        sx={{marginBottom:"4vh", width:"100%"}}
                        label="Régimen fiscal"
                        onChange={(e,v,r) => {
                            switch(r){
                                case "selectOption":
                                    setCustomer({
                                        ...customer,
                                        RegimenFiscal:v
                                    })
                                    break;
                                default:
                                    break;
                            }
                        }}
                        renderInput={(params) => <TextField  label="Selecciona un regimen" variant="standard" id="RegimenFiscalInput" {...params}/>}
                        getOptionLabel={(opt) => `${opt.Value} - ${opt.Name}`}
                        />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
