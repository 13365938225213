import React from "react";
import useAuth from "../../hooks/useAuth";
import { subscribeUserToModulePlan } from "../../services/payments/stripe";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
  Switch,
  Typography,
} from "@mui/material";
import styled from "@mui/system/styled";
import { useNavigate } from "react-router";


const classes = {
  section: `section`,
  cardHeader: `cardHeader`,
}

const SectionStyled = styled('section')((theme) => ({
  [classes.section]: {
    backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  [classes.cardHeader]: {
    paddingTop: theme.spacing(3),
  },
}));

const HeaderStyled = styled('header')((theme) => ({
  [classes.header]: {
    paddingTop: theme.spacing(3)
  }
}));

export const ModulesAccessView = () => {
  const { user, userid } = useAuth();
  const navigate = useNavigate();

  const handleAcceptPlan = () => {
    //* Redirect to payments or show payment gateway
    subscribeUserToModulePlan(
      "invoicing",
      { customerId: userid.claims.customerInPayments.customerId },
      {
        headers: {
          authorization: userid.token,
          user: user,
        },
      }
    )
      .then((result) => {
        navigate(`/${userid.claims.rol}/checkout`, {
          state: {
            clientSecret:
              result.data.latest_invoice.payment_intent.client_secret,
            subscriptionId: result.data.id,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const content = {
    badge: "Qsoft",
    "header-p1": "Gestiona tu empresa de transporte",
    "header-p2": "con un software integral",
    description: "Comienza probando el modulo de facturación.",
    option1: "Mensual",
    option2: "Anual (no disponible)",
    "01_title": "Lorem ipsum",
    "01_price": "$9",
    "01_suffix": " / mo",
    "01_benefit1": "3 Emails",
    "01_benefit2": "1 Database",
    "01_benefit3": "Unlimited Domains",
    "01_benefit4": "10 GB Storage",
    "01_primary-action": "Select plan",
    "01_secondary-action": "Learn more",
    "02_title": "Software ERP: Modulo de facturación",
    "02_price": "$100",
    "02_suffix": " / mes",
    "02_suffix2": "+ 0.75 / factura",
    "02_benefit1": "Carta Porte versión 4.0",
    "02_benefit2": "Procesamiento de datos de la mercancia",
    "02_benefit3": "Incrementa la productividad",
    "02_benefit4": "Reduce personal",
    "02_primary-action": "Seleccionar plan",
    "02_secondary-action": "Conoce un poco más",
    "03_title": "Consectuter",
    "03_price": "$499",
    "03_suffix": " / mo",
    "03_benefit1": "9 Emails",
    "03_benefit2": "3 Database",
    "03_benefit3": "Unlimited Domains",
    "03_benefit4": "50 GB Storage",
    "03_primary-action": "Select plan",
    "03_secondary-action": "Learn more",
    // ...props.content
  };

  const [state, setState] = React.useState({
    checkbox: false,
  });

  const handleChange = (event) => {
    setState({ ...state, checkbox: event.target.checked });
  };

  return (
    <SectionStyled className={classes.section}>
      <Container maxWidth="lg">
        <Box py={8} textAlign="center">
          <Box mb={3}>
            <Container maxWidth="sm">
              <Typography variant="overline" color="textSecondary">
                {content["badge"]}
              </Typography>
              <Typography variant="h3" component="h2" gutterBottom={true}>
                <Typography variant="h3" component="span" color="primary">
                  {content["header-p1"]}{" "}
                </Typography>
                <Typography variant="h3" component="span">
                  {content["header-p2"]}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
              >
                {content["description"]}
              </Typography>

              <div>
                <Typography variant="subtitle1" component="span">
                  {content["option1"]}
                </Typography>
                &nbsp;{" "}
                <Switch
                  name="checkbox"
                  color="primary"
                  checked={state.checkbox}
                  disabled
                  onChange={handleChange}
                />{" "}
                &nbsp;
                <Typography variant="subtitle1" component="span">
                  {content["option2"]}
                </Typography>
              </div>
            </Container>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              {/* <Card variant="outlined">
                <CardHeader title={content['01_title']} className={classes.cardHeader}></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content['01_price']}
                      <Typography variant="h6" color="textSecondary" component="span">{content['01_suffix']}</Typography>
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['01_benefit1']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['01_benefit2']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['01_benefit3']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>{content['01_benefit4']}</Typography>
                  </Box>
                  <Button variant="outlined" color="primary" className={classes.primaryAction}>{content['01_primary-action']}</Button>
                  <Box mt={2}>
                    <Link href="#" color="primary">{content['03_secondary-action']}</Link>
                  </Box>
                </CardContent>
              </Card> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader
                  title={content["02_title"]}
                  className={classes.cardHeader}
                ></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content["02_price"]}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="span"
                      >
                        {content["02_suffix"]}
                      </Typography>
                      <br></br>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        {content["02_suffix2"]}
                      </Typography>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit1"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit2"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit3"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                      paragraph={true}
                    >
                      {content["02_benefit4"]}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAcceptPlan}
                  >
                    {content["02_primary-action"]}
                  </Button>
                  <Box mt={2}>
                    <Link href="#" color="primary">
                      {content["02_secondary-action"]}
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <Card variant="outlined">
                <CardHeader title={content['03_title']} className={classes.cardHeader}></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content['03_price']}
                      <Typography variant="h6" color="textSecondary" component="span">{content['03_suffix']}</Typography>
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['03_benefit1']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['03_benefit2']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p">{content['03_benefit3']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>{content['03_benefit4']}</Typography>
                  </Box>
                  <Button variant="outlined" color="primary">{content['03_primary-action']}</Button>
                  <Box mt={2}>
                    <Link href="#" color="primary">{content['03_secondary-action']}</Link>
                  </Box>
                </CardContent>
              </Card> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionStyled>
  );
};
