import apiObj from "../../axios"
import { retrievePaymentStatus } from "./stripe.js"
const {api, version} = apiObj

export const checkPaymentStatus = (requestConfig) => {
   return retrievePaymentStatus(requestConfig.clientSecret)
}


export const updateSubscriptionAndPaymentStatus = (userid, user, clientSecret) => {
}