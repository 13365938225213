import { API_CONTEXT } from "../invoices/invoices"

export const getOrgLogo = (user) => {
    const getOperatorsPromise = API_CONTEXT("organization", "getLogo")

    return getOperatorsPromise(user)
}

export const updateOrgLogo = (user,logo) => {
    const getOperatorsPromise = API_CONTEXT("organization", "updateLogo")

    return getOperatorsPromise(user,logo)
}