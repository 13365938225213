import React, { useState, useEffect, useRef } from "react";
import {
  Badge,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import { useInvoiceContext } from "../../../hooks/useInvoiceContext";
import { AlertDialog } from "../../../components/userFeedback/AlertDialog";
import ferbyTheme from "../../../theme/ferbyTheme";
import { buildStepsFromCFDI } from "../utils/stepsObjects";
import { IssueButtonComponent } from "../submodules/issueComponent/IssueComponent";

//*Los datos generales incluyen datos de la factura, del emisor, y del receptor
export const InvoiceSteps = () => {
  //*The invoiceModel must exist in localStorage in order to render all the inputs in this component
  const invoiceModel = window.localStorage.getItem("invoiceModel");

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    actions: [{ label: "", execute: () => {} }],
    keep: false,
  });
  const [loading, setLoading] = useState(false);
  const { setInvoiceData } = useInvoiceContext();
  const [steps, setSteps] = useState([]);
  const { userid } = useAuth();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [complete, setComplete] = useState({
    "Configurar el pago": false,
    "Operadores y transporte": false,
    Mercancias: false,
    Ubicaciones: false,
    Conceptos: false,
    Timbrado: true,
  });
  const [hasTried, setHasTried] = useState({
    "Configurar el pago": false,
    "Operadores y transporte": false,
    Mercancias: false,
    Ubicaciones: false,
    Conceptos: false,
    Timbrado: true,
  });

  // const type = instance.mappedInvoice["CfdiType"].Name

  const handleNext = () => {
    let currentStepComplete = complete[steps[activeStep]?.label];
    if (currentStepComplete) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setDialog({
        ...dialog,
        open: true,
        title: "Datos faltantes",
        message: "Asegúrate de que llenaste todos los datos del formulario",
        actions: [
          {
            label: "Ok",
            execute: () => setDialog({ ...dialog, open: false }),
          },
        ],
      });
      setHasTried({ ...hasTried, [steps[activeStep]?.label]: true });
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(`/${userid.claims.rol}/invoices/new`);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepRender = (activeStep) => {
    return steps[activeStep].component;
  };

  const handleShowDialog = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
    });
  };

  const completeStep = (currentStateOfInvoice, step) => {
    let valid;

    //Operadores y transporte, Mercancias, Ubicaciones, Conceptos, Timbrado, Configurar el pago
    switch (step) {
      case "Operadores y transporte":
        checkCartaPorte(0);

        break;
      case "Mercancias":
        checkCartaPorte(1);

        break;
      case "Ubicaciones":
        checkCartaPorte(2);

        break;
      case "Conceptos":
        checkItems();

        break;
      default:
        valid = true;
        break;
    }
    return valid;
  };

  useEffect(() => {
    if (!invoiceModel) {
      setDialog((dlg) => {
        return {
          ...dlg,
          open: true,
          title: "Sin configuración de factura.",
          message: "No has iniciado el proceso configuración de la factura.",
          actions: [
            {
              label: "Configurar factura",
              execute: () => {
                navigate(`/${userid.claims.rol}/invoices/new`);
              },
            },
          ],
          keep: true,
        };
      });
    } else {
      let cfdi = JSON.parse(invoiceModel);
      let pasos = buildStepsFromCFDI(cfdi);
      setSteps(pasos);
    }
  }, []);

  const contentRef = useRef(null);
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.focus();
    }
  }, [activeStep]);

  return (
    <>
      <AlertDialog
        updateOpenState={handleShowDialog}
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        actions={dialog.actions}
        keep={dialog.keep}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "fit-content",
        }}
      >
        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Generar Factura
            </Typography>
            <Grid
              container
              spacing={3}
              marginTop="3vh"
              justifyContent={"center"}
              alignSelf={"center"}
              maxWidth={"md"}
              sx={{ gutterBottom: true }}
            >
              <Grid item xs={12}>
                <Stepper
                  connector={null}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignSelf: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  activeStep={activeStep}
                >
                  <Grid container spacing={1}>
                    {steps.map((obj, idx) => {
                      return (
                        <Grid key={idx} item xs={4} md={12 / 5}>
                          <Step>
                            <StepLabel>
                              <Badge
                                badgeContent={
                                  activeStep === idx || idx > activeStep
                                    ? idx + 1
                                    : "✓"
                                }
                                color={"primary"}
                              />
                              <Typography
                                fontSize={"14px"}
                                color={
                                  activeStep === idx
                                    ? "primary"
                                    : "textSecondary"
                                }
                              >
                                {obj.label}
                              </Typography>
                            </StepLabel>
                          </Step>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stepper>
              </Grid>

              <Grid item xs={12}>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      End of the line, should not be accessible
                    </Typography>
                  </React.Fragment>
                ) : (
                  <Grid
                    container
                    id="step_container"
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    {invoiceModel && (
                      <div
                        ref={contentRef}
                        tabIndex={activeStep}
                        style={{ outline: "none" }}
                      >
                        {React.cloneElement(getStepRender(activeStep), {
                          props: {
                            dialog: dialog,
                            setDialog: setDialog,
                            complete: complete[steps[activeStep]?.label],
                            setComplete: (val) => {
                              setComplete({
                                ...complete,
                                [steps[activeStep]?.label]: val,
                              });
                            },
                            hasTried: hasTried[steps[activeStep]?.label],
                          },
                        })}
                      </div>
                    )}

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Grid item xs={3}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          variant="contained"
                          color="secondary"
                          sx={{
                            color: "white",
                            mr: 1,
                            boxShadow: ferbyTheme.shadows[4],
                          }}
                        >
                          Regresar
                        </Button>
                      </Grid>

                      <Grid item xs={6}></Grid>

                      <Grid item xs={3} display="flex" justifyContent="right">
                        {activeStep === steps.length - 1 ? (
                          //timbrado
                          <IssueButtonComponent
                            cfdi={JSON.parse(
                              window.localStorage.getItem("invoiceModel")
                            )}
                            label={"Timbrar"}
                          />
                        ) : (
                          <Button
                            id="next_button"
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                            sx={{
                              //background:"linear-gradient(45deg, #cd0000, #ad0000)",
                              boxShadow: ferbyTheme.shadows[4],
                              alignSelf: "right",
                            }}
                          >
                            {"Siguiente"}
                          </Button>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </>
  );
};
