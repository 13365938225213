import React, { useState, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useAuth from "../../../hooks/useAuth";
import {
  getAllVehicles,
  getAllRemolques,
} from "../../../services/modules/transports/vehicles.js";

import ferbyTheme from "../../../theme/ferbyTheme";
import { useEffect } from "react";
import { useRef } from "react";

export const requireRemolque = {
  VL: false,
  C2: false,
  C3: false,
  C2R2: true,
  C3R2: true,
  C2R3: true,
  C3R3: true,
  T2S1: true,
  T2S2: true,
  T2S3: true,
  T3S1: true,
  T3S2: true,
  T3S3: true,
  T2S1R2: true,
  T2S2R2: true,
  T2S1R3: true,
  T3S1R2: true,
  T3S1R3: true,
  T3S2R2: true,
  T3S2R3: true,
  T3S2R4: true,
  T2S2S2: true,
  T3S2S2: true,
  T3S3S2: true,
  OTROEVGP: true,
};

const SelectTransport = ({ props }) => {
  let invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));

  const { userid } = useAuth();
  const [loading, setLoading] = useState({
    trucks: true,
    remolques: true,
  });
  const [trucks, setTrucks] = useState([]);
  const [remolques, setRemolques] = useState([]);

  const ref = useRef(invoiceJson);

  const [vehiculo, setVehiculo] = useState(
    invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte
      .IdentificacionVehicular.PlacaVM &&
      Object.keys(
        invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte
      ).length !== 0
      ? invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte
      : {}
  );

  const [remolque, setRemolque] = useState({});

  const [showRemolque, setShowRemolque] = useState(false);
  const [selected, setSelected] = useState({
    selectedTruck: {},
    selectedRemolque: {},
  });
  const [vehiclesList, setVehiclesList] = useState([]);
  const [canAddRemolque, setCanAddRemolque] = useState(false);

  console.log("vehiculo al iniciar", vehiculo);

  console.log(
    "autotransporte al iniciar",
    invoiceJson.Complementos.CartaPorte20.Autotransporte
  );
  const handleSelectTruck = (e, v, r) => {
    if (r === "selectOption") {
      const vehicleModelForInvoice = {
        PermSCT: "TPAF01",
        NumPermisoSCT: v.permisoSCT,
        IdentificacionVehicular: {
          ConfigVehicular: v.type,
          PlacaVM: v.placaTractor,
          AnioModeloVM: v.model,
        },
        Seguros: {
          AseguraRespCivil: v.polizaName,
          PolizaRespCivil: v.poliza,
        },
        Remolques: [],
      };

      setShowRemolque(requireRemolque[v.type]);

      setVehiculo(vehicleModelForInvoice);

      invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte =
        vehicleModelForInvoice;
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
    }
  };

  const handleSelectremolque = (e, v, r) => {
    if (r === "selectOption") {
      const remolque = {
        Placa: v.placaCaja,
        SubTipoRem: v.tipoRem,
      };

      setRemolque(remolque);
      // setSelected({...selected, selectedRemolque:remolque})
      //invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte.Remolques = []
      //invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte.Remolques.push(remolque)
    }

    //if(r === "clear"){
    //invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte.Remolques = [];
    //}

    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
  };

  const handleAddVehicle = () => {
    let vijicol = vehiculo;
    console.log("vijicol al añadir", vijicol);
    console.log("vehiculo al añadir", vehiculo);

    if (Object.keys(vehiculo).length > 0) {
      console.log("vehiculobject", vehiculo);
      if (Object.keys(remolque).length > 0 && vijicol.Remolques.length < 2) {
        vijicol.Remolques.push(remolque);
      }
      invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte = vijicol;

      setVehiculo({ ...vijicol });
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
    }
  };

  const handleDeleteVehicle = (vehicle) => {
    /*let tempArr = [...vehiclesList]
        let index = tempArr.indexOf(vehicle)
        if (index !== -1){
            tempArr.splice(index,1)
        }

        invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte = tempArr

        window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson))

        
        setVehiclesList(tempArr)*/

    setVehiculo({});
    invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte = vehiculo;

    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
  };

  const handleDeleteRemolque = (remolque) => {
    let tempArr = [...vehiculo.Remolques];
    let index = tempArr.indexOf(remolque);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }
    invoiceJson.Complementos.CartaPorte20.Mercancias.Autotransporte.Remolques =
      tempArr;

    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

    let tempVhcl = vehiculo;

    setVehiculo({ ...tempVhcl, Remolques: tempArr });
  };

  const getData = () => {
    return Promise.all([
      getAllVehicles(userid, userid).then((response) => {
        console.log("Troques aquí");
        setTrucks(() => response.data);
        setLoading((ldng) => {
          return { ...ldng, trucks: false };
        });
      }),
      getAllRemolques(userid, userid).then((response) => {
        console.log("Remolques aquí");
        setRemolques(() => response.data);
        setLoading((ldng) => {
          return {
            ...ldng,
            remolques: false,
          };
        });
      }),
    ]);
  };

  useMemo(() => {
    getData()
      .then(() => {
        console.log("Todo bien");
      })
      .catch((err) => {
        //TODO Mostrar en el UI en el caso de error
        console.log("Valió");
        console.log(err);
      });
    return () => {
      setTrucks(() => []);
      setRemolques(() => []);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(remolque).length > 0) {
      handleAddVehicle();
    }
  }, [remolque]);

  useEffect(() => {
    console.log("vej", vehiculo);
    if (Object.keys(vehiculo).length > 0) {
      if (!requireRemolque[vehiculo.IdentificacionVehicular.ConfigVehicular]) {
        console.log("doesnt");
        console.log("transports true");
        props.setComplete(true);
      } else {
        if (vehiculo.Remolques.length > 0) {
          console.log("transports true");
          props.setComplete(true);
        } else {
          props.setComplete(false);
        }
      }
    } else {
      props.setComplete(false);
    }
  }, [vehiculo]);

  const [error, setError] = useState({
    camion: false,
    remolque: false,
  });

  useEffect(() => {
    if (props.hasTried) {
      if (Object.keys(vehiculo).length === 0) {
        console.log("setting camion error true");
        setError({ ...error, camion: true });
      } else {
        if (vehiculo.Remolques.length === 0) {
          console.log("err remolque");
          setError({ camion: false, remolque: true });
        } else {
          setError({ camion: false, remolque: false });
        }
      }
    }
  }, [props?.hasTried, vehiculo]);

  useEffect(() => {
    console.log("error logging", error);
  }, [error]);

  window.addEventListener("haCambiao", () => {
    invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));
  });

  return (
    //<div style={{width:"100%", height:"80vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
    <>
      {
        //loading
        false ? (
          <CircularProgress />
        ) : (
          <Grid
            container
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{
              marginY: "2vh",
              "& .header-table": {
                backgroundColor: ferbyTheme.palette.primary.main,
                color: "white",
              },
            }}
            maxWidth={"xl"}
          >
            <Grid item xs={6}>
              <Autocomplete
                autoComplete
                options={trucks}
                sx={{ width: "100%" }}
                clearText="Limpiar"
                onChange={handleSelectTruck}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Vehículo"
                      name="Vehicle"
                      error={error.camion}
                      helperText={
                        error.camion ? "Seleccione un vehículo" : null
                      }
                    />
                  );
                }}
                getOptionLabel={(option) =>
                  "Modelo: " +
                    option.model +
                    "   Placa: " +
                    option.placaTractor || ""
                }
                isOptionEqualToValue={(o, v) => {
                  if (v === "") {
                    return true;
                  }

                  return v.id === o.id;
                }}
              />
              {/* <DataGrid
                            sx={{minHeight:"50vh"}}
                            rows={transportRows}
                            columns={trucks_col}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel)
                            }}
                            pageSize={10}
                            editMode='cell'
                            isCellEditable={(params) => {
                                return true
                            }}
                            onCellEditStart={(params, event) => {
                            }}
                            onCellEditCommit={(p, e, d) => {
                                let index2Edit = transportRows.findIndex(row => p.id === row.id)
                                let key2Edit = p.field
                                transportRows[index2Edit][key2Edit] = p.value
                            }}
                            rowsPerPageOptions={[10,20,40]}
                            checkboxSelection
                            disableSelectionOnClick={true}
                            getRowId={(model) => {
                                return model.id
                            }}
                            /> */}
            </Grid>

            {showRemolque && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    autoComplete
                    options={remolques}
                    sx={{ width: "100%" }}
                    clearText="Limpiar"
                    onChange={handleSelectremolque}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Remolque"
                          name="Remolque"
                          error={error.remolque}
                          helperText={
                            error.remolque ? "seleccione un remolque" : null
                          }
                        />
                      );
                    }}
                    getOptionLabel={(option) =>
                      "Tipo: " +
                        option.tipoRem +
                        "   Placa: " +
                        option.placaCaja || ""
                    }
                    isOptionEqualToValue={(o, v) => {
                      if (v === "") {
                        return true;
                      }

                      return v.id === o.id;
                    }}
                  />
                </Grid>

                {showRemolque && (
                  <Alert severity="info">
                    Este vehículo requiere un remolque — Asegúrate de añadirlo!
                  </Alert>
                )}
              </>
            )}

            {!!Object.keys(vehiculo).length > 0 && (
              <Grid item xs={12}>
                {console.log("qlooo", vehiculo)}
                {console.log(
                  invoiceJson.Complementos.CartaPorte20.Mercancias
                    .Autotransporte
                )}
                <DynamicTable
                  data={vehiculo}
                  handleDeleteVehicle={handleDeleteVehicle}
                  handleDeleteRemolque={handleDeleteRemolque}
                />
              </Grid>
            )}
          </Grid>
        )
      }
    </>
    //</div>
  );
};

function DynamicTable({ data, handleDeleteVehicle, handleDeleteRemolque }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="dynamic table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                {" "}
                Configuración
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component={"th"} scope="row">
              <Typography>Vehículo</Typography>
            </TableCell>
            <TableCell component={"th"} scope="row">
              <Typography>{data.IdentificacionVehicular.PlacaVM}</Typography>
            </TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => handleDeleteVehicle()}
              >
                Eliminar
              </Button>
            </TableCell>
          </TableRow>
          {data.Remolques?.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Typography>Remolque</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography>{item.Placa}</Typography>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDeleteRemolque(item)}
                >
                  Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { SelectTransport };
