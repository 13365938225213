import React from "react";
import { Typography } from "@mui/material";

const WelcomeMessage = ({ userName }) => {
  return (
    <Typography variant="h3" color={"primary"}>
      Bienvenido, {userName}
    </Typography>
  );
};

export { WelcomeMessage };
