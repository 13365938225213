import { API_CONTEXT } from "../invoices/invoices"

export const getAllEmployees = (user, credential) => {
    const getEmployeesPromise = API_CONTEXT("employees", "all")

    return getEmployeesPromise(user, credential)
}

export const createEmployee = (user, credential, employeeJson) => {
    const getEmployeesPromise = API_CONTEXT("employees", "new")

    return getEmployeesPromise(user, credential, employeeJson)
}

export const createEmployeesFromFile = (user, credential, employeesFormData) => {
    const getEmployeesPromise = API_CONTEXT("employees", "file")

    return getEmployeesPromise(user, credential, employeesFormData)
}

export const searchEmployeeByKey = (user, credential, keyString, valueString) => {
    const getEmployeesPromise = API_CONTEXT("employees", "searchByKey")
    
    return getEmployeesPromise(user, credential, keyString, valueString)
}
export const searchEmployeeByName = (user, credential,valueString) => {
    const getEmployeesPromise = API_CONTEXT("employees", "searchByKey")
    
    return getEmployeesPromise(user, credential, "Nombre", valueString)
}

export const updateEmployee = (user, credential, body) => {
    const getPromise = API_CONTEXT("employees", "updateEmployee");

    return getPromise(user, credential, body);
};

export const deleteEmployee = (user, credential, rfc, body) => {
    const getPromise = API_CONTEXT("employees", "deleteEmployee");
    
    return getPromise(user, credential, rfc, body);
};
