//*React
import React, { useCallback, useEffect, useMemo, useState } from "react";
//*App
import { useInvoiceContext } from "../../../../../hooks/useInvoiceContext";
//*MUI
import {
  Autocomplete,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toIsoHelper } from "./NominaDateTypeComponent";
import {
  validateCLABE,
  validateCurp,
  validateRfc,
} from "../../../utils/regexp";
import { MexicoFederalEntityKeysCatalog } from "../../../utils/staticCatalogs";
import {
  getBanks,
  getContractTypes,
  getPaymentFrequencies,
  getPositionRisks,
  getRegimenTypes,
  getRegimes,
  getTypesOfJourney,
} from "../../../../../services/modules/invoices/catalogs";
import useAuth from "../../../../../hooks/useAuth";

export const EmployeeForm = ({
  setEmployee = () => console.log("setEmployee in Form"),
  setReceiver = () => console.log("setReceiver in Form"),
  employee = {},
  setLoading = () => {
    console.log("loading in employee form");
  },
  register = false,
}) => {
  const [datosPersonales, setDatosPersonales] = useState({
    Curp: "",
    SocialSecurityNumber: "",
    Nombre: "",
    ApellidoP: "",
    ApellidoM: "",
    Rfc: "",
  });

  const [ooptions, setOoptions] = useState({
    contractType: [],
    fiscalRegime: [],
    regimenTypes: [],
    typesOfJourney: [],
    positionRisks: [],
    banks: [],
    paymentFrequencies: [],
  });

  const [datosLaborales, setDatosLaborales] = useState({
    RegimeType: { Name: "", Value: "" },
    FiscalRegime: { Name: "", Value: "" },
    ContractType: { Name: "", Value: "" },
    TypeOfJourney: { Name: "", Value: "" },
    StartDateLaborRelations: new Date(),
    EmployeeNumber: "",
    Department: "",
    Position: "",
    Unionized: { Name: "", Value: "" },
    PositionRisk: { Name: "", Value: "" },
    FederalEntityKey: { Name: "", Value: "" },
  });

  const [datosDelPago, setDatosDelPago] = useState({
    FrequencyPayment: { Name: "", Value: "" },
    Bank: { Name: "", Value: "" },
    BankAccount: "",
    BaseSalary: 0,
    DailySalary: 0,
  });

  let ComplementoEmployee = {
    Curp: "",
    SocialSecurityNumber: "",
    StartDateLaborRelations: "",
    ContractType: { Name: "", Value: "" },
    RegimeType: { Name: "", Value: "" },
    Unionized: { Name: "", Value: "" },
    TypeOfJourney: { Name: "", Value: "" },
    EmployeeNumber: "",
    Department: "",
    Position: "",
    PositionRisk: { Name: "", Value: "" },
    FrequencyPayment: { Name: "", Value: "" },
    Bank: { Name: "", Value: "" },
    BankAccount: "",
    BaseSalary: 0,
    DailySalary: 0,
    FederalEntityKey: { Name: "", Value: "" },
  };

  useMemo(() => {
    /*
        console.log("datos PERSO",datosPersonales)
        console.log("datos LABO",datosLaborales,)
        console.log("datos PAGO",datosDelPago)
        */
    setEmployee({
      ...employee,
      Curp: String(datosPersonales.Curp).toUpperCase(),
      SocialSecurityNumber: datosPersonales.SocialSecurityNumber,
    });
  }, [datosPersonales]);

  useMemo(() => {
    /*
        console.log("datos PERSO",datosPersonales)
        console.log("datos LABO",datosLaborales,)
        console.log("datos PAGO",datosDelPago)
        */
    setEmployee({
      ...employee,
      StartDateLaborRelations: datosLaborales.StartDateLaborRelations,
      ContractType: datosLaborales.ContractType.Value,
      RegimeType: datosLaborales.RegimeType.Value,
      Unionized: datosLaborales.Unionized.Value,
      TypeOfJourney: datosLaborales.TypeOfJourney.Value,
      EmployeeNumber: datosLaborales.EmployeeNumber,
      Department: datosLaborales.Department,
      Position: datosLaborales.Position,
      PositionRisk: datosLaborales.PositionRisk.Value,
      FederalEntityKey: datosLaborales.FederalEntityKey,
    });
  }, [datosLaborales]);

  useMemo(() => {
    /*
        console.log("datos PERSO",datosPersonales)
        console.log("datos LABO",datosLaborales,)
        console.log("datos PAGO",datosDelPago)
        */
    setEmployee({
      ...employee,
      FrequencyPayment: datosDelPago.FrequencyPayment.Value,
      Bank: datosDelPago.Bank.Name,
      BankAccount: datosDelPago.BankAccount,
      BaseSalary: datosDelPago.BaseSalary,
      DailySalary: datosDelPago.DailySalary,
    });
  }, [datosDelPago]);

  useMemo(() => {
    setReceiver({
      Rfc: String(datosPersonales.Rfc).toUpperCase(),
      Name: String(
        datosPersonales.Nombre +
          " " +
          datosPersonales.ApellidoP +
          " " +
          datosPersonales.ApellidoM
      ).toUpperCase(),
      CfdiUse: "CN01",
      FiscalRegime: datosLaborales.FiscalRegime.Value,
      TaxZipCode: datosPersonales.CP,
    });
  }, [
    datosPersonales.Rfc,
    datosPersonales.Nombre,
    datosPersonales.ApellidoP,
    datosPersonales.ApellidoM,
    datosLaborales.FiscalRegime,
    datosPersonales.CP,
  ]);

  let reqErrors = [];

  const { userid } = useAuth();

  useEffect(() => {
    const getOptions = () => {
      return Promise.all([
        //*Tipos de contrato
        getContractTypes(userid, userid.token)
          .then((response) => {
            setOoptions((cfdiOptions) => {
              return {
                ...cfdiOptions,
                contractType: response.data,
              };
            });
          })
          .catch((err) => {
            if (err.response || err.request || err.message) {
              reqErrors.push("Tipos de jornada");
            }
          }),

        //Fiscal Regimes
        getRegimes(userid, userid.token)
          .then((response) => {
            setOoptions((cfdiOptions) => {
              return {
                ...cfdiOptions,
                fiscalRegimes: response.data,
              };
            });
          })
          .catch((err) => {
            if (err.response || err.request || err.message) {
              reqErrors.push("Régimenes Fiscales");
            }
          }),

        //* Regimen Types
        getRegimenTypes(userid, userid.token)
          .then((response) => {
            setOoptions((cfdiOptions) => {
              return {
                ...cfdiOptions,
                regimenTypes: response.data,
              };
            });
          })
          .catch((err) => {
            if (err.response || err.request || err.message) {
              reqErrors.push("Tipos de Régimen");
            }
          }),

        //* Tipos de jornada
        getTypesOfJourney(userid, userid.token)
          .then((response) => {
            console.log("response types of journey", response.data);
            setOoptions((cfdiOptions) => {
              return {
                ...cfdiOptions,
                typesOfJourney: response.data,
              };
            });
          })
          .catch((err) => {
            if (err.response || err.request || err.message) {
              reqErrors.push("Tipos de jornada");
            }
          }),

        //* Position Risks
        getPositionRisks(userid, userid.token)
          .then((response) => {
            setOoptions((cfdiOptions) => {
              return {
                ...cfdiOptions,
                positionRisks: response.data,
              };
            });
          })
          .catch((err) => {
            if (err.response || err.request || err.message) {
              reqErrors.push("Riesgos");
            }
          }),

        //*Frecuencia de pago
        getPaymentFrequencies(userid, userid.token)
          .then((response) => {
            setOoptions((cfdiOptions) => {
              return {
                ...cfdiOptions,
                paymentFrequencies: response.data,
              };
            });
          })
          .catch((err) => {
            if (err.response || err.request || err.message) {
              reqErrors.push("Frecuencias de pago");
            }
          }),

        //* Bancos
        getBanks(userid, userid.token)
          .then((response) => {
            setOoptions((cfdiOptions) => {
              return {
                ...cfdiOptions,
                banks: response.data,
              };
            });
          })
          .catch((err) => {
            if (err.response || err.request || err.message) {
              reqErrors.push("Bancos");
            }
          }),
      ]);
    };

    setLoading(true);
    getOptions()
      .then((resolved) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const step = 1;
  //*InvoiceContext
  const { NominaType, invoicesModelBySteps } = useInvoiceContext();

  //*dataModels
  const NominaModel = invoicesModelBySteps.find(
    (object) => object.Nomina
  ).Nomina;

  const [dates, setDates] = useState({
    starts: NominaModel[step]["Payroll"]["InitialPaymentDate"],
    final: NominaModel[step]["Payroll"]["FinalPaymentDate"],
    pay: NominaModel[step]["Payroll"]["PaymentDate"],
  });
  const [daysValue, setDaysValue] = useState(
    NominaModel[step]["Payroll"]["DaysPaid"]
  );
  const [isDaysValueChange, setIsDaysValueChange] = useState(false);
  const [cfdiType, setCfdiType] = useState(
    NominaModel[step]["Payroll"]["Type"]
  );
  const [errorText, setErrorText] = useState({
    Rfc: null,
    Curp: null,
    BankAccount: null,
  });

  const handleDatosPersonalesChange = useCallback(
    (e) => {
      //console.log("eee",e)
      switch (e.target.id) {
        case "Rfc":
          if (validateRfc(String(e.target.value).toUpperCase())) {
            setErrorText({ ...errorText, Rfc: null });
            datosPersonales[e.target.id] = String(e.target.value).toUpperCase();
            setDatosPersonales({ ...datosPersonales });
          } else setErrorText({ ...errorText, Rfc: "Formato inválido" });
          datosPersonales[e.target.id] = String(e.target.value).toUpperCase();
          setDatosPersonales({ ...datosPersonales });

          break;

        case "Curp":
          if (validateCurp(String(e.target.value).toUpperCase())) {
            setErrorText({ ...errorText, Curp: null });
            datosPersonales[e.target.id] = String(e.target.value).toUpperCase();
            setDatosPersonales({ ...datosPersonales });
          } else setErrorText({ ...errorText, Curp: "Formato inválido" });
          datosPersonales[e.target.id] = String(e.target.value).toUpperCase();
          setDatosPersonales({ ...datosPersonales });

          break;

        default:
          datosPersonales[e.target.id] = e.target.value;
          setDatosPersonales({ ...datosPersonales });
          break;
      }

      datosPersonales[e.target.id] = e.target.value;
      setDatosPersonales({ ...datosPersonales });
    },
    [datosPersonales, errorText]
  );

  const handleDatosLaboralesChange = useCallback(
    (e, v, r) => {
      //console.log("eee",e)
      //console.log("vvv",v)
      //console.log("rrr",r)
      if (e.target.id === "Unionized") {
        datosLaborales[e.target.id] = e.target.value.value;
        setDatosLaborales({ ...datosLaborales });
        return;
      }

      if (e.target.id === "StartDateLaborRelations") {
        //console.log("e,v,r fecha",e,v,r)
        datosLaborales[e.target.id] = e.target.value;
        setDatosLaborales({ ...datosLaborales });
        return;
      }

      switch (r) {
        case "selectOption":
          //console.log(e)
          datosLaborales[e.target.id] = v;
          break;
        default:
          datosLaborales[e.target.id] = e.target.value;
          break;
      }

      setDatosLaborales({ ...datosLaborales });
    },
    [datosLaborales]
  );

  const handleDatosDelPagoChange = useCallback(
    (e, v, r) => {
      //console.log("eee",e)
      //console.log("vvv",v)
      //console.log("rrr",r)
      if (e.target.id === "BankAccount") {
        if (validateCLABE(e.target.value)) {
          setErrorText({ ...errorText, BankAccount: null });
          datosDelPago[e.target.id] = e.target.value;
          setDatosDelPago({ ...datosDelPago });
        } else {
          setErrorText({ ...errorText, BankAccount: "Formato inválido" });
          datosDelPago[e.target.id] = e.target.value;
          setDatosDelPago({ ...datosDelPago });
        }

        return;
      }

      switch (r) {
        case "selectOption":
          datosDelPago[e.target.id] = v;
          break;
        default:
          datosDelPago[e.target.id] = e.target.value;
          break;
      }

      setDatosDelPago({ ...datosDelPago });
    },
    [datosDelPago]
  );

  return (
    <Grid
      container
      spacing={3}
      columns={18}
      sx={{ padding: 2 }} //component={Paper}>
    >
      <Grid item xs={18}>
        <Divider
          variant="fullWidth"
          flexItem
          alignItems="left"
          textAlign="left"
        >
          <Typography
            variant="h6"
            color="secondary.dark"
            sx={{ marginBottom: 1 }}
          >
            Datos del Empleado
          </Typography>
        </Divider>
      </Grid>

      {/* Content starts here */}

      <Grid item xs={18} md={6}>
        {/* Datos personales */}
        <Grid
          container
          spacing={1}
          columns={18}
          sx={{ padding: 2, minHeight: "100%" }}
          component={Paper}
        >
          <Grid item xs={18}>
            <Typography
              variant="body1"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Datos personales
            </Typography>
          </Grid>

          <Grid item xs={18}>
            <TextField
              id="Nombre"
              variant="standard"
              label="Nombre(s)"
              required
              //defaultValue={issuer !== undefined ? issuer.Folio : ""}
              value={datosPersonales.Nombre}
              onChange={handleDatosPersonalesChange}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={9}>
            <TextField
              id="ApellidoP"
              variant="standard"
              label="Apellido Paterno"
              //defaultValue={issuer !== undefined ? issuer.Folio : ""}
              value={datosPersonales.ApellidoP}
              onChange={handleDatosPersonalesChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="ApellidoM"
              variant="standard"
              label="Apellido Materno"
              //defaultValue={issuer !== undefined ? issuer.Folio : ""}
              value={datosPersonales.ApellidoM}
              onChange={handleDatosPersonalesChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="Rfc"
              variant="standard"
              label="RFC"
              required
              helperText={errorText.Rfc}
              error={errorText.Rfc}
              inputProps={{
                style: {
                  "&:invalid": {
                    border: "red solid 2px !important",
                  },
                  textTransform: "uppercase",
                },
                maxLength: 13,
              }}
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              value={datosPersonales.Rfc}
              onChange={handleDatosPersonalesChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="Curp"
              variant="standard"
              label="CURP"
              required
              helperText={errorText.Curp}
              error={errorText.Curp}
              inputProps={{
                style: {
                  "&:invalid": {
                    border: "red solid 2px !important",
                  },
                  textTransform: "uppercase",
                },
                maxLength: 18,
              }}
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              value={datosPersonales.Curp}
              onChange={handleDatosPersonalesChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="SocialSecurityNumber"
              variant="standard"
              label="No. de Seguridad Social"
              required
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              value={datosPersonales.SocialSecurityNumber}
              onChange={handleDatosPersonalesChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="CP"
              variant="standard"
              label="Código Postal"
              inputProps={{ maxLength: 5 }}
              required
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              value={datosPersonales.CP}
              onChange={handleDatosPersonalesChange}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        {/* End datos personales */}
      </Grid>

      <Grid item xs={18} md={6}>
        {/* Datos laborales */}
        <Grid
          container
          spacing={1}
          columns={18}
          sx={{ padding: 2, height: "100%" }}
          component={Paper}
        >
          <Grid item xs={18}>
            <Typography
              variant="body1"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Datos laborales
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <TextField
              id="EmployeeNumber"
              variant="standard"
              label="No. de empleado"
              value={datosLaborales.EmployeeNumber}
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              onChange={(e, v, r) => handleDatosLaboralesChange(e, v, r)}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={9}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={ooptions.contractType}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="ContractType"
                    sx={{ width: "100%" }}
                    label="Tipo de Contrato"
                    required
                    variant="standard"
                    value={datosLaborales.ContractType}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                if (r === "clear") {
                  datosLaborales.ContractType = "";
                } else {
                  datosLaborales.ContractType = v;
                }
                setDatosLaborales({ ...datosLaborales });
              }}
            />
          </Grid>

          <Grid item xs={18}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="StartDateLaborRelations"
                slotProps={{ textField: { variant: "standard" } }}
                sx={{ width: "100%" }}
                label="Fecha de inicio de relación laboral"
                required
                disableFuture
                value={datosLaborales.StartDateLaborRelations}
                onChange={(newValue) => {
                  //console.log("e fecha",newValue)
                  //datosLaborales.StartDateLaborRelations=newValue
                  let caliz = toIsoHelper(newValue);
                  //console.log(caliz)
                  setDatosLaborales({
                    ...datosLaborales,
                    StartDateLaborRelations: caliz,
                  });
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={9}>
            <TextField
              id="Department"
              variant="standard"
              label="Departamento"
              required
              value={datosLaborales.Department}
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              onChange={(e, v, r) => handleDatosLaboralesChange(e, v, r)}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={9}>
            <TextField
              id="Position"
              variant="standard"
              label="Puesto"
              required
              value={datosLaborales.Position}
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              onChange={(e, v, r) => handleDatosLaboralesChange(e, v, r)}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={9}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={ooptions.fiscalRegimes}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="RegimeType"
                    sx={{ width: "100%" }}
                    label="Régimen Fiscal"
                    required
                    variant="standard"
                    value={datosLaborales.FiscalRegime}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                if (r === "clear") {
                  datosLaborales.FiscalRegime = "";
                } else {
                  datosLaborales.FiscalRegime = v;
                }
                setDatosLaborales({ ...datosLaborales });
              }}
            />
          </Grid>

          <Grid item xs={9}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={ooptions.regimenTypes}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="RegimeType"
                    sx={{ width: "100%" }}
                    label="Régimen"
                    required
                    variant="standard"
                    value={datosLaborales.RegimeType}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                if (r === "clear") {
                  datosLaborales.RegimeType = "";
                } else {
                  datosLaborales.RegimeType = v;
                }
                setDatosLaborales({ ...datosLaborales });
              }}
            />
          </Grid>

          <Grid item xs={9}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={ooptions.typesOfJourney}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="TypeOfJourney"
                    required
                    sx={{ width: "100%" }}
                    label="Tipo de jornada"
                    variant="standard"
                    value={datosLaborales.TypeOfJourney}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                if (r === "clear") {
                  datosLaborales.TypeOfJourney = "";
                } else {
                  datosLaborales.TypeOfJourney = v;
                }
                setDatosLaborales({ ...datosLaborales });
              }}
            />
          </Grid>

          <Grid item xs={9}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={MexicoFederalEntityKeysCatalog}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="FederalEntityKey"
                    sx={{ width: "100%" }}
                    label="Lugar donde labora"
                    required
                    variant="standard"
                    value={datosLaborales.FederalEntityKey}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                console.log(v);
                if (r === "clear") {
                  datosLaborales.FederalEntityKey = "";
                } else {
                  datosLaborales.FederalEntityKey = v.Value;
                }
                setDatosLaborales({ ...datosLaborales });
              }}
            />
          </Grid>

          <Grid item xs={9}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={[
                { Name: "Sí", Value: true },
                { Name: "No", Value: false },
              ]}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="Unionized"
                    sx={{ width: "100%" }}
                    label="Sindicalizado?"
                    required
                    variant="standard"
                    value={datosLaborales.Unionized}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                if (r === "clear") {
                  datosLaborales.Unionized = "";
                } else {
                  datosLaborales.Unionized = v;
                }
                setDatosLaborales({ ...datosLaborales });
              }}
              //value={cfdiOptions.place.length>0 ? cfdiOptions.place[0] : {"Name":"Cargando..."}}
            />
          </Grid>
          <Grid item xs={9}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={ooptions.positionRisks}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="PositionRisk"
                    sx={{ width: "100%" }}
                    label="Riesgo"
                    required
                    variant="standard"
                    value={datosLaborales.PositionRisk}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                if (r === "clear") {
                  datosLaborales.PositionRisk = "";
                } else {
                  datosLaborales.PositionRisk = v;
                }
                setDatosLaborales({ ...datosLaborales });
              }}
              //value={cfdiOptions.place.length>0 ? cfdiOptions.place[0] : {"Name":"Cargando..."}}
            />
          </Grid>
        </Grid>
        {/* End datos laborales */}
      </Grid>

      <Grid item xs={18} md={6}>
        {/* Datos del pago */}
        <Grid
          container
          spacing={1}
          columns={18}
          sx={{ padding: 2, minHeight: "100%" }}
          component={Paper}
        >
          <Grid item xs={18}>
            <Typography
              variant="body1"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Datos del pago
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <Autocomplete
              id="FrequencyPayment"
              autoComplete
              sx={{ width: "100%" }}
              options={ooptions.paymentFrequencies}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    variant="standard"
                    label="Periodicidad del pago"
                    required
                    value={datosDelPago.FrequencyPayment}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                if (r === "clear") {
                  datosDelPago.FrequencyPayment = "";
                } else {
                  datosDelPago.FrequencyPayment = v;
                }
                setDatosDelPago({ ...datosDelPago });
              }}
              //value={cfdiOptions.place.length>0 ? cfdiOptions.place[0] : {"Name":"Cargando..."}}
            />
          </Grid>

          <Grid item xs={9}>
            <Autocomplete
              autoComplete
              sx={{ width: "100%" }}
              options={ooptions.banks}
              getOptionLabel={(option) => {
                return `${option.Name}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="Bank"
                    sx={{ width: "100%" }}
                    label="Banco"
                    variant="standard"
                    value={datosDelPago.Bank}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                if (r === "clear") {
                  datosDelPago.Bank = "";
                } else {
                  datosDelPago.Bank = v;
                }
                setDatosDelPago({ ...datosDelPago });
              }}
              //value={cfdiOptions.place.length>0 ? cfdiOptions.place[0] : {"Name":"Cargando..."}}
            />
          </Grid>

          <Grid item xs={18}>
            <TextField
              id="BankAccount"
              variant="standard"
              label="Cuenta bancaria (Clabe)"
              required
              inputProps={{ maxLength: 18 }}
              error={errorText.BankAccount}
              helperText={errorText.BankAccount}
              value={datosDelPago.BankAccount}
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              onChange={(e, v, r) => handleDatosDelPagoChange(e, v, r)}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={9}>
            <TextField
              id="BaseSalary"
              variant="standard"
              label="Salario Base"
              required
              value={
                datosDelPago.BaseSalary !== 0 ? datosDelPago.BaseSalary : ""
              }
              InputProps={{ startAdornment: "$" }}
              type={"number"}
              placeholder={"0.00"}
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              onChange={(e, v, r) => handleDatosDelPagoChange(e, v, r)}
              sx={{
                width: "100%",
                display: "flex-end",
                "& .MuiInputBase-root": {
                  "& input": {
                    textAlign: "right",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={9}>
            <TextField
              id="DailySalary"
              variant="standard"
              label="Salario diario integrado"
              required
              value={datosDelPago.DailySalary ? datosDelPago.DailySalary : ""}
              InputProps={{ startAdornment: "$" }}
              type={"number"}
              placeholder={"0.00"}
              // defaultValue={issuer !== undefined ? issuer.Folio : ""}
              onChange={(e, v, r) => handleDatosDelPagoChange(e, v, r)}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  "& input": {
                    textAlign: "right",
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        {/* End datos del pago */}
      </Grid>
    </Grid>
  );
};
