import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ClientsDashboardTable } from "../../modules/invoices/dashboard/components/utils/ClientsDashboardTable";
import { DateRangeSlider } from "../../modules/invoices/dashboard/components/DateRangeSlider";
import { toIsoHelper } from "../../modules/invoices/issue/invoiceData/Nomina/NominaDateTypeComponent";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const FilterDrawer = ({
  options,
  filters,
  setFilters,
  selected,
  setSelected,
  selectedClientsArray,
  setSelectedClientsArray,
  selectedIssuersArray,
  setSelectedIssuersArray,
  selectedTypesArray,
  setSelectedTypesArray,
  toggleFilterDrawer,
  rango,
  setRango,
  customerInput,
  setCustomerInput,
  issuerInput,
  setIssuerInput,
  invoiceTypeInput,
  setInvoiceTypeInput
}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [show, setShow] = useState({
    cliente: false,
    issuer: false,
    folio: false,
    folioFiscal: false,
    status: false,
    paid: false,
    fechas: false,
    invoiceType: false,
  });

  const availableFilters = [
    { label: "Fechas", id: "fechas" },
    { label: "Tipo de operación", id: "invoiceType" },
    { label: "Clientes", id: "clientes" },
    { label: "Emisor", id: "issuer" },
    { label: "Folio", id: "folio" },
    { label: "Folio Fiscal", id: "folioFiscal" },
    { label: "Estatus Fiscal", id: "status" },
    { label: "Estatus del Pago", id: "paid" },
  ];

  const removeFilterByLabel = (label) => {
    const filterIndex = filters.findIndex((filter) => filter.label === label);

    if (filterIndex !== -1) {
      filters.splice(filterIndex, 1);
      setFilters([...filters]);
    }
    console.log("label", label);
    if (label === "Clientes") {
      setSelectedClientsArray([]);
    }
    if (label === "Emisor") {
      setSelectedIssuersArray([]);
    }
  };

  const addFiscalFilter = (id, value) => {
    const statusFisco = id.replace("fiscal", "").toLowerCase();
    const fiscalFilter = {
      label: "Estatus Fiscal",
      param: "Status.issuedStatus$in",
      value: [],
    };
    const fiscalIndex = filters.findIndex(
      (filter) =>
        filter.label === fiscalFilter.label &&
        filter.param === fiscalFilter.param
    );

    if (value) {
      if (fiscalIndex !== -1) {
        // Modify the existing filter's value array
        if (!filters[fiscalIndex].value.includes(statusFisco)) {
          filters[fiscalIndex].value.push(statusFisco);
          setFilters([...filters]);
        }
      } else {
        fiscalFilter.value.push(statusFisco);
        filters.push(fiscalFilter);
        setFilters([...filters]);
      }
    } else {
      // Remove the status from the filter's value array
      if (fiscalIndex !== -1) {
        filters[fiscalIndex].value = filters[fiscalIndex].value.filter(
          (status) => status !== statusFisco
        );

        // If no statuses are selected, remove the fiscal filter
        if (filters[fiscalIndex].value.length === 0) {
          removeFilterByLabel("Estatus Fiscal");
        } else {
          setFilters([...filters]);
        }
      }
    }
  };

  const addPaymentStatusFilter = (id, value) => {
    const statusPago = id.toLowerCase();
    const paymentStatusFilter = {
      label: "Estatus del pago",
      param: "Status.paymentStatus$in",
      value: [],
    };
    const paymentStatusIndex = filters.findIndex(
      (filter) =>
        filter.label === paymentStatusFilter.label &&
        filter.param === paymentStatusFilter.param
    );

    if (value) {
      if (paymentStatusIndex !== -1) {
        // Modify the existing filter's value array
        if (!filters[paymentStatusIndex].value.includes(statusPago)) {
          filters[paymentStatusIndex].value.push(statusPago);
          setFilters([...filters]);
        }
      } else {
        paymentStatusFilter.value.push(statusPago);
        filters.push(paymentStatusFilter);
        setFilters([...filters]);
      }
    } else {
      // Remove the status from the filter's value array
      if (paymentStatusIndex !== -1) {
        filters[paymentStatusIndex].value = filters[
          paymentStatusIndex
        ].value.filter((status) => status !== statusPago);

        // If no statuses are selected, remove the payment status filter
        if (filters[paymentStatusIndex].value.length === 0) {
          removeFilterByLabel("Estatus del pago");
        } else {
          setFilters([...filters]);
        }
      }
    }
  };

  const addDateRangeFilter = (rango) => {
    if (selectedFilters.some((item) => item.label === "Fechas")) {
      const dateRangeFilterStart = {
        label: "FechaInicial",
        param: "GeneralModel.Date$gte",
        value: [],
      };
      const dateRangeFilterEnd = {
        label: "FechaFinal",
        param: "GeneralModel.Date$lte",
        value: [],
      };

      const dateRangeIndexStart = filters.findIndex(
        (filter) =>
          filter.label === dateRangeFilterStart.label &&
          filter.param === dateRangeFilterStart.param
      );

      const dateRangeIndexEnd = filters.findIndex(
        (filter) =>
          filter.label === dateRangeFilterEnd.label &&
          filter.param === dateRangeFilterEnd.param
      );

      if (rango.length === 2) {
        // Set the value to the date range
        dateRangeFilterStart.value = [toIsoHelper(rango[0])];
        dateRangeFilterEnd.value = [toIsoHelper(rango[1])];

        // If the date-range filter exists, update it; otherwise, add it
        if (dateRangeIndexStart !== -1) {
          filters[dateRangeIndexStart] = dateRangeFilterStart;
        } else {
          filters.push(dateRangeFilterStart);
        }

        if (dateRangeIndexEnd !== -1) {
          filters[dateRangeIndexEnd] = dateRangeFilterEnd;
        } else {
          filters.push(dateRangeFilterEnd);
        }

        setFilters([...filters]);
      }
    }
  };

  const addFilter = (id, value) => {
    switch (id) {
      case "client":
        const clientFilter = {
          label: "Clientes",
          param: "GeneralModel.Receiver.Rfc$in",
          value: [],
        };

        const clientIndex = filters.findIndex(
          (filter) =>
            filter.label === clientFilter.label &&
            filter.param === clientFilter.param
        );

        if (value) {
          if (clientIndex !== -1) {
            if (!filters[clientIndex].value.includes(value.Rfc)) {
              filters[clientIndex].value.push(value.Rfc);
              setFilters([...filters]);
            }
          } else {
            clientFilter.value.push(value.Rfc);
            filters.push(clientFilter);
            setFilters([...filters]);
          }
        } else {
          // Remove the Rfc from the filter's value array
          if (clientIndex !== -1) {
            filters[clientIndex].value = filters[clientIndex].value.filter(
              (rfc) => rfc !== value.Rfc
            );

            // If no Rfcs are selected, remove the client filter
            if (filters[clientIndex].value.length === 0) {
              filters.splice(clientIndex, 1);
            }

            setFilters([...filters]);
          }
        }

        break;
      case "issuer":
        const issuerFilter = {
          label: "Emisor",
          param: "GeneralModel.Issuer.Rfc$in",
          value: [],
        };

        const issuerIndex = filters.findIndex(
          (filter) =>
            filter.label === issuerFilter.label &&
            filter.param === issuerFilter.param
        );

        if (value) {
          if (issuerIndex !== -1) {
            if (!filters[issuerIndex].value.includes(value.Rfc)) {
              filters[issuerIndex].value.push(value.Rfc);
              setFilters([...filters]);
            }
          } else {
            issuerFilter.value.push(value.Rfc);
            filters.push(issuerFilter);
            setFilters([...filters]);
          }
        } else {
          // Remove the Rfc from the filter's value array
          if (issuerIndex !== -1) {
            filters[issuerIndex].value = filters[issuerIndex].value.filter(
              (rfc) => rfc !== value.Rfc
            );

            // If no Rfcs are selected, remove the client filter
            if (filters[issuerIndex].value.length === 0) {
              filters.splice(issuerIndex, 1);
            }

            setFilters([...filters]);
          }
        }

        break;
      case "folio":
        const folioFilter = {
          label: "Folio",
          param: "GeneralModel.Folio",
          value: value,
        };

        const folioIndex = filters.findIndex(
          (filter) =>
            filter.label === folioFilter.label &&
            filter.param === folioFilter.param
        );

        if (value) {
          if (folioIndex !== -1) {
            filters[folioIndex].value = folioFilter.value;
            setFilters([...filters]);
          } else {
            filters.push(folioFilter);
            setFilters([...filters]);
          }
        } else {
          // Remove the issuer filter
          if (folioIndex !== -1) {
            filters.splice(folioIndex, 1);
            setFilters([...filters]);
          }
        }

        break;
      case "folioFiscal":
        const fiscalFolioFilter = {
          label: "Folio Fiscal",
          param: "uuid",
          value: value,
        };

        const folioFiscalIndex = filters.findIndex(
          (filter) =>
            filter.label === fiscalFolioFilter.label &&
            filter.param === fiscalFolioFilter.param
        );

        if (value) {
          if (folioFiscalIndex !== -1) {
            filters[folioFiscalIndex].value = fiscalFolioFilter.value;
            setFilters([...filters]);
          } else {
            filters.push(fiscalFolioFilter);
            setFilters([...filters]);
          }
        } else {
          // Remove the issuer filter
          if (folioFiscalIndex !== -1) {
            filters.splice(folioFiscalIndex, 1);
            setFilters([...filters]);
          }
        }

        break;
      case "invoiceType":
        const invoiceTypeFilter = {
          label: "Tipo de operación",
          param: "GeneralModel.CfdiType.Type$in",
          value: [],
        };

        const invoiceTypeIndex = filters.findIndex(
          (filter) =>
            filter.label === invoiceTypeFilter.label &&
            filter.param === invoiceTypeFilter.param
        );

        if (value) {
          if (invoiceTypeIndex != -1) {
            if (!filters[invoiceTypeIndex].value.includes(value)) {
              filters[invoiceTypeIndex].value.push(value.Value);
              setFilters([...filters]);
            }
          } else {
            invoiceTypeFilter.value.push(value.Value);
            filters.push(invoiceTypeFilter);
            setFilters([...filters]);
          }
        } else {
          if (invoiceTypeIndex !== -1) {
            filters[invoiceTypeIndex].value = filters[
              invoiceTypeIndex
            ].value.filters((invoiceType) => invoiceType !== value.Value);

            if (filters[invoiceTypeIndex].value.length === 0) {
              filters.splice(invoiceTypeFilter, 1);
            }

            setFilters([...filters]);
          }
        }
      default:
        break;
    }
  };

  const handleChangeSelection = (e, v, r) => {
    const id = e.target.id.split("-")[0];
    //console.log("e,v,r",id,v,r)
    switch (r) {
      case "selectOption":
        setSelected({ ...selected, [id]: v });
        if (id === "client" && v.Rfc) {
          if (!selectedClientsArray.includes(v)) {
            setSelectedClientsArray((prevArray) => [...prevArray, v]);
          }
        }

        if (id === "issuer" && v.Rfc) {
          if (!selectedIssuersArray.includes(v)) {
            setSelectedIssuersArray((prevArray) => [...prevArray, v]);
          }
        }

        if (id === "invoiceType") {
          if (!selectedTypesArray.includes(v)) {
            setSelectedTypesArray((prevArray) => [...prevArray, v]);
          }
        }

        addFilter(id, v);

        break;
      case "input":
        setSelected({ ...selected, [id]: v });

        addFilter(id, v);

        break;
      default:
        break;
    }

    if (
      id === "fiscalIssued" ||
      id === "fiscalUnissued" ||
      id === "fiscalCancelled"
    ) {
      addFiscalFilter(id, v);
    }

    if (id === "paid" || id === "unpaid" || id === "pending") {
      addPaymentStatusFilter(id, v);
    }

    if (id === "fechas") {
      addDateRangeFilter(id, rango);
    }
  };

  const handleDeleteClient = (client) => {
    let tempArr = [...selectedClientsArray];
    let index = tempArr.indexOf(client);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }

    setSelectedClientsArray(tempArr);

    const clientIndex = filters.findIndex(
      (filter) =>
        filter.label === "Clientes" &&
        filter.param === "GeneralModel.Receiver.Rfc$in"
    );

    if (clientIndex !== -1) {
      filters[clientIndex].value = filters[clientIndex].value.filter(
        (rfc) => rfc !== client.Rfc
      );

      setFilters([...filters]);
    }

    if (tempArr.length === 0) {
      setFilters((prevFilters) =>
        prevFilters.filter(
          (filter) => filter.param !== "GeneralModel.Receiver.Rfc$in"
        )
      );
    }
  };

  const handleDeleteIssuer = (issuer) => {
    let tempArr = [...selectedIssuersArray];
    let index = tempArr.indexOf(issuer);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }

    setSelectedIssuersArray(tempArr);

    const issuerIndex = filters.findIndex(
      (filter) =>
        filter.label === "Emisor" &&
        filter.param === "GeneralModel.Issuer.Rfc$in"
    );

    if (issuerIndex !== -1) {
      filters[issuerIndex].value = filters[issuerIndex].value.filter(
        (rfc) => rfc !== issuer.Rfc
      );

      setFilters([...filters]);
    }

    if (tempArr.length === 0) {
      setFilters((prevFilters) =>
        prevFilters.filter(
          (filter) => filter.param !== "GeneralModel.Issuer.Rfc$in"
        )
      );
    }
  };

  const handleDeleteInvoiceType = (invoiceType) => {
    let tempArr = [...selectedTypesArray];
    let index = tempArr.indexOf(invoiceType);
    if (index !== -1) {
      tempArr.splice(index, 1);
    } 

    setSelectedTypesArray(tempArr);

    const invoiceTypeIndex = filters.findIndex(
      (filter) =>
        filter.label === "Tipo de operación" &&
        filter.param === "GeneralModel.CfdiType.Type$in"
    );

    if (invoiceTypeIndex !== -1) {
      filters[invoiceTypeIndex].value = filters[invoiceTypeIndex].value.filter(
        (type) => type.Value !== invoiceType.Value
      );

      setFilters([...filters]);
    }

    if (tempArr.length === 0) {
      setFilters((prevFilters) =>
        prevFilters.filter(
          (filter) => filter.param !== "GeneralModel.CfdiType.Type$in"
        )
      );
    }
  };

  const handleChipDelete = (chipToDelete) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((filter) => filter.label !== chipToDelete)
    );

    // Remove the filter from filters array
    if (chipToDelete === "Fechas") {
      removeFilterByLabel("FechaInicial");
      removeFilterByLabel("FechaFinal");
    } else {
      removeFilterByLabel(chipToDelete);
    }
  };

  const handleCustomerInputChange = (e) => {
    const input = e?.target?.value;
    if (input !== undefined && input.length > 3) {
      setCustomerInput(input);
    }
  };

  const handleIssuerInputChange = (e) => {
    const input = e?.target?.value;
    if (input !== undefined && input.length > 3) {
      setIssuerInput(input);
    }
  };

  const handleInvoiceTypeInputChange = (e) => {
    const input = e?.target?.value;
    if (input !== undefined && input.length > 3) {
      setInvoiceTypeInput(input);
    }
  };


  useEffect(() => {
    addDateRangeFilter(rango);
  }, [rango]);

  useEffect(() => {
    const selectedIds = selectedFilters.map((filter) => filter.id);
    if (!selectedIds.includes("clientes")) {
      setSelected({ ...selected, client: {} });
    }

    setShow({
      ...show,
      cliente: selectedIds.includes("clientes"),
      issuer: selectedIds.includes("issuer"),
      folio: selectedIds.includes("folio"),
      folioFiscal: selectedIds.includes("folioFiscal"),
      status: selectedIds.includes("status"),
      paid: selectedIds.includes("paid"),
      fechas: selectedIds.includes("fechas"),
      invoiceType: selectedIds.includes("invoiceType"),
    });
  }, [selectedFilters]);

  function getSelectedFilters(filterLabels) {
    let recoveredFilters = [];
    if (filterLabels.includes("Clientes"))
      recoveredFilters.push(
        availableFilters.find((item) => item.id === "clientes")
      );
    if (filterLabels.includes("Emisor"))
      recoveredFilters.push(
        availableFilters.find((item) => item.id === "issuer")
      );
    if (filterLabels.includes("Estatus del pago"))
      recoveredFilters.push(
        availableFilters.find((item) => item.id === "paid")
      );
    if (filterLabels.includes("Estatus Fiscal"))
      recoveredFilters.push(
        availableFilters.find((item) => item.id === "status")
      );
    if (filterLabels.includes("Folio"))
      recoveredFilters.push(
        availableFilters.find((item) => item.id === "folio")
      );
    if (filterLabels.includes("Folio Fiscal"))
      recoveredFilters.push(
        availableFilters.find((item) => item.id === "folioFiscal")
      );
    if (
      filterLabels.includes("FechaInicial") ||
      filterLabels.includes("FechaFinal")
    )
      recoveredFilters.push(
        availableFilters.find((item) => item.id === "fechas")
      );

    if(filterLabels.includes("Tipo de operación"))
      recoveredFilters.push(
        availableFilters.find((item) => item.id === "invoiceType")
      );


    return recoveredFilters;
  }

  useEffect(() => {
    const preSelectedLabels = filters.map((filter) => filter.label);
    let recoveredFilters = getSelectedFilters(preSelectedLabels);

    setSelected({ ...selected });
    setSelectedFilters(recoveredFilters);
  }, []);

  return (
    <>
      {/* Main buttons */}
      <Grid container spacing={2} sx={{ textAlign: "center", padding: "4%" }}>
        <Grid item xs={12} textAlign={"right"}>
          <IconButton
            onClick={toggleFilterDrawer}
            aria-label="delete"
            size="large"
          >
            <CloseIcon sx={{ height: "24px", width: "24px" }} />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" color={"primary"}>
            Filtros
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={availableFilters}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            renderOption={(props, option, state) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    checkedIcon={checkedIcon}
                    checked={state.selected}
                  />
                  {option.label}
                </li>
              );
            }}
            filterOptions={(options, state) =>
              options.filter((option) => !selectedFilters.includes(option))
            }
            onChange={(event, value, reason) => {
              switch (reason) {
                case "selectOption":
                  setSelectedFilters(value);
                  break;
                case "removeOption":
                  handleChipDelete(event.currentTarget.parentElement.innerText);
                  break;

                default:
                  break;
              }
            }}
            value={selectedFilters}
            renderInput={(params) => (
              <TextField {...params} label="Selecciona un filtro" />
            )}
          />
        </Grid>

        {/*fields */}
        {show.fechas && (
          <Grid item xs={12}>
            <Typography variant={"button"} color={"primary"}>
              Fechas
            </Typography>
            <DateRangeSlider
              id={"fechas"}
              dateRange={rango}
              setDateRange={setRango}
              minimumDisplay="01/01/2023"
            />
          </Grid>
        )}
        {show.cliente && (
          <Grid item xs={12}>
            <Autocomplete
              id="client"
              sx={{ width: "100%" }}
              options={options.clients}
              getOptionLabel={(opt) => {
                return opt.TaxName ? `${opt.TaxName} - ${opt.Rfc}` : "";
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    //error={error.client.failed}
                    //helperText={error.client.message}
                    label={"Clientes / receptores"}
                    sx={{ width: "100%" }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              isOptionEqualToValue={(o, v) => {
                if (v === "") {
                  return true;
                }

                return o.Name === v.Name;
              }}
              onChange={(e, v, r) => {
                switch (r) {
                  case "selectOption":
                    handleChangeSelection(e, v, r);
                    break;

                  case "clear":
                    setSelected({ ...selected, client: "" });
                    break;

                  default:
                    break;
                }
              }}
              onInputChange={handleCustomerInputChange}
              value={selected.client}
            />
          </Grid>
        )}
         {selectedClientsArray.length > 0 && show.cliente && (
          <Grid item xs={12}>
            <ClientsDashboardTable
              data={selectedClientsArray}
              handleDelete={handleDeleteClient}
            />
          </Grid>
        )}

        {show.invoiceType && (
          <Grid item xs={12}>
            <Autocomplete
              id="invoiceType"
              value={selected.invoiceType}
              options={options.invoiceTypes}
              getOptionLabel={(option) => option?.Value ? option.Name : ""}
              onChange={(e, v, r) => {
                switch (r) {
                  case "selectOption":
                    handleChangeSelection(e, v, r);
                    break;

                  case "clear":
                    setSelected({ ...selected, invoiceTypes: "" });
                    break;

                  default:
                    break;
                }
              }}
              isOptionEqualToValue={(option, value) =>{
                if(value === ""){
                  return true
                }

                return option.Value === value.Value
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={"Tipo de operación"}
                    sx={{ width: "100%" }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onInputChange={handleInvoiceTypeInputChange}
            />
          </Grid>
        )}
        {selectedTypesArray.length > 0 && show.invoiceType && (
          <Grid item xs={12}>
            <ClientsDashboardTable
              data={selectedTypesArray}
              handleDelete={handleDeleteInvoiceType}
            />
          </Grid>
          )}
        
        {show.issuer && (
          <Grid item xs={12}>
            <Autocomplete
              id="issuer"
              sx={{ minWidth: "100%" }}
              options={options.issuerList}
              getOptionLabel={(option) =>
                option.Name ? `${option.Name} - ${option.Rfc}` : ""
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    //error={error.issuer.failed}
                    //helperText={error.issuer.message}
                    label="Emisor"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              onChange={(e, v, r) => {
                switch (r) {
                  case "selectOption":
                    handleChangeSelection(e, v, r);
                    break;

                  case "clear":
                    setSelected({ ...selected, issuer: "" });
                    break;

                  default:
                    break;
                }
              }}
              isOptionEqualToValue={(o, v) => {
                if (v === "") {
                  return true;
                } else {
                  return o.Name === v.Name;
                }
              }}
              onInputChange={handleIssuerInputChange}
              value={selected.issuer}
            />
          </Grid>
        )}
        {selectedIssuersArray.length > 0 && show.issuer && (
          <Grid item xs={12}>
            <ClientsDashboardTable
              data={selectedIssuersArray}
              handleDelete={handleDeleteIssuer}
            />
          </Grid>
        )}
        {/* Folios */}
        {show.folio && (
          <Grid item xs={12}>
            <TextField
              label="Folio"
              id="folio"
              variant="filled"
              //error={error.Folio.failed}
              //helperText={error.Folio.message}
              sx={{ minWidth: "100%" }}
              inputProps={{ maxLength: 10 }}
              onChange={(e) =>
                handleChangeSelection(e, e.target.value, "input")
              }
              value={selected.folio}
            />
          </Grid>
        )}
        {show.folioFiscal && (
          <Grid item xs={12}>
            <TextField
              label="Folio Fiscal"
              id="folioFiscal"
              variant="filled"
              //error={error.Folio.failed}
              //helperText={error.Folio.message}
              sx={{ minWidth: "100%" }}
              //inputProps={{ maxLength: 10 }}
              onChange={(e) =>
                handleChangeSelection(e, e.target.value, "input")
              }
              value={selected.folioFiscal}
            />
          </Grid>
        )}
        {show.status && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="button" color={"primary"}>
                Estatus Fiscal
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"issued"}
                    id="fiscalIssued"
                    checked={filters.some(
                      (filter) =>
                        filter.param === "Status.issuedStatus$in" &&
                        filter.value.includes("issued")
                    )}
                    onChange={(e, v, r) => handleChangeSelection(e, v, r)}
                  />
                }
                label="Emitidas"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"unissued"}
                    id="fiscalUnissued"
                    checked={filters.some(
                      (filter) =>
                        filter.param === "Status.issuedStatus$in" &&
                        filter.value.includes("unissued")
                    )}
                    onChange={(e, v, r) => handleChangeSelection(e, v, r)}
                  />
                }
                label="Sin Emitir"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"cancelled"}
                    id="fiscalCancelled"
                    checked={filters.some(
                      (filter) =>
                        filter.param === "Status.issuedStatus$in" &&
                        filter.value.includes("cancelled")
                    )}
                    onChange={(e, v, r) => handleChangeSelection(e, v, r)}
                  />
                }
                label="Canceladas"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        )}
        {show.paid && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="button" color={"primary"}>
                Estatus de Pago
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"paid"}
                    id="paid"
                    checked={filters.some(
                      (filter) =>
                        filter.param === "Status.paymentStatus$in" &&
                        filter.value.includes("paid")
                    )}
                    onChange={(e, v, r) => handleChangeSelection(e, v, r)}
                  />
                }
                label="Pagadas"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"unpaid"}
                    id="unpaid"
                    checked={filters.some(
                      (filter) =>
                        filter.param === "Status.paymentStatus$in" &&
                        filter.value.includes("unpaid")
                    )}
                    onChange={(e, v, r) => handleChangeSelection(e, v, r)}
                  />
                }
                label="Sin Pagar"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"pending"}
                    id="pending"
                    checked={filters.some(
                      (filter) =>
                        filter.param === "Status.paymentStatus$in" &&
                        filter.value.includes("pending")
                    )}
                    onChange={(e, v, r) => handleChangeSelection(e, v, r)}
                  />
                }
                label="Pendientes"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        )}
        {/* End folios */}
      </Grid>
    </>
  );
};

export default FilterDrawer;
