import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const GeneralServiceDataForm = ({ data, setData, sx, options, itemStates }) => {
  const ServiceItemSelector = ({
    showTransport,
    showManeuver,
    showStay,
    setShowTransport,
    setShowManeuver,
    setShowStay,
  }) => {
    let gridProps = {
      item: true,
      xs: 6,
      md: 4,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    };
    return (
      <>
        <Grid
          container
          spacing={4}
          justifyContent={"center"}
          flexDirection={"row"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>
              Añadir concepto de servicio
            </Typography>
          </Grid>
          <Grid {...gridProps}>
            <Checkbox
              name="transportCheckbox"
              checked={showTransport}
              onChange={() => setShowTransport((prev) => !prev)}
            />
            <Typography>Servicio de Transporte</Typography>
          </Grid>
          <Grid {...gridProps}>
            <Checkbox
              name="maneuverCheckbox"
              checked={showManeuver}
              onChange={() => setShowManeuver((prev) => !prev)}
            />
            <Typography>Servicio de Maniobra</Typography>
          </Grid>
          <Grid {...gridProps}>
            <Checkbox
              name="stayCheckbox"
              checked={showStay}
              onChange={() => setShowStay((prev) => !prev)}
            />
            <Typography>Estadía</Typography>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Grid item xs={12} sx={sx}>
      <Grid container minWidth={"100%"} spacing={3}>
        <Grid item xs={12} md={2}>
          <TextField
            label="Folio"
            id="Folio"
            variant="filled"
            sx={{ minWidth: "100%" }}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => setData({ ...data, Folio: e.target.value })}
            value={data?.Folio ? data.Folio : ""}
          />
        </Grid>

        <Grid item xs={12} md={10}>
          <Autocomplete
            id="client"
            sx={{ width: "100%" }}
            options={options.clients ? options.clients : []}
            getOptionLabel={(opt) => {
              return opt.TaxName || "";
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={"Clientes / receptores"}
                  sx={{ width: "100%" }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {
                          /*loading.clients*/ false ? (
                            <CircularProgress />
                          ) : null
                        }
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            isOptionEqualToValue={(o, v) => {
              if (v === "") {
                return true;
              }

              return o.Name === v.Name;
            }}
            onChange={(e, v, r) => {
              if (r === "selectOption") {
                setData({ ...data, Customer: v });
              }
            }}
            value={data?.Customer ? data.Customer : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <ServiceItemSelector
            showTransport={itemStates.transport?.state}
            showManeuver={itemStates.maniobra?.state}
            showStay={itemStates.estadia?.state}
            setShowTransport={itemStates.transport?.set}
            setShowManeuver={itemStates.maniobra?.set}
            setShowStay={itemStates.estadia?.set}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { GeneralServiceDataForm };
