import { AppBar, Avatar, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import React, { useEffect, useState } from 'react';
import { useNavContext } from '../../hooks/useNavContext';
// import {useInvoiceContext} from '../../hooks/useInvoiceContext';
import useAuth  from '../../hooks/useAuth';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';



export const NavBar = ({rol}) => {
    //*States
    const {logout} = useAuth()
    const [anchorEl, setAnchorEl] = useState(null)
    const [services, setServices] = useState(0)
    
    //*contexts
    const {toggleDrawer, selectedAction} = useNavContext()
    const navigate = useNavigate()
    const pendingServices = window.sessionStorage.getItem('services')

    const toggleSideNav = (open) => {
        toggleDrawer(open)
    }

    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleRedirectToBusiness = () => {
        navigate(`/${rol}/businessData`)
    }
    
    const handleLogout = () => {
        logout()
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }
    
    const backhome = () =>{
        navigate(`/${rol}/welcome`)
    } 

    useEffect(() => {
        if(pendingServices !== null){
            const parsedServices = JSON.parse(pendingServices)
            setServices(parsedServices.services.length)
        }
    },[services, pendingServices])
  return (
    <AppBar sx={{backgroundColor:"primary.dark"}} position="static">
        <Toolbar>
            <IconButton tabIndex={-1} onClick={() => toggleSideNav()}>
                <MenuIcon sx={{color:"white"}}/>
            </IconButton>
            <Box sx={{pl:"10px",flexGrow:1}}>
                <Typography onClick={backhome} variant="h2" sx={{fontSize:"20px", fontWeight:"bold", cursor:'grab'}} >
                    {selectedAction ? selectedAction : "Home"}
                </Typography>
            </Box>
            <Box sx={{mr:"15px"}}>
                {/* This should only be showed if the user has payed for the services module */}
                {/* <Typography variant="h2" sx={{fontSize:"20px"}}>
                    <strong>Servicios pendientes:</strong> {services}
                </Typography> */}
            </Box>
            <Box>
                <Typography  sx={{fontSize:"20px", fontWeight:"bold"}}>
                    Username
                </Typography>
            </Box>
            <Tooltip title="Configuración">
                <IconButton tabIndex={-1} onClick={handleOpenMenu}>
                    <Avatar alt="user_photo"/>
                </IconButton>
            </Tooltip>
            <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical:"top",
                horizontal:"right"
            }}
            keepMounted
            transformOrigin={{
                vertical:"top",
                horizontal:"right"
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            >
                <MenuItem onClick={handleRedirectToBusiness}>
                    <Typography textAlign="center">Mi empresa</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <Typography textAlign="center">Cerrar sesión</Typography>
                </MenuItem>
            </Menu>
        </Toolbar>
    </AppBar>
  )
};
