import './firebase'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import history from './history.js'
import {lazy} from 'react';
import FerbySuspense from './components/FerbySuspense/FerbySuspense'
import {AuthContext} from './contexts/FirebaseAuthContext';
import { PrivateRoute } from './components/PrivateRoute';
import { NavContextComponent } from './contexts/NavContext';
import { InvoiceContext } from './contexts/InvoiceContext';
import { AppContextProvider } from './contexts/AppContext';
import { privateRoutes, publicRoutes } from './routes';
import { ModulesAccessView } from './views/landingPage/ModulesAccessView';
import { ThemeProvider, createTheme } from '@mui/material';
import { red, blue, indigo } from '@mui/material/colors';
import ferbyTheme from './theme/ferbyTheme';

const Login = lazy(() => import('./views/landingPage/Login.jsx'))

const theme = ferbyTheme

function App() {
  return (
      // <ThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <AuthContext>
          <AppContextProvider>
            <NavContextComponent>
              <InvoiceContext>
                <Router history={history}>
                    <FerbySuspense> 
                      <Routes>
                        {/* *LOGIN ROUTES */}
                        <Route path="/" element={<Login/>}></Route>
                        
                        
                        {/* Public routes elements */}
                        {
                          publicRoutes.map((obj, index) => {
                            return (
                              <Route key={index} path={obj.path} element={obj.element}/>
                            )
                          })
                        }
                        
                        
                        {
                          privateRoutes.map((value, index) => {
                              return (
                                <Route
                                  key={index}
                                  path={value.path}
                                  element={
                                    <>
                                      <PrivateRoute>
                                        {value.element}
                                      </PrivateRoute>
                                    </>
                                  }
                              ></Route>
                            )
                          })
                        }
                      </Routes>
                    </FerbySuspense>
                </Router>
              </InvoiceContext>
            </NavContextComponent>
          </AppContextProvider>
        </AuthContext>
      </ThemeProvider>
  );
}

export default App;
