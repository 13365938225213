import React, { useEffect, useState } from 'react'
import { 
    Alert, Autocomplete, Button, 
    CircularProgress, TextField, Typography,
    Dialog, DialogActions, DialogContent, 
    DialogContentText, DialogTitle 
} from '@mui/material'
import { Box } from '@mui/system'
// import { DashboardNav } from '../../../components/navigation/DashboardNav'
import useAuth from '../../../../hooks/useAuth'
import {useInvoiceContext}  from '../../../../hooks/useInvoiceContext'
import { useNavigate } from 'react-router'
//Responsive
import * as Responsive from '../../../../ResponsiveOptions'

import apiObj from '../../../../axios'
const {api, apiVersion} = apiObj

const SelectIssuer = () => {

    //*Data state updates
    const [issuers, setIssuers] = useState([])
    const [selection, setSelection] = useState({})
    
    //*user Feedback
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState({
        open:false,
        message:"",
        status:""
    })

    //*Contexts
    const {userid} = useAuth()
    const {handleSetIssuer, dialog, handleCloseDialog} = useInvoiceContext()

    const navigate = useNavigate()

    const handleChangeIssuer = (e) => {
        e.preventDefault()
        handleSetIssuer(selection)
    }

    const handleActions = (action) => {
        console.log(action.execute)
        navigate(action.execute)
    }

    useEffect(() => {
        api.get('/api/invoices/issuers',{
            headers:{
                Authorization:userid.token,
                user:userid.user_id
            }
        })
        .then(res => {
            if(res.data.data){
                setLoading(() => false)
                setIssuers((issuers) => res.data.data)
            }
        })
        .catch((err) => {
            setAlert(a => {
                return ({
                    ...a,
                    open:true,
                    message:"Hubo un error al obtener los emisores, intenta recargando la página. Si el error persiste contacta a soporte",
                    status:"error"
                })
            })
        })

        return () => setIssuers([])
    },[userid.token, userid.user_id])

    // useEffect(() => {
    //     return () => {
    //         return handleCloseDialog()
    //     }
    // },[])

    return (
        <div>
            <Dialog
            open={dialog.open}
            onClose={handleCloseDialog}
            >
                <DialogTitle>
                    {dialog.title}       
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialog.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        dialog.actions.map((action, index) => {
                            return <Button key={index} onClick={() => handleActions(action)}>{action.name}</Button>
                        })
                    }
                </DialogActions>
            </Dialog>
            <Box component="form" onSubmit={handleChangeIssuer} sx={{background:"#C2F9FF",width:"100%", height:"92.5vh", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                <Typography variant="h4" sx={{margin:"2vh"}}>
                    Selecciona un emisor
                </Typography>
                <Autocomplete
                sx={Responsive.responsiveOptions.fieldResize}
                options={issuers}
                renderInput={(params) => {
                    return (
                        <TextField 
                            {...params} 
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                            label="Emisores registrados"
                        />
                    )
                }}
                onChange={(e,v,r) => {
                    switch(r){
                        case "selectOption":
                            setSelection(v)
                            break;
                        default:
                            break;
                    }
                }}
                getOptionLabel={(option) => {
                    return option.Nombre
                }}
                />        
                <Button 
                variant="outlined"
                type="submit"
                sx={
                    {
                        background:"#007000", 
                        color:"white", 
                        margin:"2vh",
                        "&:hover":{
                            color:"black",
                            border:"1px solid black"
                        }
                    }
                }>
                    Seleccionar
                </Button>
                {
                    alert.open
                    ?
                    <Alert severity={alert.status}>
                        {alert.message}
                    </Alert>
                    :
                    null
                }
            </Box>
        </div>
        
    )
}


export default SelectIssuer