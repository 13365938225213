import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";

const EditComponent = ({ editionRoute, objName, onDelete, rowParams }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleEdit = () => {
    // Construct the edition route with URL variables based on row params
    const augmentedEditionRoute = `${editionRoute}?Id=${rowParams.id}`;
    navigate(augmentedEditionRoute);
  };

  const handleDelete = () => {
    // Call the delete function specified in props
    onDelete();
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <IconButton onClick={() => setModalOpen(true)}>
        <EditIcon />
      </IconButton>
      <Modal open={isModalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            background: "white",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            width: 400,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="modal">
            <Grid
              container
              spacing={2}
              display={"flex"}
              textAlign={"center"}
              justifyContent={"center"}
              sx={{
                padding: "5%",
                "& .MuiButtonBase-root": { minWidth: "36%" },
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h4" color="primary.dark">
                  Editar {objName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={handleEdit}>
                  Editar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >
                  Eliminar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={handleClose}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditComponent;
