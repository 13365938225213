import { API_CONTEXT } from "../invoices/invoices.js";

export const getVehicle = (user, credential, id) => {
  const getPromise = API_CONTEXT("transports", "getVehicle");

  return getPromise(user, credential, id);
};

export const getRemolque = (user, credential, id) => {
  const getPromise = API_CONTEXT("transports", "getRemolque");

  return getPromise(user, credential, id);
};


export const getAllVehicles = (user, credential) => {
  const getPromise = API_CONTEXT("transports", "vehicles");

  return getPromise(user, credential);
};

export const getAllRemolques = (user, credential) => {
  const getPromise = API_CONTEXT("transports", "remolques");

  return getPromise(user, credential);
};

export const createVehicle = (user, credential, body) => {
  const getPromise = API_CONTEXT("transports", "newVehicle");

  return getPromise(user, credential, body);
};

export const createRemolque = (user, credential, body) => {
  const getPromise = API_CONTEXT("transports", "newRemolque");

  return getPromise(user, credential, body);
};

export const updateVehicle = (user, credential, id, body) => {
  const getPromise = API_CONTEXT("transports", "updateVehicle");

  return getPromise(user, credential, id, body);
};

export const updateRemolque = (user, credential, id, body) => {
  const getPromise = API_CONTEXT("transports", "updateRemolque");

  return getPromise(user, credential, id, body);
};

export const deleteVehicle = (user, credential, id, body) => {
  const getPromise = API_CONTEXT("transports", "deleteVehicle");

  return getPromise(user, credential, id, body);
};

export const deleteRemolque = (user, credential, id, body) => {
  const getPromise = API_CONTEXT("transports", "deleteRemolque");

  return getPromise(user, credential, id, body);
};
