export const toIsoDate = (date) => {
    const pad = function(num){
        const norm = Math.floor(Math.abs(num))
        return (norm < 10 ? "0" : "")
    }

    return date.getFullYear() + 
        "-" + pad(date.getMonth() + 1) + 
        "-" + pad(date.getDate()) + 
        "T" + pad(date.getHours()) +
        ":" + pad(date.getMinutes()) + 
        ":" + pad(date.getSeconds())
}

export function formatDateDDMMYYYY(dateString, includeTime = false) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear().toString();
    
    if (includeTime) {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
    
    return `${day}-${month}-${year}`;
  }