import { API_CONTEXT } from "./invoices"

export const getExpeditionPLC = (user, credential) => {
    const getExpeditionPlcPromise = API_CONTEXT("issuers", "expeditionPlc")


    return getExpeditionPlcPromise(user, credential)
}

export const deleteExpeditionPLC = (user, id) => {
    const getExpeditionPlcPromise = API_CONTEXT("issuers", "deleteExpeditionPlc")


    return getExpeditionPlcPromise(user, id)
}

export const getIssuers = (user, credential) => {
    const getPromise = API_CONTEXT("issuers", "list")

    return getPromise(user, credential)
}

export const saveIssuer = (user, credential, body) => {
    const getPromise = API_CONTEXT("issuers", "save")

    return getPromise(user, credential,body)
}

export const deleteIssuer = (user, credential, rfc) => {
    const getPromise = API_CONTEXT("issuers", "delete")

    return getPromise(user, credential,rfc)
}

export const updateIssuerCertificate = (user, credential,body,rfc) => {
    const getPromise = API_CONTEXT("issuers", "cert")

    return getPromise(user, credential,body, rfc)
}

export const updateIssuerKey = (user, credential,body,rfc) => {
    const getPromise = API_CONTEXT("issuers", "key")

    return getPromise(user, credential,body,rfc)
}


export const saveIssuerInPAC = (user, credential,body,rfc) => {
    const getPromise = API_CONTEXT("issuers", "pac")

    return getPromise(user, credential,body,rfc)
}

export const searchIssuer = (user, credential,string,value) => {
    const getPromise = API_CONTEXT("issuers", "search")

    return getPromise(user, credential,string,value)
}
