import { Button, CircularProgress } from '@mui/material'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import useAuth from '../../hooks/useAuth'

export const StripeForm = () => {
    const {user, userid} = useAuth()
    
    const [loading, setLoading] = useState(true)
    
    const {state} = useLocation()
    
    const stripe = useStripe()
    const elements = useElements()

    if(!stripe || !elements){
        return <CircularProgress/>
    }
    
    

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        await stripe.confirmPayment({
            elements,
            confirmParams:{
                return_url:`http://localhost:3000/${userid.claims.rol}/order/?c_s=${state.clientSecret}/`
            }
        })

        // await updateSubscriptionAndPaymentStatus(userid, user, state.clientSecret)
    }
    
  return (
    <form onSubmit={handleSubmit}>
        <PaymentElement/>
        <Button disabled={!stripe} type="submit" variant="contained">Pagar</Button>
    </form>
  )
}
