import React from 'react';
import { pastSideBar, Sidebar } from './SideBar';
import { NavBar } from './ToolBar';

export const DashboardNav = ({userid}) => {
  return (
      <>
        <NavBar rol={userid.claims.rol}/>
        {/* {<pastSideBar rol={userid.claims.rol}/>} */}
        <Sidebar rol={userid.claims.rol}/>
      </>
  )
};
