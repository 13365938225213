import React, { useEffect, useState } from "react";
import {
  ManeuverItem,
  ServiceItemGeneric,
} from "../../../modules/services/utils/ServiceItemsStructures";
import useAuth from "../../../hooks/useAuth";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { searchEmployeeByName } from "../../../services/modules/employees/employees";

const ManeuverItemForm = ({
  data,
  setData,
  dialog,
  setDialog,
  objectData,
  setObjectData,
  alert,
  setAlert
}) => {
  let ManeuverServiceItemStruct = {
    ...ServiceItemGeneric,
    ...ManeuverItem,
  };

  const [selected, setSelected] = useState(ManeuverServiceItemStruct);

  const [employeeInput, setEmployeeInput] = useState({});

  const [employeesOptions, setemployeesOptions] = useState([]);

  const handleChangeSelection = (e, v, r) => {
    let id = e.target.id.split("-")[0];
    let targetValue = e.target.value;
    console.log("evridtarget", e, v, r, id, targetValue);

    switch (id) {
      case "SubTotal":
        targetValue = parseFloat(e.target.value);
        break;
      default:
        break;
    }

    switch (r) {
      case "selectOption":
        setSelected({ ...selected, [id]: v });
        break;
      default:
        setSelected({ ...selected, [id]: targetValue });
        break;
    }
  };

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const handleEmpleadosDeCarga = (e, v, r) => {
    let testArr = selected.Empleados;
    switch (r) {
      case "selectOption":
        if (!testArr.includes(v._id)) {
          testArr.push(v._id);
          setObjectData((prev) => {
            return { ...prev, Empleados: [...prev.Empleados, v] };
          });
          setSelected({ ...selected, Empleados: testArr });
        }
        break;
      case "clear":
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (Array.isArray(objectData.Empleados)) {
      setSelectedEmployees(objectData.Empleados);
    }
  }, [objectData.Empleados]);

  const [loading, setLoading] = useState({ employeesInput: false });

  const { userid } = useAuth();

  const handleSetData = () => {
    let ServiceItemCopy = ManeuverServiceItemStruct;
    let keys = Object.keys(ServiceItemCopy);

    for (let index in keys) {
      if (selected[keys[index]] !== "" )
        ServiceItemCopy[keys[index]] = selected[keys[index]];
    }

    setData({ ...data, ManeuverServiceItem: ServiceItemCopy });
  };

  const handleEmployeeInput = (e) => {
    setEmployeeInput(e.target.value);
    if (e.target.value !== undefined && e.target.value.length > 3) {
      setLoading((load) => {
        return {
          ...load,
          employeesInput: true,
        };
      });

      searchEmployeeByName(userid, userid, e.target.value)
        .then((res) => {
          if (res.data.data.length > 0) {
            console.log("optionsSet");
            setemployeesOptions(res.data.data);
          }else {
            setAlert({
              ...alert,
              open: true,
              title: "Sin resultados",
              message: "No se encontraron coincidencias con los valores ingresados.",
              severity: "warning",
            });
          }
          setLoading((load) => {
            return {
              ...load,
              employeesInput: false,
            };
          });
        })
        .catch((err) => {
          setAlert({
            ...alert,
            open: true,
            title: "Error",
            message: "Error al obtener resultados, intente nuevamente",
            severity: "error",
          });
          console.log("error obteniendo empleados");
        });
    }
  };

  const handleDeleteEmployee = (employee) => {
    let tempArr = [...selectedEmployees];

    let index = tempArr.findIndex((item) => item._id === employee._id);

    if (index !== -1) {
      tempArr.splice(index, 1);
      let idArray = tempArr.map((item) => item._id);

      setObjectData({ ...objectData, Empleados: tempArr });
      setSelected({ ...selected, Empleados: idArray });
    }
  };

  function EmployeesTable({ name, data, handleDelete }) {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="dynamic table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography fontWeight={600} color="primary">
                  {" "}
                  {name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={600} color="primary">
                  {" "}
                  Opciones
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {item.Nombre} {item.ApellidoM} {item.ApellidoP}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleDelete(item)}
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  useEffect(() => {
    handleSetData();
  }, [selected]);

  useEffect(() => {
    if (data.ManeuverServiceItem) {
      setSelected(data.ManeuverServiceItem);
    }
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid item xs={12} md={4}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Tipo de Maniobra</InputLabel>
            <Select
              id={"TipoManiobra"}
              label="Tipo de Maniobra"
              value={selected.TipoManiobra}
              onChange={(e) =>
                setSelected({ ...selected, TipoManiobra: e.target.value })
              }
            >
              <MenuItem value="Carga">Carga</MenuItem>
              <MenuItem value="Descarga">Descarga</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id={"SubTotal"}
            label="Subtotal"
            onChange={handleChangeSelection}
            variant="standard"
            sx={{ width: "100%" }}
            InputProps={{
              startAdornment: "$",
              sx: {
                "& input": {
                  textAlign: "right",
                },
              },
            }}
            placeholder={"0.00"}
            type="number"
            step="any"
            value={selected.SubTotal ? selected.SubTotal : ""}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={12}>
              <Autocomplete
                autoComplete
                id="Empleados"
                options={employeesOptions}
                getOptionLabel={(option) => {
                  return `${option.Nombre} ${option.ApellidoP} ${option.ApellidoM}`;
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      value={employeeInput}
                      label="Buscar Empleados"
                      onChange={handleEmployeeInput}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading.employeesInput ? (
                              <CircularProgress />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                onChange={handleEmpleadosDeCarga}
              />
            </Grid>
          </Grid>
        </Grid>

        {selectedEmployees.length > 0 && (
          <Grid item xs={12}>
            <EmployeesTable
              name="Empleados"
              data={selectedEmployees}
              handleDelete={handleDeleteEmployee}
            />
          </Grid>
        )}

        {/* Aditional Buttons */}
        {/* End optional fields in creation */}
      </Grid>
    </>
  );
};

export { ManeuverItemForm };
