import {initializeApp} from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import {config} from 'dotenv'

config()


const configObject = {
  apiKey: "AIzaSyD6h4ufijaej1NKo03Dn4pqK_ACNEYGmjM",
  authDomain:"ferby-abbd4.firebaseapp.com",
  projectId: "ferby-abbd4",
  storageBucket: "ferby-abbd4.appspot.com",
  messagingSenderId: "606393316795",
  appId: "1:606393316795:web:51926aaec444ba8710809e"
}

const app = initializeApp(configObject)

const auth = getAuth(app)

// connectAuthEmulator(auth, 'http://127.0.0.1:9099')

export default auth