import apiObj from '../axios.js'
const {api, version} = apiObj


export const validateFiscalData = (obj) => {
    return new Promise((resolve,reject) => {
        api.post(`${version}/fiscal/validate/organization`, obj)
        .then((response) => {
            resolve(response)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export const mapSignUpData = (obj) => {
    return {
        "Name":                         obj.name,
        "lastName":                     obj.lname,
        "email":                        obj.email,
        "password":                     obj.password,
        "phone":                        obj.phone,
        "organization":{
            "name":                     obj.orgName,
            "taxData":{
                "Rfc":                  obj.rfc,
                "name":                 obj.taxName,
                "FiscalRegime":         obj.fiscalRegime,
                "PrivateKey":           obj.keyFile,
                "PrivateKeyPassword":   obj.pkPassword,
                "Certificate":          obj.csdFile
            }
        },
    }
}

export const mapOrgData = (obj) => {
    return {
        "name":                     obj.orgName,
        "taxData":{
            "Name":                 obj.taxName,
            "Rfc":                  obj.rfc,
            "FiscalRegime":         obj.fiscalRegime,
            "PrivateKey":           obj.keyFile,
            "PrivateKeyPassword":   obj.pkPassword,
            "Certificate":          obj.csdFile
        }
    }
}

export const registerRootAndOrg = (obj) => {   
    return new Promise((resolve, reject) => {
        api.post(`${version}/auth/8pCaRC=PHF.aUD$pOxkY}A1ZC[N*YMtNGf&&aUPSJxs51DC8f/signup/root`, obj)
        .then((response) => {
            resolve(response)
        })
        .catch((err) => {
            reject(err)
        })
    })
} 