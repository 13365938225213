import { requireRemolque } from "../../../components/Forms/CartaPorte/Transport";

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
function notNull(value) {
  return value !== null;
}
function notUndefined(value) {
  return value !== undefined;
}
function notEmptyString(string) {
  return string !== "" && string !== "";
}
function allValuesTrue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== true) {
      return false;
    }
  }
  return true;
}

function validateTransportItem(item, objects) {
  let { CompanyLoad, CompanyUnload, Operador, SubTotal, Unidad, Remolque, Code } =
    item;
  let valid = false;
  let validStates = {
    "Compañía de carga": false,
    "Compañía de descarga": false,
    Operador: false,
    "SubTotal-Transporte": false,
    "Unidad Vehicular": false,
    Remolque: false,
  };

  //validate service code
  if(!notEmptyString(Code)){
    validStates.Code = true;
  }
  
  //Validate Loading Companies
  if (!isEmptyObject(CompanyLoad)) {
    validStates["Compañía de carga"] = true;
  }
  if (!isEmptyObject(CompanyUnload)) {
    validStates["Compañía de descarga"] = true;
  }

  //validate subtotal
  console.log(SubTotal)
  if (!isNaN(SubTotal) && SubTotal > 0) {
    validStates["SubTotal-Transporte"] = true;
  }

  //validate Operator id (just that it exists)
  if (notNull(Operador) && notUndefined(Operador) && notEmptyString(Operador)) {
    validStates.Operador = true;
  }
  //validate vehicle
  if (
    !isEmptyObject(Unidad) &&
    notNull(Unidad) &&
    notUndefined(Unidad) &&
    notEmptyString(Unidad)
  ) {
    validStates["Unidad Vehicular"] = true;
  }
  //Validate Remolque if needed
  if (requireRemolque[objects.Unidad.type]) {
    if (
      notNull(Remolque) &&
      notUndefined(Remolque) &&
      notEmptyString(Remolque)
    ) {
      validStates.Remolque = true;
    }
  } else {
    validStates.Remolque = true;
  }

  if (allValuesTrue(validStates)) {
    valid = true;
  }

  return { valid, validStates };
}
function validateManeuverItem(item) {
  let { TipoManiobra, Empleados, SubTotal } = item;
  let valid = false;
  let validStates = {
    TipoManiobra: false,
    Empleados: false,
    "SubTotal-Maniobra": false,
  };

  //validate subtotal
  if (!isNaN(SubTotal) && SubTotal > 0) {
    validStates["SubTotal-Maniobra"] = true;
  }
  //validate Empleados
  if (Array.isArray(Empleados)) {
    if (Empleados.length > 0) {
      validStates.Empleados = true;
    }
  }
  //validate tipo maniobra
  if (
    notNull(TipoManiobra) &&
    notUndefined(TipoManiobra) &&
    notEmptyString(TipoManiobra)
  ) {
    validStates.TipoManiobra = true;
  }

  if (allValuesTrue(validStates)) {
    valid = true;
  }

  return { valid, validStates };
}
function validateStayItem(item) {
  let { TipoEstadia, Code, SubTotal, Tiempo, Riesgo } = item;
  let valid = false;
  let validStates = {
    TipoEstadia: false,
    Code: false,
    SubTotal: false,
    Tiempo: false,
    Riesgo: false,
  };

  //validate subtotal
  if (!isNaN(SubTotal) && SubTotal > 0) {
    validStates.SubTotal = true;
  }
  //validate tiempo
  if (!isNaN(Tiempo) && Tiempo > 0) {
    validStates.Tiempo = true;
  }
  //validate riesgo
  if (!isNaN(Riesgo) && Riesgo > 0 && Riesgo <= 10) {
    validStates.Riesgo = true;
  }

  //validate tipo estadia
  if (
    notNull(TipoEstadia) &&
    notUndefined(TipoEstadia) &&
    notEmptyString(TipoEstadia)
  ) {
    validStates.TipoEstadia = true;
  }
  //validate code
  if (notNull(Code) && notUndefined(Code) && notEmptyString(Code)) {
    validStates.Code = true;
  }

  if (allValuesTrue(validStates)) {
    valid = true;
  }

  return { valid, validStates };
}

export {
  validateTransportItem,
  validateManeuverItem,
  validateStayItem,
  isEmptyObject,
  notUndefined,
};
