import React, { useState, useEffect } from "react";
//*MUI COMPONENTS
import {
  Typography,
  CircularProgress,
  Button,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Box,
  Backdrop,
  Alert,
  AlertTitle,
} from "@mui/material";
import { getCustomers } from "../../services/modules/invoices/organization";
import { getOperators } from "../../services/modules/organization/operators";
import {
  getAllRemolques,
  getAllVehicles,
} from "../../services/modules/transports/vehicles";
import useAuth from "../../hooks/useAuth";
import {
  AlertDialog,
  InitialDialogState,
} from "../../components/userFeedback/AlertDialog";
import { createService } from "../../services/modules/services/servicesGeneral";
import { getAllTransportProductServicesCodes } from "../../services/modules/invoices/catalogs";
import { useNavigate } from "react-router";
import { StayItemForm } from "../../components/Forms/Services/StayItemForm";
import { ManeuverItemForm } from "../../components/Forms/Services/ManeuverItemForm";
import { GeneralServiceDataForm } from "../../components/Forms/Services/GeneralServiceDataForm";
import {
  isEmptyObject,
  notUndefined,
  validateManeuverItem,
  validateStayItem,
  validateTransportItem,
} from "./utils/ServiceValidationFunctions";
import { TransportItemForm } from "../../components/Forms/Services/TransportItemForm";

export function NewService() {
  const { userid } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(InitialDialogState);
  const [alertState, setAlertState] = useState({
    severity: "info",
    title: "",
    message: "",
    open: false,
  });
  const [withTransport, setWithTransport] = useState(
    window.localStorage.getItem("withTransport") !== null
      ? JSON.parse(window.localStorage.getItem("withTransport"))
      : false
  );
  const [withManeuver, setWithManeuver] = useState(
    window.localStorage.getItem("withManeuver") !== null
      ? JSON.parse(window.localStorage.getItem("withManeuver"))
      : false
  );
  const [withStay, setWithStay] = useState(
    window.localStorage.getItem("withStay") !== null
      ? JSON.parse(window.localStorage.getItem("withStay"))
      : false
  );
  const [options, setOptions] = useState({
    loadingCompanies: [],
  });
  const [formData, setFormData] = useState(
    window.localStorage.getItem("serviceFormData")
      ? JSON.parse(window.localStorage.getItem("serviceFormData"))
      : {}
  );
  const [objectData, setObjectData] = useState(
    window.localStorage.getItem("serviceObject")
      ? JSON.parse(window.localStorage.getItem("serviceObject"))
      : { SubTotal: 0, Unidad: {}, Remolque: {}, Empleados: [], Code: {} }
  );
  // Stepper management
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Nuevo Servicio",
      content: (
        <GeneralServiceDataForm
          data={formData}
          setData={setFormData}
          options={options}
          itemStates={{
            transport: {
              state: withTransport,
              set: setWithTransport,
            },
            maniobra: {
              state: withManeuver,
              set: setWithManeuver,
            },
            estadia: {
              state: withStay,
              set: setWithStay,
            },
          }}
        />
      ),
    },
    /*Conditionally add steps */
    withTransport
      ? {
          label: "Transporte",
          content: (
            <TransportItemForm
              options={options}
              setDialog={setDialog}
              data={formData}
              setData={setFormData}
              objectData={objectData}
              setObjectData={setObjectData}
              alert={alertState}
              setAlert={setAlertState}
            />
          ),
        }
      : null,
    withManeuver
      ? {
          label: "Maniobra",
          content: (
            <ManeuverItemForm
              data={formData}
              setData={setFormData}
              objectData={objectData}
              setObjectData={setObjectData}
              alert={setAlertState}
              setAlert={setAlertState}
            />
          ),
        }
      : null,
    withStay
      ? {
          label: "Estadía",
          content: <StayItemForm data={formData} setData={setFormData} />,
        }
      : null,
  ].filter((step) => step !== null);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  function removeEmptyKeys(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.filter((item) => removeEmptyKeys(item) !== null);
    }

    return Object.keys(obj).reduce((acc, key) => {
      const value = removeEmptyKeys(obj[key]);
      if (
        value !== null &&
        value !== undefined &&
        (typeof value !== "string" ||
          value !== "" ||
          (typeof value === "number" && !isNaN(value))) &&
        (!isEmptyObject(value) || typeof value === "number")
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  const clearData = () => {
    window.localStorage.removeItem("serviceFormData");
    window.localStorage.removeItem("serviceObject");
    window.localStorage.removeItem("withTransport");
    window.localStorage.removeItem("withManeuver");
    window.localStorage.removeItem("withStay");
  };

  const handleCreateService = () => {
    let errorItems = [];

    let ServiceStruct = {
      Folio:formData.Folio
    };

    if (notUndefined(formData.Customer) && !isEmptyObject(formData.Customer)) {
      ServiceStruct.Client = {
        TaxName: formData.Customer.TaxName,
        Rfc: formData.Customer.Rfc,
        CustomerId: formData.Customer._id,
      };
    } else {
      errorItems.push("Cliente");
    }

    if (withTransport) {
      let { valid, validStates } = validateTransportItem(
        formData.TransportServiceItem,
        objectData
      );
      if (valid) {
        ServiceStruct.TransportService = formData.TransportServiceItem;
      } else {
        let transportError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(transportError));
      }
    }

    if (withManeuver) {
      let { valid, validStates } = validateManeuverItem(
        formData.ManeuverServiceItem
      );
      if (valid) {
        ServiceStruct.Maniobra = formData.ManeuverServiceItem;
      } else {
        let maneuverError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(maneuverError));
      }
    }

    if (withStay) {
      let { valid, validStates } = validateStayItem(formData.StayServiceItem);
      if (valid) {
        ServiceStruct.Estadia = formData.StayServiceItem;
      } else {
        let stayError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(stayError));
      }
    }

    let serviceClean = removeEmptyKeys(ServiceStruct);

    if (errorItems.length === 0) {
      setLoading(true);
      createService(userid, userid, serviceClean)
        .then((res) => {
          if (res.status === 201) {
            setLoading(false);
            setDialog({
              ...dialog,
              open: true,
              title: "Servicio creado con éxito",
              content:
                "El servicio se ha creado y puede ser manejado en la sección correspondiente.",
              actions: [
                {
                  label: "Continuar",
                  execute: () => {
                    navigate(`/${userid.claims.rol}/welcome`);
                    clearData();
                  },
                },
              ],
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setDialog({
            ...dialog,
            open: true,
            title: "Ocurrió un error al crear el servicio",
            content: <>"Por favor verifique los datos e inténtelo de nuevo."</>,
            actions: [
              {
                label: "Ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
          });
        });
    } else {
      setDialog({
        ...dialog,
        open: true,
        title: "Información incompleta",
        content: (
          <>
            Falta información en los siguientes campos:{" "}
            <ul>
              {errorItems.map((error, index) => (
                <>
                  <li key={index}>{error}</li>
                </>
              ))}
            </ul>
          </>
        ),
        actions: [
          { label: "Ok", execute: () => setDialog({ ...dialog, open: false }) },
        ],
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getCustomers(userid),
      getOperators(userid),
      getAllVehicles(userid),
      getAllRemolques(userid),
      /*This line is commented because, until it's integrated, it makes the app crash
       */
      getAllTransportProductServicesCodes(userid, userid),
    ])
      .then((results) => {
        results.forEach((result, index) => {
          const { data } = result;
          switch (index) {
            case 0:
              setOptions((obj) => ({ ...obj, clients: data.data }));
              break;
            case 1:
              setOptions((obj) => ({ ...obj, operators: data.data }));
              break;
            case 2:
              setOptions((obj) => ({ ...obj, vehicles: data }));
              break;
            case 3:
              setOptions((obj) => ({ ...obj, remolques: data }));
              break;
            case 4:
              setOptions((obj) => ({ ...obj, productCodes: data }));
              break;
            default:
              break;
          }
          setLoading(false);
          if (data.length === 0) {
            setDialog({
              title: "Error",
              content:
                "No se ha obtenido la información necesaria para los formularios",
              open: true,
              actions: [
                { label: "Recargar", execute: () => window.location.reload() },
                {
                  label: "Salir",
                  execute: () => navigate(`/${userid.claims.rol}/serviceIndex`),
                },
              ],
            });
          }
        });
      })
      .catch((errors) => {
        const errorMessages = [];
        errors.forEach((err, index) => {
          errorMessages.push(
            index === 4
              ? "Error fetching product codes: " + err.message
              : index === 3
              ? "Error fetching remolques: " + err.message
              : index === 2
              ? "Error fetching vehicles: " + err.message
              : index === 1
              ? "Error fetching operators: " + err.message
              : "Error fetching customers: " + err.message
          );
        });

        setDialog({
          title: "Error",
          content: `No se ha obtenido la información necesaria para los formularios ${errorMessages}`,
          open: true,
          actions: [
            { label: "Recargar", execute: () => window.location.reload() },
            {
              label: "Salir",
              execute: () => navigate(`/${userid.claims.rol}/welcome`),
            },
          ],
        });
      });
  }, []);

  useEffect(() => {
    window.localStorage.setItem("serviceFormData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    window.localStorage.setItem("serviceObject", JSON.stringify(objectData));
  }, [objectData]);

  useEffect(() => {
    window.localStorage.setItem("withTransport", withTransport);
    window.localStorage.setItem("withManeuver", withManeuver);
    window.localStorage.setItem("withStay", withStay);
  }, [withTransport, withManeuver, withStay]);

  //autoclose feedback alert
  useEffect(() => {
    if (alertState.open) {
      const timer = setTimeout(() => {
        setAlertState({
          severity: "info",
          title: "",
          message: "",
          open: false,
        });
      }, 5000); // Set the duration in milliseconds (e.g., 5000ms = 5 seconds)

      return () => clearTimeout(timer);
    }
  }, [alertState.open]);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <AlertDialog {...dialog} />

      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Generar Servicio
          </Typography>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <div>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5">Service Preview</Typography>
                <Button variant="contained" onClick={handleCreateService}>
                  Crear Servicio
                </Button>
              </Container>

              {alertState.open && (
                <Alert
                  severity={alertState.severity}
                  onClose={() => setAlertState({ ...alertState, open: false })}
                >
                  <AlertTitle>{alertState.title}</AlertTitle>
                  {alertState.message}
                </Alert>
              )}
              {/* Stepper Buttons */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleBack} disabled={activeStep === 0}>
                  Anterior
                </Button>

                <Button onClick={handleNext} disabled={true}>
                  Siguiente
                </Button>
              </Box>
            </div>
          ) : (
            <div>
              {/* Content for the current step */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "2vh",
                  marginTop: "3vh",
                }}
              >
                {steps[activeStep].content}
              </Box>

              {alertState.open && (
                <Alert
                  severity={alertState.severity}
                  onClose={() => setAlertState({ ...alertState, open: false })}
                >
                  <AlertTitle>{alertState.title}</AlertTitle>
                  {alertState.message}
                </Alert>
              )}

              {/* Stepper Buttons */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleBack} disabled={activeStep === 0}>
                  Anterior
                </Button>

                <Button onClick={handleNext}>Siguiente</Button>
              </Box>
            </div>
          )}
        </Paper>
      </Container>
    </div>
  );
}
