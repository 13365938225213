import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export function ClientsDashboardTable({ data, handleDelete }) {
  return (
    <TableContainer component={Paper} sx={{ backgroundColor: "#BAEAFF" }}>
      <Table aria-label="dynamic table">
        <TableHead></TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Typography color={"primary.dark"} variant="button">
                  {item.TaxName || item.Name}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDelete(item)}
                >
                  X
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
