import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useAuth from "../../../hooks/useAuth";
import {
  deleteRemolque,
  deleteVehicle,
  getAllRemolques,
  getAllVehicles,
} from "../../../services/modules/transports/vehicles";
import { useNavigate } from "react-router";
import ferbyTheme from "../../../theme/ferbyTheme";
import EditComponent from "./components/EditComponent";
import { AlertDialog } from "../../../components/userFeedback/AlertDialog";

const TransportsIndex = () => {
  const [showRemolques, setShowRemolques] = useState(false);
  const navigate = useNavigate();

  const { userid } = useAuth();

  const [loading, setLoading] = useState(false);

  const [camionesRows, setCamionesRows] = useState([]);

  const camionesColumns = [
    {
      field: "type",
      headerName: "Tipo de camión",
      width: 300,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "model",
      headerName: "Modelo",
      editable: true,
      width: 150,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "placaTractor",
      headerName: "Placas",
      width: 150,
      editable: true,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "polizaName",
      headerName: "Aseguradora",
      width: 150,
      editable: true,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "_id",
      headerName: "Acciones",
      renderCell: (params) => {
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/transports/updateTruck`}
            objName={"Vehículo"}
            onDelete={() => deleteVehicles(params.row.id)}
            rowParams={params.row}
          />
        );
      },
      flex: 1,
      headerClassName: "header-table",
    },
  ];

  const deleteVehicles = (id) => {
    console.log("userid", userid);
    deleteVehicle(userid, userid, id, { reasonByDelete: "default" })
      .then((response) => {
        console.log(response);
        setDialog({
          ...dialog,
          open: true,
          title: "Vehículo eliminado",
          content: "",
          actions: [{ label: "Ok", execute: () => window.location.reload() }],
        });
      })
      .catch((err) => {
        console.log(err.response);
        setDialog({
          ...dialog,
          open: true,
          title: "Ocurrió un error al eliminar el vehículo",
          content: "Inténtelo más tarde",
          actions: [
            { label: "Ok", execute: () => window.location.reload() },
            { label: "Reintentar", execute: () => deleteVehicles(id) },
          ],
        });
      });
  };

  const deleteRemolques = (id) => {
    deleteRemolque(userid, userid, id, { reasonByDelete: "default" })
      .then((response) => {
        console.log(response);
        setDialog({
          ...dialog,
          open: true,
          title: "Remolque eliminado",
          content: "",
          actions: [{ label: "Ok", execute: () => window.location.reload() }],
        });
      })
      .catch((err) => {
        console.log(err.response);
        setDialog({
          ...dialog,
          open: true,
          title: "Ocurrió un error al eliminar el remolque",
          content: "Inténtelo más tarde",
          actions: [
            { label: "Ok", execute: () => window.location.reload() },
            { label: "Reintentar", execute: () => deleteRemolques(id) },
          ],
        });
      });
  };

  const [remolquesRows, setRemolquesRows] = useState([]);

  const remolquesColumns = [
    {
      field: "tipoRem",
      headerName: "Tipo de remolque",
      width: 300,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "placaCaja",
      headerName: "Placas",
      width: 150,
      editable: true,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "_id",
      headerName: "Acciones",
      renderCell: (params) => {
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/transports/updateTow`}
            objName={"Remolque"}
            onDelete={() => deleteRemolques(params.row.id)}
            rowParams={params.row}
          />
        );
      },
      flex: 1,
      headerClassName: "header-table",
    },
  ];

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  useEffect(() => {
    const rowsToSet = [];
    const rowsToSet2 = [];

    setLoading(true);
    getAllVehicles(userid, userid)
      .then((res) => {
        console.log("Troques aquí");
        console.log("respuesta camiones ", res);
        let issuersData = res.data;
        issuersData.forEach((element) => {
          rowsToSet.push({
            id: element._id,
            _id: element._id,
            type: element.type,
            placaTractor: element.placaTractor,
            model: element.model,
            polizaName: element.polizaName,
          });
        });
        //rowsToSet.concat(res.data)
        setCamionesRows((ir) => rowsToSet);
        setLoading((l) => false);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          setLoading((l) => false);
          switch (err.response.status) {
            case 401:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo emisores",
                  content:
                    "No tienes autorización para consultar esta información, verifica que tienes conexión y tu sesión sigue activa.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Terminar",
                      execute: () => navigate(`/`),
                    },
                  ],
                };
              });
              break;
            case 400:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo emisores",
                  content:
                    "El servidor no ha comprendido lo que necesitas, por favor intenta de nuevo, o contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intentar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
            case 500:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR de servidor",
                  content:
                    "Hubo un error al obtener los datos de los emisores. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intentar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
            default:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo emisores",
                  content:
                    "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intentar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
          }
          return;
        }

        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "ERROR Obteniendo emisores",
            content:
              "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
            actions: [
              {
                label: "Recargar",
                execute: () => window.location.reload(),
              },
              {
                label: "Continuar",
                execute: () => navigate(`/`),
              },
            ],
          };
        });
      });

    setLoading(true);
    getAllRemolques(userid, userid)
      .then((res) => {
        console.log("respuesta remolques ", res);
        let issuersData = res.data;
        issuersData.forEach((element, index) => {
          rowsToSet2.push({
            id: element._id ? element._id : "provid" + index,
            _id: element._id,
            tipoRem: element.tipoRem,
            placaCaja: element.placaCaja,
          });
        });
        //rowsToSet.concat(res.data)
        setRemolquesRows((ir) => rowsToSet2);
        setLoading((l) => false);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          setLoading((l) => false);
          switch (err.response.status) {
            case 401:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo remolques",
                  content:
                    "No tienes autorización para consultar esta información, verifica que tienes conexión y tu sesión sigue activa.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Terminar",
                      execute: () => navigate(`/`),
                    },
                  ],
                };
              });
              break;
            case 400:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo remolques",
                  content:
                    "El servidor no ha comprendido lo que necesitas, por favor intenta de nuevo, o contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intentar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
            case 500:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR de servidor",
                  content:
                    "Hubo un error al obtener los datos de los remolques. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intentar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
            default:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "ERROR Obteniendo remolques",
                  content:
                    "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                  actions: [
                    {
                      label: "Recargar sesión",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Intentar más tarde",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                };
              });
              break;
          }
          return;
        }

        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "ERROR Obteniendo emisores",
            content:
              "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
            actions: [
              {
                label: "Recargar",
                execute: () => window.location.reload(),
              },
              {
                label: "Continuar",
                execute: () => navigate(`/`),
              },
            ],
          };
        });
      });
  }, []);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AlertDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />

      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Box display="flex" flexDirection={"row"} justifyContent={"right"}>
            <Typography variant="body1" color="primary">
              {"Camiones"}
            </Typography>
            <Switch
              checked={showRemolques}
              onChange={() => setShowRemolques(!showRemolques)}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
            <Typography variant="body1" color="primary">
              {"Remolques"}
            </Typography>
          </Box>
          <Grid container spacing={2} sx={{ mt: "3vh" }}>
            <Grid item xs={9} display="flex" justifyContent={"left"}>
              <Typography variant="h4" align="center" color="primary.dark">
                {showRemolques ? "Remolques" : "Camiones"}
              </Typography>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent={"right"}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ color: "white" }}
                onClick={
                  showRemolques
                    ? () => navigate(`/${userid.claims.rol}/transports/newTow`)
                    : () =>
                        navigate(`/${userid.claims.rol}/transports/newTruck`)
                }
              >
                {showRemolques ? "Registrar Remolque" : "Registrar Camión"}
              </Button>
            </Grid>
            {!showRemolques && (
              <Grid
                item
                xs={12}
                sx={{
                  "& .header-table": {
                    backgroundColor: ferbyTheme.palette.primary.main,
                    color: "white",
                  },
                }}
              >
                <DataGrid
                  sx={{ width: "100%" }}
                  rows={camionesRows}
                  autoHeight
                  pageSize={10}
                  columns={camionesColumns}
                  disableSelectionOnClick
                />
              </Grid>
            )}

            {showRemolques && (
              <Grid
                item
                xs={12}
                sx={{
                  "& .header-table": {
                    backgroundColor: ferbyTheme.palette.primary.main,
                    color: "white",
                  },
                }}
              >
                <DataGrid
                  sx={{ width: "100%" }}
                  rows={remolquesRows}
                  autoHeight
                  pageSize={10}
                  columns={remolquesColumns}
                  disableSelectionOnClick
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default TransportsIndex;
