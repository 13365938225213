import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";


export function ErrorComponent({message = "Error genérico"}){
    return <Box sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "fit-content"
    }}>
        <Grid container 
            marginY="3vh"
            justifyContent={"center"}
            alignSelf={"center"}
            maxWidth={"sm"}
            spacing={4}
        >
            <Grid item xs={12}>
                <Typography variant="h3" textAlign={"center"}>Ups, algo ha salido mal!</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" textAlign={"center"}>{message}</Typography>
            </Grid>
        </Grid>
    </Box>

}