import { useState } from "react";

import { format, addDays, parse } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Box, Grid, Slider, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function DateRangeSlider({
  initialDate,
  dateRange,
  setDateRange,
  minimumDisplay = "01/01/2023",
}) {
  const lowerLimit = new Date(minimumDisplay).getTime();

  const [datesState, setDatesState] = useState([dateRange[0], dateRange[1]]);

  function valuetext(value, index) {
    const date = new Date(value);
    let formatted = format(date, "dd/MM/yyyy");

    return formatted;
  }

  const handleChangeInSlider = (event, newValue) => {
    //console.log(event.target);
    //console.log(newValue);
    setDatesState([new Date(newValue[0]), new Date(newValue[1])]);
  };
  const handleChangeOnCommitment = (event, newValue) => 
    setDateRange([new Date(newValue[0]), new Date(newValue[1])]);

  const handleMinTextFieldChange = (event) => {
    const dateObject = new Date(event);
    setDatesState([dateObject, datesState[1]]);
    setDateRange([dateObject, datesState[1]]);
  };

  const handleMaxTextFieldChange = (event) => {
    const dateObject = new Date(event);
    setDatesState([datesState[0], dateObject]);
    setDateRange([datesState[0], dateObject]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Slider
            getAriaLabel={() => "Date range"}
            value={datesState.map((date) => date.getTime())}
            onChange={handleChangeInSlider}
            onChangeCommitted={handleChangeOnCommitment}
            valueLabelDisplay="auto"
            step={24 * 60 * 60 * 1000}
            //getAriaValueText={valuetext}
            valueLabelFormat={valuetext}
            min={lowerLimit}
            max={new Date().getTime()}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="d_date_from"
              label="Desde"
              value={datesState[0]}
              onChange={handleMinTextFieldChange}
              format="dd/MM/yyyy"
              showTabs={false}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "100%" }} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="d_date_to"
              label="Hasta"
              value={datesState[1]}
              onChange={handleMaxTextFieldChange}
              format="dd/MM/yyyy"
              showTabs={false}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
}

export { DateRangeSlider };
