import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TableCell,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { formatDateDDMMYYYY } from "../../modules/invoices/utils/dateManipulators";
import { getIssuedDocs } from "../../services/modules/invoices/organization";
import useAuth from "../../hooks/useAuth";
import { getFiscalRegimeNameFromKey, getTypeOfInvoice } from "./Getters";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PayrollKeyMap = {
  NameId: "Identificador factura:",
  ExpeditionPlace: "C.P Expedición:",
  CfdiType: "Tipo de CFDI",
  PaymentForm: "Forma de Pago",
  PaymentMethod: "Método de Pago",
  Folio: "Folio",
  Receiver: {
    Rfc: "RFC",
    Name: "Nombre",
    CfdiUse: "Uso de CFDI",
    FiscalRegime: "Régimen Fiscal",
  },
  Issuer: {
    EmployerRegistration: "Registro Patronal",
    Rfc: "RFC:",
  },
  Complemento: {
    Payroll: {
      Type: "Tipo",
      PaymentDate: "Fecha de Pago",
      InitialPaymentDate: "Inicio de Pago",
      FinalPaymentDate: "Final de Pago",
      DaysPaid: "Días Pagados",
      Employee: {
        Curp: "CURP",
        SocialSecurityNumber: "NSS",
        StartDateLaborRelations: "Fecha inicio relaciones laborales",
        ContractType: "Tipo de Contrato",
        RegimeType: "Tipo de Régimen",
        Unionized: "Sindicalizado",
        TypeOfJourney: "Tipo de jornada",
        EmployeeNumber: "Número de empleado",
        Department: "Departamento",
        Position: "Puesto",
        PositionRisk: "Riesgo del puesto",
        FrequencyPayment: "Periodicidad del pago",
        Bank: "Banco",
        BankAccount: "CLABE",
        BaseSalary: "Salario Base",
        DailySalary: "Salario Diario",
        FederalEntityKey: {
          Id: "Federal Entity ID",
          Name: "Federal Entity Name",
          Description: "Federal Entity Description",
          Address: {
            Street: "Street",
            ExteriorNumber: "Exterior Number",
            Neighborhood: "Neighborhood",
            ZipCode: "Zip Code",
            Locality: "Locality",
            Municipality: "Municipality",
            State: "State",
            Country: "Country",
          },
        },
      },
      Perceptions: {
        Details: [
          {
            PerceptionType: "Tipo de Percepción",
            Code: "Código",
            Description: "Descripción",
            TaxedAmount: "Monto Gravado",
            ExemptAmount: "Monto Exento",
          },
        ],
      },
      Deductions: {
        Details: [
          {
            DeduccionType: "Tipo de Deducción",
            Code: "Código",
            Description: "Descripción",
            Amount: "Cantidad",
          },
        ],
      },
      OtherPayments: {
        Details: [],
      },
    },
  },
};

export const FullCfdiKeymap = {
  GeneralModel: {
    CfdiType: {
      Type: "Tipo de CFDI",
      Name: "Identificador de tipo de CFDI",
      Value: "Valor CFDI",
    },
    PaymentForm: "Forma de Pago",
    PaymentMethod: "Método de Pago",
    ExpeditionPlace: {
      Id: "Id",
      Name: "Lugar de Expedición",
      Description: "",
      Address: {
        Street: "Calle",
        ExteriorNumber: "Número exterior",
        Neighborhood: "Colonia",
        ZipCode: "Código Postal",
        Locality: "Localidad",
        Municipality: "Municipio",
        State: "Estado",
        Country: "País",
      },
    },
    Date: "Fecha",
    Folio: "Folio",
    Issuer: {
      FiscalRegime: "Régimen Fiscal del Emisor",
      Rfc: "RFC del Emisor",
      Name: "Nombre del Emisor",
      _id: "_id del Emisor",
    },
    Receiver: {
      Rfc: "RFC del Receptor",
      CfdiUse: {
        Natural: "Uso del CFDI para Persona Física", //boolean, doesn't really matter
        Moral: "Uso del CFDI para Persona Moral", //boolean, doesn't really matter
        Name: "Nombre del Uso del CFDI",
        Value: "Valor del Uso del CFDI",
      },
      Name: "Nombre del Receptor",
      FiscalRegime: "Régimen Fiscal del Receptor",
      TaxZipCode: "Código Postal del Receptor",
    },
  },
  Models: {
    I: {
      Items: [
        {
          quantity: "Cantidad",
          product: {
            Complement: "Complemento del Producto",
            Name: "Nombre del Producto",
            Value: "Valor del Producto",
          },
          unit: {
            ShortName: "Nombre Corto de la Unidad",
            Name: "Nombre de la Unidad",
            Value: "Valor de la Unidad",
          },
          description: "Descripción",
          price: "Precio",
          taxObject: {
            id: "",
            Value: "Objeto de impuesto",
          },
          taxes: [
            {
              Name: "Nombre del Impuesto",
              IsRetention: "Es retención?",
              Rate: "Tasa del Impuesto",
              Base: "Base del Impuesto",
              Total: "Total del Impuesto",
            },
          ],
          total: "",
        },
      ],
    },
  },
  Complementos: {
    CartaPorte20: {
      TranspInternac: "Transporte Internacional",
      Ubicaciones: [
        {
          TipoUbicacion: "Ubicación de salida",
          RFCRemitenteDestinatario: "RFC del Remitente",
          FechaHoraSalidaLlegada: "Fecha y Hora de Salida",
          Domicilio: {
            Pais: "País",
            CodigoPostal: "Código Postal",
            Estado: "Estado",
            Municipio: "Municipio",
            Localidad: "Localidad",
            Colonia: "Colonia",
            Calle: "Calle",
          },
        },
        {
          TipoUbicacion: "Ubicación de llegada",
          RFCRemitenteDestinatario: "RFC del Destinatario",
          FechaHoraSalidaLlegada: "Fecha y Hora de Llegada",
          DistanciaRecorrida: "Distancia recorrida",
          Domicilio: {
            Pais: "País",
            CodigoPostal: "Código Postal",
            Estado: "Estado",
            Municipio: "Municipio",
            Localidad: "Localidad",
            Colonia: "Colonia",
            Calle: "Calle",
          },
        },
      ],
      Mercancias: {
        UnidadPeso: "Unidad Peso",
        Mercancia: [
          {
            Cantidad: "Cantidad",
            BienesTransp: "Bienes transportados",
            Descripcion: "Descripción",
            ClaveUnidad: "Clave unidad",
            PesoEnKg: "Peso en KG",
            ValorMercancia: "Valor de la mercancía",
            Moneda: "Moneda",
          },
        ],
        Autotransporte: {
          PermSCT: "Permiso de la SCT",
          NumPermisoSCT: "Número del permiso",
          IdentificacionVehicular: {
            ConfigVehicular: "Configuración vehicular",
            PlacaVM: "Código de la placas",
            AnioModeloVM: "Año del modelo",
          },
          Seguros: {
            AseguraRespCivil: "Asegurado",
            PolizaRespCivil: "No de Póliza",
          },
          Remolques: [
            {
              Placa: "Placa remolque",
              SubTipoRem: "Tipo remolque",
            },
          ],
        },
      },
      FiguraTransporte: [
        {
          TipoFigura: "Tipo de figura de transporte",
          RFCFigura: "RFC figura de transporte",
          NombreFigura: "Nombre figura de transporte",
          NumLicencia: "Num licencia figura de transporte",
        },
      ],
    },
    Payments: [
      {
        Date: "Fecha",
        PaymentForm: "Forma de Pago",
        Amount: "Total del pago",
        RelatedDocuments: [
          {
            Uuid: "",
            Folio: "Folio",
            PaymentMethod: "Método de pago",
            PartialityNumber: "No. de Parcialidad",
            PreviousBalanceAmount: "Balance anterior",
            AmountPaid: "Cantidad del pago",
          },
        ],
      },
    ],
  },
};

export function createRow(...cols) {
  const row = {};
  for (let i = 0; i < cols.length; i++) {
    row[`col${i + 1}`] = cols[i];
  }
  return row;
}

export const mapDataToColumns = (
  data /*The object to search */,
  pathArray /* an array of object paths  */
) => {
  //example use
  /**
   * if your object had the structure
   *      data={
   *          name:{
   *              firstName:"John",
   *              LastName:"Atom"
   *          },
   *          age:{
   *              value:45
   *          },
   *          address:{
   *              street:"Columbia",
   *              ZipCode:45689,
   *              country:"USA"
   *          }
   *       };
   * and your pathArrays is ["name.LastName",age,address.Zipcode]
   *
   * You would get this array as return value ["Atom",{value:45},45689]
   * so be careful with those paths, as it may return an object or something that you were not expecting
   *
   *
   *
   */
  const values = pathArray.map((column) => {
    const path = column.split(".");
    let value = data;
    for (const prop of path) {
      value = value[prop];
    }
    return value;
  });
  return values;
};

const buildContent = (jsonInvoice, type) => {
  if (type === "cartaPorte") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Receptor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Receiver.Rfc,
                "Nombre:",
                jsonInvoice.GeneralModel.Receiver.Name
              ),

              createRow(
                "Regimen Fiscal:",
                getFiscalRegimeNameFromKey(601),
                "Código Postal:",
                jsonInvoice.GeneralModel.Receiver.TaxZipCode
              ),

              createRow(
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.Receiver.CfdiUse.Name
              ),
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Emisor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Issuer.Rfc,
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.CfdiType.Name
              ),

              createRow(
                "Nombre:",
                jsonInvoice.GeneralModel.Issuer.Name,
                "Régimen Fiscal:",
                getFiscalRegimeNameFromKey(
                  jsonInvoice.GeneralModel.Issuer.FiscalRegime
                )
              ),

              createRow(
                "Lugar de Expedición:",
                jsonInvoice.GeneralModel.ExpeditionPlace.ZipCode
              ),
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewVehicularIDTable
            AutoTransporte={
              jsonInvoice.Complementos.CartaPorte20.Mercancias.Autotransporte
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewProductsTable productsArray={jsonInvoice.Models.I.Items} />
        </Grid>
        <Grid item xs={12}>
          <PreviewUbicacionesTable
            locationsArray={jsonInvoice.Complementos.CartaPorte20.Ubicaciones}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewMerchTable
            merchArray={
              jsonInvoice.Complementos.CartaPorte20.Mercancias.Mercancia
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewFiguraTransporteTable
            FiguraTransporteArr={
              jsonInvoice.Complementos.CartaPorte20.FiguraTransporte
            }
          />
        </Grid>
      </Grid>
    );
  }
  if (type === "facturaIngreso") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"General"}
            rows={[
              createRow(
                "Tipo de recibo:",
                jsonInvoice.GeneralModel.CfdiType.Name,
                "Efecto del comprobante:",
                jsonInvoice.GeneralModel.CfdiType.Type
              ),

              createRow(
                "Folio:",
                jsonInvoice.GeneralModel.Folio,
                "Fecha y hora de emisión:",
                jsonInvoice.GeneralModel.Date
              ),

              createRow(
                "Lugar de Expedición:",
                jsonInvoice.GeneralModel.ExpeditionPlace.ZipCode
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Emisor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Issuer.Rfc,
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.CfdiType.Name
              ),

              createRow(
                "Nombre:",
                jsonInvoice.GeneralModel.Issuer.Name,
                "Régimen Fiscal:",
                getFiscalRegimeNameFromKey(
                  jsonInvoice.GeneralModel.Issuer.FiscalRegime
                )
              ),

              createRow(
                "Lugar de Expedición:",
                jsonInvoice.GeneralModel.ExpeditionPlace.ZipCode
              ),
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Receptor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Receiver.Rfc,
                "Nombre:",
                jsonInvoice.GeneralModel.Receiver.Name
              ),

              createRow(
                "Regimen Fiscal:",
                getFiscalRegimeNameFromKey(601),
                "Código Postal:",
                jsonInvoice.GeneralModel.Receiver.TaxZipCode
              ),

              createRow(
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.Receiver.CfdiUse.Name
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <PreviewProductsTable productsArray={jsonInvoice.Models.I.Items} />
        </Grid>
      </Grid>
    );
  }

  if (type === "pago") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"General"}
            rows={[
              createRow(
                "Tipo de recibo:",
                jsonInvoice.GeneralModel.CfdiType.Name || "Pago",
                "Efecto del comprobante:",
                jsonInvoice.GeneralModel.CfdiType.Type
              ),

              createRow(
                "Folio:",
                jsonInvoice.GeneralModel.Folio,
                "Fecha y hora de emisión:",
                formatDateDDMMYYYY(jsonInvoice.Complementos?.Payments[0].Date)
              ),

              createRow(
                "Lugar de Expedición:",
                jsonInvoice.GeneralModel.ExpeditionPlace?.ZipCode
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Receptor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Receiver?.Rfc,
                "Nombre:",
                jsonInvoice.GeneralModel.Receiver?.Name
              ),

              createRow(
                "Regimen Fiscal:",
                getFiscalRegimeNameFromKey(
                  jsonInvoice.GeneralModel.Receiver?.FiscalRegime
                ),
                "Código Postal:",
                jsonInvoice.GeneralModel.Receiver?.TaxZipCode
              ),

              createRow(
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.Receiver?.CfdiUse?.Name
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          {/* Preview Payment info table */}
          <PreviewCustomizedTables
            title={"Información del Pago"}
            rows={[
              createRow(
                "Fecha:",
                jsonInvoice.Complementos?.Payments[0].Date,
                "Forma de pago:",
                jsonInvoice.Complementos?.Payments[0].PaymentForm
              ),

              createRow(
                "Cantidad:",
                jsonInvoice.Complementos?.Payments[0].Amount
              ),
            ]}
          />
        </Grid>

        {jsonInvoice.Complementos?.Payments[0].RelatedDocuments.length > 0 && (
          <Grid item xs={12}>
            {/* Preview Related documents (if any) */}
            {jsonInvoice.Complementos?.Payments.map((payComp_obj) => {
              return PreviewRelatedDocumentsForPaymentTable({
                relatedDocuments: payComp_obj.RelatedDocuments,
              });
            })}
          </Grid>
        )}
      </Grid>
    );
  }

  //build contents for Database obtained invoices
  if (type === "postPago") {
    //console.log("inner space",jsonInvoice)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"General"}
            rows={[
              createRow(
                "Tipo de recibo:",
                jsonInvoice.GeneralModel.CfdiType.Name || "Pago",
                "Efecto del comprobante:",
                jsonInvoice.GeneralModel.CfdiType.Type
              ),

              createRow(
                "Folio:",
                jsonInvoice.GeneralModel.Folio,
                "Fecha y hora de emisión:",
                formatDateDDMMYYYY(new Date(jsonInvoice.GeneralModel.Date))
              ),

              createRow(
                "Lugar de Expedición:",
                jsonInvoice.GeneralModel.ExpeditionPlace
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Receptor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Receiver?.Rfc,
                "Nombre:",
                jsonInvoice.GeneralModel.Receiver?.Name
              ),

              createRow(
                "Regimen Fiscal:",
                getFiscalRegimeNameFromKey(
                  jsonInvoice.GeneralModel.Receiver?.FiscalRegime
                ),
                "Código Postal:",
                jsonInvoice.GeneralModel.Receiver?.TaxZipCode
              ),

              createRow(
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.Receiver?.CfdiUse || "N/A"
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          {/* Preview Payment info table */}
          <PreviewCustomizedTables
            title={"Información del Pago"}
            rows={[
              createRow(
                "Status del pago",
                jsonInvoice.paymentStatus === "unpaid" ? "Sin pagar" : "Pagado",
                "Total a cubrir:",
                jsonInvoice.Total
              ),
              createRow(
                "Cantidad:",
                jsonInvoice.Complementos?.Payments[0].Amount,
                "Forma de pago:",
                jsonInvoice.Complementos?.Payments[0].PaymentForm
              ),
            ]}
          />
        </Grid>

        {jsonInvoice.Complementos?.Payments[0].RelatedDocuments.length > 0 && (
          <Grid item xs={12}>
            {/* Preview Related documents (if any) */}
            {jsonInvoice.Complementos?.Payments.map((payComp_obj) => {
              return PreviewRelatedDocumentsForPaymentTable({
                relatedDocuments: payComp_obj.RelatedDocuments,
              });
            })}
          </Grid>
        )}
      </Grid>
    );
  }
  if (type === "postIngreso") {
    //console.log("inner space",jsonInvoice)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"General"}
            rows={[
              createRow(
                "Tipo de recibo:",
                "Ingreso",
                "Efecto del comprobante:",
                jsonInvoice.GeneralModel.CfdiType.Type
              ),

              createRow(
                "Folio:",
                jsonInvoice.GeneralModel.Folio || 0,
                "Fecha y hora de emisión:",
                formatDateDDMMYYYY(new Date(jsonInvoice.GeneralModel.Date))
              ),

              createRow(
                "Lugar de Expedición:",
                jsonInvoice.GeneralModel.ExpeditionPlace
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Receptor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Receiver?.Rfc,
                "Nombre:",
                jsonInvoice.GeneralModel.Receiver?.Name
              ),

              createRow(
                "Regimen Fiscal:",
                getFiscalRegimeNameFromKey(
                  jsonInvoice.GeneralModel.Receiver?.FiscalRegime
                ),
                "Código Postal:",
                jsonInvoice.GeneralModel.Receiver?.TaxZipCode
              ),

              createRow(
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.Receiver?.CfdiUse || "N/A"
              ),
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          {/* Preview Payment info table */}
          <PreviewCustomizedTables
            title={"Información del Pago"}
            rows={[
              createRow(
                "Status del pago",
                jsonInvoice.Status.paymentStatus === "unpaid"
                  ? "Sin pagar"
                  : "Pagado",
                "Total a cubrir:",
                jsonInvoice.Total
              ),
              createRow(
                "Cantidad:",
                jsonInvoice.Complementos?.Payments[0].Amount,
                "Forma de pago:",
                jsonInvoice.Complementos?.Payments[0].PaymentForm
              ),
            ]}
          />
        </Grid>
      </Grid>
    );
  }

  if (type === "postCartaPorte") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Receptor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Receiver.Rfc,
                "Nombre:",
                jsonInvoice.GeneralModel.Receiver.Name
              ),

              createRow(
                "Regimen Fiscal:",
                getFiscalRegimeNameFromKey(601),
                "Código Postal:",
                jsonInvoice.GeneralModel.Receiver.TaxZipCode
              ),

              createRow(
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.Receiver.CfdiUse
              ),
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewCustomizedTables
            title={"Emisor"}
            rows={[
              createRow(
                "RFC:",
                jsonInvoice.GeneralModel.Issuer.Rfc,
                "Uso de CFDI:",
                jsonInvoice.GeneralModel.CfdiType.Name
              ),

              createRow(
                "Nombre:",
                jsonInvoice.GeneralModel.Issuer.Name,
                "Régimen Fiscal:",
                getFiscalRegimeNameFromKey(
                  jsonInvoice.GeneralModel.Issuer.FiscalRegime
                )
              ),

              createRow(
                "Lugar de Expedición:",
                jsonInvoice.GeneralModel.ExpeditionPlace
              ),
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewVehicularIDTable
            AutoTransporte={
              jsonInvoice.Complementos.CartaPorte20.Mercancias.Autotransporte
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewProductsTable productsArray={jsonInvoice.Models.I.Items} />
        </Grid>
        <Grid item xs={12}>
          <PreviewUbicacionesTable
            locationsArray={jsonInvoice.Complementos.CartaPorte20.Ubicaciones}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewMerchTable
            merchArray={
              jsonInvoice.Complementos.CartaPorte20.Mercancias.Mercancia
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewFiguraTransporteTable
            FiguraTransporteArr={
              jsonInvoice.Complementos.CartaPorte20.FiguraTransporte
            }
          />
        </Grid>
      </Grid>
    );
  }
};

const PreviewPayrollInvoice = (cfdi) => {
  //console.log("cfdi in preview", cfdi);

  function getCfdiFromKey(key) {
    switch (key) {
      case "N":
        return "Nómina";
      default:
        return "";
    }
  }

  function getNominaFromKey(key) {
    switch (key) {
      case "O":
        return "Nómina Ordinaria";
      case "E":
        return "Nómina Extraordinaria";
      default:
        return "";
    }
  }

  function getPaymentMethodFromKey(key) {
    switch (key) {
      case "PUE":
        return "Pago en una sola exhibición";
      case "PDD":
        return "Pago en mensualidades o diferido";
      default:
        return "";
    }
  }

  function getFiscalRegimeNameFromKey(key) {
    switch (key) {
      case "626":
        return "Régimen para personas Físicas";
      case "601":
        return "Régimen para personas morales";
      default:
        return "";
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createRow(...cols) {
    const row = {};
    for (let i = 0; i < cols.length; i++) {
      row[`col${i + 1}`] = cols[i];
    }
    return row;
  }

  const hasOtherPayments =
    Object.keys(cfdi.Complemento.Payroll.OtherPayments.Details).length > 0;

  function getTotal() {
    let total = 0;
    for (const array in cfdi.Complemento.Payroll.Perceptions.Details) {
      total =
        total +
        Number(
          cfdi.Complemento.Payroll.Perceptions.Details[array].TaxedAmount
        ) +
        Number(
          cfdi.Complemento.Payroll.Perceptions.Details[array].ExemptAmount
        );
    }
    for (const array in cfdi.Complemento.Payroll.Deductions.Details) {
      total =
        total -
        Number(cfdi.Complemento.Payroll.Deductions.Details[array].Amount);
    }
    if (hasOtherPayments) {
      for (const array in cfdi.Complemento.Payroll.OtherPayments.Details) {
        total =
          total +
          Number(cfdi.Complemento.Payroll.OtherPayments.Details[array].Amount);
      }
    }

    return total;
  }

  const rows = {
    issuer: [
      createRow(
        PayrollKeyMap.Issuer.Rfc,
        cfdi.Issuer.Rfc,
        PayrollKeyMap.CfdiType,
        getCfdiFromKey(cfdi.CfdiType)
      ),
      createRow(
        "Nombre",
        cfdi.Issuer.Name,
        PayrollKeyMap.Issuer.EmployerRegistration,
        cfdi.Issuer.EmployerRegistration
      ),
      createRow(
        PayrollKeyMap.Folio,
        cfdi.Folio,
        "Lugar de Expedición",
        "C.P: " + cfdi.ExpeditionPlace
      ),
      createRow(
        "Régimen Fiscal",
        getFiscalRegimeNameFromKey(cfdi.Issuer.FiscalRegime),
        "Fecha y hora de emisión",
        formatDateDDMMYYYY(cfdi.Complemento.Payroll.PaymentDate, true)
      ),
    ],
    receiver: [
      createRow(
        PayrollKeyMap.Receiver.Name,
        cfdi.Receiver.Name,
        PayrollKeyMap.Receiver.FiscalRegime,
        getFiscalRegimeNameFromKey(Number(cfdi.Receiver.FiscalRegime))
      ),
      createRow(
        PayrollKeyMap.Receiver.Rfc,
        cfdi.Receiver.Rfc,
        PayrollKeyMap.Complemento.Payroll.Employee.Curp,
        cfdi.Complemento.Payroll.Employee.Curp
      ),
      createRow(
        PayrollKeyMap.Complemento.Payroll.Employee.EmployeeNumber,
        cfdi.Complemento.Payroll.Employee.EmployeeNumber,
        PayrollKeyMap.Complemento.Payroll.Employee.Position,
        cfdi.Complemento.Payroll.Employee.Position
      ),
      createRow(
        PayrollKeyMap.Complemento.Payroll.Employee.Department,
        cfdi.Complemento.Payroll.Employee.Department,
        PayrollKeyMap.Complemento.Payroll.Employee.PositionRisk,
        cfdi.Complemento.Payroll.Employee.PositionRisk
      ),
      createRow(
        PayrollKeyMap.Complemento.Payroll.Employee.SocialSecurityNumber,
        cfdi.Complemento.Payroll.Employee.SocialSecurityNumber,
        "Antigüedad",
        "N/A", //selectedEmployee?.Antiguedad,
        "", //keyMap.Complemento.Payroll.Employee.PositionRisk,
        "" //cfdi.Complemento.Payroll.Employee.PositionRisk
      ),
    ],
    general: [
      createRow(
        "Tipo de Nómina",
        getNominaFromKey(cfdi.Complemento.Payroll.Type),
        PayrollKeyMap.Complemento.Payroll.DaysPaid,
        cfdi.Complemento.Payroll.DaysPaid
      ),
      createRow(
        PayrollKeyMap.Complemento.Payroll.InitialPaymentDate,
        formatDateDDMMYYYY(cfdi.Complemento.Payroll.InitialPaymentDate),
        PayrollKeyMap.Complemento.Payroll.FinalPaymentDate,
        formatDateDDMMYYYY(cfdi.Complemento.Payroll.FinalPaymentDate)
      ),
      createRow(
        PayrollKeyMap.Complemento.Payroll.PaymentDate,
        formatDateDDMMYYYY(cfdi.Complemento.Payroll.PaymentDate),
        PayrollKeyMap.PaymentMethod,
        getPaymentMethodFromKey(cfdi.PaymentMethod)
      ),
      createRow(
        PayrollKeyMap.Complemento.Payroll.Employee.Bank,
        cfdi.Complemento.Payroll.Employee.Bank
      ),
    ],
  };

  return (
    <Grid container spacing={2} minWidth={"xl"}>
      {/* Emisor */}
      <Grid item xs={12}>
        <PreviewCustomizedTables rows={rows["issuer"]} title={"Emisor"} />
      </Grid>

      {/* Receptor */}
      <Grid item xs={12}>
        <PreviewCustomizedTables rows={rows["receiver"]} title={"Receptor"} />
      </Grid>

      {/* Datos Generales */}
      <Grid item xs={12}>
        <PreviewCustomizedTables
          rows={rows["general"]}
          title={"Datos Generales"}
        />
      </Grid>

      {/* Percepciones */}
      <Grid item xs={12}>
        <PreviewPerceptionsTable
          perceptionsArray={cfdi.Complemento.Payroll.Perceptions.Details}
        ></PreviewPerceptionsTable>
      </Grid>

      {/* Deducciones */}
      <Grid item xs={12}>
        <PreviewDeduccionesTable
          deductionsArray={cfdi.Complemento.Payroll.Deductions.Details}
        ></PreviewDeduccionesTable>
      </Grid>

      {/* Otros Pagos */}
      {hasOtherPayments && (
        <Grid item xs={12}>
          <PreviewOtherPaymentsTable
            OPArray={cfdi.Complemento.Payroll.OtherPayments.Details}
          />
        </Grid>
      )}

      {/* Totales */}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" colSpan={4}>
                  Totales
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableRow
              sx={{ backgroundColor: "secondary.dark", color: "white" }}
            >
              <StyledTableCell align="left">Percepciones</StyledTableCell>
              <StyledTableCell align="left">Deducciones</StyledTableCell>
              <StyledTableCell align="left">Otros Pagos</StyledTableCell>
              <StyledTableCell align="center">
                <b>Total percibido</b>
              </StyledTableCell>
            </TableRow>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="right">
                  $
                  {cfdi.Complemento.Payroll.Perceptions.Details.reduce(
                    function (acc, obj) {
                      return acc + (obj.TaxedAmount + obj.ExemptAmount);
                    },
                    0
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  -$
                  {cfdi.Complemento.Payroll.Deductions.Details.reduce(function (
                    acc,
                    obj
                  ) {
                    return acc + obj.Amount;
                  },
                  0)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  $
                  {cfdi.Complemento.Payroll.OtherPayments.Details.reduce(
                    function (acc, obj) {
                      return acc + obj.Amount;
                    },
                    0
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>${getTotal()}</b>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export function PreviewCFDI({ CFDI }) {
  let type = getTypeOfInvoice(CFDI);
  console.log("tipo factura", type);

  if (type === "nomina") {
    return PreviewPayrollInvoice(CFDI);
  }

  //In this conditional validate all the types of invoices that calls buildContent with parameters CFDI and type to avpoid redundancy
  if (
    type === "cartaPorte" ||
    type === "facturaIngreso" ||
    type === "pago" ||
    type === "postPago" ||
    type === "postIngreso" ||
    type === "postCartaPorte"
  ) {
    return buildContent(CFDI, type);
  }

  //If the type is not any of the above, it will return an error showing the type received and the CFDI to mmake it easy to identify the error
  return (
    <>
      <p>Error</p>
      <p>{type}</p>
      <p>{JSON.stringify(CFDI)}</p>
    </>
  );
}

export function PreviewCustomizedTables({ rows, title }) {
  const cellDestructor = (row) => {
    return Object.keys(row).map((col) => {
      let value = row[`${col}`];
      return <StyledTableCell align="left">{value}</StyledTableCell>;
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" colSpan={"100%"}>
              {title}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow key={idx}>
              {/* THIS FUNCTION MAPS ROWS WITH N NUMBER OF CELLS WITH ANY NAME
                    AT THE COST OF NOT BEING ABLE TO SET "BOLD" INDICATORS 
                    *********
                    cellDestructor(row)   
                */}
              <StyledTableCell align="left">
                <b>{row.col1}</b>
              </StyledTableCell>
              <StyledTableCell align="left">{row.col2}</StyledTableCell>
              <StyledTableCell align="left">
                <b>{row.col3}</b>
              </StyledTableCell>
              <StyledTableCell align="left">{row.col4}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const PreviewDeduccionesTable = ({
  deductionsArray = [
    { Description: "", DeduccionType: "", Code: "", Amount: 0 },
  ],
}) => {
  const cellDestructor = (row, align = ["left"]) => {
    // This function takes an object and returns a cell for every parameter it has
    // it doesnt really work for nested objects, but its useful to display strings
    // and numbers in row form
    // it uses an array of align parameters to, well, align each cell's text.
    // If the align array has only one value it will act as a default.
    // Default is left
    return Object.keys(row).map((col, idx) => {
      let value = row[`${col}`];
      return (
        <StyledTableCell align={align.length > 1 ? align[idx] : align[0]}>
          {/* in the used rows all the numbers are related to currency,
                   this is a reason for keeping each destructuror inside its own
                   table, so it can be finely tuned to display the desired data  */}
          {typeof value === "number" ? "$ " + value : value}
        </StyledTableCell>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" colSpan={4}>
              Deducciones
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
          <StyledTableCell align="left">Tipo de Deducción</StyledTableCell>
          <StyledTableCell align="left">Clave</StyledTableCell>
          <StyledTableCell align="center">Concepto</StyledTableCell>
          <StyledTableCell align="center">Importe</StyledTableCell>
        </TableRow>
        <TableBody>
          {deductionsArray.map((deduction, idx) => (
            <StyledTableRow key={idx}>
              {cellDestructor(deduction, ["left", "left", "center", "right"])}
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <StyledTableCell align="right" colSpan={3}>
              <b>Total</b>
            </StyledTableCell>
            <StyledTableCell align="right">
              <b>
                $
                {deductionsArray.reduce(function (acc, obj) {
                  return acc + obj.Amount;
                }, 0)}
              </b>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PreviewPerceptionsTable = ({
  perceptionsArray = [
    { Description: "", PerceptionType: "", TaxedAmount: 0, ExemptAmount: 0 },
  ],
}) => {
  const cellDestructor = (row, align = ["left"]) => {
    // This function takes an object and returns a cell for every parameter it has
    // it doesnt really work for nested objects, but its useful to display strings
    // and numbers in row form
    // it uses an array of align parameters to, well, align each cell's text.
    // If the align array has only one value it will act as a default.
    // Default is left
    return Object.keys(row).map((col, idx) => {
      let value = row[`${col}`];
      return (
        <StyledTableCell align={align.length > 1 ? align[idx] : align[0]}>
          {/* in the used rows all the numbers are related to currency,
                   this is a reason for keeping each destructuror inside its own
                   table, so it can be finely tuned to display the desired data  */}
          {typeof value === "number" ? "$ " + value : value}
        </StyledTableCell>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" colSpan={"100%"}>
              Percepciones
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
          <StyledTableCell align="left">Tipo de percepción</StyledTableCell>
          <StyledTableCell align="left">Clave</StyledTableCell>
          <StyledTableCell align="center">Importe Gravado</StyledTableCell>
          <StyledTableCell align="center">Importe Exento</StyledTableCell>
        </TableRow>
        <TableBody>
          {perceptionsArray.map((perception, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell align="left">
                {perception.Description}
              </StyledTableCell>
              <StyledTableCell align="right">
                {perception.PerceptionType}
              </StyledTableCell>
              <StyledTableCell align="right">
                ${perception.TaxedAmount}
              </StyledTableCell>
              <StyledTableCell align="right">
                ${perception.ExemptAmount}
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <StyledTableCell align="right" colSpan={2}>
              <b>Totales</b>
            </StyledTableCell>
            <StyledTableCell align="right">
              <b>
                $
                {perceptionsArray.reduce(function (acc, obj) {
                  return acc + obj.TaxedAmount;
                }, 0)}
              </b>
            </StyledTableCell>
            <StyledTableCell align="right">
              <b>
                $
                {perceptionsArray.reduce(function (acc, obj) {
                  return acc + obj.ExemptAmount;
                }, 0)}
              </b>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PreviewRelatedDocumentsForPaymentTable = ({
  relatedDocuments = [
    {
      Uuid: "00a0aa0a-888a-4a4-8745-9aa99a9999a",
      Folio: "1",
      PaymentMethod: "PPD",
      PartialityNumber: "1",
      PreviousBalanceAmount: "0",
      AmountPaid: "1",
    },
  ],
}) => {
  const cellDestructor = (row, align = ["left"]) => {
    // This function takes an object and returns a cell for every parameter it has
    // it doesnt really work for nested objects, but its useful to display strings
    // and numbers in row form
    // it uses an array of align parameters to, well, align each cell's text.
    // If the align array has only one value it will act as a default.
    // Default is left
    return Object.keys(row).map((col, idx) => {
      let value = row[`${col}`];
      return (
        <StyledTableCell align={align.length > 1 ? align[idx] : align[0]}>
          {/* in the used rows all the numbers are related to currency,
                   this is a reason for keeping each destructuror inside its own
                   table, so it can be finely tuned to display the desired data  */}
          {value}
        </StyledTableCell>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" colSpan={"100%"}>
              Documentos Relacionados con el Pago
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {relatedDocuments.map((doc, idx) => (
            <PreviewCustomizedTables
              title={`Identificador Único: ${doc.Uuid}`}
              rows={[
                createRow(
                  "Folio:",
                  doc.Folio,
                  "Balance Previo:",
                  doc.PreviousBalanceAmount
                ),

                createRow(
                  "Parcialidad:",
                  doc.PartialityNumber,
                  "Cantidad del pago:",
                  String(doc.AmountPaid)
                ),
              ]}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PreviewOtherPaymentsTable = ({
  OPArray = [{ Description: "", OtherPaymentType: "", Code: "", Amount: "" }],
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" colSpan={4}>
              Otros pagos
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
          <StyledTableCell align="left">Tipo de pago</StyledTableCell>
          <StyledTableCell align="left">Clave</StyledTableCell>
          <StyledTableCell align="center">Concepto</StyledTableCell>
          <StyledTableCell align="center">Importe</StyledTableCell>
        </TableRow>
        <TableBody>
          {OPArray.map((payment, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell align="left">
                {payment.Description}
              </StyledTableCell>
              <StyledTableCell align="left">
                {payment.OtherPaymentType}
              </StyledTableCell>
              <StyledTableCell align="right">{payment.Code}</StyledTableCell>
              <StyledTableCell align="right">${payment.Amount}</StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <StyledTableCell align="right" colSpan={3}>
              <b>Total</b>
            </StyledTableCell>
            <StyledTableCell align="right">
              <b>
                $
                {OPArray.reduce(function (acc, obj) {
                  return acc + obj.Amount;
                }, 0)}
              </b>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

//Componente y función para parsear, determinar el tipo de factura,
//delimitar los campos opcionales y abrir un diálogo de previsualización
//para facturas por ser emitidas

export const PreviewInvoice = ({ json, invoiceID, filters, onClose }) => {
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
    content: "",
    actions: [],
  });
  const { userid } = useAuth();
  const [invoiceJson, setInvoiceJson] = useState(json);
  const [fullFilters, setFullFilters] = useState(filters);

  useEffect(() => {
    if (json) {
      setInvoiceJson(json);
      //console.log(invoiceJson)
      handlePreviewInvoice({ invoiceJson, dialog, setDialog, onClose });
    }
  }, [json]);

  useEffect(() => {
    //console.log(invoiceID)
    if (invoiceID) {
      let filters = [
        {
          label: "_id",
          param: "_id",
          value: invoiceID,
        },
      ];
      getIssuedDocs(userid, userid, 1, filters)
        .then((res) => {
          //console.log("respuesta filtro id",res.data)
          if (res.data.data.length === 0) {
            setDialog({
              ...dialog,
              open: true,
              title: "Error obteniendo la factura",
              content: res.data.message,
              actions: [
                {
                  label: "OK",
                  execute: () =>
                    setDialog({
                      ...dialog,
                      open: false,
                      title: "",
                      message: "",
                      actions: [],
                    }),
                },
              ],
            });
          } else {
            if (res.data.data.length === 1) {
              setInvoiceJson(res.data.data[0]);
            } else {
              setDialog({
                ...dialog,
                open: true,
                content:
                  "Hay más de una factura ocn este identificador. Contacta a soporte",
                actions: [
                  {
                    label: "Ok",
                    execute: () => setDialog({ ...dialog, open: false }),
                  },
                ],
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            setDialog({
              ...dialog,
              open: true,
              title: "Error de valores",
              content:
                "Ha ocurrido un error en el filtrado relacionado a los valores que colocaste y verifica los valores o tu conexión",
              actions: [
                {
                  label: "Ok",
                  execute: () => setDialog({ ...dialog, open: false }),
                },
              ],
            });
          }
          if (err.response.status === 401) {
            setDialog({
              ...dialog,
              open: true,
              title: "Tu sesión expiró",
              content: "Recarga la página",
              actions: [
                { label: "Recargar", execute: () => window.location.reload() },
              ],
            });
          }
          if (err.response.status === 500) {
            setDialog({
              ...dialog,
              open: true,
              title: "Error interno",
              content:
                "Ha ocurrido un error en el servidor, intentalo de nuevo más tarde.",
              actions: [
                {
                  label: "Ok",
                  execute: () => setDialog({ ...dialog, open: false }),
                },
              ],
            });
          }
        });
    }
  }, [invoiceID]);

  return (
    <Dialog open={dialog.open} maxWidth={"md"}>
      <DialogTitle>{dialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialog.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {dialog.actions.map((action, index) => (
          <Button key={index} variant="contained" onClick={action.execute}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export const handlePreviewInvoice = ({
  invoiceJson,
  dialog,
  setDialog,
  onClose,
}) => {
  let usableJson = JSON.parse(invoiceJson);
  console.log("usable", usableJson);

  const type = getTypeOfInvoice(usableJson);
  //console.log("tipo", type);

  let optionalArray;
  let itemsCount = usableJson.Models?.I?.Items.length || 0;
  let addressCount = usableJson.GeneralModel.Issuer.Address?.length || 0;
  let paymentsCount = usableJson.Complementos?.Payments?.length || 0;

  //setting the optional routes depending on the type of cfdi
  switch (type) {
    case "pago":
      optionalArray = [
        "GeneralModel.ExpeditionPlace.Address.Locality",
        "GeneralModel.ExpeditionPlace.Address.InteriorNumber",
        "GeneralModel.Date",
        "GeneralModel.PaymentForm",
        "GeneralModel.PaymentMethod",
        "GeneralModel.Issuer.Address",
        "GeneralModel.Issuer.CURP",
        "Models",
        "uuid",
        "invoiceId",
      ];
      for (let i = 0; i < paymentsCount; i++) {
        let relatedCount =
          usableJson.Complementos.Payments[i].RelatedDocuments?.length;
        for (let j = 0; j < relatedCount; j++) {
          optionalArray.push(
            `Complementos.Payments.${i}.RelatedDocuments.${j}.Serie`
          );
          optionalArray.push(
            `Complementos.Payments.${i}.RelatedDocuments.${j}.Currency`
          );
          optionalArray.push(
            `Complementos.Payments.${i}.RelatedDocuments.${j}.ImpSaldoInsoluto`
          );
        }
      }
      break;
    case "cartaPorte":
      optionalArray = [
        "GeneralModel.ExpeditionPlace.Address.Locality",
        "GeneralModel.ExpeditionPlace.Address.InteriorNumber",
        "GeneralModel.Date",
        "Complementos.CartaPorte20.Ubicaciones.0.Domicilio.Colonia",
        "Complementos.CartaPorte20.Ubicaciones.1.Domicilio.Colonia",
      ];

      for (let i = 0; i < itemsCount; i++) {
        optionalArray.push(`Models.I.Items.${i}.product.Complement`);
        optionalArray.push(`Models.I.Items.${i}.description`);
      }

      for (let i = 0; i < addressCount; i++) {
        optionalArray.push(`GeneralModel.Issuer.Address.${i}.Status.deletedAt`);
        optionalArray.push(`GeneralModel.Issuer.Address.${i}.Status.updatedBy`);
        optionalArray.push(
          `GeneralModel.Issuer.Address.${i}.Status.reasonByDelete`
        );
        optionalArray.push(`GeneralModel.Issuer.Address.${i}.Status.deletedBy`);
      }

      break;
    case "facturaIngreso":
      optionalArray = [
        "GeneralModel.ExpeditionPlace.Address.Locality",
        "GeneralModel.ExpeditionPlace.Address.InteriorNumber",
        "GeneralModel.Date",
      ];

      for (let i = 0; i < itemsCount; i++) {
        optionalArray.push(`Models.I.Items.${i}.product.Complement`);
        optionalArray.push(`Models.I.Items.${i}.description`);
      }

      for (let i = 0; i < addressCount; i++) {
        optionalArray.push(`GeneralModel.Issuer.Address.${i}.Status.deletedAt`);
        optionalArray.push(`GeneralModel.Issuer.Address.${i}.Status.updatedBy`);
        optionalArray.push(
          `GeneralModel.Issuer.Address.${i}.Status.reasonByDelete`
        );
        optionalArray.push(`GeneralModel.Issuer.Address.${i}.Status.deletedBy`);
      }

      break;
    default:
      break;
  }

  //validating and object searching functions
  function checkEmptyData(
    data,
    path = "",
    emptyFields = [],
    optionalRoutes = []
  ) {
    for (const key in data) {
      const newPath = path === "" ? key : `${path}.${key}`;
      const currentRoute = newPath.replace(/^.*?\./, ""); // Extract current route from newPath
      if (typeof data[key] === "object" && data[key] !== null) {
        checkEmptyData(data[key], newPath, emptyFields, optionalRoutes);
      } else if (data[key] === "" || data[key] === null) {
        // Check if current route is not in optionalRoutes array before adding to emptyFields
        if (!optionalRoutes.includes(newPath)) {
          emptyFields.push(newPath);
        }
      }
    }
    return emptyFields;
  }
  const deep_value = (obj, peth) => {
    for (let i = 0, path = peth.split("."); i < path.length; i++) {
      if (!obj) return undefined;
      //if the path key is a number, it implies its on an array, so we must "print"
      //the same values as in the 0 element of the array, as is the only one defined in the keymap
      if (!Number.isNaN(Number(path[i]))) obj = obj[0];
      //else: we just keep digging
      else {
        obj = obj[path[i]];
      }
    }
    return obj;
  };

  //values to display if something is missing
  let emptyFieldsRoutes = checkEmptyData(usableJson, "", [], optionalArray);
  //console.log("efr", emptyFieldsRoutes);
  let emptyRoutesKeymappedNames = [];

  if (type !== "postPago") {
    for (const route in emptyFieldsRoutes) {
      emptyRoutesKeymappedNames.push(
        deep_value(FullCfdiKeymap, emptyFieldsRoutes[route])
      );
    }
  }

  if (type === "cartaPorte" || type === "postCartaPorte") {
    let configVehicular =
      usableJson.Complementos.CartaPorte20.Mercancias.Autotransporte
        .IdentificacionVehicular.ConfigVehicular;

    if (
      configVehicular !== "VL" &&
      configVehicular !== "C2" &&
      configVehicular !== "C3"
    ) {
      if (
        usableJson.Complementos.CartaPorte20.Mercancias.Autotransporte.Remolques
          .length === 0
      )
        emptyRoutesKeymappedNames.push("Remolques");
    }
  }

  if (type !== "pago" || type == "postIngreso") {
    if (usableJson.Models.I.Items.length === 0) {
      emptyRoutesKeymappedNames.push("Conceptos De La Factura");
    }
  }

  const MissingList = () => {
    return (
      <Grid container maxWidth={"md"} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" color="error.main">
            Falta información en los siguientes campos:
          </Typography>
        </Grid>
        {emptyRoutesKeymappedNames.map((MF, idx /*Missing Field, mofoca */) => {
          if (typeof MF !== "object")
            return (
              <Grid key={idx} item xs={3}>
                <Typography>-{MF}</Typography>
              </Grid>
            );
        })}
      </Grid>
    );
  };

  if (
    false /*
    emptyFieldsRoutes.length !== 0 ||
    emptyRoutesKeymappedNames.length !== 0
    */
  ) {
    setDialog({
      open: true,
      title: "Datos Faltantes",
      content: MissingList(),
      actions: [
        {
          label: "ok",
          execute: () => {
            setDialog({ ...dialog, open: false });
          },
        },
      ],
    });
  } else {
    setDialog({
      open: true,
      title: "Previsualización",
      content: (
        <>
          <PreviewCFDI CFDI={usableJson} />
        </>
      ),
      actions: [
        {
          label: "Cerrar",
          execute: () => {
            setDialog({ ...dialog, open: false });
            onClose();
          },
        },

        /* Timbrar es opcional, falta checarlo pero por el
        momento el invoice generator y el dashboard preview están separados
        es necesario porner una validación para saber desde dónde se está haciendo
        la solicitud del preview
        */

        /*
        {
          label: "aceptar y timbrar",
          execute: () => {
            //setLoading(true)
            //handleTimbrado(usableJson);
            setDialog({...dialog,open:false});
          },
        }
        */
      ],
    });
  }
};

//ubicaciones para carta porte, no se quedará aquí

export const PreviewUbicacionesTable = ({
  locationsArray = [
    {
      Domicilio: {
        Calle: "",
        CodigoPostal: 0,
        Colonia: "",
        Estado: "",
        Localidad: "",
        Municipio: "",
        País: "",
      },
      FechaHoraSalidaLlegada: "",
      RFCRemitenteDestinatario: "",
      TipoUbicacion: "",
      DistanciaRecorrida: "",
    },
  ],
}) => {
  const cellDestructor = (row, align = ["left"]) => {
    // This function takes an object and returns a cell for every parameter it has
    // it doesnt really work for nested objects, but its useful to display strings
    // and numbers in row form
    // it uses an array of align parameters to, well, align each cell's text.
    // If the align array has only one value it will act as a default.
    // Default is left
    return Object.keys(row).map((col, idx) => {
      let value = row[`${col}`];
      return (
        <StyledTableCell align={align.length > 1 ? align[idx] : align[0]}>
          {/* in the used rows all the numbers are related to currency,
                   this is a reason for keeping each destructuror inside its own
                   table, so it can be finely tuned to display the desired data  */}
          {value}
        </StyledTableCell>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" colSpan={"100%"}>
              Ubicaciones
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
          <StyledTableCell align="left">Tipo </StyledTableCell>
          <StyledTableCell align="left">RFC</StyledTableCell>
          <StyledTableCell align="center">Código Postal</StyledTableCell>
          <StyledTableCell align="center">Domicilio</StyledTableCell>
          <StyledTableCell align="center">Estado</StyledTableCell>
          <StyledTableCell align="center">Distancia</StyledTableCell>
        </TableRow>
        <TableBody>
          {locationsArray.map((location, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell align="left">
                {location.TipoUbicacion}
              </StyledTableCell>
              <StyledTableCell align="left">
                {location.RFCRemitenteDestinatario}
              </StyledTableCell>
              <StyledTableCell align="center">
                {location.Domicilio.CodigoPostal}
              </StyledTableCell>
              <StyledTableCell align="left">
                {location.Domicilio.Calle}
              </StyledTableCell>
              <StyledTableCell align="center">
                {location.Domicilio.Estado}
              </StyledTableCell>
              <StyledTableCell align="right">
                {location.DistanciaRecorrida ? location.DistanciaRecorrida : 0}{" "}
                km
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PreviewProductsTable = ({
  productsArray = [
    {
      quantity: 1,
      product: {
        Complement: "",
        Name: "Servicios de transporte de carga por carretera (en camión) en área local",
        Value: "78101801",
      },
      unit: {
        ShortName: "Unidad de servicio",
        Name: "Unidad de servicio",
        Value: "E48",
      },
      description: "xxx",
      price: "5000",
      taxObject: {
        id: "02",
        Value: "Sí objeto de impuesto",
      },
      taxes: [
        {
          Name: "IVA RET",
          IsRetention: "true",
          Rate: "0.04",
          Base: 5000,
          Total: "200.00",
        },
        {
          Name: "IVA",
          Rate: "0.16",
          Base: 5000,
          Total: "800.00",
        },
      ],
      total: "5600.00",
    },
    {
      quantity: 1,
      product: {
        Complement: "",
        Name: "Servicios de transporte de carga por carretera (en camión) en área local",
        Value: "78101801",
      },
      unit: {
        ShortName: "Unidad de servicio",
        Name: "Unidad de servicio",
        Value: "E48",
      },
      description: "xxx",
      price: "5000",
      taxObject: {
        id: "02",
        Value: "Sí objeto de impuesto",
      },
      taxes: [
        {
          Name: "IVA",
          Rate: "0.16",
          Base: 5000,
          Total: "800.00",
        },
        {
          Name: "IVA RET",
          IsRetention: "true",
          Rate: "0.04",
          Base: 5000,
          Total: "200.00",
        },
      ],
      total: "5600.00",
    },
  ],
}) => {
  const cellDestructor = (row, align = ["left"]) => {
    // This function takes an object and returns a cell for every parameter it has
    // it doesnt really work for nested objects, but its useful to display strings
    // and numbers in row form
    // it uses an array of align parameters to, well, align each cell's text.
    // If the align array has only one value it will act as a default.
    // Default is left
    return Object.keys(row).map((col, idx) => {
      let value = row[`${col}`];
      return (
        <StyledTableCell align={align.length > 1 ? align[idx] : align[0]}>
          {/* in the used rows all the numbers are related to currency,
                   this is a reason for keeping each destructuror inside its own
                   table, so it can be finely tuned to display the desired data  */}
          {value}
        </StyledTableCell>
      );
    });
  };

  function calculateTaxTotals(array) {
    const taxTotals = {};

    for (let i = 0; i < array.length; i++) {
      const taxes = array[i].taxes;

      for (let j = 0; j < taxes.length; j++) {
        const tax = taxes[j];

        if (tax.Name in taxTotals) {
          taxTotals[tax.Name] += tax.Total;
        } else {
          taxTotals[tax.Name] = tax.Total;
        }
      }
    }

    return taxTotals;
  }

  let taxTotals = calculateTaxTotals(productsArray);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
            <StyledTableCell align="left">Producto </StyledTableCell>
            <StyledTableCell align="left">Cantidad</StyledTableCell>
            <StyledTableCell align="center">Unidad</StyledTableCell>
            <StyledTableCell align="center">Concepto(s)</StyledTableCell>
            <StyledTableCell align="center">Precio Unitario</StyledTableCell>
            <StyledTableCell align="center">Importe</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {productsArray.map((product, idx) => (
            <>
              <StyledTableRow key={idx}>
                <StyledTableCell align="left">
                  {product.product.Value}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {product.quantity}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {product.unit.Value}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {product.product.Name}
                  <br />
                  {product.description}
                </StyledTableCell>
                <StyledTableCell align="center">
                  ${product.price}
                </StyledTableCell>
                <StyledTableCell align="right">
                  ${product.price}
                </StyledTableCell>
              </StyledTableRow>
            </>
          ))}

          <TableRow>
            <StyledTableCell align="left" colSpan={4}></StyledTableCell>
            <StyledTableCell align="left">
              <b>{"Subtotal:"}</b>
            </StyledTableCell>
            <StyledTableCell align="right">
              $
              {productsArray.reduce(function (acc, obj) {
                return acc + Number(obj.subtotal);
              }, 0)}
            </StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell align="left" colSpan={4}></StyledTableCell>
            <StyledTableCell align="left">
              <b>{"IVA 16%:"}</b>
            </StyledTableCell>
            <StyledTableCell align="right">${taxTotals["IVA"]}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="left" colSpan={4}></StyledTableCell>
            <StyledTableCell align="left">
              <b>{"IVA Ret 4%:"}</b>
            </StyledTableCell>
            <StyledTableCell align="right">
              ${taxTotals["IVA RET"]}
            </StyledTableCell>
          </TableRow>
          <StyledTableRow>
            <StyledTableCell align="left" colSpan={4}></StyledTableCell>
            <StyledTableCell align="left">
              <b>{"Total"}</b>
            </StyledTableCell>
            <StyledTableCell align="right">
              <b>
                $
                {productsArray.reduce(function (acc, obj) {
                  return acc + Number(obj.total);
                }, 0)}
              </b>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PreviewMerchTable = ({
  merchArray = [
    {
      Cantidad: 0,
      BienesTransp: 0,
      Descripcion: "",
      ClaveUnidad: "",
      PesoEnKg: 0,
      ValorMercancia: 0,
      Moneda: "",
    },
  ],
}) => {

  const columnsToShow = [
    { label: "Cantidad", value: "Cantidad", align: "left" },
    { label: "Clave", value: "BienesTransp", align: "center" },
    { label: "Descripción", value: "Descripcion", align: "center" },
    { label: "Clave unidad", value: "ClaveUnidad", align: "center" },
    { label: "Peso (Kg)", value: "PesoEnKg", align: "center" },
    { label: "Valor", value: "ValorMercancia", align: "center" },
    { label: "Moneda", value: "Moneda", align: "center" },
  ];

  const cellDestructor = (row, align = ["left"]) => {
    return columnsToShow.map((column, idx) => {
      const { label } = column;
      let value = row[column.value] || "-";
    // This function takes an object and returns a cell for every parameter it has
    // it doesnt really work for nested objects, but its useful to display strings
    // and numbers in row form
    // it uses an array of align parameters to, well, align each cell's text.
    // If the align array has only one value it will act as a default.
    // Default is left
      return (
        <StyledTableCell align={align.length > 1 ? align[idx] : align[0]}>
          {/* in the used rows all the numbers are related to currency,
                   this is a reason for keeping each destructuror inside its own
                   table, so it can be finely tuned to display the desired data  */}
          {value}
        </StyledTableCell>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" colSpan={"100%"}>
              Mercancías
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
          {columnsToShow.map((column) => (
            <StyledTableCell align={column.align}>
              {column.label}
            </StyledTableCell>
          ))}
        </TableRow>

        <TableBody>
          {merchArray.map((merch, idx) => (
            <>
              <StyledTableRow key={idx}>
                {cellDestructor(merch, ["center"])}
              </StyledTableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PreviewVehicularIDTable = ({
  AutoTransporte = {
    PermSCT: "",
    NumPermisoSCT: "",
    IdentificacionVehicular: {
      ConfigVehicular: "",
      PlacaVM: "",
      AnioModeloVM: 0,
    },
    Seguros: {
      AseguraRespCivil: "",
      PolizaRespCivil: "",
    },
    Remolques: [
      {
        Placa: "",
        SubTipoRem: "",
      },
    ],
  },
}) => {
  const cellDestructor = (row, align = ["left"]) => {
    // This function takes an object and returns a cell for every parameter it has
    // it doesnt really work for nested objects, but its useful to display strings
    // and numbers in row form
    // it uses an array of align parameters to, well, align each cell's text.
    // If the align array has only one value it will act as a default.
    // Default is left
    return Object.keys(row).map((col, idx) => {
      let value = row[`${col}`];
      return (
        <StyledTableCell align={align.length > 1 ? align[idx] : align[0]}>
          {/* in the used rows all the numbers are related to currency,
                   this is a reason for keeping each destructuror inside its own
                   table, so it can be finely tuned to display the desired data  */}
          {value}
        </StyledTableCell>
      );
    });
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginBottom: "3vh" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" colSpan={"100%"}>
                Autotransporte
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
            <StyledTableCell align="left">Permiso SCT </StyledTableCell>
            <StyledTableCell align="left">Número de Permiso</StyledTableCell>
          </TableRow>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="left">
                {AutoTransporte.PermSCT}
              </StyledTableCell>
              <StyledTableCell align="left">
                {AutoTransporte.NumPermisoSCT}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" colSpan={"100%"}>
                Identificación Vehicular
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
            <StyledTableCell align="left">
              Configuración Vehicular{" "}
            </StyledTableCell>
            <StyledTableCell align="left">Placa</StyledTableCell>
            <StyledTableCell align="center">Año</StyledTableCell>
            <StyledTableCell align="center">
              Aseguradora y Poliza
            </StyledTableCell>
            <StyledTableCell align="center">Remolques</StyledTableCell>
          </TableRow>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="left">
                {AutoTransporte.IdentificacionVehicular.ConfigVehicular}
              </StyledTableCell>
              <StyledTableCell align="left">
                {AutoTransporte.IdentificacionVehicular.PlacaVM}
              </StyledTableCell>
              <StyledTableCell align="center">
                {AutoTransporte.IdentificacionVehicular.AnioModeloVM}
              </StyledTableCell>
              <StyledTableCell align="center">
                {AutoTransporte.Seguros.AseguraRespCivil} -{" "}
                {AutoTransporte.Seguros.PolizaRespCivil}
              </StyledTableCell>
              <StyledTableCell align="center">
                {AutoTransporte.Remolques.map((remolque) => {
                  return remolque.SubTipoRem + " - " + remolque.Placa;
                })}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const PreviewFiguraTransporteTable = ({
  FiguraTransporteArr = [
    {
      NombreFigura: "",
      NumLicencia: "",
      RFCFigura: "",
      TipoFigura: "",
    },
  ],
}) => {
  const cellDestructor = (row, align = ["left"]) => {
    // This function takes an object and returns a cell for every parameter it has
    // it doesnt really work for nested objects, but its useful to display strings
    // and numbers in row form
    // it uses an array of align parameters to, well, align each cell's text.
    // If the align array has only one value it will act as a default.
    // Default is left
    return Object.keys(row).map((col, idx) => {
      let value = row[`${col}`];
      return (
        <StyledTableCell align={align.length > 1 ? align[idx] : align[0]}>
          {/* in the used rows all the numbers are related to currency,
                   this is a reason for keeping each destructuror inside its own
                   table, so it can be finely tuned to display the desired data  */}
          {value}
        </StyledTableCell>
      );
    });
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginBottom: "3vh" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" colSpan={"100%"}>
                Autotransporte
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableRow sx={{ backgroundColor: "secondary.dark", color: "white" }}>
            <StyledTableCell align="center">RFC Figura</StyledTableCell>
            <StyledTableCell align="left">Nombre Figura </StyledTableCell>
            <StyledTableCell align="center">TipoFigura</StyledTableCell>
            <StyledTableCell align="center">Licencia</StyledTableCell>
          </TableRow>

          <TableBody>
            {FiguraTransporteArr.map((product, idx) => (
              <>
                <StyledTableRow key={idx}>
                  <StyledTableCell align="left">
                    {product.RFCFigura}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {product.NombreFigura}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {product.TipoFigura}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {product.NumLicencia}
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
