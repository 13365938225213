import { Box, Typography } from "@mui/material";

const IngresoTotalComponent = ({ total }) => {
    return (
      <Box
        sx={{
          backgroundColor: "#06ca14",
          backgroundImage: "linear-gradient(45deg, #10a31a, #06ca14)",
          borderRadius: "8px",
          padding: "16px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Typography variant="button" color="white">
          Total de Ingresos
        </Typography>
        <Typography
          variant="h4"
          color="white"
          sx={{
            marginLeft: "auto",
          }}
        >
          ${Number(total).toFixed(2)}
        </Typography>
      </Box>
    );
  };
  
  const FacturadoTotalComponent = ({ total }) => {
    return (
      <Box
        sx={{
          backgroundColor: "#002F6C",
          backgroundImage: "linear-gradient(45deg, #002F6C, #0052D4)",
          borderRadius: "8px",
          padding: "16px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Typography variant="button" color="white">
          Total facturado
        </Typography>
        <Typography
          variant="h4"
          color="white"
          sx={{
            marginLeft: "auto",
          }}
        >
          ${Number(total).toFixed(2)}
        </Typography>
      </Box>
    );
  };

  export {FacturadoTotalComponent,IngresoTotalComponent}