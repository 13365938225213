import React, { useState } from 'react';
//*mui components
import { AppBar, Button, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Toolbar, Typography } from '@mui/material';
//*mui Icons 
import AttributionIcon from '@mui/icons-material/Attribution';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PlaceIcon from '@mui/icons-material/Place';
import OutboxIcon from '@mui/icons-material/Outbox';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import ContactsIcon from '@mui/icons-material/Contacts';
// import PaymentsIcon from '@mui/icons-material/Payments';
// import WarehouseIcon from '@mui/icons-material/Warehouse';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Add from '@mui/icons-material/Add';
// import PreviewIcon from '@mui/icons-material/Preview';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';

//*mui system
import { Box } from '@mui/system';
//*navigation
import { useNavigate } from 'react-router';
import { useNavContext } from '../../hooks/useNavContext';
import { Dashboard, Search } from '@mui/icons-material';


const actionStructure = [
    // {
    //     id:"dash_button",
    //     label:"Dashboard", 
    //     link:"/businessData", 
    //     icon:<DashboardIcon/>, 
    //     access:["admin"]
    // },
    {
        id:"services_button",
        label:"Servicios", 
        link:"", 
        icon:<AttributionIcon/>, 
        access:["admin", "coordinator"],
        items:[
            {
                
                id:"services_index",
                label:"Dashboard", 
                link:"/serviceIndex", 
                icon:<Dashboard/>, 
                access:["admin", "coordinator"]
            },
            {
                id:"new_service",
                label:"Crear", 
                link:"/services/new", 
                icon:<Add/>, 
                access:["admin", "coordinator"]
            },
            {
                id:"customers",
                label:"Clientes", 
                link:"/customers", 
                icon:<AttributionIcon/>, 
                access:["admin", "coordinator"]
            },
            {
                id:"destinations",
                label:"Destinos", 
                link:"/destinations", 
                icon:<PlaceIcon/>, 
                access:["admin", "coordinator"]
            },
        ]
    },
    {
        id:"invoicing_collapse",
        label:"Facturación", 
        link:"", 
        icon:<ReceiptIcon/>, 
        access:["manager", "admin", "employee"],
        items:[
            
            {
                id:"consult_button",
                label:"Busqueda",
                link:"/consultInvoices",
                icon:<Search    />,
                access:["admin", "manager", "employee"]
            },
            {
                id:"emmit_button",
                label:"Emisión",
                link:"/invoices",
                icon:<OutboxIcon/>,
                access:["admin", "manager"]
            },
            {
                id:"issuers_collapse",
                label:"Emisores",
                link:"",
                icon:<PeopleIcon/>,
                access:["admin", "manager"],
                items:[
                    {
                        id:"consult_button",
                        label:"Consultar",
                        link:"/invoices/consultIssuers",
                        icon:<PersonIcon/>,
                        access:["admin", "manager"]
                    },
                    {
                        id:"register_button",
                        label:"Registrar",
                        link:"/invoices/NuevoEmisor",
                        icon:<Add/>,
                        access:["admin", "manager"]
                    },
                ]
            },
            {
                id:"issuers_collapse",
                label:"Lugares",
                link:"",
                icon:<PlaceIcon/>,
                access:["admin", "manager"],
                items:[
                    {
                        id:"consult_button",
                        label:"Consultar",
                        link:"/issuers/places",
                        icon:<NotListedLocationIcon/>,
                        access:["admin", "manager"]
                    },
                    {
                        id:"register_button",
                        label:"Registrar",
                        link:"/issuers/places/new",
                        icon:<Add/>,
                        access:["admin", "manager"]
                    },
                ]
            },
            {
                id:"customers_button",
                label:"Clientes",
                link:"/customers",
                icon:<ContactsIcon/>,
                access:["admin", "manager"]
            },
        ],
        
    },
    {
        id:"transports_button",
        label:"Vehículos", 
        link:"", 
        icon:<LocalShippingIcon/>, 
        access:["manager", "coordinator", "admin"],
        items:[
            {
                
                id:"services_index",
                label:"Index", 
                link:"/transports", 
                icon:<DashboardIcon/>, 
                access:["admin", "coordinator"]
            },
            {
                id:"new_truck",
                label:"Camión", 
                link:"/transports/newTruck", 
                icon:<Add/>, 
                access:["admin", "coordinator"]
            },
            {
                id:"new_tow",
                label:"Remolque", 
                link:"/transports/newTow", 
                icon:<Add/>, 
                access:["admin", "coordinator"]
            },
        ]
    },
    {
        id:"employees_collapse",
        label:"Empleados", 
        link:"",
        icon:<PersonIcon/>, 
        access:["admin"],
        items:[
            {
                id:"seeAll_button",
                label:"Ver todos", 
                link:"/empleados/",
                icon:<GroupsIcon/>, 
                access:["admin"]
            },
            {
                id:"payroll_button",
                label:"Nómina rápida", 
                link:"/empleados/nominaIndex",
                icon:<ReceiptIcon/>, 
                access:["admin"]
            },
            {
                id:"register_button",
                label:"Registrar", 
                link:"/empleados/crear",
                icon:<Add/>, 
                access:["admin"]
            },
        ]
    },
]

function SidebarItem({ depthStep = 20, depth = 0, expanded, rol, item, ...rest }) {
    const [collapsed, setCollapsed] = React.useState(true);
    const { label, items, icon, link: onClickProp } = item;

    const {toggleDrawer,setSelectedAction} = useNavContext()

    const navigate = useNavigate()
    
    const handleRedirect = (route, label) => {
        setSelectedAction(label)
        navigate(`/${rol}${route}`)
        toggleDrawer(false)
        
    }
  
    function toggleCollapse() {
      setCollapsed(prevValue => !prevValue);
    }
  
    function onClick(e) {
      if (Array.isArray(items)) {
        toggleCollapse();
      }
      if (onClickProp) {
        handleRedirect(item.link, item.label)
      }
    }
  
    let expandIcon;
  
    if (Array.isArray(items) && items.length) {
      expandIcon = !collapsed ? (
        <ExpandLess
          className={
            "sidebar-item-expand-arrow"
          }
          sx={{color:"warning.light",fontWeight:"bold"}}
        />
      ) : (
        <ExpandMore className="sidebar-item-expand-arrow" />
      );
    }
  
    return (
      <>
        <ListItemButton
          className="sidebar-item"
          onClick={onClick}
          //dense
          {...rest}
          sx={{ display: !item.access.includes(rol) ? "none" : "" }}
        >
          <div
            style={{ paddingLeft: depth * depthStep }}
            className="sidebar-item-content"
          > 
          <ListItemIcon className='sidebar-item-icon' sx={{color:"primary.light"}}>{icon}</ListItemIcon>
          <ListItemText primary={<Typography variant="h6">{label}</Typography>} disableTypography sx={{fontWeight:700,fontSize:"2.5vh"}}/>
          </div>
          {expandIcon}
        </ListItemButton>
        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
          {Array.isArray(items) ? (
            <List disablePadding dense>
              {items.map((subItem, index) => (
                <React.Fragment key={`${subItem.name}${index}`}>
                  {subItem === "divider" ? (
                    <Divider style={{ margin: "6px 0" }} />
                  ) : (
                    <SidebarItem
                      depth={depth + 1}
                      depthStep={depthStep}
                      item={subItem}
                      rol={rol}
                    />
                  )}
                </React.Fragment>
              ))}
            </List>
          ) : null}
        </Collapse>
      </>
    );
  }


export function Sidebar({ items, depthStep, depth, expanded, rol }) {
    items=actionStructure
    const {open,toggleDrawer} = useNavContext()
    //console.log("rol en sidebar",rol)
    return (
        <SwipeableDrawer 
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)} 
        >
            <Box sx={{width:250}}>
                
                {/* cajita para que se vea mejor el menú, puede o no llevar texto */}
                <Box sx={{
                    color:"white",
                    backgroundColor:"primary.dark",
                    maxWidth:250,minHeight:"2vh",
                    maxHeight:"3vh",padding:3}}
                >
                    <Typography variant="h5"> Q-Soft</Typography>
                </Box>
                
                <List disablePadding dense>
                {items.map((sidebarItem, index) => (
                    <React.Fragment key={`${sidebarItem.name}${index}`}>
                    {sidebarItem === "divider" ? (
                        <Divider style={{ margin: "6px 0" }} />
                    ) : (
                        <SidebarItem
                        depthStep={depthStep}
                        depth={depth}
                        expanded={expanded}
                        item={sidebarItem}
                        rol={rol}
                        />
                    )}
                    </React.Fragment>
                ))}
                </List>
                </Box>
        </SwipeableDrawer>
    );
  }






/*
export function pastSideBar ({rol}) {
    const [openCollapse, setOpenCollapse] = useState({
        id:"",
        open:false
    })
    const [openNestedCollapse, setOpenNestedCollapse] = useState({
        id:"",
        open:false
    })
    
    const {toggleDrawer, open, setSelectedAction} = useNavContext()
    
    const navigate = useNavigate()
    
    const handleRedirect = (route, label) => {
        setSelectedAction(label)
        navigate(`/${rol}${route}`)
        toggleDrawer(false)
        
    }

    const handleEmployeesAction = () => {
        console.log("Haz algo")
    }

    const handleOpenCollapse = (Id) => {
        if (openCollapse.id === Id){
            setOpenCollapse({
                ...openCollapse,
                id:Id,
                open:!openCollapse.open
                
            })}
            else{
        setOpenCollapse({
            ...openCollapse,
            id:Id,
            open:true
            
        })
    }
}

    const handleOpenNestedCollapse = (Id) => {
        setOpenNestedCollapse({
            ...openNestedCollapse,
            id:Id,
            open:!openNestedCollapse.open
        })
    }


    
  return (
    <SwipeableDrawer
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
    >
        <Box sx={{width:250}} role="presentation">
            <List>
                {actionStructure.map((action, key) => {
                    return (
                        <ListItem key={key}>
                            {
                                !action.options
                                ?
                                    <ListItemButton
                                    key={key}
                                    startIcon={action.icon}
                                    onClick={() => handleRedirect(action.link, action.label)}
                                    sx={{color:"#487E91", display: !action.access.includes(rol) ? "none" : "" }}
                                    >
                                        <ListItemIcon>{action.icon}</ListItemIcon>
                                        <ListItemText primary={action.label} />
                                    </ListItemButton>
                                :
                                    <>
                                    {/* {<Box key={key} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>} }
                                        {/* //*Botón que despliega el collapse de las opciones de la action}
                                        <ListItemButton variant="outlined"
                                            key={key}
                                            id={action.id}
                                            startIcon={action.icon}
                                            endIcon={<ExpandLess />}
                                            onClick={() => handleOpenCollapse(action.id)}
                                            sx={{ textDecoration: "none", color: "#487E91", width: "100%", display: !action.access.includes(rol) ? "none" : "" }}
                                        >
                                            <ListItemIcon >{action.icon}</ListItemIcon>
                                            <ListItemText primary={action.label} />
                                        </ListItemButton>

                                        {/* //*Elementos de lista si el nodo tiene opciones; nodo = action }
                                        <Collapse in={openCollapse.id === action.id && openCollapse.open} unmountOnExit >
                                            {
                                                action.options.map((value, index) => {
                                                    return (
                                                        <>
                                                            {
                                                                value.options
                                                                ?
                                                                    <Box key={index} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                     <>   <ListItemButton
                                                                        variant="outlined"
                                                                        startIcon={value.icon}
                                                                        key={index}
                                                                        onClick={() => handleOpenNestedCollapse(value.id)}
                                                                        sx={{ display: !value.access.includes(rol) ? "none" : "" }}
                                                                        >
                                                                            <ListItemIcon >{value.icon}</ListItemIcon>
                                                                            <ListItemText primary={value.label} />
                                                                            {openNestedCollapse.id === value.id && openNestedCollapse.open ? <ExpandLess /> : <ExpandMore />}
                                                                        </ListItemButton>

                                                                        <Collapse in={openNestedCollapse.id === value.id && openNestedCollapse.open} unmountOnExit>
                                                                            {
                                                                                value.options.map((option, idx) => {
                                                                                    return (
                                                                                        <Box key={idx} sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                                                            <ListItemButton
                                                                                                key={idx}
                                                                                                startIcon={option.icon}
                                                                                                onClick={() => handleRedirect(option.link, option.label)}
                                                                                            >
                                                                                                <ListItemIcon>{option.icon}</ListItemIcon>
                                                                                                <ListItemText>{option.label}</ListItemText>
                                                                                            </ListItemButton>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Collapse>
                                                                        </>//</Box>
                                                                :
                                                                    <>
                                                                        <Box key={index} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                            <Button
                                                                            key={index}
                                                                            variant="filled"
                                                                            startIcon={value.icon}
                                                                            onClick={() => {handleRedirect(value.link, value.label)}}
                                                                            sx={{ textDecoration: "none", color: "#487E91", width: "100%", display: !value.access.includes(rol) ? "none" : "" }}
                                                                            >
                                                                                <ListItemText primary={value.label} />
                                                                            </Button>
                                                                        </Box>
                                                                    </>
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                    {/* </Box> }
                                </>
                            }
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    </SwipeableDrawer>
  );
            };*/
