import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button, InputAdornment, LinearProgress } from "@mui/material";
import { FormControl } from "@mui/material";
import { AlertDialog } from "../../../components/userFeedback/AlertDialog";
import { getPostalCodesCatalog } from "../../../services/modules/invoices/catalogs";
import useAuth from "../../../hooks/useAuth";

export default function CustomerAddressForm({
  type = "Cliente",
  setter = function test() {
    console.log("algo falló mijo");
  },
  typeHook = { error: "error en el hook de cliente" },
  multiple = true,
  label = null,
}) {
  /*
    This components accepts a " type " prop to determine whether the 
    solicited address is the main company one, a sub-company one or a 
    client's one
    Expected Values: ["Principal","Sub-Organización","Cliente"]
    */
  let addressType = type;
  let name = label !== null ? label : addressType;
  let setCustomerAddress = setter;
  let objectHook = typeHook;

  const { userid } = useAuth();

  const [address, setAddress] = React.useState({
    Type: addressType,
    CP: "",
    Street: "",
    Number: "",
    State: "",
    Locality: "",
    Municipality: "",
    Name: "",
  });

  const [loading, setLoading] = React.useState({
    location: false,
    state: false,
    municipality: false,
  });

  const [dialog, setDialog] = React.useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const handleCloseDialog = (boolean) => {
    setDialog({
      ...dialog,
      open: boolean,
    });
  };

  const [addresses, setAddresses] = React.useState([]);

  const handleAddAddress = () => {
    if (
      address.CP === "" ||
      address.Street === "" ||
      address.Number === "" ||
      address.State === "" ||
      address.Name === ""
    ) {
      setDialog((dialog) => {
        return {
          ...dialog,
          open: true,
          title: "Datos Faltantes",
          content: "Verifica que has ingresado todos los datos solicitados.",
          actions: [
            {
              label: "cerrar",
              execute: () => setDialog({ ...dialog, open: false }),
            },
          ],
        };
      });
    } else {
      const mappedAddress = {
        ...address,
        ZipCode: address.CP,
        Country: "México",
        Neighborhood: address.Locality,
        ExteriorNumber: address.Number,
      };
      delete mappedAddress.CP;

      addresses.push(mappedAddress);

      handleSaveAddresses();
      setAddress({
        Type: addressType,
        CP: "",
        Street: "",
        Number: "",
        State: "",
        Locality: "",
        Name: "",
      });
    }
  };

  const handleDeleteAddress = (address) => {
    console.log("antes", addresses);

    let arr = addresses.filter((adrs) => {
      return adrs.Name != address.Name;
    });
    setAddresses(arr);

    console.log("despues", addresses);
    handleSaveAddresses();
    console.log("tempArr", arr);
  };

  const handleSaveAddresses = () => {
    setCustomerAddress({ ...objectHook, Addresses: addresses });
  };

  const isValidLocation = (stringCode) => {
    return stringCode !== "" && stringCode !== "NULL";
  };

  const isValidMunicipioCode = (stringCode) => {
    return stringCode !== "" && stringCode !== "NULL";
  };

  const handleSetAddress = (e) => {
    setAddress({
      ...address,
      [e.target.id]: e.target.value,
    });
  };

  const showDirections = () => {
    return (
      <>
        <Typography variant="h6">Direcciones Añadidas</Typography>
        <Grid container spacing={2} sx={{ marginBottom: "0.6vh" }}>
          <Grid item xs={3}>
            <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
              Alias
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
              Dirección
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
              C. Postal
            </Typography>
          </Grid>
        </Grid>
        {addresses.map((address, id) => {
          return (
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "1vh", marginBottom: "1vh" }}
              key={id}
            >
              <Grid item xs={3}>
                {address.Name}
              </Grid>
              <Grid item xs={3}>
                {address.Street} {address.Number}
              </Grid>
              <Grid item xs={3}>
                {address.ZipCode}
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleDeleteAddress(address);
                  }}
                >
                  Eliminar
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  };

  React.useEffect(() => {
    if (!multiple) {
      const mappedAddress = {
        ...address,
        ZipCode: address.CP,
        Country: "México",
        Neighborhood: address.Locality,
        ExteriorNumber: address.Number,
        InteriorNumber: address.Number,
      };
      delete mappedAddress.CP;
      delete mappedAddress.Number;
      setter(mappedAddress);
    }
  }, [address]);

  React.useMemo(() => {
    if (address.CP.length > 3) {
      setLoading({
        ...loading,
        location: true,
        state: true,
        municipality: true,
      });
      getPostalCodesCatalog(userid, userid, address.CP).then((res) => {
        console.log(res);
        if (res.data.length === 1) {
          setLoading((ldng) => {
            return {
              ...ldng,
              location: false,
              state: false,
              municipality: false,
            };
          });

          setAddress((adr) => {
            return {
              ...adr,
              Municipality: "",
              Locality: "",
              State: res.data[0].StateCode,
            };
          });

          if (
            isValidLocation(res.data[0].LocationCode) &&
            isValidMunicipioCode(res.data[0].MunicipalityCode)
          ) {
            setAddress((adr) => {
              return {
                ...adr,
                Municipality: res.data[0].MunicipalityCode,
                Locality: res.data[0].LocationCode,
                State: res.data[0].StateCode,
              };
            });
            return;
          }

          if (isValidLocation(res.data[0].LocationCode)) {
            setAddress((adr) => {
              return {
                ...adr,
                Locality: res.data[0].LocationCode,
                State: res.data[0].StateCode,
              };
            });
            return;
          }

          if (isValidMunicipioCode(res.data[0].MunicipalityCode)) {
            setAddress((adr) => {
              return {
                ...adr,
                Municipality: res.data[0].MunicipalityCode,
                State: res.data[0].StateCode,
              };
            });
            return;
          }
        }
      });
    }
  }, [address.CP]);

  return (
    <React.Fragment>
      <AlertDialog
        updateOpenState={handleCloseDialog}
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />

      <Typography variant="h6" gutterBottom>
        Dirección {name}
      </Typography>
      <FormControl>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="Name"
              name="name"
              label="Alias"
              fullWidth
              autoComplete="Name"
              variant="standard"
              onChange={handleSetAddress}
              value={address.Name}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              required
              id="Street"
              name="street"
              label="Calle"
              fullWidth
              autoComplete="street"
              variant="standard"
              onChange={handleSetAddress}
              value={address.Street}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              required
              id="Number"
              name="number"
              label="Número exterior"
              fullWidth
              autoComplete="number"
              variant="standard"
              onChange={handleSetAddress}
              value={address.Number}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              required
              id="CP"
              name="cp"
              label="Código Postal"
              fullWidth
              autoComplete="88888"
              variant="standard"
              onChange={handleSetAddress}
              value={address.CP}
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              disabled
              id="State"
              name="state"
              label="Estado"
              fullWidth
              autoComplete="State"
              variant="standard"
              onChange={handleSetAddress}
              value={address.State}
              InputProps={{
                endAdornment: loading.state ? (
                  <InputAdornment position="end">
                    <LinearProgress />
                  </InputAdornment>
                ) : null,
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#333333",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  WebkitTextFillColor: "#757575",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              disabled
              id="Locality"
              name="locality"
              label="Localidad"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              onChange={handleSetAddress}
              value={address.Locality}
              InputProps={{
                endAdornment: loading.location ? (
                  <InputAdornment position="end">
                    <LinearProgress />
                  </InputAdornment>
                ) : null,
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#333333",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  WebkitTextFillColor: "#757575",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              disabled
              id="municipality"
              name="municipality"
              label="Municipio"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              onChange={handleSetAddress}
              value={address.Municipality}
              InputProps={{
                endAdornment: loading.municipality ? (
                  <InputAdornment position="end">
                    <LinearProgress />
                  </InputAdornment>
                ) : null,
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#333333",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  WebkitTextFillColor: "#757575",
                },
              }}
            />
          </Grid>

          {multiple && (
            <Grid item xs={6}>
              <Button variant="contained" onClick={handleAddAddress}>
                Añadir Dirección
              </Button>
            </Grid>
          )}
          {/*<Grid item xs={6}>
          <Button variant="contained" color="primary" onClick={handleSaveAddresses} disabled={addresses.length<1}>
            Guardar Direcciones
          </Button>
          </Grid>*/}
          <Grid item xs={12}>
            {addresses.length > 0 && showDirections()}
          </Grid>
        </Grid>
      </FormControl>
    </React.Fragment>
  );
}
