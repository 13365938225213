export const MexicoFederalEntityKeysCatalog = [
  {
    Name: "Aguascalientes",
    Value: "AGU",
  },
  {
    Name: "Baja California",
    Value: "BCN",
  },
  {
    Name: "Baja California Sur",
    Value: "BCS",
  },
  {
    Name: "Campeche",
    Value: "CAM",
  },
  {
    Name: "Chiapas",
    Value: "CHP",
  },
  {
    Name: "Ciudad de México",
    Value: "CMX",
  },
  {
    Name: "Chihuahua",
    Value: "CHH",
  },
  {
    Name: "Coahuila",
    Value: "COA",
  },
  {
    Name: "Colima",
    Value: "COL",
  },
  {
    Name: "Durango",
    Value: "DUR",
  },
  {
    Name: "Guanajuato",
    Value: "GUA",
  },
  {
    Name: "Guerrero",
    Value: "GRO",
  },
  {
    Name: "Hidalgo",
    Value: "HID",
  },
  {
    Name: "Jalisco",
    Value: "JAL",
  },
  {
    Name: "México",
    Value: "MEX",
  },
  {
    Name: "Michoacán",
    Value: "MIC",
  },
  {
    Name: "Morelos",
    Value: "MOR",
  },
  {
    Name: "Nayarit",
    Value: "NAY",
  },
  {
    Name: "Nuevo León",
    Value: "NLE",
  },
  {
    Name: "Oaxaca",
    Value: "OAX",
  },
  {
    Name: "Puebla",
    Value: "PUE",
  },
  {
    Name: "Querétaro",
    Value: "QUE",
  },
  {
    Name: "Quintana Roo",
    Value: "ROO",
  },
  {
    Name: "San Luis Potosí",
    Value: "SLP",
  },
  {
    Name: "Sinaloa",
    Value: "SIN",
  },
  {
    Name: "Sonora",
    Value: "SON",
  },
  {
    Name: "Tabasco",
    Value: "TAB",
  },
  {
    Name: "Tamaulipas",
    Value: "TAM",
  },
  {
    Name: "Tlaxcala",
    Value: "TLA",
  },
  {
    Name: "Veracruz",
    Value: "VER",
  },
  {
    Name: "Yucatán",
    Value: "YUC",
  },
  {
    Name: "Zacatecas",
    Value: "ZAC",
  },
];

export const PermitTypesCatalog = [
  {
    value: "TPAF01",
    label: "Autotransporte Federal de carga general.",
    transport: "01",
  },
  {
    value: "TPAF02",
    label: "Transporte privado de carga.",
    transport: "01",
  },
  {
    value: "TPAF03",
    label:
      "Autotransporte Federal de Carga Especializada de materiales y residuos peligrosos.",
    transport: "01",
  },
  {
    value: "TPAF04",
    label: "Transporte de automóviles sin rodar en vehículo tipo góndola.",
    transport: "01",
  },
  {
    value: "TPAF05",
    label:
      "Transporte de carga de gran peso y/o volumen de hasta 90 toneladas.",
    transport: "01",
  },
  {
    value: "TPAF06",
    label:
      "Transporte de carga especializada de gran peso y/o volumen de más 90 toneladas.",
    transport: "01",
  },
  {
    value: "TPAF07",
    label: "Transporte Privado de materiales y residuos peligrosos.",
    transport: "01",
  },
  {
    value: "TPAF08",
    label: "Autotransporte internacional de carga de largo recorrido.",
    transport: "01",
  },
  {
    value: "TPAF09",
    label:
      "Autotransporte internacional de carga especializada de materiales y residuos peligrosos de largo recorrido.",
    transport: "01",
  },
  {
    value: "TPAF10",
    label:
      "Autotransporte Federal de Carga General cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.",
    transport: "01",
  },
  {
    value: "TPAF11",
    label:
      "Autotransporte Federal de Carga Especializada cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.",
    transport: "01",
  },
  {
    value: "TPAF12",
    label:
      "Servicio auxiliar de arrastre en las vías generales de comunicación.",
    transport: "01",
  },
  {
    value: "TPAF13",
    label:
      "Servicio auxiliar de servicios de arrastre, arrastre y salvamento, y depósito de vehículos en las vías generales de comunicación.",
    transport: "01",
  },
  {
    value: "TPAF14",
    label:
      "Servicio de paquetería y mensajería en las vías generales de comunicación.",
    transport: "01",
  },
  {
    value: "TPAF15",
    label:
      "Transporte especial para el tránsito de grúas industriales con peso máximo de 90 toneladas.",
    transport: "01",
  },
  {
    value: "TPAF16",
    label:
      "Servicio federal para empresas arrendadoras servicio público federal.",
    transport: "01",
  },
  {
    value: "TPAF17",
    label: "Empresas trasladistas de vehículos nuevos.",
    transport: "01",
  },
  {
    value: "TPAF18",
    label: "Empresas fabricantes o distribuidoras de vehículos nuevos.",
    transport: "01",
  },
  {
    value: "TPAF19",
    label:
      "Autorización expresa para circular en los caminos y puentes de jurisdicción federal con configuraciones de tractocamión doblemente articulado.",
    transport: "01",
  },
  {
    value: "TPAF20",
    label: "Autotransporte Federal de Carga Especializada de fondos y valores.",
    transport: "01",
  },
];

export const TruckTypesCatalog = [
  {
    value: "VL",
    label:
      "Vehículo ligero de carga (2 llantas en el eje delantero y 2 llantas en el eje trasero)",
    axles: "02",
    wheels: "04",
    reqTrailers: "0, 1",
  },
  {
    value: "C2",
    label:
      "Camión Unitario (2 llantas en el eje delantero y 4 llantas en el eje trasero)",
    axles: "02",
    wheels: "06",
    reqTrailers: "0",
  },
  {
    value: "C3",
    label:
      "Camión Unitario (2 llantas en el eje delantero y 6 o 8 llantas en los dos ejes traseros)",
    axles: "03",
    wheels: "8 o 10",
    reqTrailers: "0",
  },
  {
    value: "C2R2",
    label: "Camión-Remolque (6 llantas en el camión y 8 llantas en remolque)",
    axles: "04",
    wheels: "14",
    reqTrailers: "1",
  },
  {
    value: "C3R2",
    label: "Camión-Remolque (10 llantas en el camión y 8 llantas en remolque)",
    axles: "05",
    wheels: "18",
    reqTrailers: "1",
  },
  {
    value: "C2R3",
    label: "Camión-Remolque (6 llantas en el camión y 12 llantas en remolque)",
    axles: "05",
    wheels: "18",
    reqTrailers: "1",
  },
  {
    value: "C3R3",
    label: "Camión-Remolque (10 llantas en el camión y 12 llantas en remolque)",
    transport: "06",
    wheels: "22",
    reqTrailers: "1",
  },
  {
    value: "T2S1",
    label:
      "Tractocamión Articulado (6 llantas en el tractocamión, 4 llantas en el semirremolque)",
    axles: "03",
    wheels: "10",
    reqTrailers: "1",
  },
  {
    value: "T2S2",
    label:
      "Tractocamión Articulado (6 llantas en el tractocamión, 8 llantas en el semirremolque)",
    axles: "04",
    wheels: "14",
    reqTrailers: "1",
  },
  {
    value: "T2S3",
    label:
      "Tractocamión Articulado (6 llantas en el tractocamión, 12 llantas en el semirremolque)",
    axles: "05",
    wheels: "18",
    reqTrailers: "1",
  },
  {
    value: "T3S1",
    label:
      "Tractocamión Articulado (10 llantas en el tractocamión, 4 llantas en el semirremolque)",
    axles: "04",
    wheels: "14",
    reqTrailers: "1",
  },
  {
    value: "T3S2",
    label:
      "Tractocamión Articulado (10 llantas en el tractocamión, 8 llantas en el semirremolque)",
    axles: "05",
    wheels: "18",
    reqTrailers: "1",
  },
  {
    value: "T3S3",
    label:
      "Tractocamión Articulado (10 llantas en el tractocamión, 12 llantas en el semirremolque)",
    axles: "06",
    wheels: "22",
    reqTrailers: "1",
  },
  {
    value: "T2S1R2",
    label:
      "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)",
    axles: "05",
    wheels: "18",
    reqTrailers: "1",
  },
  {
    value: "T2S2R2",
    label:
      "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)",
    axles: "06",
    wheels: "22",
    reqTrailers: "1",
  },
  {
    value: "T2S1R3",
    label:
      "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)",
    axles: "06",
    wheels: "22",
    reqTrailers: "1",
  },
  {
    value: "T3S1R2",
    label:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)",
    axles: "06",
    wheels: "22",
    reqTrailers: "1",
  },
  {
    value: "T3S1R3",
    label:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)",
    axles: "07",
    wheels: "26",
    reqTrailers: "1",
  },
  {
    value: "T3S2R2",
    label:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)",
    axles: "07",
    wheels: "26",
    reqTrailers: "1",
  },
  {
    value: "T3S2R3",
    label:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 12 llantas en el remolque)",
    axles: "08",
    wheels: "30",
    reqTrailers: "1",
  },
  {
    value: "T3S2R4",
    label:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 16 llantas en el remolque)",
    axles: "09",
    wheels: "34",
    reqTrailers: "1",
  },
  {
    value: "T2S2S2",
    label:
      "Tractocamión Semirremolque-Semirremolque (6 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
    axles: "06",
    wheels: "22",
    reqTrailers: "1",
  },
  {
    value: "T3S2S2",
    label:
      "Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
    axles: "07",
    wheels: "26",
    reqTrailers: "1",
  },
  {
    value: "T3S3S2",
    label:
      "Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 12 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
    axles: "08",
    wheels: "30",
    reqTrailers: "1",
  },
];

export const TowCarTypesCatalog = [
  {
    value: "CTR001",
    label: "Caballete",
  },
  {
    value: "CTR002",
    label: "Caja",
  },
  {
    value: "CTR003",
    label: "Caja Abierta",
  },
  {
    value: "CTR004",
    label: "Caja Cerrada",
  },
  {
    value: "CTR005",
    label: "Caja De Recolección Con Cargador Frontal",
  },
  {
    value: "CTR006",
    label: "Caja Refrigerada",
  },
  {
    value: "CTR007",
    label: "Caja Seca",
  },
  {
    value: "CTR008",
    label: "Caja Transferencia",
  },
  {
    value: "CTR009",
    label: "Cama Baja o Cuello Ganso",
  },
  {
    value: "CTR010",
    label: "Chasis Portacontenedor",
  },
  {
    value: "CTR011",
    label: "Convencional De Chasis",
  },
  {
    value: "CTR012",
    label: "Equipo Especial",
  },
  {
    value: "CTR013",
    label: "Estacas",
  },
  {
    value: "CTR014",
    label: "Góndola Madrina",
  },
  {
    value: "CTR015",
    label: "Grúa Industrial",
  },
  {
    value: "CTR016",
    label: "Grúa",
  },
  {
    value: "CTR017",
    label: "Integral",
  },
  {
    value: "CTR018",
    label: "Jaula",
  },
  {
    value: "CTR019",
    label: "Media Redila",
  },
  {
    value: "CTR020",
    label: "Pallet o Celdillas",
  },
  {
    value: "CTR021",
    label: "Plataforma",
  },
  {
    value: "CTR022",
    label: "Plataforma Con Grúa",
  },
  {
    value: "CTR023",
    label: "Plataforma Encortinada",
  },
  {
    value: "CTR024",
    label: "Redilas",
  },
  {
    value: "CTR025",
    label: "Refrigerador",
  },
  {
    value: "CTR026",
    label: "Revolvedora",
  },
  {
    value: "CTR027",
    label: "Semicaja",
  },
  {
    value: "CTR028",
    label: "Tanque",
  },
  {
    value: "CTR029",
    label: "Tolva",
  },
  {
    value: "CTR031",
    label: "Volteo",
  },
  {
    value: "CTR032",
    label: "Volteo Desmontable",
  },
];
