import React,{useEffect, useState} from 'react'
//*MATERIAL UI COMPONENTS
import { Box } from '@mui/system'
import { Alert, Button, CircularProgress, Container, Input, Paper, Typography } from '@mui/material'
import { CheckCircleOutlineOutlined, FileUploadSharp } from '@mui/icons-material'
//*Componentes APP y API
import { parseXMLCartaPorte } from '../../../services/modules/invoices/invoices'
import useAuth from '../../../hooks/useAuth'
let XMLinvoice={}
let version = 1;

const UploadCartaPorte = () => {
    const {userid} = useAuth()

    
    const [selectedFile, setSelectedFile] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const handleChange =  (e) => {
        if(e.target.files[0]){
            setSelectedFile(e.target.files[0])
        }
    }
    
    const formData = new FormData()
    const renderProcess = async () => {
        setLoading(true)
        
        formData.append(
            "complemento",
            selectedFile
        )

        //*Envío de datos a través de axios
        try {
            const response = await parseXMLCartaPorte(userid, userid, "valder", formData)

            if (response.status !== 200){
                setError(true)
                return
            }
            
            XMLinvoice = response.data
            setLoading(false)
        } catch(err) {
            console.log(err)
            setError(true)
        } 
                
        return XMLinvoice
    }

    return <>
            <Typography variant="h5" sx={{textAlign:"center", margin:"2%"}}>Selecciona el complemento Carta Porte en formato XML</Typography>
            <Box 
            sx={
                {
                    display:"flex",
                    alignItems:"center", 
                    justifyContent:"center"
                }
            }>
                {/* //*Version carta porte */}
                <label style={{margin:"1.2vh"}} htmlFor="xmlUpload">
                    <Input onChange={handleChange} sx={{display:"none"}}  id="xmlUpload" type="file" />
                    <Button disabled={loading} sx={{background:"green", color:"white"}} variant="contained" component="span"> Buscar </Button>
                </label>

            </Box>
            {
                selectedFile !== undefined
                ?
                <Box 
                    sx={{
                            display:"flex",
                            alignItems:"center", 
                            justifyContent:"center"
                        }}
                >
                    <Button id="xml" disabled={loading} color={Object.keys(XMLinvoice).length>0 ? "success":"primary"} sx={{margin:"1.2vh"}} onClick={renderProcess} variant="contained">
                        {Object.keys(XMLinvoice).length>0 ? <CheckCircleOutlineOutlined/> : "Subir archivo"}
                    </Button>
                </Box>
                :
                null
            }

            {
                loading
                ?
                <>
                    <CircularProgress/>
                    <Typography variant="body2" sx={{fontSize:"2vh"}}>Cargando...</Typography>
                </>
                :
                null
            }

            {
                error
                ?
                <Alert severity='error' action={<Button color='inherit' onClick={() => window.location.reload()}>Reintentar</Button>}>Error procesando el archivo</Alert>
                :
                null
            }
        </>

    
}

function FileUploadButton() {
    const [fileName, setFileName] = useState('');

    const {userid} = useAuth()

    
    const [selectedFile, setSelectedFile] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)


    const handleChange =  (e) => {
        if(e.target.files[0]){
            setFileName(e.target.files[0].name);
            setSelectedFile(e.target.files[0])
            
            
        }
        
    }
    
    const formData = new FormData()
    const renderProcess = async () => {
        setLoading(true)
        
        formData.append(
            "complemento",
            selectedFile
        )

        //*Envío de datos a través de axios
        try {
            const response = await parseXMLCartaPorte(userid, userid, "valder", formData)

            if (response.status !== 200){
                setError(true)
                return
            }
            
            XMLinvoice = response.data
            setLoading(false)
        } catch(err) {
            console.log(err)
            setError(true)
        } 
                
        return XMLinvoice
    }
  
    return (
      <>
        <input
          accept="*.xml"
          id="contained-button-file"
          type="file"
          onChange={handleChange}
          style={{display:"none"}}
        />
        <label htmlFor="contained-button-file">
          <Button color="warning" variant="contained" sx={{color:"white", padding:"1em" ,display:(Object.keys(XMLinvoice).length>0 ? "none":"flex")}} component="span" >
            {fileName ? fileName: <FileUploadSharp/>}
          </Button>
        </label>
         <Button id="xml" disabled={loading || (selectedFile === undefined)} color={Object.keys(XMLinvoice).length>0 ? "success":"primary"} sx={{margin:"1.2vh"}} onClick={selectedFile !== undefined ? renderProcess : null} variant="contained">
            {Object.keys(XMLinvoice).length>0 ? <CheckCircleOutlineOutlined/> : "Subir archivo"}
        </Button>
      </>
    );
  }

export {XMLinvoice, version, UploadCartaPorte, FileUploadButton}