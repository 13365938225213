import { DataGrid } from "@mui/x-data-grid";
import ferbyTheme from "../../../../theme/ferbyTheme";
import { useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import { getInvoiceFiles } from "../../../../services/modules/invoices/invoices";
import { PreviewInvoice } from "../../../../components/Previews/PreviewTables";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
} from "@mui/material";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DashboardDataGrid = ({
  rows,
  columns,
  max,
  locked = false,
  height = 300,
}) => {
  const MAX_RESULTS = max || 5;
  const limitedData = rows.slice(0, MAX_RESULTS);

  return (
    <div style={{ height: height, width: "100%" }}>
      <DataGrid
        sx={{
          "& .header-table": {
            backgroundColor: ferbyTheme.palette.primary.main,
            color: "white",
          },
        }}
        getRowHeight={() => "auto"}
        rows={limitedData}
        columns={columns}
        pageSize={MAX_RESULTS}
        hideFooter={locked}
        disableSelectionOnClick={locked}
        disableColumnMenu={locked}
        disableColumnSelector={locked}
        disableDensitySelector={locked}
        disableExtendRowFullWidth={locked}
        disableColumnReorder={locked}
      />
    </div>
  );
};

const RenderActions = (props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfSource, setPdfSource] = useState("");

  const { userid } = useAuth();

  const { dialog, setDialog, rawRows } = props;

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleOpen = () => {
    setOpen(true);
    if (props.data.row.status !== "unissued") {
      setLoading(true);
      console.log("pdf to get", props.data.row);
      getInvoiceFiles(userid, userid, "pdf", props.data.row.invoiceId)
        .then((data) => {
          const binary = atob(data.data.pdf.replace(/\s/g, ""));
          const buffer = new ArrayBuffer(binary.length);
          const view = new Uint8Array(buffer);

          for (let i = 0; i < binary.length; i++) {
            view[i] = binary.charCodeAt(i);
          }

          const blob = new Blob([view], { type: "application/pdf" });
          const source = window.URL.createObjectURL(blob);
          setPdfSource(source);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setOpen(false);
          setDialog({
            ...dialog,
            open: true,
            title: "Algo ha fallado",
            content: "Hubo un error al obtener la factura",
            actions: [
              {
                label: "Ok",
                execute: () =>
                  setDialog({
                    ...dialog,
                    open: false,
                  }),
              },
            ],
          });
          console.error("Error fetching PDF data:", error);
        });
    } else {
      setLoading(false);
      setOpen(false);
      setDialog({
        ...dialog,
        open: true,
        title: "La factura no se ha emitido",
        content: "La factura fue creada pero no se ha emitido",
        actions: [
          {
            label: "Ok",
            execute: () =>
              setDialog({
                ...dialog,
                open: false,
              }),
          },
        ],
      });
    }
  };

  const [wantsPreview, setWantsPreview] = useState(false);
  const [previewCfdi, setPreviewCfdi] = useState({});
  const findObjectById = (id) => {
    const foundObject = rawRows.find((obj) => obj._id === id);
    return JSON.stringify(foundObject) || null; // Return null if not found
  };
  const handlePreview = () => {
    let pvObject = findObjectById(props.data.row.id);
    console.log("pvObject", pvObject);
    setPreviewCfdi(pvObject);
    setWantsPreview(true);
  };

  const handleClosePreview = () => {
    console.log("cerrando");
    setWantsPreview(false);
    setPreviewCfdi({}); // Reset the previewCfdi when closing the Modal
    setOpen(false)
  };

  const handleClose = () => {
    setOpen(false);
    setPdfSource("");
  };

  return (
    <div>
      <Dialog open={dialog.open} onClose={handleCloseDialog}>
        <DialogTitle>{dialog.title}</DialogTitle>

        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
      <Modal open={open} onClose={handleClose} sx={{ height: "85%" }}>
        <Box sx={{ width: "auto", height: "90%", mx: "auto", my: "auto" }}>
          <Container sx={{ height: "100%" }}>
            {pdfSource ? (
              <embed
                src={pdfSource}
                width="100%"
                height="100%"
                type="application/pdf"
                controls={false}
              />
            ) : (
              <div>Loading PDF...</div>
            )}
          </Container>
        </Box>
      </Modal>

      <Modal
        open={wantsPreview}
        onClose={handleClosePreview}
        sx={{ height: "85%" }}
      >
        {previewCfdi ? (
          <PreviewInvoice json={previewCfdi} onClose={handleClosePreview} />
        ) : (
          <div>Loading CFDI...</div>
        )}
      </Modal>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ display: "flex" }}>
            {props.data.row.status !== "unissued" && (
              <IconButton onClick={handleOpen}>
                <PictureAsPdfIcon />
              </IconButton>
            )}

            {/*Preview */}
            <IconButton onClick={handlePreview}>
              <VisibilityIcon />
            </IconButton>
            {/*<IconButton onClick={handleDownloadXML}>
                <CodeIcon />
        </IconButton>*/}
          </Box>
        </>
      )}
    </div>
  );
};

const getDashboardMappedRows = (data) => {
  const modArr = data.map((item, index) => {
    const {
      GeneralModel: {
        Issuer: {
          Name: emisorName,
          Rfc: emisorRfc,
          FiscalRegime: emisorRegistration,
        },
        Receiver: {
          Name: receptorName,
          Rfc: receptorRfc,
          FiscalRegime: receptorRegistration,
        },
      },
      status,
      uuid,
      invoiceId,
    } = item;

    //console.log("item example", item);

    return {
      id: item._id,
      emisorName,
      emisorRfc,
      emisorRegistration,
      receptorName,
      receptorRfc,
      receptorRegistration,
      status,
      uuid,
      invoiceId,
    };
  });

  return modArr;
};

export { DashboardDataGrid, getDashboardMappedRows, RenderActions };
