import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import UserForm from "./UserForm";
import OrganizationForm from "./OrganizationForm";
import {
  mapSignUpData,
  registerRootAndOrg,
  validateFiscalData,
} from "../../services/signupAndLogin";
import { useNavigate } from "react-router";
import ferbyTheme from "../../theme/ferbyTheme";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Q-soft
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = ["Create User", "Create Organization"];

const theme = ferbyTheme;

export default function FirstStepsForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [alert, setAlert] = React.useState({
    open: false,
    status: "info",
    title: "",
    slideMessage: "",
    slide: false,
  });
  // const [validatedFiscal, setValidatedFiscal] = React.useState(false)
  const [signUpData, setSignUpData] = React.useState({});
  const navigate = useNavigate();

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <UserForm setState={setSignUpData} state={signUpData} />;
      case 1:
        return <OrganizationForm setState={setSignUpData} state={signUpData} />;
      default:
        throw new Error("Unknown step");
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSignUp = async () => {
    const mappedSignup = mapSignUpData(signUpData);

    validateFiscalData(mappedSignup.organization)
      .then((result) => {
        registerRootAndOrg(mappedSignup).then((response) => {
          if (response.status === 201) {
            setAlert({
              ...alert,
              open: true,
              slide: false,
              status: "error",
              message:
                "Cuenta creada con exito. Verifica tu correo electrónico.",
              slideMessage: "",
            });
            navigate("/");
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          ...alert,
          open: false,
          slide: true,
          status: "error",
          slideMessage: "Error creando tu cuenta",
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Configura tu cuenta
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #2001539. We have emailed your order
                  confirmation, and will send you an update when your order has
                  shipped.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Regresar
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={
                      activeStep === steps.length - 1
                        ? handleSignUp
                        : handleNext
                    }
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1
                      ? "Registrar"
                      : "Siguiente"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
