import React, { useState, useRef, useEffect } from "react";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { getPostalCodesCatalog } from "../../../services/modules/invoices/catalogs";
import useAuth from "../../../hooks/useAuth";
import { validateRfc } from "../../../modules/invoices/utils/regexp";

let locationsData = [];
let locationsObjects = [
  {
    TipoUbicacion: "Origen",
    RFCRemitenteDestinatario: "",
    FechaHoraSalidaLlegada: "",
    Domicilio: {
      Calle: "",
      Pais: "MEX",
      CodigoPostal: "",
    },
  },
  {
    TipoUbicacion: "Destino",
    RFCRemitenteDestinatario: "",
    FechaHoraSalidaLlegada: "",
    DistanciaRecorrida: "",
    Domicilio: {
      Calle: "",
      Pais: "MEX",
      CodigoPostal: "",
    },
  },
];

const Locations = ({ props }) => {
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));
  locationsObjects = invoiceJson.Complementos.CartaPorte20.Ubicaciones;
  const { userid } = useAuth();
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(false);
  const [errorEmpty, setErrorEmpty] = useState([]);
  const [succes, setSuccess] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  //**Sólo cuando se esté modificando los datos del XML es necesario saber si esta modificando inputs por la fecha */
  const [state, setState] = useState({
    CodigoPostal: locationsObjects[activeStep].Domicilio.CodigoPostal * 1 || "",
    pais: locationsObjects[activeStep].Domicilio.Pais || "MEX",
    estado: locationsObjects[activeStep].Domicilio.Estado || "",
    localidad: locationsObjects[activeStep].Domicilio.Localidad || "",
    municipio: locationsObjects[activeStep].Domicilio.Municipio || "",
    calle: locationsObjects[activeStep].Domicilio.Calle || "",
    Rfc: locationsObjects[activeStep].RFCRemitenteDestinatario || "",
    distance: locationsObjects[activeStep].DistanciaRecorrida || 0,
  });

  const handleLocations = (id, value) => {
    const idsStates = {
      CodigoPostal: () => {
        //since textfield type="number" doesnt work with inputProps maxLength,
        //we need to restrict it's length with a number
        if (value < 100000) {
          setState({
            ...state,
            CodigoPostal: value,
          });
        }
      },
      RFCRemitenteDestinatario: () => {
        setState({
          ...state,
          Rfc: value.toUpperCase(),
        });
        if (validateRfc(value.toUpperCase())) {
          if (errorEmpty.includes("invalidRfcFormat")) {
            let tempArr = errorEmpty.filter(
              (item) => item !== "invalidRfcFormat"
            );
            setErrorEmpty(tempArr);
          }
        } else {
          if (!errorEmpty.includes("invalidRfcFormat")) {
            setErrorEmpty([...errorEmpty, "invalidRfcFormat"]);
          }
        }
      },
      Calle: () =>
        setState({
          ...state,
          calle: value,
        }),
      DistanciaRecorrida: () =>
        setState({
          ...state,
          distance: value,
        }),
      FechaHoraSalidaLlegada: () => console.log("Setting date"),
    };

    if (Object.keys(locationsObjects[activeStep].Domicilio).includes(id)) {
      if (id === "CodigoPostal") {
        locationsObjects[activeStep].Domicilio[id] = value * 1;
      } else {
        locationsObjects[activeStep].Domicilio[id] = value;
      }
    } else {
      if (id === " RFCRemitenteDestinatario") {
        locationsObjects[activeStep][id] = value.toUpperCase();
      } else {
        locationsObjects[activeStep][id] = value;
      }
    }

    idsStates[id]();
    invoiceJson.Complementos.CartaPorte20.Ubicaciones[activeStep] =
      locationsObjects[activeStep];
    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
  };

  const searchEmpty = (object) => {
    const paths = [];
    const loopObject = (obj) => {
      for (const [key, value] of Object.entries(obj)) {
        if (key === "IDUbicacion" || key === "Colonia") {
          continue;
        }

        if (value === "" || value === undefined || value === null) {
          paths.push(key);
        }

        if (typeof value === "object") {
          loopObject(value);
        }
      }
    };

    loopObject(object);

    return paths;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();

    console.log(locationsObjects[activeStep]);
    let empty = searchEmpty(locationsObjects[activeStep]);

    if (empty.length === 0) {
      if (activeStep === 1) {
        setSuccess(true);
        setAlertMessage("Ubicaciones completadas, pasa al siguiente paso");
        setDisableSubmit(true);
        props.setComplete(true);
        return;
      }

      setActiveStep(activeStep + 1);
      setError(false);
    } else {
      setError(true);
      setAlertMessage(empty);
      setErrorEmpty(empty);
      props.setComplete(false);
    }
  };

  const toIsoHelper = (date) => {
    var pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    };

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds())
    );
  };

  const validateAndSetLocationsFromResponse = (res) => {
    const isValidLocation = (stringCode) => {
      return stringCode !== "" && stringCode !== "NULL";
    };

    const isValidMunicipioCode = (stringCode) => {
      return stringCode !== "" && stringCode !== "NULL";
    };

    setState((state) => {
      return {
        ...state,
        estado: res.data[0].StateCode,
        localidad: "",
        municipio: "",
      };
    });
    locationsObjects[activeStep].Domicilio.Estado = res.data[0].StateCode;
    invoiceJson.Complementos.CartaPorte20.Ubicaciones[activeStep] =
      locationsObjects[activeStep];
    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

    if (
      isValidLocation(res.data[0].LocationCode) &&
      isValidMunicipioCode(res.data[0].MunicipalityCode)
    ) {
      setState((state) => {
        return {
          ...state,
          localidad: res.data[0].LocationCode,
          municipio: res.data[0].MunicipalityCode,
        };
      });

      locationsObjects[activeStep].Domicilio.Localidad =
        res.data[0].LocationCode;
      locationsObjects[activeStep].Domicilio.Municipio =
        res.data[0].MunicipalityCode;

      invoiceJson.Complementos.CartaPorte20.Ubicaciones[activeStep] =
        locationsObjects[activeStep];
      console.log(
        "Values mapped and assigned to invoice model, saving in localStorage..."
      );
      console.log(locationsObjects[activeStep]);
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
      return;
    }

    if (isValidLocation(res.data[0].LocationCode)) {
      setState((state) => {
        return {
          ...state,
          localidad: res.data[0].LocationCode,
        };
      });

      locationsObjects[activeStep].Domicilio.Localidad =
        res.data[0].LocationCode;
      delete locationsObjects[activeStep].Domicilio.Municipio;
      console.log(locationsObjects[activeStep]);

      invoiceJson.Complementos.CartaPorte20.Ubicaciones[activeStep] =
        locationsObjects[activeStep];
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
      return;
    }

    if (isValidMunicipioCode(res.data[0].MunicipalityCode)) {
      setState((state) => {
        return {
          ...state,
          municipio: res.data[0].MunicipalityCode,
        };
      });

      locationsObjects[activeStep].Domicilio.Municipio =
        res.data[0].MunicipalityCode;
      delete locationsObjects[activeStep].Domicilio.Localidad;

      invoiceJson.Complementos.CartaPorte20.Ubicaciones[activeStep] =
        locationsObjects[activeStep];
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
      return;
    }

    delete locationsObjects[activeStep].Domicilio.Localidad;
    delete locationsObjects[activeStep].Domicilio.Municipio;
    invoiceJson.Complementos.CartaPorte20.Ubicaciones[activeStep] =
      locationsObjects[activeStep];
    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
  };

  useEffect(() => {
    if (state.CodigoPostal.length > 3) {
      setLoading(true);
      getPostalCodesCatalog(userid, userid, state.CodigoPostal)
        .then((result) => {
          if (result.data.length === 1) {
            validateAndSetLocationsFromResponse(result);
          }
          setLoading(false);
        })
        .catch((err) => {
          setAlertMessage("Error obteniendo catálogo de códigos postales");
          setLoading(false);
        });
    }
  }, [state.CodigoPostal]);

  const useDidUpdate = (fn, inputs) => {
    const didMountRef = useRef(false);

    useEffect(() => {
      if (didMountRef.current) {
        return fn();
      }

      didMountRef.current = true;
      return fn(true);
    }, inputs);
  };

  useEffect(() => {
    if (props.hasTried) {
      let empty = searchEmpty(locationsObjects[activeStep]);

      if (empty.length === 0) {
        if (activeStep === 1) {
          setSuccess(true);
          setAlertMessage("Ubicaciones completadas, pasa al siguiente paso");
          setDisableSubmit(true);
          props.setComplete(true);
          return;
        }

        setActiveStep(activeStep + 1);
        setError(false);
      } else {
        setError(true);
        setAlertMessage(empty);
        setErrorEmpty(empty);
        props.setComplete(false);
      }
    }
  }, [props.hasTried]);
  const updateDataOnLocationsAfterFirstRender = () => {
    setState((state) => {
      return {
        ...state,
        CodigoPostal: locationsObjects[activeStep].Domicilio.CodigoPostal,
        municipio: locationsObjects[activeStep].Domicilio.Municipio,
        localidad: locationsObjects[activeStep].Domicilio.Localidad,
        calle: locationsObjects[activeStep].Domicilio.Calle,
        distance: locationsObjects[activeStep].DistanciaRecorrida || 0,
        Rfc: locationsObjects[activeStep].RFCRemitenteDestinatario,
      };
    });

    setLoading(true);
    getPostalCodesCatalog(
      userid,
      userid,
      locationsObjects[activeStep].Domicilio.CodigoPostal
    )
      .then((res) => {
        if (res.data.length === 1) {
          validateAndSetLocationsFromResponse(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        setAlertMessage("Error obteniendo catálogo de códigos postales");
        setLoading(false);
      });
  };

  useDidUpdate(
    (dflt) => {
      if (
        dflt &&
        state.CodigoPostal !== "" &&
        locationsObjects[activeStep].Domicilio.Localidad === "" &&
        locationsObjects[activeStep].Domicilio.Municipio === ""
      ) {
        setLoading(true);
        console.log("State comes with default values");
        getPostalCodesCatalog(
          userid,
          userid,
          locationsObjects[activeStep].Domicilio.CodigoPostal
        )
          .then((res) => {
            if (res.data.length === 1) {
              validateAndSetLocationsFromResponse(res);
            }
            setLoading(false);
          })
          .catch((err) => {
            setAlertMessage("Error obteniendo catálogo de códigos postales");
            setLoading(false);
          });

        return;
      }

      if (state.CodigoPostal !== "") {
        //*UPDATE AFTER FIRST RENDER THE TEXTFIELDS to the default values, if there is one. ONLY IF THERE IS A CHANGE IN ACTIVESTEP WICH MUST HAPPEN WHEN ACCEPTS THE ORIGIN location[0] LOCATION
        updateDataOnLocationsAfterFirstRender();
      }
    },
    [activeStep]
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {error ? (
        <Alert severity="error">
          No se ha recibido {`${alertMessage}`}, favor de completar todos los
          datos, si no aparece un campo para su dato, contacte a soporte.
        </Alert>
      ) : null}
      {error && alertMessage ? (
        <Alert severity="warning">{`${alertMessage}`}</Alert>
      ) : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid
        container
        sx={{ marginY: "5vh" }}
        maxWidth={"md"}
        textAlign="center"
      >
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ fontSize: "4vh", fontWeight: "bold" }}>
            Ubicación de {`${locationsObjects[activeStep].TipoUbicacion}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ fontSize: "2vh", fontWeight: "bold" }}
          >
            Domicilio
          </Typography>
        </Grid>
      </Grid>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: { xs: "column", sm: "row" },
          marginY: "5vh",
        }}
        maxWidth={"md"}
      >
        <Grid
          container
          spacing={2}
          paddingX={"3vh"}
          alignSelf="center"
          justifycontent="center"
          textAlign="center"
        >
          <Grid item xs={6} md={6}>
            <TextField
              sx={{ minWidth: "100%" }}
              label={`RFC de ${locationsObjects[
                activeStep
              ].TipoUbicacion.toLowerCase()}`}
              variant="outlined"
              autoCapitalize="characters"
              inputProps={{
                maxLength: 13,
              }}
              error={
                errorEmpty.includes("RFCRemitenteDestinatario") ||
                errorEmpty.includes("invalidRfcFormat")
              }
              id="RFCRemitenteDestinatario"
              value={state.Rfc}
              onChange={(e) => handleLocations(e.target.id, e.target.value)}
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextField
              sx={{ minWidth: "100%" }}
              variant="outlined"
              id="Calle"
              error={errorEmpty.includes("Calle")}
              label={`Calle de ${locationsObjects[
                activeStep
              ].TipoUbicacion.toLowerCase()}`}
              value={state.calle}
              onChange={(e) => handleLocations(e.target.id, e.target.value)}
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextField
              sx={{ minWidth: "100%" }}
              label="Código postal"
              variant="outlined"
              id="CodigoPostal"
              error={
                errorEmpty.includes("CodigoPostal") &&
                (state.CodigoPostal !== "" || state.CodigoPostal !== 0)
              }
              type="number"
              inputProps={{
                style: {
                  "&:invalid": {
                    border: "red solid 2px !important",
                  },
                },
              }}
              value={state.CodigoPostal}
              onChange={(e) => handleLocations(e.target.id, e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              sx={{ minWidth: "100%" }}
              disabled
              label="Localidad"
              variant="outlined"
              id="Localidad"
              value={state.localidad}
              onChange={(e) => handleLocations(e.target.id, e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              sx={{ minWidth: "100%" }}
              label="Estado"
              value={state.estado}
              id="Estado"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              sx={{ minWidth: "100%" }}
              label="Municipio"
              value={state.municipio}
              id="Municipio"
            />
          </Grid>

          <Grid item xs={12}>
            {locationsObjects[activeStep].TipoUbicacion === "Destino" ? (
              <TextField
                sx={{ minWidth: "100%" }}
                label="Distancia recorrida"
                id="DistanciaRecorrida"
                error={errorEmpty.includes("DistanciaRecorrida")}
                value={state.distance}
                onChange={(e) => handleLocations(e.target.id, e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">KM</InputAdornment>
                  ),
                }}
              />
            ) : null}
          </Grid>
        </Grid>

        <Grid
          container
          maxWidth={{ xs: "100%", sm: "33.33%" }}
          spacing={2}
          padding={"3vh"}
          alignSelf="center"
          justifycontent="center"
          textAlign="center"
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                id="d_date"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    error={errorEmpty.includes("FechaHoraSalidaLlegada")}
                  />
                )}
                label="Fecha y hora"
                value={
                  new Date(
                    locationsObjects[activeStep]?.FechaHoraSalidaLlegada
                  ) || date
                }
                onChange={(newValue) => {
                  //*Esto es necesario porque al convertir el valor de la fecha en ISO, cambia la zona horaria por automatico
                  let dateFormated = toIsoHelper(new Date(newValue));
                  handleLocations("FechaHoraSalidaLlegada", dateFormated);
                  setDate(newValue);
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              disabled={disableSubmit}
              color="success"
              sx={{ width: "66%", height: "100%" }}
              type="submit"
            >
              Completar
            </Button>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>

      {alertMessage && succes ? (
        <Alert severity="success">{`${alertMessage}`}</Alert>
      ) : null}
    </Box>
  );
};

export { Locations, locationsData };
