import { RedirectComponent } from "./components/navigation/RedirectComponent";
import { CreateCustomer } from "./modules/customers/CreateCustomer/CreateCustomer";
import { InvoiceGeneralData } from "./modules/invoices/issue/invoiceData/InvoiceGeneralData";
import { InvoicesIndex } from "./modules/invoices/InvoicesIndex";
import { InvoiceSteps } from "./modules/invoices/issue/InvoiceSteps";
import { ConsultIssuers } from "./modules/invoices/submodules/issuers/consult/ConsultIssuers";
import { PaymentFormContainer } from "./views/landingPage/Checkout";
import { NominaSingleEmployee } from "./modules/invoices/issue/invoiceData/Nomina/NominaSingleEmployee";
import { EmployeesNominaIndex } from "./modules/invoices/issue/invoiceData/Nomina/EmployeesNominaIndex";
import { NominaLibre } from "./modules/invoices/issue/invoiceData/Nomina/NominaLibre";
import { UploadEmployees } from "./modules/admin/employees/UploadEmployees";
import { EmployeesIndex } from "./modules/admin/employees/EmployeesIndex";
import { UpdateCsds } from "./modules/invoices/submodules/issuers/New/UpdateIssuerCsd";
import { ExpeditionPlaceForm } from "./components/Forms/Places/ExpeditionPlaceForm";
import { NewService } from "./modules/services/NewService";
import { DashboardIndex } from "./modules/admin/dashboard/DashboardIndex";
import EmployeeRegistrationForm from "./modules/admin/employees/CreateEmployee";
import IssuerData from "./modules/invoices/submodules/issuers/New/Issuer";
import CustomersIndex from "./modules/customers/CustomersIndex";
import ConsultInvoiceIndex from "./modules/invoices/submodules/consult/ConsultInvoiceIndex";
import SelectIssuer from "./modules/invoices/submodules/issuers/SelectIssuer";
import FirstStepsForm from "./modules/registration/FirstStepsForm";
import AdminServiceIndex from "./modules/services/ServiceIndex";
import RegisterIndex from "./modules/invoices/submodules/issuers/RegisterIndex";
import AdminRegisterTransports from "./modules/admin/trucksControl/AdminRegisterTransports";
import TransportsIndex from "./modules/admin/trucksControl/TransportsIndex";
import AdminRegisterRemolques from "./modules/admin/trucksControl/AdminRegisterRemolques";
import InvoiceDashboard from "./modules/invoices/dashboard/InvoiceDashboard";
import DestinationsIndex from "./modules/services/destinations/DestinationsIndex";
import CreateDestination from "./components/Forms/Places/Routes/CreateDestination";
import ExpeditionPlacesIndex from "./modules/invoices/submodules/issuers/consult/ConsultExpeditionPlaces";

export const privateRoutes = [
  {
    path: "/:rol/welcome",
    element: <InvoiceDashboard />,
  },
  {
    path: "/:rol/checkout",
    element: <PaymentFormContainer />,
  },
  {
    path: "/:rol/order/",
    element: <RedirectComponent />,
  },
  {
    path: "/:rol/businessData",
    element: <DashboardIndex />,
  },
  {
    path: "/:rol/issuers/places",
    element: <ExpeditionPlacesIndex />,
  },
  {
    path: "/:rol/issuers/places/new",
    element: <ExpeditionPlaceForm />,
  },
  {
    path: "/:rol/invoices",
    element: <InvoicesIndex />,
  },
  {
    path: "/:rol/invoices/dashboard",
    element: <InvoiceDashboard />,
  },
  {
    path: "/:rol/invoices/new",
    element: <InvoiceGeneralData />,
  },
  {
    path: "/:rol/invoices/new/steps",
    element: <InvoiceSteps />,
  },
  {
    path: "/:rol/empleados/nominaIndividual",
    element: <NominaSingleEmployee />,
  },
  {
    path:"/:rol/empleados/",
    element:<EmployeesIndex/>
  },
  {
    path:"/:rol/empleados/crear",
    element:<UploadEmployees/>
  },
  {
    path:"/:rol/empleados/crear/nuevo",
    element:<EmployeeRegistrationForm/>
  },
  {
    path:"/:rol/empleados/actualizar",
    element:<EmployeeRegistrationForm/>
  },
  {
    path: "/:rol/empleados/nominaIndex",
    element: <EmployeesNominaIndex />,
  },
  {
    path: "/:rol/invoices/nomina",
    element: <NominaLibre />,
  },
  {
    path: "/:rol/invoices/consultIssuers",
    element: <ConsultIssuers />,
  },
  {
    path: "/:rol/invoices/selectIssuer",
    element: <SelectIssuer />,
  },
  {
    path: "/:rol/invoices/NuevoEmisor",
    element: <IssuerData />,
  },
  {
    path: "/:rol/invoices/ActualizarCSD",
    element: <UpdateCsds />,
  },
  {
    path: "/:rol/consultInvoices",
    element: <ConsultInvoiceIndex />,
  },
  {
    path: "/:rol/customers",
    element: <CustomersIndex />,
  },
  {
    path: "/:rol/customers/new",
    element: <CreateCustomer />,
  },

  {
    path: "/:rol/customers/update",
    element: <CreateCustomer />,
  },
  {
    path: "/:rol/serviceIndex",
    element: <AdminServiceIndex />,
  },
  {
    path: "/:rol/services/new",
    element: <NewService />,
  },
  {
    path: "/:rol/destinations",
    element: <DestinationsIndex />,
  },
  {
    path: "/:rol/destinations/new",
    element: <CreateDestination />,
  },
  {
    path: "/:rol/destinations/update",
    element: <CreateDestination />,
  },
  {
    path: "/:rol/signup",
    element: <FirstStepsForm />,
  },
  {
    path: "/:rol/transports/newTruck",
    element: <AdminRegisterTransports />,
  },
  {
    path: "/:rol/transports/newTow",
    element: <AdminRegisterRemolques />,
  },
  {
    path: "/:rol/transports/updateTruck",
    element: <AdminRegisterTransports />,
  },
  {
    path: "/:rol/transports/updateTow",
    element: <AdminRegisterRemolques />,
  },
  {
    path: "/:rol/transports",
    element: <TransportsIndex />,
  },
  {
    path: "/register",
    element: <RegisterIndex />,
  },
];

export const publicRoutes = [
  {
    path: "/signup",
    element: <FirstStepsForm />,
  },
];
