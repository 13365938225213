import axios from "axios"

export const getFiscalRegimes = () => {
    return new Promise((resolve, reject) => {
        let credentials;

        if(process.env.NODE_ENV === "production"){
            credentials = {
                "url": process.env.REACT_APP_FACTURAMA_API,
                "token": process.env.REACT_APP_FACTURAMA_TOKEN
            }
        }else{
            credentials = {
                "url": process.env.REACT_APP_FACTURAMA_API_TEST,
                "token": process.env.REACT_APP_FACTURAMA_TOKEN_TEST
            }
        }
        
        axios.get(`${credentials.url}catalogs/FiscalRegimens`,{
            headers:{
                Authorization:`Basic ${credentials.token}`
            }
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
    
}